import { combineReducers } from 'redux'
import {Login,getToken} from './login'
import {formType} from './register'
import {blogList} from './home'
import {loading} from './loader'

export default combineReducers({
	Login,
	blogList,
	formType,
	getToken,
	loading,
});
