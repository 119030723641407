import React from 'react';
import {Link} from 'react-router-dom';
import RegisterForm from './form';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Helmet } from "react-helmet";

var config = require('../../react-config.js');
const api_url = config.api_url;
export default class Register extends React.Component {

    constructor(props){
        super(props);        

        let token = localStorage.getItem('token');
        if(token !== null)
        {
            this.props.history.push('/');
        }

        this.state = { 
            first_name:'',
            last_name:'',
            email:'',
            organization:'',
            uuid:'',
            saveType:'',
        }


    }

    componentDidMount() {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const uuid = params.get('uuid'); 
        const saveType = params.get('type'); 
        if(uuid !== null && uuid !== undefined)
        {
            
            //console.log('uuid-new', uuid)
            if(saveType !== null && saveType !== undefined)
            {
                if(saveType === 'researcher')
                {
                    this.props.onHandleAccountType('Researcher')
                    this.setResearcher(uuid)
                    //this.setState({'saveType':'researcher'})

                }
                else if(saveType === 'invitations')
                {
                    //set invitations by email
                    this.setInvitations(uuid);
                    //this.setState({'saveType':'invitations'})

                }

            }
        }

      
    }

    setResearcher = async (uuid) =>{

            if(uuid){
        
            let _this = this;
            let url = '';
            let data ={'uuid':uuid};            
            url = api_url + 'user/researcher/details'

            await axios.post(url,data,
                {
                    //headers: { "Authorization": "Token " + token },
                }).then((res)=>{
                    
                    if(res && res.data && res.data.records){

                        _this.setState({ 
                            first_name:(res.data.records.first_name)?res.data.records.first_name:'',
                            last_name:(res.data.records.last_name)?res.data.records.last_name:'',
                            email:(res.data.records.email)?res.data.records.email:'',
                            organization:(res.data.records.organization)?res.data.records.organization:'',
                            uuid:uuid,
                            saveType:'researcher',
                            
                        })
                        
                        
                    }

                   

                
                }).catch((error)=>{
                    
                })

            }
        
    } 

    setInvitations = async (uuid) =>{

            if(uuid){
        
            let _this = this;
            let url = '';
            let data ={'uuid':uuid};            
            url = api_url + 'user/invitations/details'

            await axios.post(url,data,
                {
                    //headers: { "Authorization": "Token " + token },
                }).then((res)=>{
                    
                    if(res && res.data && res.data.records){
                        console.log('res.data.records')
                        console.log(res.data.records.email)

                        _this.setState({ 
                            email:(res.data.records.email)?res.data.records.email:'',                            
                            uuid:uuid,
                            saveType:'invitations',
                         })
                        
                        
                    }

                   

                
                }).catch((error)=>{
                    
                })

            }
        
    } 

    


    handleBack = () => {
        this.props.onHandleAccountType(false)
    }

render() {
    // console.log("props inside register indexjs",this.props)
    const  formType = this.props.accountType;
    return(
        <>
         <Helmet>
				<title>Register | AISquare.org</title>
				<meta name="description" content="Register - AISquare.org"/>
		</Helmet>
        {
            formType ? (
                (formType === 'Researcher') ? (
                    <RegisterForm
                        back={this.handleBack}
                        btnText="Apply for Researcher Account"
                        title="Create Research Account"
                        type={formType}
                        handleSubmitRegister = {this.props.handleSubmitRegister}
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        email={this.state.email}
                        organization={this.state.organization}
                        uuid={this.state.uuid}
                        saveType={this.state.saveType}
                        onSetToken={ this.props.onSetToken}
                    />
                ) :
                (formType === 'Guest') ? (
                    <RegisterForm
                        back={this.handleBack}
                        btnText="Join As Curious Minds Account"
                        title="Create Curious Minds Account"
                        type={formType}
                        handleSubmitRegister = {this.props.handleSubmitRegister}
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        email={this.state.email}
                        organization={""}
                        uuid={this.state.uuid}
                        saveType={this.state.saveType}
                        onSetToken={() =>this.props.onSetToken()}                       
                    />
                ) : (
                    <RegisterForm
                        back={this.handleBack}
                        btnText="Create Correspondent Account"
                        title="Create Correspondent Account"
                        type={formType}
                        handleSubmitRegister = {this.props.handleSubmitRegister}
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        email={this.state.email}
                        organization={this.state.organization}
                        uuid={this.state.uuid}
                        saveType={this.state.saveType}
                        onSetToken={() =>this.props.onSetToken()}                       
                    />
                )                                        
            ) : (
                <div className="page-full page-login-full flex items-center">
                    <Link to={'/login'} className="back-action"><i className="icon-back"></i></Link>
                    <div className="page-center">
                        <div className="page-body">
                            <div className="form-box">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="heading-box text-center"><div className="heading">Account Type</div></div>
                                        <div className="form-main">
                                            <div className="row btn-group">
                                                <div className="col"><span className="btn btn-primary btn-full" onClick={() => this.props.onHandleAccountType('Researcher')}>Researcher</span></div>
                                                <div className="col"><span className="btn btn-outline btn-full" onClick={() => this.props.onHandleAccountType('Correspondent')}>Correspondent</span></div>
                                                <div className="col"><span className="btn btn-outline btn-full" onClick={() => this.props.onHandleAccountType('Guest')}>Curious Mind</span></div>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        </>
    )
}
}

Register.propTypes = {
    accountType: PropTypes.string,
    handleSubmitRegister: PropTypes.func,
    onHandleAccountType: PropTypes.func,
};