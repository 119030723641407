import React, {Component} from 'react'

class RelatedPostSkeleton extends Component {
    render(){
        return(
            <div className="post-item flex skeleton-main">
                <div className="post-img-box skeleton"></div>
                <div className="post-info-box flex-auto">
                    <div className="title"><h5 className="link skeleton">&nbsp;</h5></div>
                    <div className="date skeleton"></div>
                    <div className="post-meta user-info">
                        <span className="user-meta skeleton">&nbsp;</span>
                        <span className="user-meta skeleton">&nbsp;</span>
                        <span className="user-meta skeleton">&nbsp;</span>
                    </div>
                </div>
            </div>
        )
    }
}
export default RelatedPostSkeleton