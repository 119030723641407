import React from 'react';
import renderHTML from 'react-render-html';
import moment from 'moment';
import PropTypes from 'prop-types';
import BlogCommentList from './../Home/blog-comment-list';
import AddBlogComment from './../Home/add-blog-comment';
import { Helmet } from "react-helmet";
import { checkLogin, get_user_data, limitWord } from '../../utils.js';
import SharePost from '../Home/share';
import { Link } from 'react-router-dom'
import Tag from '../Home/tag';
import RelatedPost from './relatedPost';
import WishList from '../Home/wishList';

import axios from 'axios';
import DetailSkeleton from '../Skeletons/Detail'

const user_default_img2 = require('./../../Assets/img/user-default.jpg')

var config = require('../../react-config.js');
const api_url = config.api_url;
const base_url = config.base_url;

const token = localStorage.getItem('token');
const default_img = require('./../../Assets/img/img-default.jpg')
//const default_img = require('./../../Assets/img/img.jpg')
const user_default_img = require('../../Assets/img/user-img2.jpg')
const researcher_default_img = require('./../../Assets/img/user-default.jpg')

export default class Details extends React.Component {
	constructor(props) {
		super(props);

		let isLoggedIn = checkLogin();
		let user_data = get_user_data();

		const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const edit = (params.get('edit'))?params.get('edit'):'';

		this.state = {
			user_data: (user_data) ? user_data : '',
            logged_user_groups_id: (user_data) ? user_data.groups_id : '',
			comments: [],
			page: 1,
			limit: 11,
			item: {},
			edit: edit,
			post_uuid: '',
			wishlist: '',
			isLoggedIn: isLoggedIn,
			totalComments: '',
			relatedPostData: [],
			loader: true
		};
	}

	componentDidMount = () => {
		const { comments, totalComments, item } = this.props;
		this.props.getComments(item.uuid);
		if (item.slug) {
			this.getItem(item.slug);
		}
		this.setState({
			comments: comments,
			totalComments: totalComments,
		});
		//window.scrollTo(0, 0);
		const { location } = this.props;

		const params = new URLSearchParams(location.search);
        const edit = params.get('edit');
        this.setState({edit:true})
	}
	getItem = async (slug) => {
		const url = api_url + 'post/details';
		await axios.get(url,
            {
                params: {slug:slug, edit:this.state.edit},
                headers: token ? { "Authorization": "Token " + token } : {},
            })
			.then((res) => {
				//console.log(res.data.records);
				this.setState({
					item: res.data.records,
					loader: false
				});
			});
	}

	sendToLogin = () => {
		this.props.history.push('/login');
        
    }

	componentWillReceiveProps(nextProps) {

		// const params = new URLSearchParams(this.props.location.search);
        // const params_next = new URLSearchParams(nextProps.location.search);
        // const editText = params_next.get('edit') ?params_next.get('edit'):'';
        // if(params !== params_next){
        //     this.setState({edit:editText})
        //     // this.getItem(nextProps.slug)
		// }

		const comments = nextProps.comments;
		const totalComments = nextProps.totalComments;
		if (nextProps.comments !== this.props.comments || nextProps.totalComments !== this.props.totalComments) {
			this.setState({
				comments: comments,
				totalComments: totalComments
			});
		}


	}
	handleScrollTo = (e) => {
		let comtSection = document.getElementById('commentSection')
		let detailPopup = document.getElementById('articleDetailPopup')
		if(!detailPopup){
			window.scrollTo(0,comtSection.offsetTop)
		} else {
			detailPopup.scrollTo(0,comtSection.offsetTop)
		}		
	}
	handleStreamClick = (stream) => {
       
        this.props.history.push({
            pathname: '/search',
            search: "?stream=" + stream.name,
        })
    }
	render() {

		const { item, totalComments, comments, post_uuid, isLoggedIn, logged_user_groups_id, loader } = this.state;

		let groups_id = ''
		if(item.groups_id && item.groups_id!=='')
		{
			groups_id = item.groups_id

		}

		let researchers_name = '';
        if(item && item.researcher)
        {
            researchers_name = item.researcher.map(researcher => {            		
                    const name = ' '+researcher.name
                    return name;
                })
            researchers_name = (researchers_name)?'| By '+ researchers_name:'';
        }

        const meta_title = (item)?item.title+' '+researchers_name+' | '+moment(item.date_added).format('MMM, YYYY')+' | AISquare':'AISquare';
        const meta_description = (item)?item.brief:'AISquare';

		return (
			<div className="detail-full-container">
				{loader ? <DetailSkeleton /> :
					<div className="detail-page-full flex">
					<Helmet>
							{/* <MetaTags> */}
							<title> { meta_title }</title>
							<meta name="description" content={limitWord(meta_description, 200)}/>
							<link rel="canonical" href={base_url+"details/"+item.slug} />
							<meta id="og-url" property="og:url" content={base_url+"details/"+item.slug} />
							<meta id="og-title" property="og:title" content={item.title} />
							<meta id="og-description" name="description" property="og:description" content={meta_description} />
							<meta id="og-image" property="og:image" content={item.post_image} />
							<meta property="og:image:secure_url" content={item.post_image}></meta>
						{/* <link rel="canonical" href={base_url+"details/"+item.slug} /> */}
						{/* </MetaTags> */}
					</Helmet>
						<div className="detail-container-main flex-auto">
							<div className="detail-title-box flex">
								<div className="post-thumb-img img-box"><img src={(item.post_image) ? item.post_image : default_img} alt={(item.post_image) ? item.title : 'Article Image'} /></div>
								<div className="detail-title-info flex-auto">
									<div className="detail-title flex-auto"><h1 className="heading2">{item.title}</h1></div>
									<div className="detail-meta-outer flex">
										<div className="detail-meta">
											<div className="list-meta">
												<WishList handleWishlist={this.props.handleWishlist} item={item} isLoggedIn={isLoggedIn} setToWishList={this.props.setToWishList} history={this.props.history}></WishList>
												<span onClick={this.handleScrollTo} className="meta-item action-comments count-box"><i className="icon-comments"></i>{totalComments ? <span className="count" id='total_comments'>{totalComments}</span>:''}</span>
												<SharePost handlePin={this.props.handlePin} item={item}  ></SharePost>
											</div>

										</div>
										<div className="detail-date detail-user flex">
											<div className="meta-item"><i className="icon-date"></i></div>
											<div className="user-info">
												<div className="user-posted-by">Posted date: </div>
												<div className="username">  {moment(item.date_added).format('DD MMM, YYYY')}</div>
											</div>
										</div>
										{(item.research_date ?<div className="detail-date detail-user research-date-detail flex">
											<div className="meta-item"><i className="icon-date"></i></div>
											<div className="user-info">
												<div className="user-posted-by">Research date: </div>
												<div className="username">  {moment(item.research_date).format('DD MMM, YYYY')}</div>
											</div>
										</div>:'')}
									</div>
								</div>
							</div>
							<div className="detail-blog-data">

								{renderHTML(item.details.replace('\r\n', '<br/><br/>'))}
								
								<div className="detail-tags">

									<div className="posted-by-meta">
                                    { 
                                        item.researcher.length  ? (
                                    <span className="posted-by-users-outer">
                                        <span className="posted-by-users researcher-list-outer">
                                        {                                       
                                            item.researcher.map((researcher, idx) => {
                                            	let res_title = researcher.name;
                                                
                                                //  if(researcher.researcher_data && researcher.researcher_data.organization)
                                                // {
                                                //     res_title = res_title + " ("+researcher.researcher_data.organization+")";
                                                // }
                                                
                                                return (
                                                    <div className="researcher-list-item" key={idx}>

                                                    {researcher.researcher_data && researcher.researcher_data.user_slug ? (
                                                        <Link to={'/p/' + researcher.researcher_data.user_slug}>
                                                        <span className="posted-by-user flex"><span className="user-thumb" title={res_title}><img src={researcher_default_img} alt="User Icon"/> </span> <span className="user-name">{res_title} <span className="researcher-org">{researcher.researcher_data.organization}</span></span> </span> 
                                                        </Link>
                                                        ) : 
                                                        <span className="posted-by-user flex"><span className="user-thumb" title={res_title}><img src={researcher_default_img} alt="User Icon"/> </span> <span className="user-name">{res_title} <span className="researcher-org">{researcher.researcher_data.organization}</span> </span> </span>
                                                        
															
                                                         
                                                    }



                                                    </div> 
                                                    )
                                            })

                                        }
                                            
                                        </span>     
                                    </span> 

                                    ) : ('')
                                }   
                                </div>
									{/*}
									<div className="tags-outer">
										<div className="posted-by-users-outer">
											<span className="posted-by-users flex">
												{
													item.researcher.map((researcher, idx) => {
														return (
															<span className="posted-by-user flex items-center">
																<span className="user-thumb"><img src={user_default_img2} /> </span>
																<span className="user-name">{researcher.user_slug ? <Link to={'/p/' + researcher.user_slug}>{researcher.name}</Link> : researcher.name}{(item.researcher.length - 1) > idx ? " " : ''}</span>
															</span>
														)
													})
												}
											</span>
										</div>
										
									</div>
									{*/}

                            <div className="stream-tag-box">
                                    {
                                        item.streams.length ? (
                                            <div className="stream-labels">
                                            {                                       
                                                item.streams.map((stream, idx) => {
                                                    return (
                                                    <>
                                                    <span className="label stream-label link" onClick={()=>this.handleStreamClick(stream)} >{stream.name}</span>
                                                    </> 
                                                    )
                                                })
                                            }
                                            </div>
                                        ) : ('')
                                    }
                                
                                    <Tag post={item} location={this.props.location} history={this.props.history} onSetBlogList={this.props.onSetBlogList}></Tag>
                                </div>
									 {/* {
                                        item.streams.length ? (
                                            <div className="stream-labels">
                                            {                                       
                                                item.streams.map((stream, idx) => {
                                                    return (
                                                    <>
                                                    <span className="label stream-label link" onClick={()=>this.handleStreamClick(stream)} >{stream.name}</span>
                                                    </> 
                                                    )
                                                })
                                            }
                                            </div>
                                        ) : ('')
                                    }
									<Tag post={item} location={this.props.location} history={this.props.history} onSetBlogList={this.props.onSetBlogList}></Tag> */}
								</div>
							</div>
							<div className="author-small-view">
								<div className="author-info-box sidebar-group">
									<div className="sidebar-title">Posted by</div>
									<div className="detail-user flex items-center">
										<div className="thumb"><img src={item.user_profile_image ? item.user_profile_image : user_default_img} alt={(item.user_profile_image) ? item.user_name : 'User Image'} /></div>
										<div className="user-info flex-auto">
											<div className="username">
												<Link to={'/p/' + item.user_slug}>{item.user_name}</Link>												
											</div>
											<div className="blog-meta user-info">
												<span className="user-meta"><span className="meta-count">{item.user_total_post ? item.user_total_post : 0}</span> Posts</span>
												<span className="user-meta"><span className="meta-count">{item.user_total_follower ? item.user_total_follower : 0}</span> Followers</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="detail-comments-full" id="commentSection">
								<div className="comment-full-panel">
									{(this.state.isLoggedIn  && logged_user_groups_id != 6) ? (
										<AddBlogComment blog_uuid={item.uuid} handleCommentAdd={this.props.handleCommentAdd} updateCommentsList={this.props.updateCommentsList} />
									) : null}
									<div className="comment-listing-outer detail-comment-listing">
										<div className="comment-title-box">
											<div className="heading2 flex items-center be-the-first">
											{
												(totalComments) ? (
													<>
													{totalComments} Comments
													</>

													):
												<>
												<span className="flex-auto">Be the first to comment on this. </span>
												{
													(!isLoggedIn) ? (


														<div className="btn btn-sm btn-write-comment" onClick={() => this.sendToLogin()}>Login</div>

														):''

													}
													 </>

											}

											</div>
										</div>
										<ul className="comment-listing-box">
											{(comments) ? (
												<>
													{comments[item.uuid] ? comments[item.uuid].map((comment, index) => {
														return (
															<li key={index}>
																<BlogCommentList comment={comment} blog_uuid={item.uuid} handleCommentAdd={this.props.handleCommentAdd} parent_uuid={comment.uuid} />
															</li>
														)
													}) : null
													}
												</>
											) : null}
										</ul>
									</div>
								</div>
							</div>

						</div>
						<div className="detail-sidebar">
							<div className="author-info-box sidebar-group hide-small-view">
								<div className="sidebar-title">Posted by</div>
								<div className="detail-user flex items-center">
									<div className="thumb"><img src={item.profile_image_thumb ? item.profile_image_thumb : user_default_img} alt={item.profile_image_thumb ? item.user_name : 'User Image'} /></div>
									<div className="user-info flex-auto">
										<div className="username">
											<Link to={'/p/' + item.user_slug}>{item.user_name}</Link>
											<span>&nbsp;</span>
											<div className="profile-type-box">
												{ 
													groups_id && groups_id===5 ?
													<span className="profile-type-label" title="Correspondent">C</span>
													:
													groups_id && groups_id===4 ?
													<span className="profile-type-label" title="Researcher">R</span>
													:
													groups_id && groups_id===6 ?
													<span className="profile-type-label" title="Curious Mind">C</span>
													:''
												}												
											</div>
										</div>
										<div className="blog-meta user-info">
											<span className="user-meta"><Link to={'/p/' + item.user_slug}>
											 <span className="meta-count">{item.user_total_post ? item.user_total_post : ''}</span> {item.user_total_post ?"Posts" : ('')} </Link></span>
											<span className="user-meta"><span className="meta-count">{item.user_total_follower ? item.user_total_follower : ''}</span> {item.user_total_follower ?"Followers" :''}</span>
										</div>
									</div>
								</div>
							</div>
							<RelatedPost post={item} history={this.props.history}></RelatedPost>
						</div>
					</div>
				}</div>
		)
	}
}

Details.propTypes = {
	handleWishlist: PropTypes.func.isRequired,
	item: PropTypes.object.isRequired,
	handlePin: PropTypes.func.isRequired,
	getComments: PropTypes.func.isRequired,
	handleCommentAdd: PropTypes.func.isRequired,
	comments: PropTypes.array.isRequired
};