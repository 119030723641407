import React from 'react';
import { List } from 'immutable'
//import { TagBox } from 'react-tag-box'
import CreatableSelect from 'react-select/creatable';

export default class TagBoxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.list,
            placeholder: props.placeholder,
            //selectedTags: props.list.take(0),
            selectedTags: [],
           // selectedTags: List.of(props.selectedTags.first()),
            //selectedTags: (props.selectedTags)?List.of(props.selectedTags):props.list.take(0),
            
            // reply_error: '',
            // reply_success: '',
            // parent_uuid:props.parent_uuid
        };
        //console.log('constructor props.selectedTags=>>',props.selectedTags)
        
    }

    /*componentDidMount() {

        if (this.props.selectedTags && this.props.selectedTags.length > 0) {
            this.props.selectedTags.map(t => {
                   this.handleTag(t)
                });

            
        }

        
    }*/

    handleTag = selectedTags => {
        /*var selectedTags = this.state.selectedTags
        const newTag = {
            label: tag.label,
            value: tag.value || tag.label
        }

        selectedTags = selectedTags.push(newTag)*/
        this.setState({
            selectedTags: selectedTags
        })
        //console.log('handleTag', selectedTags)

        this.props.handleChange(selectedTags);


    };

    /*removeTag = tag => {
        var selectedTags = this.state.selectedTags
        this.setState({
            selectedTags: selectedTags.filter(t => t.value !== tag.value)
        })

        this.props.handleChange(selectedTags);
    }*/

    componentWillReceiveProps(nextProps) {
        //console.log('nextProps', nextProps)
        /*if (nextProps.list) {
            this.setState({
                list: nextProps.list
            })

        }*/
        //console.log('nextProps',nextProps)

        if(nextProps.selectedTags)
        {
            //var selectedTags = this.state.selectedTags

           /* nextProps.selectedTags.map(obj => {
                const newTag = {
                    label: obj.label,
                    value: obj.value || obj.label
                }
                        selectedTags.push(newTag)
                        console.log('obj', obj)
                    })*/
            this.setState({selectedTags:nextProps.selectedTags})
            //this.props.handleChange(selectedTags);

        }

    }
    render() {
        const {selectedTags,selectedTags1,placeholder}=this.state;
        const list = (this.props.list && this.props.list!==undefined)?this.props.list:[]
        //console.log('list - 2',list);
        //console.log('selectedTags=', selectedTags);
        //console.log('selectedTags1', selectedTags);
        // const {title,brief,details,slug,post_image,uuid} = item;
        return (

            <CreatableSelect
                isMulti
                onChange={this.handleTag}
                options={list}
                value={selectedTags}
                placeholder={placeholder}
              />

            
        );
    }
}
