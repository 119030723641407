import {
    BLOG_LIST,STREAM_LIST,TAG_LIST
} from './../actions';

export const blogList = (state = [], action) => {
    switch(action.type){
        case BLOG_LIST:
            return action.value
        default:
            return state
    }
}

export const streamList = (state = [], action) => {
    switch(action.type){
        case STREAM_LIST:
            return action.value
        default:
            return state
    }
}

export const tagList = (state = [], action) => {
    switch(action.type){
        case TAG_LIST:
            return action.value
        default:
            return state
    }
}