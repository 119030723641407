import React from 'react';
import firebase from "../../firebase";
import moment from 'moment';
import renderHTML from 'react-render-html';
var config = require('../../react-config.js');
var userDefault = require('../../Assets/img/user-default.jpg')
var db = firebase.firestore();

export default class Room extends React.Component {
    constructor(props) {
        super(props);
        // console.log('props.room')
        // console.log(props.room)
        this.state = {
            room: props.room,
            userRef: props.userRef,            
            activate_room: {},
            search : props.search,
            loadAgain : props.loadAgain
        };
    }
    loadChat = () =>{
        var params = {
            room:this.state.room
        }
        this.props.loadChat(params)
        // console.log();
    }

    componentDidMount = () => {
        this.roomInit(this.state.room)        
    }

    roomInit = (room, loadRoom=false) => {
        var _this1 = this

        if(room.room_id)
        {
            var user_uid = (room.sender_uid === _this1.state.userRef.uid) ? room.user_uid: room.sender_uid;
            // console.log('roomInit.user_uid')
            // console.log(user_uid)
            var senderRef = {}
            if(user_uid)
            {
                db.collection("users").doc(user_uid).get().then((doc) => {
                    // console.log('doc')
                    // console.log(doc.data())
                    let senderRef = doc.data();
                    if(senderRef)
                    {
                        // console.log('senderRef');
                        // console.log(_this1.state.loadAgain);
                        senderRef['avatar'] = config.BACKEND_URL+senderRef.avatar
                        room['senderRef'] = senderRef
                        _this1.setState({room});
                        //(_this1.props.room_key === 0 && _this1.props.t_username === '')
                        if((_this1.state.loadAgain && _this1.props.room_key === 0 && _this1.props.t_username === '') || loadRoom === true )
                        {   
                            // console.log('Loading chat...');
                           
                            var params = {
                                room:room
                            }
                            _this1.props.loadChat(params);
                        }

                        db.collection("users").doc(user_uid)
                        .onSnapshot(function(doc) {
                            // console.log("Users onSnapshot.Current data: ", doc.data());
                            if(doc.data() && _this1.state.room.senderRef && doc.data().online !== _this1.state.room.senderRef.online)
                            {
                                senderRef = doc.data();
                                senderRef['avatar'] = config.BACKEND_URL+senderRef.avatar;
                                room['senderRef'] = senderRef;
                                _this1.setState({room});

                                room['senderRefUpdated'] = senderRef
                                _this1.props.roomGotMessage(room);

                               
                            }
                        });
                    }            
                });

                db.collection("rooms").doc(room.room_id)
                .onSnapshot(function(doc) {
                    // console.log("Rooms onSnapshot.Current data: ", doc.data());
                    if(doc && doc.data() && doc.data().timestamp)
                    {
                        const room = doc.data()
                        room['room_id'] = doc.id
                        room['senderRef'] = _this1.state.room.senderRef;
                        // console.log('onSnapshot.room')
                        // console.log(room)
                        // console.log(doc.data().timestamp.seconds)
                        // console.log(_this1.state.room.timestamp.seconds)
                        if(doc.data().timestamp && doc.data().timestamp.seconds !== _this1.state.room.timestamp.seconds)
                        {
                            room.timestamp = doc.data().timestamp
                            _this1.setState({room:room});
                            _this1.props.roomGotMessage(room)
                        }
                    }
                });
            }  
        }
       
    }

    componentWillReceiveProps = (nextProps) => {
        /*if(nextProps.room !== this.state.room)
        {
            // this.setState({room:nextProps.room});
        }
        if(nextProps.activate_room && nextProps.activate_room.room_id && nextProps.activate_room.room_id !== this.state.activate_room.room_id)
        {
            // console.log('nextProps.activate_room')
            // console.log(nextProps.activate_room)
            // this.setState({room:nextProps.activate_room,activate_room:nextProps.activate_room})
            // this.roomInit(nextProps.activate_room, true)
            // this.setState({activate_room:nextProps.activate_room});
            // var params = {
            //     room:this.state.room,
            //     senderRef:this.state.senderRef,
            // }
            // this.props.loadChat(params)            

            if(this.state.activate_room && nextProps.activate_room && this.state.activate_room.sender_user_ids !== nextProps.activate_room.sender_user_ids)
            {
                this.setState({room:nextProps.activate_room,activate_room:nextProps.activate_room})
                this.roomInit(nextProps.activate_room, true)
            }
        }*/
       // console.log('nextProps.activate_room',this.state.search)
           
        if(nextProps.activate_room.room_id === this.state.room.room_id)
        {
            // alert(nextProps.activate_room.room_id);
              
            //  console.log(nextProps.activeRoom)
            if(nextProps.activeRoom && nextProps.activeRoom.length > 0)
            {
                // console.log('nextProps.activeRoom')
                // console.log(nextProps.activeRoom)
            }
            else
            {
                // console.log('nextProps.activate_room')
                if(nextProps.activate_room !== this.state.activate_room)
                {
                    // console.log('nextProps.roomInit')
                    this.setState({activate_room:nextProps.activate_room})
                    this.roomInit(nextProps.activate_room,true);
                }
            }
        }


    }

    isUserOnline = user => user.online === true;

    render() {
        const {room,search} = this.state;
        // const {title,brief,details,slug,post_image,uuid} = item;
        // console.log('render.room')
      //  console.log("room",room)
        const last_text = (room.last_text && room.last_text.length > 40) ? room.last_text.substring(0,37)+'...' : room.last_text;
        var unread_count = 0;
        if(this.props.activeRoom && room.unread_count && this.props.activeRoom.room_id !== room.room_id && room.last_uuid !== this.state.userRef.uuid)
        {
            unread_count = room.unread_count;
        }
        return (
            <li onClick={this.loadChat}>
                {(room && room.senderRef )?(
                <div className="contact-item" >
                    <div className="contact-item-left">
                        <div className="thumb user-thumb">
                            <img src={room.senderRef.avatar} />
                            <span className={this.isUserOnline(room.senderRef) ? "user-status online" : "user-status"}></span>
                        </div>
                    </div>                                                          
                    <div className="contact-info">
                        <div className="user-info">
                            <div className="user-name-date flex">
                                <div className="username flex-auto">{room.senderRef.name} {(unread_count > 0) ? '('+unread_count+')' : ''}</div>
                                <div className="date">{(room.new) ? 'now' : ((room.timestamp) ? moment(room.timestamp.toDate()).format('h:mm A') : '')}</div>
                            </div>
                        </div>
                        <div className="contact-message-text">{(room.last_uuid === this.state.userRef.uuid)?'You:':''} {renderHTML(last_text)}</div>
                    </div>
                </div>
                ):null}
            </li>
        );
    }
}
