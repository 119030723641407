import React from 'react';
import axios from 'axios';
import Button from '../Button'
import PasswordInput from "react-password-indicator";
import Alert from '../Alert';
var config = require('../../react-config.js');
const api_url = config.api_url;

// const token = localStorage.getItem('token');

const errorMessages = {
    minLen: "Password is not long enough!",
    maxLen: val => `Password exceeded the maximum length of ${val}`,
  };
export default class FormScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form_state: props.form_state,
            errors: props.errors,
            username: props.username,
            submit_message: '',
            otp: props.otp,
            reset:props.reset,
            css_class: "form-style",
            type:"text",
            confirm_password:props.confirm_password,
            visible: false, 
            validation: {},
            hidden : true,
            hidden1:true,
            alert: false,
            alert_msg: '',
            alert_type: '',
        };
        this.togglePasswod = this.togglePasswod.bind(this);
    }
    togglePasswod=(id)=> {
        if(id === 1){
            this.setState({ hidden: !this.state.hidden });
        }else if(id === 2){
            this.setState({ hidden1: !this.state.hidden1 });
        }
        
     }
    handleResendOtp = async () =>{
        const {username} = this.state;
        this.setState({alert: false,alert_msg: '',alert_type: '',})
        const url = api_url + 'user/resend_otp';
        await axios.post(url, {email:username,otp_type:1},
            {
                //  header section
            }).then((res) => {
                if (res && res.data && res.data.status === "success") {
                    //alert("Resend OTP Successfully!")
                    this.setState({  alert: true, alert_msg: "OTP Sent Successfully", alert_type: 'success' })
                }
            }).catch((error) => {
                // 
            })
    }

   
    componentWillReceiveProps = (nextProps) =>{
        if(nextProps.username !== this.props.username){
            this.setState({username:nextProps.username})
        }
        if(nextProps.form_state !== this.props.form_state){
            this.setState({form_state:nextProps.form_state})
        }
        if(nextProps.otp !== this.props.otp){
            this.setState({otp:nextProps.otp})
        }
        if(nextProps.errors !== this.props.errors){
            this.setState({errors:nextProps.errors})
        }
       
        if(nextProps.reset !== this.props.reset){
            this.setState({reset:nextProps.reset,type:"password"})
        }
        if(nextProps.confirm_password !== this.props.confirm_password){
            this.setState({confirm_password:nextProps.confirm_password})
        }
    }
    handleChangePassword = (reset,validation) => {
      //  let value = event.target.value;
       
        this.setState({reset, validation })
        this.props.handleChangePassword(reset,validation)
        //this.validate_form(value);
    }

    render() {
        // console.log("props inside form",this.props)
        const { form_state, username, css_class, errors,otp,reset,type,confirm_password,alert } = this.state;
    //    console.log("form state",form_state)
    //console.log('this.props.otpSendSuccessfullyMessageFlag', this.props.otpSendSuccessfullyMessageFlag)
    //console.log('this.props.otpSendSuccessfullyMessage', this.props.otpSendSuccessfullyMessage)
    //console.log('this.props.alertType',this.props.alertType)
        return (
            <>
                <div className="heading-box text-center">
                    <div className="heading">{form_state === "forgot" ? "Forgot Password" : (form_state==="otp" ? "Verify OTP" : "Reset Password")}</div>
                </div>
                <div className={this.props.processing ? "form-main form-processing" : "form-main"}>
                    <form onSubmit={this.props.handleSubmit}>
                        <div className="form-inner">
                            <div className="form-item">
                            {form_state === "reset"
                               ?
                               ( <PasswordInput
                                minLen={8} 
                                digits={1} 
                                specialChars={1} 
                                uppercaseChars={1} 
                                lowerCaseLetters = {1}
                                defaultMessages={errorMessages}
                                isVisible={this.state.visible}
                                // onChange={(reset, validation) =>
                                //    this.setState({reset, validation },console.log(reset))
                                //  }
                                 onChange={this.handleChangePassword}> 
                                {({
                                   getInputProps,valid,touched}) => (
                                        <div className="form-password-item">
                                           <input
                                                {...getInputProps()}
                                                 value={(form_state==="reset" ? reset : '')}
                                                 className={touched ? (valid ? "valid" : "invalid") : ""}
                                                 autoComplete="off"
                                                 className="form-style"
                                                 name="username"
                                                 placeholder="Enter New Password"
                                                 type={this.state.hidden ? 'password' : 'text'}
                                                 data-success=''/>
                                                 <span className={this.state.hidden? 'show pwd-show-hide' :'hide pwd-show-hide'} onClick={() =>this.togglePasswod(1)}><svg id="Capa_1" enable-background="new 0 0 551.121 551.121" height="512" viewBox="0 0 551.121 551.121" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m275.561 68.887c-123.167 0-233.534 80.816-274.64 201.107-1.228 3.616-1.228 7.518 0 11.134 41.106 120.291 151.473 201.107 274.64 201.107s233.534-80.816 274.64-201.107c1.228-3.616 1.228-7.518 0-11.134-41.107-120.291-151.474-201.107-274.64-201.107zm0 378.902c-106.532 0-202.284-68.975-240.077-172.228 37.793-103.253 133.544-172.228 240.077-172.228 106.532 0 202.284 68.975 240.077 172.228-37.793 103.253-133.545 172.228-240.077 172.228z"></path><path d="m275.561 172.224c-56.983 0-103.337 46.354-103.337 103.337s46.354 103.337 103.337 103.337 103.337-46.353 103.337-103.337-46.354-103.337-103.337-103.337zm0 172.228c-37.995 0-68.891-30.897-68.891-68.891 0-37.995 30.897-68.891 68.891-68.891 37.995 0 68.891 30.897 68.891 68.891s-30.897 68.891-68.891 68.891z"></path></svg></span>                     
                                                    <span className='form-error'>{
                                                        ( this.state.errors && this.state.errors.new_password
                                                         ) ? this.state.errors.new_password: ''
                                                    }</span>
                                                
                                            </div>
                                     )}
                            </PasswordInput>) :
                             (<input
                             type={type}
                             className={css_class}
                             name="username"
                             placeholder={form_state === "forgot" ? "Username or Email address" : (form_state==="otp" ? "Enter OTP" : "Enter New Password")}
                             onChange={this.props.handleChange}
                             value={form_state === "forgot" ? username : (form_state==="otp" ? otp : reset)}
                             data-success=''
                             /> )}
                                {form_state === "reset" &&<div className="form-password-item"> <input
                                    type={type}
                                    className={css_class}
                                    name="confirm_password"
                                    placeholder="Enter Confirm Password"
                                    onChange={this.props.handleChange}
                                    type={this.state.hidden1 ? 'password' : 'text'}
                                    value={confirm_password}
                                    data-success=''
                                />
                                <span className={this.state.hidden1? 'show pwd-show-hide' :'hide pwd-show-hide'} onClick={() => this.togglePasswod(2)}><svg id="Capa_1" enable-background="new 0 0 551.121 551.121" height="512" viewBox="0 0 551.121 551.121" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m275.561 68.887c-123.167 0-233.534 80.816-274.64 201.107-1.228 3.616-1.228 7.518 0 11.134 41.106 120.291 151.473 201.107 274.64 201.107s233.534-80.816 274.64-201.107c1.228-3.616 1.228-7.518 0-11.134-41.107-120.291-151.474-201.107-274.64-201.107zm0 378.902c-106.532 0-202.284-68.975-240.077-172.228 37.793-103.253 133.544-172.228 240.077-172.228 106.532 0 202.284 68.975 240.077 172.228-37.793 103.253-133.545 172.228-240.077 172.228z"></path><path d="m275.561 172.224c-56.983 0-103.337 46.354-103.337 103.337s46.354 103.337 103.337 103.337 103.337-46.353 103.337-103.337-46.354-103.337-103.337-103.337zm0 172.228c-37.995 0-68.891-30.897-68.891-68.891 0-37.995 30.897-68.891 68.891-68.891 37.995 0 68.891 30.897 68.891 68.891s-30.897 68.891-68.891 68.891z"></path></svg></span></div>                     
                                }
                                <span className='form-error'> {errors && errors} </span>
                            </div>
                            <div className="row">
                                {this.state.validation.errors && (
                                <div className="pwd-validation-box">
                                    <ul> 
                                    {this.state.validation.errors.map(e => (
                                    <li key={e.key}>{e.message}</li> ))}
                                    </ul>                                         
                                </div>
                                )} 
                            </div>
                            {form_state === "otp" && <span onClick={this.handleResendOtp} className="link text-sm">Resend OTP</span>}
                            <div className="row btn-outer">
                                <div className="col">
                                    <Button
                                        type={'submit'}
                                        className={'btn btn-primary btn-full'}
                                        value={'Submit'}
                                        processing={this.props.processing}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    {alert ? <Alert message={this.state.alert_msg} type={this.state.alert_type}></Alert> : ""}
                    {this.props.otpSendSuccessfullyMessageFlag ? <Alert message={this.props.otpSendSuccessfullyMessage} type={this.props.alertType}></Alert> : ""}
                </div>
            </>
        )
    }
}