import React, {Component} from 'react'

class TermsPage extends Component{
    render(){
        return(
            <div className="page-full page-home-full cms-page-full">
                <div className="banner-inner">
                    <div className="container">
                        <div className="heading-box text-center"><div className="page-title">Terms</div></div>
                    </div>
                </div>
                <div className="page-main-body">
                    <div className="container">
                        <div className="cms-page-container">
                            <div className="cms-data-inner">
                                <p>AISquare is intended to connect student correspondents and researchers. The research and content written by the student correspondents reflect their scientific discovery and commentary. It does not represent AISquare’s ideas or opinions. The use of this content if purely for scientific purposes and fostering innovation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default TermsPage