import React, { Component } from 'react'
import ConnectItem from './ConnectItem'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Following from './Following'
import Search from '../Search';
import { Helmet } from "react-helmet";
import { Invitation } from './Invitation';
import { checkLogin, get_user_data } from '../../utils.js';
var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');
let user = {}
if(localStorage.getItem('user'))
{
	user = JSON.parse(localStorage.getItem('user'));
}
//const following = [1,2,3,4,5.6]

class Connect extends Component {
	constructor(props) {
		super(props);
        let isLoggedIn = checkLogin();
        let user_data = get_user_data();
        // console.log('isLoggedIn')
        // console.log(isLoggedIn)
        // return false;
        if (!isLoggedIn) {
        	// return false;
            this.props.history.push('/login');
        }

		this.state = {			
			conncections: [],
			groups_id: (user_data) ? user_data.groups_id : '',
			loader:true,
			tabActive: 1,
			invitation: 1,
			sent : false,
			received: true,
			invite:false,
			total_invitation: 0,
			total_connection: 0 ,
			total_following: 0,
			following : [],
			user:user_data,
			is_connected:false,
			following_type:1,
			emailIdForInvitation:'',
			invitebyEmailIdPopUp:false,
			invitationList:[],
			emailErrors:''
			
		};


		//In case of curius mind user =6
		if((this.state.groups_id && (this.state.groups_id===4 || this.state.groups_id===5)) === false)
        {
            this.props.history.push('/');

        }
	}
	
	 componentDidMount = async () => {
		// await this.getlistOfInvitation()

		user = this.state.user; //JSON.parse(localStorage.getItem('user'));
		// console.log("user ",user)
		
		if(!user.total_following){
			user.total_following = 0
		}
		if(user){
		 await this.setState({ total_following : user.total_following, 
			total_connection : user.total_connection
			});
		 } 
		 
		this.list();
		if(window.location.pathname === '/mynetwork/connections'){
			this.setState({
				tabActive: 2
			})
		} else if(window.location.pathname === '/mynetwork/following') {
			this.setState({
				tabActive: 3,
				following_type:1,
			})
		} else {
			this.setState({
				tabActive: 1
			})
		}	


	}



	list = async () =>{
		

		if(token){
			//console.log(window.location.pathname);
			 // default status 0-Pending, 1- accepted, 2- ignore
			let url = '';
			let data ={};
			
			if("/mynetwork/connections"=== window.location.pathname){
				data ={connection_status:1};
				url = api_url + 'user/connection/list'
			}else if("/mynetwork/following" === window.location.pathname){
				this.setState({total_following : this.state.user.total_following})
				
				url = api_url + 'user/profile/follow/list'
				data = {}
				if(this.state.is_connected)
				{
					data ={is_connected:true}
					
				}
			}else{
				this.setState({conncections:[]})
				data = { connection_status:0,received:this.state.received, sent:this.state.sent}
				url = api_url + 'user/connection/list'
			}
			//console.log(url)
            await axios.post(url,data,
                {
                    headers: { "Authorization": "Token " + token },
                }).then((res)=>{
					// console.log("res",res.data)
					if(res && res.data && res.data.records){ 
						this.setState({conncections:res.data.records,loader:false,total_invitation:res.data.total_invitation_received})
						
					} else if(res && res.data){
						this.setState({following:res.data.record,loader:false,total_invitation:res.data.total_invitation_received})
				
					}
					else {
						this.setState({conncections:[],loader:false,following:[],total_invitation:res.data.total_invitation_received})
						
					}

					if("/mynetwork/connections"=== window.location.pathname){
						//console.log(user.total_connection)
						if((res && res.data && res.data.records) && res.data.total_records !== this.state.total_connection){
							this.setState({total_connection : res.data.total_records })
							user.total_connection = res.data.total_records
							localStorage.setItem('user',JSON.stringify(user))
							//console.log(localStorage.getItem('user'))
						}
					}else if("/mynetwork/following" === window.location.pathname){
						 if((res && res.data && !this.state.is_connected) && res.data.total_records !== this.state.total_following ){
							this.setState({total_following : res.data.total_records })
							user.total_following = res.data.total_records
							localStorage.setItem('user',JSON.stringify(user))
							
							if(!user.total_following){
								user.total_following = 0
								this.setState({total_following : user.total_following})
							}
						 }
					}

					//console.log("user",user)

				
                }).catch((error)=>{
                    // this.props.history.push('/login');
                })
        }else{
            this.props.history.push('/login');
        }
	}


	 handleInvitationType = async (id) => {
	
		await this.setState({
			invitation: id,
			loader : true
		})
		
		if(this.state.invitation === 1){
		  this.setState({
				received : true,
				sent: false
			})
		}else if(this.state.invitation === 2){
			 this.setState({
				received : false,
				sent : true
			})
		}
		this.list();
	}

//Invitation by email id code here
	handleOnChangeInvitationSendByEmail = (e) =>{
             this.setState({[e.target.name]:e.target.value})
	}

	inviteByEmailIdButton = () =>{
		this.setState({invitebyEmailIdPopUp:true,emailErrors:''})
	}

	handleInviteByEmailIdClose =() =>{
		this.setState({invitebyEmailIdPopUp:false})
	}


	handleOnSubmitInvitationByEmailId = async () =>{
			  console.log("i am clicked")

			  let url = '';
			  url = api_url + 'user/invitations/add'
			  const data ={
				  email:this.state.emailIdForInvitation
			  }

			  await axios.post(url,data,
                {
                    headers: { "Authorization": "Token " + token },
                }).then((res)=>{
					// console.log("res",res.data.records)
					console.log("res",res)
					this.setState({conncections:res.data.records,invitebyEmailIdPopUp:false})

				}).catch((error)=>{

					// console.log("errrrrr",error.response.data.message)
					 this.setState({emailErrors :error.response.data.message})
                })
	}

	//To get list of invitation send when component is mounted 

	async getlistOfInvitation()
	{
		// console.log("hii i am called")
		let url = '';
		url = api_url + 'user/invitations/list';
		await axios.post(url,{email:''},
		{
			headers: { "Authorization": "Token " + token },
		}).then((res)=>{
			// console.log("res",res.data.records)
			this.setState({invitationList:res.data.records,loader:false,conncections:[]})

		}).catch((error)=>{
			// this.props.history.push('/login');
		})
	}


	//TO validdate email which will be invited

  validateEmail(email){

        if(email !== ''){
            var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if(reg.test(email) === false)
            {
				//   console.log("email not valid")
				   this.setState({ emailErrors: "Please Enter valid Email"})
				  return false
            }else{
				// console.log("email valid")
				return true
			}
        }else{
			// console.log("enter email id")
			this.setState({ emailErrors: "Please Enter email id"})
			return false
        } 
	}

	//To withdram send invitation 

	// withDrawSendInvitation =  (uuid) =>{
	// 	// console.log("invitatiolist called")
	// 	//  console.log("invitationlist",this.state.invitationList)
	// 		//  console.log("id ",id)
	// 		 let url = '';
	// 		 url = api_url + 'user/invitations/delete';
	// 		 axios.post(url,{uuid:uuid},
	// 		 {
	// 			 headers: { "Authorization": "Token " + token },
	// 		 }).then((res)=>{
	// 			//  console.log("res withdraw send invitation",res)


	// 			 var data = this.state.invitationList.filter((list) =>list.uuid !== uuid)

	// 			//  console.log("data",data)

	// 			 this.setState({invitationList : data})

	 
	// 		 }).catch((error)=>{
	// 			 // this.props.history.push('/login');
	// 		 })
	// }

	//Upto here
	handleFollowingType = async (id) => {
		
		await this.setState({
			following_type: id,
			loader : true,
		})

		if(this.state.following_type === 1){
			this.setState({
				is_connected : false
			})
		}else  {
			this.setState({
				is_connected : true,       
			})
		}
		this.list();
	}

	handleSearch= (data)=>{
		console.log("handlse search data",data)
		this.setState({following:[], conncections:[]})
		if(data){
			if("/mynetwork/following" === window.location.pathname){
				this.setState({following:data})
			}else this.setState({conncections:data})
		} else this.setState({following:[], conncections:[]})
		
	}

	handleTotalCount = (userData) =>{
		const following = JSON.parse( userData).total_following
		const connection = JSON.parse( userData).total_connection
		const invitation = JSON.parse( userData).total_invitation
		this.setState({
			total_following :following,
			total_connection : connection,
			//total_invitation : invitation
		}) 
	}

	connectionListTotal = (data)=>{
		if(data === "sent" || data === "received"){
			var totalConncections = this.state.conncections.length - 1
			this.setState({total_invitation :totalConncections })
			if(totalConncections === 0)
			this.setState({conncections :[]})

		}else if(data === "following"){
			var totalFollowing = this.state.following.length - 1
			if(totalFollowing === 0)
			this.setState({following :[]})
		}else if( data === "network"){
			var totalConncections = this.state.conncections.length - 1
			this.setState({total_connection :totalConncections })
			if(totalConncections === 0)
			this.setState({conncections :[]}) 

			user.total_connection = totalConncections
			localStorage.setItem('user',JSON.stringify(user))

		//	console.log(localStorage.getItem('user'))
		}

	}
	render() {


		// console.log("this.state.amit.nishad",this.state)

		const {conncections,loader, invitation,following,following_type, is_connected, sent,received,invite} = this.state;
		
		return (
			<div className="page-full page-connect-full">
				-<Helmet>
-                           <title>Mynetwork | AISquare.org</title>
-                            <meta name="description" content="Mynetwork - AISquare.org"/>
-                </Helmet>
				<div className="page-main-body">
					<div className="container">
						<div className="container-inner2">
							<div className="heading-box">
								<ul className="nav-tabs">
									<li className={this.state.tabActive === 1 ? "active" : ""}><Link to={"/mynetwork"}>Invitation <span>({this.state.total_invitation})</span></Link></li>
									<li className={this.state.tabActive === 2 ? "active" : ""}><Link to={"/mynetwork/connections"}>Network {
									user? (<span>({this.state.total_connection})</span>):('')}</Link></li>
									<li className={this.state.tabActive === 3 ? "active" : ""}><Link to={"/mynetwork/following"}>Following{user? ( <span>({this.state.total_following})</span>):('')}</Link></li>
								</ul>
							</div>
							{
								this.state.tabActive === 1 ? (
									<div className="tab-filter-box">
										<ul className="tab-filter">
											<li className={(invitation === 1) ? "active" : ""} onClick={() => this.handleInvitationType(1)}><span>Received</span></li>
											<li className={(invitation === 2) ? "active" : ""} onClick={() => this.handleInvitationType(2)}><span>Sent</span></li>
											{/* <li className={(invitation === 3) ? "active" : ""} onClick={() => this.handleInvitationType(3)}><span>Email Invitations</span></li> */}
										</ul>
										{ this.state.tabActive === 1 && <button className="invite-by-email-id-button"  onClick={() =>this.inviteByEmailIdButton()}  >Invite By EmailId</button>}
									</div>
								) : ('')
							}
							{
								this.state.tabActive === 3 ? (
									<div className="tab-filter-box">
										<ul className="tab-filter">
											<li className={(following_type === 1) ? "active" : ""} onClick={() => this.handleFollowingType(1)}><span>All</span></li>
											<li className={(following_type === 2) ? "active" : ""} onClick={() => this.handleFollowingType(2)}><span>My Network</span></li>
										</ul>
									</div>
								) : ('')
							}
                               {/* { this.state.sent && <div className="" >
										<button className="invite-by-email-id-button" style={{marginBottom:"1%"}} onClick={() =>this.inviteByEmailIdButton()}  >Invite By EmailId</button>
									</div>
								} */}

								{this.state.invitebyEmailIdPopUp &&
								
								<Invitation close={this.handleInviteByEmailIdClose} onChange={this.handleOnChangeInvitationSendByEmail} 
								invited={this.handleOnSubmitInvitationByEmailId} validateEmail={() =>this.validateEmail(this.state.emailIdForInvitation)}
							    emailErrors={this.state.emailErrors} 
								/>}

						          <div className="card">
										<div className="card-header">
											<Search tabActive={this.state.tabActive} history={this.props.history} sent = {sent} received = {received} is_connected = {is_connected} onSearchResult={this.handleSearch} invite={invite} />
										</div>

										{
											loader ? <div className="card-body"><div className="loading"></div></div> : (
												<div className="user-list-view">
											{
											this.state.tabActive === 3 ? (
												(following ? following.length: 0) ? following.map((item,i)=>{
													return <Following key={i} item={item} onTotalResult={this.handleTotalCount} history={this.props.history} connectionTotal={this.connectionListTotal} />
												}) : <div className="no-found color-danger text-center"><div className="card-body">No Following Found</div></div>
												
											) : (											
                                              conncections.length ? conncections.map((item, i) => {
													return <ConnectItem item={item} key={i} invitation={invitation} history={this.props.history} sent = {this.state.sent} received = {this.state.received}
													onTotalResult={this.handleTotalCount} connectionTotal={this.connectionListTotal}></ConnectItem>
												}) : <div className="no-found color-danger text-center"><div className="card-body">No connection Request Found</div></div>
												)
												// (<InvitationList invitationlist={this.state.invitationList} withDrawSendInvitation={this.withDrawSendInvitation} emailErrors={this.state.emailErrors} onSearchData = {this.state.conncections} />)											
				
										}
									</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default Connect