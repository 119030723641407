import React, {Component} from 'react'
import Filepreview from '../File-preview'
import { Link } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';
import { Picker } from 'emoji-mart'
import firebase from "../../firebase";
import Room from "./room";
import Message from "./message";
import 'emoji-mart/css/emoji-mart.css'
import ContentEditable from 'react-contenteditable'
import axios from 'axios';
import { Helmet } from "react-helmet";
import Suggestion from './../Search/suggestion'
// import $ from 'jquery';
import ConnectItem from '../Connect/ConnectItem'
import { checkLogin, get_user_data, removeTags, blobToFile } from '../../utils.js';

var db = firebase.firestore();
var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');


class Messages extends Component {

    constructor(props){
        super(props);

        let isLoggedIn = checkLogin();
        let user_data = get_user_data();
        if (!isLoggedIn) {
            this.props.history.push('/login');
        }

        var t_username = (props.match.params.username) ? props.match.params.username : '';
        // console.log('Messages.username',t_username)

        this.contentEditable = React.createRef();
        this.state = { 
        	groups_id: (user_data) ? user_data.groups_id : '',
			filePreview: false,
			uploadedFiles: [],
			emojiPanel: false,
			message: '',
			search: '',
			searchBoxVisiblity:false,
			searchConversation: '',
			userRef: props.userRef,
			chatHeadList: [],
			chatHeadListtLoading: true,
			chatList: [],
			chatListLoading:false,
			activeRoom: {},
			conncections: [],
			conncected_users: [],
			selected_conncected_users: [],
			t_username: t_username,
			activate_room:{},
			message_processing: false,
			newChatList :[],
			newChatHeadList :[],
			clickable : true,
			loadAgain : true,
			mobileSearch: true,
			loadConnection:true
        };

		//In case of curius mind user =6
		if((this.state.groups_id && (this.state.groups_id===4 || this.state.groups_id===5)) === false)
        {
            this.props.history.push('/');

        }
    }

    handleMessage = (e) => {
        let message = '';
        if(e.target.value)
        {
        	message = removeTags(e.target.value);
        }
    	this.setState({message: message});
    };

	handleAttachment = (e) => {
		// console.log('handleAttachment')
		// console.log(e.target.files)
		// if(!e.target.value === ''){
		if(e.target.files){
			let uploadedFiles = [];
			uploadedFiles.push({file:e.target.files[0],'blob_url':URL.createObjectURL(e.target.files[0])});
			this.setState({
				uploadedFiles: uploadedFiles,
				filePreview: true
			})
		} else {
			this.setState({
				filePreview: false
			})
		}		
	}


    handleAddFile = (e) => {
        let newFile = {file:e.target.files[0],'blob_url':URL.createObjectURL(e.target.files[0])}
        const uploadedFiles = [...this.state.uploadedFiles, newFile]
        this.setState({
            uploadedFiles: uploadedFiles,
            mainPreview: newFile
        })
    }

    handleFileRemove = (file) => {
        let filteredFiles = this.state.uploadedFiles.filter(item=>{
            return item !== file
        })
        this.setState({
            uploadedFiles: filteredFiles
        })
        setTimeout(()=>{
            this.setState({
                mainPreview: filteredFiles[0]
            })
        },50)
    }


	handleClosePreview = () => {
		this.setState({
			filePreview: false
		})
	}

	handleEmojiPanel = () => {
		this.setState({
			emojiPanel: !this.state.emojiPanel
		})
	}

	addEmoji = (emoji) => {
		this.setState({
			message: this.state.message + emoji.native
		})
	}

	handleSearch = async (e) => {
		const search = e.target.value
		this.setState({search})
		await this.setState({chatHeadList :[]})
			if(this.state.search){
				let headList = this.state.newChatHeadList.filter(value =>  (value.senderRef.name).toLowerCase().includes (this.state.search.toLowerCase()))
				await this.setState({chatHeadList : headList})
				//console.log(this.state.chatHeadList.filter(value =>  (value.senderRef.name) .includes (this.state.search)))
			}else {
				this.setState({chatHeadList : this.state.newChatHeadList})
			}
	}

    searchKeyPressConnected = async (e) => {
    	// alert(this.state.search)
		// this.loadFrinds()
		if(e.target.value){
			const connectUserList = this.state.conncected_users.filter(value =>(value.connection_details.first_name).toLowerCase().includes (e.target.value.toLowerCase()))
			//console.log(connectUserList)
			this.setState({selected_conncected_users : connectUserList})
		}else {
			this.setState({selected_conncected_users : []})
		}
    }

    searchKeyPress = async (e) => {
        if(e.keyCode === 13){
        	// alert(this.state.search)
			// this.loadFrinds()
			await this.setState({chatHeadList :[]})
			if(this.state.search){
				let headList = this.state.newChatHeadList.filter(value =>(value.senderRef.name).toLowerCase().includes (this.state.search.toLowerCase()))
				await this.setState({chatHeadList : headList})
				//console.log(this.state.chatHeadList.filter(value =>  (value.senderRef.name) .includes (this.state.search)))
			}else {
				this.setState({chatHeadList : this.state.newChatHeadList})
			}
        }
    }

	handleSearchConversation = async(e) => {
		const searchConversation = e.target.value
		this.setState({searchConversation})		
		await this.setState({chatList :[]})
			if(this.state.searchConversation){
				let list = this.state.newChatList.filter(value =>  (value.text).toLowerCase().includes (this.state.searchConversation.toLowerCase()))
				await this.setState({chatList : list})
			
			}else {
				this.setState({chatList : this.state.newChatList})
			}
	}

    searchConversationKeyPress = async (e) => {
        if(e.keyCode === 13){
			
        	// let room = this.state.activeRoom;
        	// var params = {
        	// 	room:room,
        	// 	searchConversation:this.state.searchConversation
			// }
			await this.setState({chatList :[]})
			if(this.state.searchConversation){
				let list = this.state.newChatList.filter(value =>  (value.text).toLowerCase().includes (this.state.searchConversation.toLowerCase()))
				await this.setState({chatList : list})
			
			}else {
				this.setState({chatList : this.state.newChatList})
			}
		  //  this.loadChat(params)
		}
    }

	componentDidMount = () => {
		// this.loadChatHead(this.userRef);
		/*if(this.state.t_username.length > 0)
		{
			this.handleConnectItem(this.state.t_username)
		}*/
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.messagesEnd) {
			this.scrollToBottom();
			var currentUserUid =   firebase.auth().currentUser.uid
			var userRef = db.collection("users").doc(currentUserUid)
			if(userRef)
			{
				 userRef.update({
					unread_count: 0,
				})
			}
		}
	}

	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView({ behavior: "smooth" });
	}

	componentWillReceiveProps = (nextProps) => {
		if(nextProps.userRef  !== this.props.userRef)
		{
			// console.log('Messages.nextProps')
			// console.log(nextProps)
			this.setState({userRef:nextProps.userRef});
			this.loadChatHead(nextProps.userRef);
			this.setConnectedUser(nextProps.userRef);
			if(nextProps.match.params && nextProps.match.params.username && nextProps.match.params.username.length > 0)
			{
				this.handleConnectItem(nextProps.match.params.username)
			}
		}
	}

	/*loadFrinds = async () =>{
		if(token){
            await axios.post(api_url+"user/connection/list",{"connection_status": 1, "search_text": this.state.search},
                {
                    headers: { "Authorization": "Token " + token },
                }).then((res)=>{
                	// console.log('loadFrinds.res.data')
                	// console.log(res.data)
					if(res && res.data && res.data.records)
					{
						this.setState({conncections:res.data.records,loader:false})
					}
					else
					{
						this.setState({conncections:[],loader:false})
					}
                }).catch((error)=>{
                    // this.props.history.push('/login');
                })
        }else{
            this.props.history.push('/login');
        }
	}*/

	loadChatHead = (userRef) => {
		if(userRef && userRef.uid)
		{
			this.setState({chatHeadList:[],chatHeadListtLoading:true})
			//.where("sender_uid", "==", this.state.userRef.uid) //
			db.collection("rooms")
			  .where("sender_user_uids", "array-contains", userRef.uid)
			  .orderBy("timestamp","desc")
			  .get()
			  .then((querySnapshot) => {
				var chatHeadList = []
			    querySnapshot.forEach((doc) => {
			        // console.log(`${doc.id} => ${doc.data()}`);
			        var room = doc.data()
			        room['room_id'] = doc.id
			        chatHeadList.push(room)
			    });
			    // console.log('chatHeadList')
			    // console.log(chatHeadList)
			    let activeRoom = (chatHeadList[0]) ? chatHeadList[0] : {}
				this.setState({chatHeadList:chatHeadList,chatHeadListtLoading:false,newChatHeadList:chatHeadList,activeRoom:activeRoom})
				if(chatHeadList.length==0)
				this.setState({loadConnection:false})
			});
		}
	}

	loadChat = (params) => {
		// console.log('loadChat.params')
		// console.log(params)
		// return false;
		var room = (params.room) ? params.room : {}
		var searchConversation = (params.searchConversation) ? params.searchConversation : ''
		var loadNewChat = (params.loadNewChat) ? params.loadNewChat : false
		var _this1 = this;
		_this1.state.loadAgain = false
		if(this.state.userRef && this.state.userRef.uid && room && room.room_id)
		{
			if(loadNewChat)
			{
				// _this1.setState({activeRoom:room,chatList:[],chatListLoading:true})
			}
			else
			{
				_this1.setState({activeRoom:room,chatList:[],chatListLoading:true})
			}
			var collRef = db.collection("messages").doc(room.room_id).collection('chats');
			if(searchConversation)
			{
				collRef = collRef.where("text",">=",searchConversation).orderBy("text","asc")
			}
			else
			{
				if(loadNewChat)
				{
					collRef = collRef.orderBy("timestamp","desc").limit(1)
				}
				else
				{					
					// collRef = collRef.orderBy("timestamp","asc")
					collRef = collRef.orderBy("timestamp","desc").limit(200)
					_this1.markRoomAsRead(room);
				}
			}

			collRef.get().then(function(querySnapshot) {
				if(loadNewChat)
				{
					// console.log('loadNewChat')
					const chatList = _this1.state.chatList
				    querySnapshot.forEach((doc) => {
				        chatList.push(doc.data())
				    });
				    // console.log(chatList);
				    _this1.setState({chatList:chatList,newChatList:chatList})
				}
				else
				{
					var chatList = []
				    querySnapshot.forEach((doc) => {
				        // chatList.push(doc.data())
				        chatList.unshift(doc.data())
				    });
				    // console.log('chatList')
				    // console.log(chatList)
				    _this1.setState({chatList:chatList,chatListLoading:false,newChatList:chatList})
				}
			}).catch(function(error) {
			    console.log("Error getting document:", error);
			});
		}

		
	}

	roomGotMessage = (room) => {
		var _this1 = this;
		// console.log('roomGotMessage')
		// console.log(room)
		var room_last_uuid = (room.last_uuid) ? room.last_uuid : '';
        var chatHeadList = _this1.state.chatHeadList;
        const index = chatHeadList.findIndex(row => row.room_id === room.room_id);
        if (index !== -1) {
	        // console.log('roomGotMessage.index.found')
            chatHeadList[index] = room;
        }
	    _this1.setState({chatHeadList:chatHeadList})
	    // console.log(room.room_id+' === '+this.state.activeRoom.room_id)
		if(room.room_id === _this1.state.activeRoom.room_id)
		{
			if(room.senderRefUpdated)
			{
				let activeRoom = _this1.state.activeRoom
				activeRoom['senderRef'] = room.senderRefUpdated;
				_this1.setState({activeRoom});
			}
			else
			{
				let room = _this1.state.activeRoom;
	        	var params = {
	        		room:room,
	        		loadNewChat:true
	        	}
				_this1.loadChat(params);

				//Mark unread counter to zero for this room
				if(_this1.state.userRef.uuid !== room_last_uuid)
				{
					_this1.markRoomAsRead(room);
				}
			}
		}
	}

	markRoomAsRead = (room) => {
		if(room.room_id)
		{
			var _this1 = this
			var roomRef = db.collection("rooms").doc(room.room_id);
			if(roomRef)
			{
				return roomRef.update({
				    unread_count: 0,
				})
				.then(function() {
					// Successfully updated
			        var chatHeadList = _this1.state.chatHeadList;
			        const index = chatHeadList.findIndex(row => row.room_id === room.room_id);
			        if (index !== -1) {
			        	room['unread_count'] = 0;
			            chatHeadList[index] = room;
			        }
				    _this1.setState({chatHeadList:chatHeadList});
				})
				.catch(function(error) {
				    // The document probably doesn't exist.
				    console.error("Error updating document: ", error);
				});
			}
		}		
	}

	createMessage = async () => {
		var message = '';
		if(this.state.message)
		{
			const regex = /&nbsp;/gi;
			message = this.state.message;
			message = message.replaceAll(regex, ' ');
			message = message.trim();
		}
		// console.log('message')
		// console.log('a'+message+'b')
		// return false;
		if(message || (this.state.uploadedFiles && this.state.uploadedFiles.length > 0))
		{
			this.setState({
                message_processing: true
            });
			const createMessage = {
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				user: {
					id: this.state.userRef.uid
				}
			};
			createMessage["text"] = message;
			let files = []
			if(this.state.uploadedFiles.length > 0)
			{
				// console.log('createMessage.uploadedFiles');
				// console.log(this.state.uploadedFiles);
				var formData = new FormData();
				formData.append('room_id',this.state.activeRoom.room_id);
				this.state.uploadedFiles.forEach( (file) => {
					// let file = await blobToFile(blob_url);
					formData.append('files',file.file);
				});
				this.setState({uploadedFiles:[]})
				var res_files = await axios.post(
					api_url+"message/file/add",formData,
					{
						headers: { "Authorization": "Token " + token },
					}
				).then((res)=>{
					if(res.data.status === 'success' && res.data.files.length > 0)
					{
						return res.data.files;					
					}
					else
					{
						return false;
					}
				});
				if(res_files)
				{
					createMessage['files'] = res_files
				}
				return createMessage;
			}
			else
			{
				return createMessage;	
			}
		}
		else
		{
			return false;
		}		
	};

	sendTimeOut = async ()=>{
		this.sendChat()	
		await this.setState({clickable : false})
		setTimeout(() => {
			this.setState({clickable : true})			
		}, 2000);
	}

	sendChat = async () => {
		if(this.state.userRef && this.state.userRef.uid && this.state.activeRoom && this.state.activeRoom.room_id)
		{
			var _this1 = this;
			var room_id = this.state.activeRoom.room_id;
			var createMessage = await this.createMessage();
			// console.log('sendChat.createMessage')
			 //console.log(createMessage)
			// return false;
			if(createMessage)
			{
				db.collection("messages")
				.doc(room_id)
				.collection("chats")
				.add(createMessage)
				.then(function(docRef) {
				    // console.log("Document successfully written!"+docRef.id);
				    // console.log(docRef);
				    // console.log(docRef.id)			    
					/*var docRefNew = db.collection("messages").doc(room_id).collection("chats").doc(docRef.id);
					docRefNew.get().then(function(doc) {
					    if (doc.exists) {
					        // console.log("Document data:", doc.data());
						    // console.log(docRef.user)
						    // _this1.setState({chatList:chatList})
						    // const chatList = _this1.state.chatList
						    // chatList.push(docRef)
						    // _this1.setState({chatList:[...chatList,doc.data()],message:"", errors:{}})
						    _this1.setState({message:"", errors:{}})
						    _this1.updateRoomLastText(doc.data().text,doc.data().timestamp)
					    } else {
					        // doc.data() will be undefined in this case
					        console.log("No such document!");
					    }
					}).catch(function(error) {
					    console.log("Error getting document:", error);
					});*/

				    _this1.setState({message:"", errors:{},message_processing: false})
				    _this1.updateRoomLastText(createMessage.text)

					
				    // console.log(docRef.user)
				    /*_this1.setState({chatList:chatList})
				    const chatList = _this1.state.chatList
				    // chatList.push(docRef)
				    createMessage['id'] = docRef.id
				    _this1.setState({chatList:[createMessage,...chatList],message:"", errors:{}})
				    _this1.updateRoomLastText(createMessage.text)*/
				})
				.catch(function(error) {
				    console.error("Error writing document: ", error);
				    _this1.setState({message:"", errors:error,message_processing: false});
				});
			}
		}
	}

	updateRoomLastText = (last_text) => {
		if(this.state.activeRoom && this.state.activeRoom.room_id)
		{
			var _this1 = this
			var roomRef = db.collection("rooms").doc(_this1.state.activeRoom.room_id);
		
			if(roomRef)
			{
				

				return roomRef.update({
				    last_text: last_text,
				    last_uuid: _this1.state.userRef.uuid,
				    unread_count: firebase.firestore.FieldValue.increment(1),
					timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					
					
				})
				.then(function() {
					//console.log(_this1.state.activeRoom)
					var sender_uid = _this1.state.activeRoom.sender_user_uids[0] === _this1.state.userRef.uuid?
					_this1.state.activeRoom.sender_user_uids[1] : _this1.state.activeRoom.sender_user_uids[0]
					var userRef = db.collection("users").doc(sender_uid);
					
					if(userRef)
					{
						return userRef.update({
							unread_count: firebase.firestore.FieldValue.increment(1),
						})
					}
					
					//if(roomRef)

				    // console.log("Room successfully updated!");
				    // var activeRoom = _this1.state.activeRoom
				    // activeRoom['last_text'] = last_text
				    // activeRoom['timestamp'] = timestamp
			     //    var chatHeadList = _this1.state.chatHeadList;
			     //    const index = chatHeadList.findIndex(row => row.id === activeRoom.id);
			     //    if (index !== -1) {
			     //        chatHeadList[index] = activeRoom;
			     //    }
				    // _this1.setState({chatHeadList:chatHeadList})
				})
				.catch(function(error) {
				    // The document probably doesn't exist.
				    console.error("Error updating document: ", error);
				});
			}
		}
	}

	handleConnectItem = async (t_username) => {
		// console.log('handleConnectItem')
		// console.log(t_username)
		if(token && this.state.userRef && this.state.userRef.uuid){
			var _this1 = this
            await axios.post(api_url+"user/connection/check/friend",{"username": t_username},
                {
                    headers: { "Authorization": "Token " + token },
                }).then((res)=>{
                	// console.log('loadFrinds.res.data')
                	// console.log(res.data)
					if(res && res.data && res.data.status === 'success')
					{
						var item = res.data.user;
						// console.log('handleConnectItem.item')
						// console.log(item)						
						// console.log('this.state.userRef')
						// console.log(this.state.userRef)
						var sender_user_ids = (parseInt(_this1.state.userRef.users_id) < parseInt(item.users_id)) ? _this1.state.userRef.users_id+'_'+item.users_id : (item.users_id+'_'+_this1.state.userRef.users_id)
						// console.log('sender_user_ids')
						// console.log(sender_user_ids)
						// return false;
						db.collection("rooms")
						  .where("sender_user_ids", "==", sender_user_ids)
						  .orderBy("timestamp","desc").limit(1).get().then((querySnapshot) => {
						  	// console.log('querySnapshot.size')
						  	// console.log(querySnapshot.size)
						  	// return false;
						  	if(querySnapshot.size > 0)
						  	{
						  		// console.log('already_room')
						  		// var room = {}
							    querySnapshot.forEach((doc) => {
							        // console.log('handleConnectItem');
							        // console.log(`${doc.id} => ${doc.data()}`);
							        var room = doc.data()
							        room['room_id'] = doc.id
								    // console.log('activate_room')
								    // console.log(room)
								    _this1.setState({activate_room:room})
							    });
						  	}
						  	else
						  	{
						  		if(_this1.state.userRef.uuid && item.uuid && _this1.state.userRef.uuid !== item.uuid)
						  		{
									// console.log('creating_room')
									// console.log(sender_user_ids)
									var createMessage = {}
									createMessage['sender_user_ids'] = sender_user_ids
									createMessage['sender_user_uids'] = [_this1.state.userRef.uuid,item.uuid]
									createMessage['sender_uid'] = _this1.state.userRef.uuid
									createMessage['user_uid'] = item.uuid
									createMessage['last_text'] = ""
									createMessage['timestamp'] = firebase.firestore.FieldValue.serverTimestamp()
									createMessage['status'] = false
									db.collection("rooms")
									.add(createMessage)
									.then(function(docRef) {
										// console.log("Room successfully created!"+docRef);
										_this1.setState({chatHeadList:[]})
										createMessage['id'] = docRef.id
										createMessage['room_id'] = docRef.id
										createMessage['new'] = true
										const chatHeadList = _this1.state.chatHeadList
										_this1.setState({chatHeadList:[createMessage,...chatHeadList],activate_room:createMessage})
									})
									.catch(function(error) {
										console.error("Error writing document: ", error);
										alert(error)
									});
								}
								else
								{
									alert('Something went wrong, please try again.');
									this.props.history.push('/messages');
								}
						  	}
							/*var chatHeadList = []
							console.log('querySnapshot')
							console.log(querySnapshot.length)
						    querySnapshot.forEach((doc) => {
						        console.log('handleConnectItem');
						        console.log(`${doc.id} => ${doc.data()}`);
						        // var room = doc.data()
						        // room['room_id'] = doc.id
						        // chatHeadList.push(room)
						    });*/
						    // console.log('chatHeadList')
						    // console.log(chatHeadList)
						    // this.setState({chatHeadList:chatHeadList,chatHeadListtLoading:false})
						});
					}
					else
					{
						console.log(t_username+" is not in friend list!");
						this.props.history.push('/messages');
					}
                }).catch((error)=>{
                	//console.log(error);
                    this.props.history.push('/messages');
                })
        }
        else
        {
            this.props.history.push('/login');
        }
	}

	handleFilePreviewSubmit = (data) => {
		// console.log('handleFilePreviewSubmit');
		// console.log(data);
		// this.setState({message:data.message});
		this.sendTimeOut();
		this.handleClosePreview();
	}

	keyPress(event) {
		console.log("event",event.keyCode,event.which);
		if(event.keyCode === 13 || event.which === 13) {
			
			 // event.preventDefault()
			 this.sendTimeOut()
			 
		}
	  }

	  handleConnection(){
		  window.location.href = "/mynetwork/connections"
	  }
	handleMobileSearch = () => {
		this.setState({
			mobileSearch: !this.state.mobileSearch
		})
	}

	toggleSearch = () => {
		const { searchBoxVisiblity }  = this.state;
		this.setState({searchBoxVisiblity:!searchBoxVisiblity});
		setTimeout(()=>{
			window.scrollTo(0,0)
		},40)
	}

	setConnectedUser = async (userInfo) => {
		if(token){
			//console.log(window.location.pathname);
			 // default status 0-Pending, 1- accepted, 2- ignore
			let url = '';
			let data ={};
			
			data ={connection_status:1};
			url = api_url + 'user/connection/list'
			
            await axios.post(url,data,
                {
                    headers: { "Authorization": "Token " + token },
                }).then((res)=>{
					if(res && res.data && res.data.records){
						this.setState({conncected_users:res.data.records,loader:false})	
					}
                }).catch((error)=>{
                    // this.props.history.push('/login');
                })
        }else{
            this.props.history.push('/login');
        }
	}

	handleChangeRoom = (userInfo) => {
        this.handleConnectItem(userInfo.username);
        this.setState({selected_conncected_users:[], searchBoxVisiblity: false });
    }

	render(){
		const {conncections, chatHeadList, chatHeadListtLoading, chatList, chatListLoading, userRef, activeRoom, t_username, activate_room} = this.state;
		/*let contactList = userList.filter(item =>{
			return item.name.toString().toLowerCase().search(this.state.search.toString().toLowerCase()) !== -1;
		});*/
		// let chatHeadList = (this.state.chatHeadList) ? this.state.chatHeadList : [];
		// console.log(chatHeadList.length==0)
		// console.log("t_user",t_username)
		// console.log('conncections.length')
			 
		return(
			<>
			<div className="page-full page-messages-full">
			    <Helmet>
                           <title>Messages | AISquare.org</title>
                            <meta name="description" content="Messages - AISquare.org"/>
                </Helmet>
                <div className="page-main-body">
                    <div className="container">
						<div className="messages-page-outer">
                            <div className="card message-card message-main-height">
								<div className="message-card-heading">
									<div className="heading2">
										<div className="msg-search-box flex">
											<span className="msg-top-left">
												<span className="msg-top-label">Messages</span>
												<span className={this.state.searchBoxVisiblity ? "add-action-box active" : "add-action-box"} onClick={this.toggleSearch}>
													<i className="icon icon-plus"></i>
												</span>
											</span>
											{(this.state.searchBoxVisiblity === false) ? null : (
												<div className="flex-auto msg-connection-search">
													<div className="" >
														<div className="message-sarch-input">
															<i className="icon-sarch"></i>
															<input type="text" placeholder="Search From Connection" onKeyDown={this.searchKeyPressConnected} className="form-style" />
														</div>
														{(this.state.selected_conncected_users && this.state.selected_conncected_users.length > 0)?(
															<div
																className="suggestions"
																style={{
																	'width':'239px',
																	'top': '79px',
																	'right': '120px',
																	'left': 'auto'
																}}>
																<ul className="suggestion-list">
																{
																	this.state.selected_conncected_users.map((sl,si)=>{
																		return (
																			<li key={si} onClick={() => this.handleChangeRoom(sl.connection_details)} >
																				<div >
																					<div className="suggestion-item">
																						<img src={sl.connection_details.profile_image} width="20" />
																						
																						
																						&nbsp; {sl.connection_details.first_name}
																				</div>
																				</div>
																			</li>
																		)
																	})
																}
																</ul>
															</div>
														):null}
													</div>
												</div>
											) }
										</div>
									</div>
								</div>
								<div className="message-card-body">
									<div className="message-main-view">
										<div className="message-left-panel">
											<div className="message-left-top">
												<div className="message-contact-search">
													<div className="message-sarch-input">
														<i className="icon-sarch"></i>
														<input type="text" placeholder="Search" onKeyDown={this.searchKeyPress} onChange={this.handleSearch} value={this.state.value} className="form-style" />
													</div>
												</div>
											</div>
											<div className="message-left-body">
												{(chatHeadListtLoading)?(
												<div className="loading"></div>
												):(
												<Scrollbars className="customScrollBar" style={{height:'100%'}}>
													{(chatHeadList.length > 0)?(
													<ul className="contact-list">
														{
															chatHeadList.map((room,i)=>{
																return(
																	<Room 
																		key={i} 
																		room_key={i} 
																		room={room} 
																		userRef={userRef} 
																		loadChat={this.loadChat} 
																		roomGotMessage={this.roomGotMessage} 
																		t_username={t_username} 
																		activeRoom={activeRoom} 
																		activate_room={activate_room} 
																		search = {this.state.search}
																		loadAgain = {this.state.loadAgain}
																	></Room>
																)
															})	
														}
													</ul>
													):(this.state.search ? <div className="form-error card-body"> No result found for '{this.state.search}'</div> :(''))}
												</Scrollbars>
												)}
												{(conncections.length) > 0 ?(
												<div className="card">
													<div className="user-list-view">
														{
															conncections.map((item, i) => {
																return <ConnectItem item={item} key={i} handleConnectItem={this.handleConnectItem}  ></ConnectItem>
															})
														}
													</div>
												</div>
												):null}
											</div>
										</div>
										{(activeRoom && activeRoom.senderRef)?(
										<div className="message-container">
											<div className="message-top">
												<div className="message-top-left">
													<div className="contact-item contact-item-top">
														<div className="contact-item-left">
															<div className="thumb user-thumb">
																<img src={activeRoom.senderRef.avatar} />
																<span className={"user-status "+((activeRoom.senderRef.online) ? 'online' : '')}></span>
															</div>	
														</div>															
														<div className="contact-info">
															<div className="user-info">
																<div className="user-name-date flex">
																	<div className="username flex-auto">{activeRoom.senderRef.name}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="message-top-right">
													<span className={this.state.mobileSearch ? "mobile-search-action" : "mobile-search-action search-close"} onClick={this.handleMobileSearch}><i className="icon-sarch"></i></span>
													<div className={this.state.mobileSearch ? "message-sarch-input search-hide" : "message-sarch-input"}>
														<i className="icon-sarch"></i>
														<input type="text" placeholder="Search conversation" onKeyDown={this.searchConversationKeyPress} onChange={this.handleSearchConversation} className="form-style" />
													</div>
												</div>
											</div>
											<div className="message-container-body">
												{
													this.state.filePreview ? (
													<div className="preview-panel message-file-preview">
														<Filepreview
														file={this.state.uploadedFiles} 
														close={this.handleClosePreview} 
														message={this.state.message} 
														handleAddFile={this.handleAddFile} 
														handleFileRemove={this.handleFileRemove} 
														filePreviewSubmit={this.handleFilePreviewSubmit} 
														handleMessage={this.handleMessage}
														/></div>
													) : null
												}
												<div className="message-container-main">
													<div className="message-body message-main-body">
													{(chatListLoading)?(
													<div className="loading"></div>
													):(
													<Scrollbars className="customScrollBar" style={{height:'100%'}}>
														<div className="message-list-outer">
															{
														(chatList.length > 0)?
																(chatList.map((message,i)=>
																{
																	return(
																		<Message 
																		message={message} 
																		userRef={userRef} 
																		key={i}
																		senderRef={activeRoom.senderRef}
																		></Message>
																	)
																})) :( this.state.searchConversation ? <div className="form-error card-body"> No result found for '{this.state.searchConversation}'</div>:
																  ('')
																)}
														</div>
														
														<div ref={node => (this.messagesEnd = node)} />
													</Scrollbars>
													)}
												</div>
												<div className="message-post">
													<div className="message-emoji-panel">
														{this.state.emojiPanel ? <Picker set='apple' onSelect={this.addEmoji} /> : null }
													</div>
													<div className="message-post-body">
														<div className="msg-post-item post-attachment">
															<input type="file" onChange={this.handleAttachment} />
															<i className="icon-attachment"></i>
														</div>
														<div className="msg-post-item post-textbox-panel">
															<div className="post-textbox" >
																{/*<div className="post-message-textbox" contentEditable={true} placeholder="Type a message" suppressContentEditableWarning={true} onInput={(e) => {this.handleMessage(e);this.cursor = e.target.selectionStart;} } onFocus={(e) => {e.target.selectionStart = this.cursor;}} >{this.state.message}</div>*/}
																<ContentEditable
													              innerRef={this.contentEditable}
													              html={this.state.message} // innerHTML of the editable div
													              disabled={false}       // use true to disable editing
													              onChange={this.handleMessage} // handle innerHTML change
													              // tagName='message' // Use a custom HTML tag (uses a div by default)
													              className="post-message-textbox"
																  placeholder="Type a message"
																  onKeyPress={this.keyPress.bind(this)}
																  
													            />
															</div>
															<div className={this.state.emojiPanel ? "post-emoji-box active" : "post-emoji-box" } onClick={this.handleEmojiPanel}>
																<i className="icon-emoji"></i>
															</div>
														</div>
														<div className="msg-post-item post-button">
															<button type="button" className="btn-send-message" onClick={ this.state.clickable? (this.sendTimeOut): ('')}><i className="icon-send"></i></button>
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
										):(this.state.loadConnection?(
											<div className="loading"></div>
											):(<div className="card-body text-center"> <div className="heading2">You don't have a message selected</div>
										<div className="message" >Choose one from your existing messages, or start a new one.</div>
										<span className="btn btn-sm " onClick={this.handleConnection} >New Message</span>
										</div>))}
									</div>
								</div>								
							</div>
                        </div>
                    </div>
                </div>
            </div>	
			</>
		)
	}
}
export default Messages