import React, { Component } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import Item from './Item'
import { get_user_data } from '../../utils.js';

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');
const url = api_url + 'user/researcher/list';



class Researcher extends Component {

	constructor(props) {
		super(props)
		let user_data = get_user_data();

		this.state = {
			users_id: (user_data)?user_data.users_id:'',
			researcherList: [],
			researchers: [],
			loader:true,
		}

	}
	
	componentDidMount = () => {	

		this.researcherList()
        .then((res)=>{

            if(res && res.data && res.data.records){
                this.setState({researcherList:res.data.records, loader: false});
            }else{
                this.setState({researcherList:[], loader: false});
            }
        })

	/*  this.allResearcherList()
        .then((res)=>{

            if(res && res.data && res.data.records){

            	let obj = {}
                    const options = res.data.records.map(n => {
                        if(n.organization)
                         obj = { value: n.id, label: n.first_name + ' ' + n.last_name+' ('+n.organization+')' }
                        else obj = { value: n.id, label: n.first_name + ' ' + n.last_name}
                        return obj;
                    })
                    this.setState({
                        researchers: options
                    })
                
            }
        })*/
		
	}
	
	researcherList = async () => {
        if(token){
            return await axios.post(url, {added_by:this.state.users_id}, {
                //headers: {'Authorization': `token ${token}`}
            });
        }else{
            this.props.history.push('/login');
        }
    }

    allResearcherList = async () => {        
            return await axios.post(url, {}, {                
            });
        
    }

    updateResearcherList = (data) => {
        let obj = {}
        
        const options = data.map(n => {
            if( n.organization)
             obj = { value: n.id, label: n.first_name + ' ' + n.last_name+' ('+ n.organization+')' }
             else obj =  { value: n.id, label: n.first_name + ' ' + n.last_name}
            return obj;
        })
        this.setState({
            researchers: options
        })


    }

	
	render() {
		const { loader, researcherList, researchers, users_id} = this.state;
		//console.log('researchers', researchers)
		return (
			<>
				<div className="page-full page-connect-full">
				   <Helmet>
                           <title>Researcher | AISquare.org</title>
                            <meta name="description" content="Researcher - AISquare.org"/>
                    </Helmet>
					<div className="page-main-body">
						<div className="container">
							<div className="container-inner2">
							<div className="heading-box">
								<div className="heading">
									Researchers
								</div>
							</div>
								<div className="card">
								{
									loader ? <div className="card-body"><div className="loading"></div></div> :
									(
										<div className="user-list-view">
										{
										 	
												(researcherList.length) ? researcherList.map((item, i) => {

													return <Item item={item} researchers={researchers} updateResearcherList = {this.updateResearcherList} key={i} history={this.props.history} users_id={users_id} ></Item>

												}):
												<div className="user-list flex items-center">
												<div className="user-list-info flex-auto">No researchers found.</div>

												</div>
											
										}
											
										
										</div>
									)
								}

								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
export default Researcher