import React, { Component } from 'react';
import axios from 'axios';
import Experience from './Experience.js';
import SkillsList from './skillsList.js';
import ResumeAboutMe from './resumeAboutMe.js';
import ExperienceModal from './Experience-modal'
import SkillsModal from './Skills-modal'
import Alert from '../Alert.js';


var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

class Resume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            experience: [],
            experience_loader: true,
            addExperience_message: '',
            skills: [],
            skills_loader: true,
            tab: 1,
            my_post: props.my_post,
            about_me_detail: undefined,
            addExperience: false,
            addSkills: false,
            editAbout: false,
            about_me_error: '',
            alert:false,
            msg_type:'',
            skill_alert:false,
            skill_msg_type:'',
            alert_msg:'',
        }
    }
    componentDidMount() {
        //console.log("after renderrrrrrrrrrrrrrrrrrr")
        this.getResume();
        this.getSkills();
        //console.log("state",this.state)
    }
    getResume = async () => {
        //console.log("get resume called")
        const { user_data } = this.props;
        const user_id = user_data ? (user_data.users_id ? user_data.users_id : undefined) : undefined;
        const url = api_url + 'user/experience/list';
        this.setState({ about_me_detail: user_data ? (user_data.about_me_detail ? user_data.about_me_detail : undefined) : undefined },()=>console.log("after updating detail about me",this.state.about_me_detail))
        await axios.post(url, { user_id: user_id }, {
            // for send header
        }).then((res) => {
            if (res && res.data && res.data.records) {

                this.setState({ experience: res.data.records, experience_loader: false })
            }else this.setState({experience_loader: false})
        });
    }
    getSkills = async () => {
        const { user_data } = this.props;
        const user_id = user_data ? (user_data.users_id ? user_data.users_id : undefined) : undefined;
        const url = api_url + 'user/profile-skill/list';
        await axios.post(url, { user_id: user_id }, {
            // for send header
        }).then((res) => {
            if (res && res.data && res.data.records) {
                this.setState({ skills: res.data.records, skills_loader: false })
            } else {
                this.setState({ skills_loader: false, })
            }
        });
    }
    handleTab = (id) => {
        this.setState({
            tab: id
        })
    }
    handleAddExpModal = () => {
        this.setState({
            addExperience: !this.state.addExperience
        })
    }
    handleAddSkills = () => {
        this.setState({
            addSkills: !this.state.addSkills
        })
    }
    handleAddExperiance = async (data) => {
        const { experience } = this.state;
        const url = api_url + 'user/experience/add';
        this.setState({alert:false,msg_type:''});
        await axios.post(url, data, {
            headers: { "Authorization": "Token " + token }
        }).then((res) => {
            if (res && res.data && res.data.status === "success") {
                // if(experience.length){
                this.setState({ experience: [...experience, res.data.record], addExperience_message: "Experience Add Successfully", experience_loader: false, alert:true,msg_type:"success" });

            }
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.message) {
                this.setState({ addExperience_message: err.response.data.message,alert:true,msg_type:"error" });
            } else {
                this.setState({ addExperience_message: "error",alert:true,msg_type:"error"});
            }
        });
    }
    handleAddSkillsApi = async (data) => {
        const url = api_url + 'user/profile-skill/add';
        this.setState({skill_alert:false,alert_msg:''});
        await axios.post(url, { skills: data }, {
            headers: { "Authorization": "Token " + token }
        }).then((res) => {
            if (res && res.data && res.data.status === "success") {
                this.setState({ skills: res.data.record, alert_msg: "Skill Add/Update Successfully", addSkills: false,tab:2,skill_alert:true,skill_msg_type:"success"});
            }
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.message) {
                this.setState({ alert_msg: err.response.data.message, addSkills: false,skill_alert:true,skill_msg_type:"error" });
            } else {
                this.setState({ alert_msg: "error", addSkills: false,skill_alert:true,skill_msg_type:"error" });
            }
        });
    }
    onhandleAboutMeDetail = (event) => {
        const value = event.target.value;
        this.setState({ about_me_detail: value });
    }
    onUpdateProfile = async (event) => {
        const { about_me_detail } = this.state;
        if (about_me_detail === '') {
            const error = "About Me required.";
            this.setState({ about_me_error: error });
        } else {
            const token = localStorage.getItem('token');
            const url = api_url + 'user/update';
            this.setState({skill_alert:false,alert_msg:''});
            await axios.post(url, { about_me_detail: about_me_detail }, {
                headers: { "Authorization": "Token " + token }
            }).then((res) => {
                if (res && res.data && res.data.status === "success") {
                    this.setState({ about_me_error: '', editAbout: false,skill_alert:true,alert_msg:"Updated successfully",skill_msg_type:"success"})
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                } else {
                    this.setState({ editAbout: false,skill_alert:true,alert_msg:"error",skill_msg_type:"Nothing to update" })
                }
            }).catch(err => {
                this.setState({skill_alert:true,alert_msg:"error",skill_msg_type:"error"})
            });
        }
    }
    handleEditAbout = () => {
        this.setState({
            editAbout: !this.state.editAbout
        })
    }

    render() {
        // console.log("props inside resume",this.props)
        //console.log("state inside resume",this.state)
        const { experience_loader, experience, my_post, skills, skills_loader, about_me_detail, addExperience_message, editAbout, about_me_error,alert,msg_type,skill_alert,skill_msg_type,alert_msg } = this.state;
        var hash = {}, result = {};
        for (var i = 0, l = skills.length; i < l; ++i) {
            if (!hash.hasOwnProperty(skills[i])) {
                hash[skills[i].parent_id] = skills[i].parent_id;
                result[skills[i].parent_id] = skills[i].parent_title;
            }
        }
        let unique_skills = Object.values(result);
        return (
            <div className="profile-resume-panel">
                {skill_alert ? <Alert message={alert_msg} type={skill_msg_type}></Alert>:""}
             { this.props.user_data.groups_id !==6 && <div className="nav-tab-full">
                    <ul className="nav-tab">
                        <li className={this.state.tab === 1 ? "active" : ""} key={1}><span onClick={() => this.handleTab(1)}>
                            <span>Experience</span>
                        </span></li>
                        <li className={this.state.tab === 2 ? "active" : ""} key={2}><span onClick={() => this.handleTab(2)}>
                            <span>Skills</span>
                        </span></li>
                        <li className={this.state.tab === 3 ? "active" : ""} key={3}><span onClick={() => this.handleTab(3)}>
                            <span>About me</span>
                        </span></li>
                    </ul>
                </div>
             }
                <div className="tab-data-main card-body">
                    {
                        (this.state.tab === 1 && this.props.user_data.groups_id !==6 ) && (
                            <div className="exprience-outer box" key={1}>
                                <div className="box-body">
                                    <div className="heading-box flex items-center">
                                        <div className="heading-left flex-auto">
                                            <div className="heading2">Experience</div>
                                        </div>
                                        <div className="heading-right">{my_post && <span className="add-action-box" onClick={this.handleAddExpModal}><i className="icon icon-plus"></i></span>}</div>
                                    </div>
                                    <div className="exprience-item-outer">
                                        {
                                            experience_loader ? <div className="loading"></div> : (Array.isArray(experience) ? experience.map((item, i) => {
                                                return <Experience my_post={my_post} item={item} key={i}></Experience>
                                            }) : '')
                                        }                                        
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (this.state.tab === 2 && this.props.user_data.groups_id !==6) && (
                            <div className="exprience-outer box">
                                <div className="box-body">
                                    <div className="heading-box flex items-center">
                                        <div className="heading-left flex-auto">
                                            <div className="heading2">Skills</div>
                                        </div>
                                        <div className="heading-right">{my_post && <span className="add-action-box" onClick={this.handleAddSkills}><i className="icon icon-plus"></i></span>}</div>
                                    </div>
                                    <div className="skills-outer" key={2}>
                                        {skills_loader ? <div className="loading"></div> :
                                            unique_skills.length ? unique_skills.map((skill, i) => {
                                                return <SkillsList skill={skill} key={i} skills={skills}></SkillsList>;
                                            }) : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        this.state.tab === 3 && (
                            <div className="exprience-outer box">
                                <div className="box-body">
                                    

                                    <div className="heading-box flex items-center">
                                    
                                        <div className="heading-left flex-auto">
                                        {
                                            editAbout ? (
                                                <>
                                                <div className="heading2">About Me</div>
                                                </>) : (<div className="heading2">About</div>)
                                        }
                                        </div>
                                
                                        <div className="heading-right">{my_post && <span className="add-action-box edit-action-box" onClick={this.handleEditAbout}><i className="icon icon-edit"></i></span>}</div>
                                    </div>
                                    {
                                        editAbout ? <>
                                        <div className="about-edit" key={3}>
                                            <textarea className={(about_me_error) ? 'form-style error' : 'form-style'} value={about_me_detail} onChange={this.onhandleAboutMeDetail} />
                                        </div>
                                        <div><button className="btn btn-outline btn-sm" onClick={this.onUpdateProfile}>Update</button></div>
                                    </> : <ResumeAboutMe about_me_detail={about_me_detail}></ResumeAboutMe>
                                    }
                                
                                </div>
                            </div>
                            
                        )
                    }
                     {
                        this.props.user_data.groups_id === 6 && (
                            <div className="exprience-outer box">
                                <div className="box-body">
                                    

                                    <div className="heading-box flex items-center">
                                    
                                        <div className="heading-left flex-auto">
                                        {
                                            editAbout ? (
                                                <>
                                                <div className="heading2">About Me</div>
                                                </>) : (<div className="heading2">About</div>)
                                        }
                                        </div>
                                        <div className="heading-right">{my_post && <span className="add-action-box edit-action-box" onClick={this.handleEditAbout}><i className="icon icon-edit"></i></span>}</div>
                                    </div>
                                    {
                                        editAbout ? 
                                        <>
                                        <div className="about-edit" key={3}>
                                            <textarea className={(about_me_error) ? 'form-style error' : 'form-style'} value={about_me_detail} onChange={this.onhandleAboutMeDetail} />
                                        </div>
                                        <div>
                                        <button className="btn btn-outline btn-sm" onClick={this.onUpdateProfile}>Update</button></div>
                                        </>
                                       : <ResumeAboutMe about_me_detail={about_me_detail}></ResumeAboutMe>
                                    }
                                
                                </div>
                            </div>
                        )
                    }
                    {this.state.addExperience ? <ExperienceModal alert={alert} msg_type={msg_type} addExperience_message={addExperience_message} handleAddExperiance={this.handleAddExperiance} close={this.handleAddExpModal} /> : ''}
                    {this.state.addSkills ? <SkillsModal existing_skills={skills} close={this.handleAddSkills} handleAddSkillsApi={this.handleAddSkillsApi} /> : ''}

                </div>
            </div>
        )
    }
}
export default Resume