import React from 'react';
import axios from 'axios';
import FormScreen from './form'

var config = require('../../react-config.js');
const api_url = config.api_url;
// const token = localStorage.getItem('token');
// const url = api_url + 'user/forgot/password';

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: '',
            username: '',
            otp: '',
            reset: '',
            confirm_password: '',
            submit_message: '',
            form_state: "forgot",            
            css_class: "form-style",
            processing: false,
            validation : {},
            otpSendSuccessfullyMessage:'',
            otpSendSuccessfullyMessageFlag:false,
            alertType:''
        };
    }
    handleChangePassword =(password,validation)=>{
        this.setState({ css_class: "form-style success", errors: '' })
        const { form_state } = this.state;
        this.setState({ reset: password, validation : validation });
    }

    handleChange = (event) => {
        // console.log("event",event.target.name)
        let value = event.target.value;
        const { form_state } = this.state;
        this.setState({ css_class: "form-style success", errors: '' })

        if (form_state === "forgot") {
            this.setState({ username: value });
        }else if (form_state === "otp") {
            this.setState({ otp: value });
        }else if (form_state === "reset") {
            if (event.target.name === "confirm_password") {
                this.setState({ confirm_password: value });
            }
        }

        // this.validate_form(value);
    }

    validate_form(username) {
        const { form_state, reset, confirm_password,validation } = this.state;
        //console.log("reset",reset)
        //console.log("confirm_password",confirm_password)
        //console.log(validation)
        let error = '';
        if (form_state === "forgot") {
            error = "Email address/Username required."
        } if (form_state === "otp") {
            error = "OTP required."
        } if (form_state === "reset") {
            if (confirm_password && reset && (confirm_password !== reset)) {  
                error = "New Password and Confirmed password do not match.";
                this.setState({ css_class: "form-style error", errors: error })
                return false;
            } else {
                if(validation.errors.length > 0){
                    error = "Invalid Password.";
                    this.setState({ css_class: "form-style error", errors: error })
                    return false
                }else { 
                this.setState({ css_class: "form-style success", errors: '' })
                return true; 
                }
            }
        }
        if (username === undefined || username === '') {
            this.setState({ css_class: "form-style error", errors: error })
            return false
        } else {
            this.setState({ css_class: "form-style success", errors: '' })
            return true;
        }
    }

    validateForgotPasswords =() =>{
        const { username, form_state, reset,confirm_password } = this.state;
        // console.log("username",reset)
        // console.log("confirmpassword",confirm_password)
        if( reset === '' && confirm_password === '' && form_state === 'reset' ){
            let error = "New Password and Confirmed password required."
            this.setState({ css_class: "form-style error", errors: error })
            return false
        }else if( reset === '' && form_state === 'reset' ){
            let error = "New Password required."
            this.setState({ css_class: "form-style error", errors: error })
            return false
        }else if( confirm_password === '' && form_state === 'reset' ){
            let error = "Confirm Password required."
            this.setState({ css_class: "form-style error", errors: error })
            return false
        }else if( confirm_password !== reset ){
            let error = "New Password and Confirmed password do not match."
            this.setState({ css_class: "form-style error", errors: error })
            return false
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        

        const { username, form_state, reset,confirm_password } = this.state;
        if((this.validateForgotPasswords() === false) && form_state === 'reset' ){
            return
        }
        var otp = this.state.otp.trim()
        
        if (this.validate_form(username) === true) {
            
            this.setState({ processing: true,otpSendSuccessfullyMessageFlag:false });
            let url = '';
            let data = {};
            if (form_state === "forgot") {
                url = api_url + 'user/forgot/password';
                data = { email: username };
            } if (form_state === "otp") {
                url = api_url + 'user/verify_otp';
                data = { email: username, otp: otp }
            } if (form_state === "reset") {
              //  alert("success")
              //  return false
                url = api_url + 'user/reset_pwd';
                data = { email: username, new_password: reset }
            }
            await axios.post(url, data,
                {
                    //  header section
                }).then((res) => {
                    if (res && res.data && res.data.status === "success") {
                        form_state === "forgot" ? this.setState({ form_state: "otp", processing: false,otpSendSuccessfullyMessage:" OTP Sent Successfully",otpSendSuccessfullyMessageFlag:true,alertType:"success" }) : (form_state === "otp" ? this.setState({ form_state: "reset", processing:false }) : this.handleReset()
                        )
                    }
                }).catch((error) => {
                    this.setState({ css_class: "form-style error", errors: error.response.data.message, processing:false })
                })
                
        }
        return false;
    }
    handleReset = () => {
        this.setState({otpSendSuccessfullyMessageFlag:false})
        setTimeout(()=>{
                this.setState({ form_state: "reset", processing: false,otpSendSuccessfullyMessage:" Your password has been reset successfully.",otpSendSuccessfullyMessageFlag:true,alertType:"success" })
            }, 200);
        // alert('passsword reset successfully');
        
        setTimeout(()=>{
                this.props.history.push('/login')
            }, 2000);
    }

    render() {
        const { submit_message, username, css_class, errors, form_state, otp, reset, confirm_password, processing,otpSendSuccessfullyMessage,otpSendSuccessfullyMessageFlag,alertType } = this.state;
        return (
            <div className="page-full page-login-full flex items-center">
                <div className="page-center">
                    <div className="page-body">
                        <div className="form-box">
                            <div className="card">
                                <div className="card-body">
                                    {submit_message ? <p> {submit_message} </p> :
                                        <>
                                            <FormScreen form_state={form_state} username={username} css_class={css_class} errors={errors} handleChange={this.handleChange} handleChangePassword={this.handleChangePassword} handleSubmit={this.handleSubmit} otp={otp} reset={reset} confirm_password={confirm_password} processing={processing} otpSendSuccessfullyMessage={ otpSendSuccessfullyMessage } otpSendSuccessfullyMessageFlag={otpSendSuccessfullyMessageFlag} alertType={alertType} ></FormScreen>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}