import React, { Component } from 'react'

export class Invitation extends Component {

    onInvited = () =>{

        if(this.props.validateEmail()){
            this.props.invited()
        }
    }

    render() {

        return (
            <div className="popup-full">
                <span className="close-action" onClick={this.props.close} ><i className="icon-close"></i></span>
                <div className="popup-main popup-md">
                    <div className="popup-body">
                        <h4 className="text-center"> Invite by EmailId</h4>
                        <form autoComplete="off">                        
                        <div className="form-main-box">
                            <div className="form-item">
                                <input
                                    className="form-style"
                                    name="emailIdForInvitation"
                                    onChange={this.props.onChange}
                                    type="email"
                                    required
                                />
                                 <span className='form-error'>{
                                 (this.props.emailErrors) ? this.props.emailErrors:''
                                }</span>
                            </div>
                            <div className="btn-outer row">
                                <div className="col"><button type="button" className="btn btn-primary btn-full" onClick={this.onInvited} > Invite</button></div>
                                <div className="col"><button type="button" className="btn btn-primary btn-full btn-outline" onClick={this.props.close} >Cancel</button></div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
              
            </div>
        )
    }
}




// export class InvitationList extends Component{
//       handleOnclick = (id) =>{
//         //   console.log("e",id)
//           this.props.withDrawSendInvitation(id)
//       }

//        render(){
        
//         // console.log("invitationlist amit",this.props.onSearchData.length)

//         if(this.props.invitationlist)
//         {
//                     if(this.props.onSearchData.length === 0)
//                     {
//                         return(
//                             <div>
//                                 { this.props.invitationlist.map((list) =>
//                                         {
//                                             return(
                                                // <div className="user-list flex items-center" key={list.uuid} >
                                                    // <div className="user-list-info flex-auto" >
                                                    //         <div className="username"  ><h1>{list.email}</h1></div>
                                                    // </div>
                                                //     {(!list.registered)?(<span className="btn btn-outline btn-sm btn-danger" onClick ={() =>this.handleOnclick(list.uuid)}  >WITHDRAW</span>):(<div className="user-list-right flex items-center"><span className="claimed"><i className="icon-check"></i>Registered</span></div>)}
                                                //     </div>
//                                             )
//                                         })}
//                             </div>
//                             )
//                     }else{
//                         return(
//                             <div>
//                                 {this.props.onSearchData.map((list) =>{
//                                         return(
//                                             <div className="user-list flex items-center" key={list.uuid} >
//                                                 <div className="user-list-info flex-auto" >
//                                                         <div className="username"  ><h1>{list.email}</h1></div>
//                                                 </div>
//                                                 {(!list.registered)?(<span className="btn btn-outline btn-sm btn-danger" onClick ={() =>this.handleOnclick(list.uuid)}  >WITHDRAW</span>):(<div className="user-list-right flex items-center"><span className="claimed"><i className="icon-check"></i>Registered</span></div>)}
//                                                 </div>
//                                         )}
//                                     )}
//                                 </div>
//                             )
//                         }
//         }else{
//             return(
//                    <div className="no-found color-danger text-center"><div className="card-body">No Email id found</div></div>
//                )
//             }
//     }
// }