import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ProfileEdit from './profile-edit';
import ProfileImage from './profile-image';
import $ from 'jquery';
var config = require('../../react-config.js');
const api_url = config.api_url;


const imgExt = ['.jpg', '.jpeg', '.JPG', '.JPEG', '.gif', '.png'];
export default class ProfileImageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posted_by: props.posted_by,
            profileImage: false,
            file: '',
            uploadedImage: '',
            profile_image:props.profile_image,
            errorImage: false,
			user_data:props.user_data,
			loading:props.loading
        };
    }

    handleImageUpload = (e) => {

		let file = e.target.files[0];
		let file_name = (file) ? file.name : '';
		
		this.setState({
			uploadedImage: URL.createObjectURL(e.target.files[0]),
			file: file,
			errorImage: false,
		})
	
		
		if (!e.target.value == '') {
			 if (new RegExp('(' + imgExt.join('|').replace(/\./g, '\\.') + ')$').test(e.target.value)) {
				//alert('k'+file_name)
				
				this.setState({
					profileImage: true
				})

			
				// Ajax

				// const token = localStorage.getItem('token');
				// const url = api_url + 'user/update';			
				// const _this1 = this;            
				// const data = new FormData();
				// data.append('profile_image', _this1.state.uploadedImage);
			    
				// $.ajax({
				// 		type: 'POST',
				// 		url,
				// 		data: JSON.stringify(data),
				// 		headers:{"Authorization": "Token "+token},
				// 		contentType: 'application/json',
				// 		dataType: 'json',
				// 		beforeSend: function() {
						   
				// 		},
				// 		success: function(res) {                        
				// 			if(res.status==='success'){
							   
				// 			}
	
						    
				// 		}
				// 	}).catch();



				//save file
			} else {
				this.setState({
					errorImage: true
				})
			}
		} else {
			this.setState({
				profileImage: false
			})
			//console.log("false")
		}
    }
    handleEditProfile = () => {
		this.setState({
			editProfile: !this.state.editProfile
		})
    }
    handleProfileImage = () => {
		
		this.setState({
			profileImage: !this.state.profileImage
		})
    }
    onUpdateProfileImage = (data) => {
		
		const token = localStorage.getItem('token');
		const url = api_url + 'user/update';

		const _this1 = this;

		if (data.profile_image && data.profile_image !== '') {

			//let formData = {};
			//alert(data.profile_image)
			//formData['profile_image'] = data.profile_image;


			const data1 = new FormData();
			//formData.append('profile_image', data.profile_image);
			data1.append('profile_image', data.profile_image);


			axios({
				method: 'post',
				url: url,
				data: data1,
				headers: { "Authorization": "Token " + token },
			}).then(function (response) {

				if (response.data.status === 'success') {

					//alert('Image Updated successfully');
					_this1.setState({ profileImage: false });
					_this1.setState({ updatedImage: true });
                    _this1.setState({ profile_image: response.data.user.profile_image });
                    _this1.props.imageError(false,true,response.data.user.profile_image);
					//this.close_popup;

					localStorage.setItem('user', JSON.stringify(response.data.user));
				//s	console.log( response.data.user.profile_image_thumb)
					//
					
					var img = document.getElementById('user_img')
				
					img.src = response.data.user.profile_image
				
					setTimeout(()=>{
						_this1.renderThumbImage(response.data.user.profile_image_thumb)
						
					},25000)
					//img.src = response.data.user.profile_image_thumb
				}
				else {
					alert('Some error occured');
				}



			}).catch(function (error) {
				console.log(error);
				//let error_message = (error.responseJSON.message)?error.responseJSON.message:'Some error occured';
			});

            /*$.ajax({
                    type: 'POST',
                    url,
                    //data: JSON.stringify(formData),
                    data: formData,
                    headers:{"Authorization": "Token "+token},
                    contentType: 'application/json',
                    dataType: 'json',
                    beforeSend: function() {
                       
                    },
                    success: function(res) {
                        
                        if(res.status==='success'){
                            alert('Image Updated successfully');
                            _this1.setState({profileImage:false});
                            _this1.setState({profile_image:res.user.profile_image});
                            //this.close_popup;
                             localStorage.setItem('user',JSON.stringify(res.user));
                        }

                        
                    }
                }).catch();*/

		}
		else {
			alert('not added')
		}
	}
	renderThumbImage = async (url) =>{
	   // const url = this.state.user_data.profile_image_thumb
	   var img =	document.getElementById('user_img')
        await axios.get(url, {}, {
            
        }).then((res) => {
		//	alert(res)
			img.src = ''
			img.src = url
		
        }).catch((res) => {
           
		});
		// setTimeout(()=>{
		// 	img.src = this.state.user_data.profile_image_thumb
        // },5000)
    }
    componentWillReceiveProps(nextProps) {
		this.setState({
			profile_image: nextProps.profile_image,
			user_data: nextProps.user_data,
			posted_by: nextProps.posted_by,
		});
	}
    render() {
        const {profile_image ,posted_by,user_data,loading} =this.state;
        return (
            <>
            <div className="profile-left">
                <div className="thumb thumb-lg">
                    {posted_by ? <div className="profile-image-action"></div> : <div className="profile-image-action"><span className="profile-image-change"><input type="file" onChange={this.handleImageUpload} /></span></div>}
                    {/* {loading ? 'error':<img src={profile_image } />} */}
					<img src={profile_image } alt={(user_data.first_name)?user_data.first_name:'Blank Image'}/>
                </div>
            </div>
                {this.state.editProfile ? <ProfileEdit close={this.handleEditProfile} user_data={user_data} /> : null}
				{this.state.profileImage ? <ProfileImage close={this.handleProfileImage} onUpdateProfileImage={this.onUpdateProfileImage} image={this.state.uploadedImage} file={this.state.file} /> : null}
			</>
        );
    }
}

ProfileImageBox.propTypes = {
    posted_by: PropTypes.number.isRequired,
    profile_image: PropTypes.string.isRequired,
    user_data:PropTypes.array
};