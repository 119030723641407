import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class ArticleDraft extends Component{
    state = {
        dropdown: false
    }
    handleDropdown = () => {
        this.setState({
            dropdown: !this.state.dropdown
        })
    }
    render(){
        return(
            <div className="article-list-item">
                <div className="heading">Article title</div>
                <div className="article-desc">Description...</div>
                <div className="article-meta-items flex">
                    <span>Last edited 21 days ago</span>
                    <span className="meta-dot"></span>
                    <span>1 min read (59 words) so far</span>
                    <span className="article-list-action">
                        <span class="btn-dot has-dropdown">
                            <i className="icon-open-menu" onClick={this.handleDropdown}></i>
                            {
                                this.state.dropdown ? (
                                    <>
                                    <div class="dropdown right dropwdown-nav">
                                        <ul class="nav">
                                            <li><span>Edit draft</span></li>
                                            <li><span>Delete draft</span></li>
                                        </ul>
                                    </div>
                                    <div className="page-overlay" onClick={this.handleDropdown}></div>
                                    </>
                                ) : ('')
                            }
                        </span>
                    </span>
                </div>
            </div>
        )
    }
}
export default ArticleDraft