import React from 'react';
import PropTypes from 'prop-types';

export default class Tag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            post: props.post,
            location:props.location ? props.location.search : '',
            urlPath:props.location ? props.location.pathname : ''
        };
    }
    handleTag = (tag) => {
        // const currentUrl = window.location.href;
        // const data = {};
        // const query = currentUrl.split('/');
        // if(query && query[5]){
        //     data ['search_text'] = decodeURIComponent(query[5]);
        // }
        // data ['search_tag_text'] = tag;
        let search='?';
        const { location,urlPath } = this.state;
        let url=urlPath;
        //if(url==="/"){
            this.setState({urlPath:"/search"});
            url ="/search";
        //}
        const params = location ? new URLSearchParams(location):'';
        const search_text = params ? params.get('q') : '';
        const uuid = params ? params.get('stream') : '';
        const pin = params ? params.get('pin') : '';
        if(search_text || uuid || pin){
            search= search_text ? 'q='+search_text+'&tag='+tag.name : ( uuid ? 'stream='+uuid+'&tag='+tag.name : 'pin='+pin+'&tag='+tag.name);
        }
        else{
            search='tag='+tag.name;
        }
        this.props.history.push({
            pathname: url,
            search: search,
        })
      };
      componentWillReceiveProps(nextProps) {
        if (nextProps.location.search!==undefined && nextProps.location.search !== this.props.location.search) {
            this.setState({location:nextProps.location.search})
        }
    }
    render() {
        const {post}=this.state;
        // console.log("post inside tag",post)
        return (
            <div className="tags-outer">
                {
                    post.tags.map((tag, idx) => {
                        return <span className="label label-tag" key={idx} onClick={() => this.handleTag(tag)}>#{tag.name}</span>
                    })
                }
            </div>
        );
    }
}

Tag.propTypes = {
    onSetBlogList: PropTypes.func.isRequired,
};