import React, {Component} from 'react'

class Button extends Component{

	handleSubmit = (e) => {
       if(this.props.handleSubmit)
       {
       	this.props.handleSubmit(e);

       }
        
    }

    render(){
        return(
        <button onClick = {this.handleSubmit} className={this.props.className} type={this.props.type} disabled={this.props.processing ? true : false}>
            {this.props.processing ? <i className="icon-loading"></i> : ''}            
            {this.props.value}
        </button>
        )
    }
}
export default Button