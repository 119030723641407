import React, {Component} from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import getCroppedImg from './crop-image';
import { blobToFile,base64StringtoFile } from '../../utils.js';

class ProfileImage extends Component {
    state = {
        image: '',
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1 / 1,
        rotation: 0,
        croppedImage:'',
        loading: false,
    }
    onCropChange = crop => {
		this.setState({ crop })
	}
	onCropComplete = (croppedArea, croppedAreaPixels) => {
		//console.log('onCropComplete')
        //console.log(croppedArea, croppedAreaPixels)

        const croppedImage = getCroppedImg(
            this.state.image,
            croppedAreaPixels,
            this.state.rotation
          ).then(result => this.setState({
      croppedImage: result
    }))

       // this.setState({ croppedImage})


	}
	 
	onZoomChange = (zoom) => {
		this.setState({ zoom })
	}
	onRotationChange = (rotation) => {
		this.setState({rotation })
    }
    componentDidMount = () => {
        //console.log(this.props.image)
        //console.log(this.props.file)
        this.setState({
            image: this.props.image, 
            file: this.props.file, 
        })
    }

    handleImageUpload = () => {
        
        let data = {}
        var getFile = base64StringtoFile(this.state.croppedImage,'profile.jpg');
        //data['profile_image'] = this.state.file;
        data['profile_image'] = getFile;
        //data['profile_image'] = blobToFile(this.state.croppedImage,'');
        //data['croppedImage'] = this.state.croppedImage;
      //  console.log('Image=>');
        //console.log(data['profile_image']);
        //console.log('croppedImage=>');
        //console.log(this.state.croppedImage);
        this.setState({
            loading: true
        })
       // console.log("data",data)
        const result = this.props.onUpdateProfileImage(data);
    }

    
	render(){
       
		return(
			<div className="popup-full">
                <span className="close-action" onClick={this.props.close}><i className="icon-close"></i></span>
                <div className="popup-main popup-sm">
                    {this.state.loading ? <div className="loading-full"><div className="loading"></div></div> : ''}
                    <div className="popup-body">
                        <div className="heading-box"><h4>Update Image</h4></div>
                        <div className="profile-image-box">
                            <div className="image-crop-box">
                                <Cropper
                                    image={this.state.image}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={this.state.aspect}
                                    rotation={this.state.rotation}
                                    onRotationChange={this.onRotationChange}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                />
                            </div>
                            <div className="controls">
                                <div className="row">
                                    <div className="col">
                                        <div className="control-label">Zoom</div>
                                        <div className="control-item">
                                            <Slider type="range"
                                            value={this.state.zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            onChange={(e, zoom) => this.onZoomChange(zoom)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="control-label">Rotate</div>
                                        <div className="control-item">
                                            <Slider type="range"
                                            value={this.state.rotation}
                                            min={0}
                                            max={360}
                                            step={1}
                                            aria-labelledby="Rotate"
                                            onChange={(e, rotation) => this.onRotationChange(rotation)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-outer row">
                            <div className="col"><button type="button" className="btn btn-primary btn-full" onClick={this.handleImageUpload} >Update</button></div>
                            <div className="col"><button type="submit" className="btn btn-primary btn-full btn-outline" onClick={this.props.close}>Cancel</button></div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}
export default ProfileImage