

export function getAccountType(state) {
    return state.formType;
}

export function getBlogData(state) {
    return state.blogList;
}

export function getStreamData(state) {
    return state.streamList;
}

export function getTagData(state) {
    return state.tagList;
}

export function getToken(state) {
    return state.getToken;
}