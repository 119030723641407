import React from 'react';

export default class SkillsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { skill, skills } = this.props;
        return (

            <div className="skills-item flex">
                <div className="skills-left">
                    <div className="title2">{skill} Skills</div>
                </div>
                <div className="skills-right">
                    <div className="skills-main-outer">
                        {
                            skills && skills.length && skills.map((child_skill, key) => {
                                return skill === child_skill.parent_title ? 
                                <span className="label label-skills" key={key}>{child_skill.title}</span>:''
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}