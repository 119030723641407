import {connect} from 'react-redux';
import Register from './../Components/Register/';
import {getAccountType} from './../selectors';
import {handleAccountType,setToken} from './../actions';
import {registerAccount} from './../thunks';

export function mapStateToProps(state) {
    return {
        accountType: getAccountType(state)
    };
}

export function mapDispatchToProps(dispatch) {
    return {
    	onHandleAccountType: type => dispatch(handleAccountType(type)),
    	handleSubmitRegister: data => dispatch(registerAccount(data)),
    	onSetToken:token => dispatch(setToken(token))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)