import React, {Component} from 'react'
import TeamItem from '../Components/Team/TeamItem'
import {Leader,Leadership,Affiliatepartners,Studentteam,Techteam} from '../team-data';
import { Helmet } from "react-helmet";

class AboutPage extends Component{
    render(){
        return(
            <div className="page-full page-home-full cms-page-full page-login-full">
                <Helmet>
							<title>Our Team | AISquare.org</title>
							<meta name="description" content="Our Team | AISquare.org"/>
				 </Helmet>
                <div className="banner-inner">
                    <div className="container">
                        <div className="heading-box text-center"><div className="page-title">Team</div></div>
                    </div>
                </div>
                <div className="page-main-body">
                    <div className="team-section-full">
                        <div className="container">
                            <div className="cms-page-container">
                                <div className="cms-data-inner">
                                    <div className="team-list-group">
                                        <div className="heading-new text-center">Leaders</div>
                                        <div className="team-listing-outer">
                                            {
                                                Leader.map((item,i)=>{
                                                    return <TeamItem data={item} key={i} />
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="team-list-group">
                                        <div className="heading-new text-center">Leadership Circle</div>
                                        <div className="team-listing-outer">
                                            {
                                                Leadership.map((item,i)=>{
                                                    return <TeamItem data={item} key={i} />
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="team-list-group">
                                        <div className="heading-new text-center">Affiliate Partners</div>
                                        <div className="team-listing-outer">
                                            {
                                                Affiliatepartners.map((item,i)=>{
                                                    return <TeamItem data={item} key={i} />
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="team-list-group">
                                        <div className="heading-new text-center">Student Team</div>
                                        <div className="team-listing-outer">
                                            {
                                                Studentteam.map((item,i)=>{
                                                    return <TeamItem data={item} key={i} />
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="team-list-group">
                                        <div className="heading-new text-center">Tech Team</div>
                                        <div className="team-listing-outer">
                                            {
                                                Techteam.map((item,i)=>{
                                                    return <TeamItem data={item} key={i} />
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AboutPage