import React, { Component } from 'react'
import 'react-quill/dist/quill.snow.css'
import Select from 'react-select'
import TextareaAutosize from 'react-textarea-autosize'
import { List } from 'immutable'
import { TagBox } from 'react-tag-box'
import { checkLogin } from '../../utils.js';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import TextEditor from './editor.js'
var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

class CreatePost extends Component {

    constructor(props) {
        super(props);
        let isLoggedIn = checkLogin();

        if (!isLoggedIn) {
            this.props.history.push('/');
        }


        const tags = List();
        const researchers = List();
        this.state = {
            editorData: '',
            //tags: sampleTags,
            //selected: sampleTags.take(0),
            form: {},
            tags: tags,
            //selected: sampleTags.take(0),
            selectedTags: tags.take(0),
            selectedStream: [],
            // researchers: sampleResearcher,
            // selectedResearcher: sampleResearcher.take(0),
            researchers: researchers,
            selectedResearcher: researchers.take(0),
            streamData: {},
            userData: {},
            title: '',
            brief: '',
            uuid:uuidv4(),
            // researcher_name:'',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onhandleChange = this.onhandleChange.bind(this);
        this.validate_form = this.validate_form.bind(this);

        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeBrief = this.handleChangeBrief.bind(this);

        // console.log(this.state.tags3);

        //alert(this.state.tags3)
    }

    componentDidMount() {
        this.streamList();
        this.tagList();
        this.userList();
    }

    onhandleChange = (e) => {
        var name = e.target.name
        var value = e.target.value
        this.setState({ [name]: value })
    }



    handleChangeTitle = (e) => {
        // console.log(e)
        //let form = this.state.form;

        var name = e.target.name
        var value = e.target.value

        //form[name] = value;
        //this.setState({form: form});

        this.setState({ [name]: value })

    }

    handleChangeBrief = value => {
        // console.log(value)
        //let form = this.state.form;
        let brief = 'brief';

        //form[brief] = value;
        this.setState({ [brief]: value });
        //this.setState({form: form});

    };

    validate_form(form_data) {
        var errors = {};
        let title = this.state.title;
        //let tags = form_data['tags'];
        //let stream = form_data['stream'];
        let brief = this.state.brief
        //let researchers = form_data['researchers'];

        if (title === undefined || title === '') {
            errors['title'] = "Heading is required";
            $('input[name="title"]').addClass('error');



        }

        if (brief === undefined || brief === '') {
            errors['brief'] = "Detail is required";
            $('input[name="brief"]').addClass('error');

        }



        const error_len = Object.keys(errors).length;

        this.setState({ errors: errors });

        if (error_len > 0) {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {uuid} = this.state;
        //const form_data = this.state.form;   
        let errors = {};
        const match = this.state.brief.match(/<(\w+)>(.*?)<\/\1>/);
        // console.log(this.state.brief)

        var validateForm = this.validate_form()
        //var validateForm = true

        if (validateForm) {

            // alert('hi');
            // return false;

            const url = api_url + 'post/add';
            let _this = this;
            let data = {};
            data['title'] = this.state.title;
            data['brief'] = match ? match[2]: '';
            data['details'] = this.state.brief;
            // data['researcher_name'] = this.state.researcher_name;
            data['uuid'] = uuid;

            /*let stream = [];
            let selectedStream = _this.state.selectedStream;
            const streamId = selectedStream.map( n =>{
                        stream.push(n.value)
                    });*/

            let stream = [];
            let selectedStream = this.state.selectedStream;
            selectedStream.map(n => {
                stream.push(n.value)
            });

            let tag = [];
            let selectedTags = this.state.selectedTags;
            selectedTags.map(t => {
                tag.push(t.label)
            });
            let researcher = [];
            let selectedResearcher = this.state.selectedResearcher;
            selectedResearcher.map(t => {
                researcher.push({ "id": t.value, "name": t.label })
            });
            data['streams'] = stream;
            data['tags'] = tag;
            data['researcher'] = researcher;

            //console.log('stream=',stream);
            //console.log(selectedStream);return false;
            //console.log('tags=',tag);
            // console.log(data);return false;

            // return false;
            $.ajax({
                type: 'POST',
                url,
                data: JSON.stringify(data),
                headers: { "Authorization": "Token " + token },
                contentType: 'application/json',
                dataType: 'json',
                beforeSend: function () {

                },
                success: function (res) {

                    if (res.status === 'success') {
                        alert('Added successfully');
                        window.location.href = "/create-post";

                    }
                    else {
                        errors['researchers'] = res.message;
                        _this.setState({ errors: errors });
                    }


                }
            }).catch();

        }

        //alert('hi')


    }

    async streamList() {

        var _this1 = this;

        const url = api_url + 'post/stream/list';

        $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json',
            headers: { "Authorization": "Token " + token },
            data: {},
            dataType: 'json',
            success: function (resp) {
                if (resp.records && resp.records.length > 0) {
                    // _this1.setState({streamData:resp.records})

                    const options = resp.records.map(n => {
                        const obj = { value: n.uuid, label: n.name }
                        return obj;
                    })
                    _this1.setState({ streamData: options })

                    //console.log(options)
                }


            }
        }).catch();
    }

    async userList() {

        var _this1 = this;

        const url = api_url + 'user/list';

        $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json',
            headers: { "Authorization": "Token " + token },
            data: {},
            dataType: 'json',
            success: function (resp) {
                if (resp.records) {
                    const options = resp.records.map(n => {
                        const obj = { value: n.users_id, label: n.first_name + ' ' + n.last_name }
                        return obj;
                    })
                    _this1.setState({
                        userData: options,
                        researchers: options
                    })
                    //console.log(options)
                }
            }
        }).catch();
    }

    async tagList() {

        var _this1 = this;

        const url = api_url + 'post/tag/list';

        $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json',
            headers: { "Authorization": "Token " + token },
            data: {},
            dataType: 'json',
            success: function (resp) {
                if (resp.records && resp.records.length > 0) {
                    //_this1.setState({tags:resp.records})

                    const options = resp.records.map(n => {
                        const obj = { value: n.uuid, label: n.name }
                        return obj;
                    })
                    _this1.setState({ tags: options })
                }


            }
        }).catch();
    }

    /*function getStreamList(data) {
        const url = api_url + 'post/stream/list';
        //alert('StreamList')
        return $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json',
            headers:{"Authorization": "Token "+token},
            data: JSON.stringify(data),
            dataType: 'json',
        }).catch(ajaxErrorHandler);
    }

    function getTagsList(data) {
        const url = api_url + 'post/tag/list';
        //alert(token)
        return $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json',
            headers:{"Authorization": "Token "+token},
            data: JSON.stringify(data),
            dataType: 'json',
        }).catch(ajaxErrorHandler);
    }*/


    handleChangeStream = stream => {
        // console.log('stream=',stream)
        var selectedStream = this.state.selectedStream

        if (stream && stream.length > 0) {
            stream.map((s) => {

                var newStream = {
                    label: s.label,
                    value: s.value || s.label
                }
                selectedStream.push(newStream)

            })
        }

        // console.log('selectedStream=',selectedStream)
        this.setState({ selectedStream })

    };


    handleTag = tag => {
        //console.log('tag=',tag)
        var selectedTags = this.state.selectedTags
        const newTag = {
            label: tag.label,
            value: tag.value || tag.label
        }
        this.setState({
            selectedTags: selectedTags.push(newTag)
        })

    };

    removeTag = tag => {
        var selectedTags = this.state.selectedTags
        this.setState({
            selectedTags: selectedTags.filter(t => t.value !== tag.value)
        })
    }

    render() {
        //const { tags, selected, researchers, selectedResearcher } = this.state
        const { researchers, selectedResearcher,uuid } = this.state;
        /*const onSelect = tag => {
            const newTag = {
                label: tag.label,
                value: tag.value || tag.label
            }
            this.setState({
                selected: selected.push(newTag)
            })
        }
        const remove = tag => {
            this.setState({
                selected: selected.filter(t => t.value !== tag.value)
            })
        }*/

        const onSelectResearcher = researcher => {
            const newResearcher = {
                label: researcher.label,
                value: researcher.value ? researcher.value : ''
            }
            this.setState({
                selectedResearcher: selectedResearcher.push(newResearcher)
            })
        }
        const removeResearcher = researcher => {
            this.setState({
                selectedResearcher: selectedResearcher.filter(t => t.value !== researcher.value)
            })
        }

        const options = (this.state.streamData) ? this.state.streamData : [];
        const tags = (this.state.tags) ? this.state.tags : [];
        // console.log(tags)



        /*const options = List(this.state.streamData.map(t => ({
          label: t.uuid,
          value: v.name
        }))*/
        // console.log(this.state.streamData)



        //const options = this.state.streamData.map(([key,value])=>{

        //})
        return (
            <>
                <div className="create-post-full">

                    <div className="page-main-body">
                        <div className="container">
                            <form onSubmit={this.handleSubmit}>
                                <div className="profile-page-outer">
                                    <div className="cp-form">
                                        <div className="cp-title">
                                            <TextareaAutosize onChange={(e) => this.handleChangeTitle(e)} className="form-style" placeholder="Heading" name="title" />

                                            <span className='form-error'>
                                                {
                                                    (
                                                        this.state.errors && this.state.errors.title
                                                    ) ? this.state.errors.title : ''
                                                }
                                            </span>
                                        </div>
                                        <div className="cp-tag-stream row">
                                            <div className="cp-tags col">
                                                <Select
                                                    options={options}
                                                    isMulti
                                                    placeholder="Stream"
                                                    onChange={this.handleChangeStream}
                                                />

                                                <span className='form-error'>
                                                    {
                                                        (
                                                            this.state.errors && this.state.errors.stream
                                                        ) ? this.state.errors.stream : ''
                                                    }
                                                </span>
                                            </div>
                                            <div className="cp-stream col">


                                                <TagBox
                                                    tags={tags}
                                                    selected={this.state.selectedTags.toJS()}
                                                    onSelect={this.handleTag}
                                                    removeTag={this.removeTag}
                                                    backspaceDelete={true}
                                                    placeholder={'Add new tag'}

                                                />

                                                <span className='form-error'>
                                                    {
                                                        (
                                                            this.state.errors && this.state.errors.tags
                                                        ) ? this.state.errors.tags : ''
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-editor">
                                        <TextEditor uuid={uuid} brief={this.state.brief} handleChangeBrief={this.handleChangeBrief}> </TextEditor>
                                        <span className='form-error'>
                                            {
                                                (
                                                    this.state.errors && this.state.errors.brief
                                                ) ? this.state.errors.brief : ''
                                            }
                                        </span>
                                    </div>
                                    <div className="cs-bottom row">
                                        <div className="cs-researchers-box col">
                                            <div className="cs-researchers">

                                                <TagBox
                                                    tags={researchers}
                                                    selected={selectedResearcher.toJS()}
                                                    onSelect={onSelectResearcher}
                                                    removeTag={removeResearcher}
                                                    backspaceDelete={true}
                                                    placeholder={'Add Researchers'}

                                                />
                                                <span className='form-error'>
                                                    {
                                                        (
                                                            this.state.errors && this.state.errors.researchers
                                                        ) ? this.state.errors.researchers : ''
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="post-btn-outer text-right col">
                                            <button type="submit" className="btn btn-outline">Save</button>
                                            <button type="submit" className="btn btn-primary">Publish</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default CreatePost