import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { get_user_data } from '../../utils.js';


const default_img = require('../../Assets/img/user-img.jpg')

export default class BlogCommentList extends React.Component {
    constructor(props){
        super(props);
        let user_data = get_user_data();

        this.state = { 
            blog_uuid:props.blog_uuid,
            comment:'',
            comment_error:'',
            comment_success:'',
            profile_image: (user_data)?user_data.profile_image:'',
            profile_image_thumb: (user_data)?user_data.profile_image_thumb:'',
        };
        this.onCommentChange = this.onCommentChange.bind(this);
    }

    onCommentChange = (event) => {
        const value = event.target.value;
        this.setState({comment: value});
    }

    onSubmitComment = (event) => {
        const comment = this.state.comment;
        if(comment === ''){
            const error = "Comment required.";
            this.setState({comment_error:error});
        }else{
            let data = {};
            const _this1 = this;
            data['comment'] = comment;
            data['uuid'] = uuidv4();
            data['post_uuid'] = this.state.blog_uuid;
            const result = this.props.handleCommentAdd(data);
            result.then( async (res) => {
                _this1.props.updateCommentsList(res.record);
                const success = 'Comment added successfully.'
                _this1.setState({ 'comment_success': success, comment:'' });
                setTimeout(() => {
                        _this1.setState({ 'comment_success' : ''});
                      }, 2000);
            });
        }
    }

    render(){
    	 return (
            <div className="write-comment-box">
                <div className="write-comment-inner flex">
                    <div className="write-comment-left">
                        <div className="thumb"><img src={this.state.profile_image_thumb ? this.state.profile_image_thumb : default_img } alt="Add Comment User" /></div>
                    </div>
                    <div className="write-comment-main flex-auto">
                        <div className="write-comment-textbox">
                            <textarea placeholder="Leave a message..." name="comment" className={(this.state.comment_error) ? 'form-style error':'form-style'} value={this.state.comment} onChange = {this.onCommentChange }></textarea>
                            </div>
                        <div className="write-comment-button">
                            <button className="btn btn-sm" type="button" onClick={this.onSubmitComment}>Post</button>
                        </div>
                            <div style={{ 'color' : 'green', 'textAlign': 'left'}}>{(this.state.comment_success) ? this.state.comment_success : '' }</div>
                    </div>
                </div>
            </div>
    	);
    }
}

BlogCommentList.propTypes = {
    blog_uuid: PropTypes.string.isRequired,
    handleCommentAdd: PropTypes.func.isRequired,
};