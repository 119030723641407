import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReplyOnPost from './replyBox.js';
import $ from 'jquery';
import { checkLogin } from '../../utils.js';
var config = require('../../react-config.js');
TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US');
const token = localStorage.getItem('token');
const api_url = config.api_url;
export default class ReplyList extends React.Component {
    constructor(props){
        super(props);

        let isLoggedIn = checkLogin();

        this.state = { 
            groups_id:(props.groups_id)?props.groups_id:'',
            comment:props.comment,
            blog_uuid:props.blog_uuid,
            reply:0,
            comments_user:{},
            award_status:props.comment.award_status,
            parent_uuid:props.parent_uuid,
            award: '',
            isLoggedIn: isLoggedIn
        };
    }

    onReply = () => {
        const {reply,comment} = this.state;
        this.setState({comments_user:{name:comment.user_name}})
        this.setState({ reply: (reply ? 0 : 1) });
    }
    handleAwardbox = (item) => {
        if (this.state.award === item) {
            this.setState({ award: '' });
        } else {
            this.setState({ award: item });
        }
    }
    handleAward = (comment) => {
        // this is for comment award
        //  if (!this.state.isLoggedIn) {
        //      this.props.history.push('/login');
        //  }
        const {award_status} = this.state;
        let comment_award ={};
        let _this = this;
        comment_award['comment_uuid'] = comment.uuid;
        comment_award['award_status'] = award_status ? false : true ;
        const url = api_url + 'post/comment/award';
        $.ajax({
            type: 'POST',
            url,
            data: JSON.stringify(comment_award),
            headers: { "Authorization": "Token " + token },
            contentType: 'application/json',
            dataType: 'json',
            beforeSend: function () {

            },
            success: function (res) {
                if (res.status === 'success') {
                    _this.setState({
                        award_status:res.record.award_status
                    });

                }
                else {
                    // errors['researchers'] = res.message;
                    // _this.setState({ errors: errors });
                }
            }
        }).catch();
     }
    render(){
        const { reply , groups_id, comment ,blog_uuid,award_status,parent_uuid,comments_user} = this.state;

    	 return (
             <div className="comments-outer-main">
                <div className="comment-listing-item flex">
                    <div className="user-thumb">
                        <div className="thumb">
                            <img src={comment.profile_image_thumb ? comment.profile_image_thumb : require('../../Assets/img/user-default.jpg')} alt="blog_image" />
                        </div>
                    </div>
                    <div className="comment-listing-right">
                        <div className="comment-listing-top">
                            <div className="user-info-box">
                                <div className="user-info">
                                    <div className="user-meta"><div className="username">{comment.user_name}</div></div>
                                    <div className="user-meta"><div className="posted-date">{timeAgo.format(new Date(comment.date_added))}</div></div>
                                    <div className="user-meta comment-award-box">
                                        <div className={(award_status) ? "btn-link active " : "btn-link"} onClick={() => this.handleAwardbox(comment)} >Awards</div>
                                        {
                                            this.state.award && (
                                                <>
                                                <div className="msg-action-popup arrow-left">
                                                    <div className="awards-popup-body">
                                                        <div className="title1"><strong>17</strong> Awards Received</div>
                                                        <div className="btn-outer">
                                                            <span className="btn btn-outline btn-sm" onClick={() => this.handleAward(comment)}>Give Award</span>
                                                            <span className="btn btn-cancel btn-sm" onClick={() => this.handleAwardbox(comment)}>Dismiss</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="page-overlay" onClick={() => this.handleAwardbox(comment)}></div>
                                                </>
                                            )
                                        }                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="comment-listing-body">{comment.comment}</div>
                        <div className="comment-listing-meta">
                            <div className="user-info">
                                {/* <span className="user-meta"><span className="comment-like meta-link"> <i className="icon-like user-meta-icon"></i></span></span> */}
                                
                                {
                                    (this.state.isLoggedIn  && groups_id != 6) ? (
                                        <span className="user-meta"><span className="comment-reply meta-link" onClick={this.onReply}>Reply</span></span>
                                        ) : null
                                }

                            {/* <span className="user-meta"><span className="comment-share meta-link">Share</span></span> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comment-reply-box">
                    {reply ? <ReplyOnPost blog_uuid={blog_uuid} updateReplyList={this.props.updateReplyList} handleCommentAdd={this.props.handleCommentAdd} parent_uuid={parent_uuid} onReply={this.onReply} comments_user={comments_user}></ReplyOnPost> : '' }
                </div>
            </div>
    	);
    }
}

ReplyList.propTypes = {
    blog_uuid: PropTypes.string.isRequired,
    handleCommentAdd: PropTypes.func.isRequired,
    parent_uuid:PropTypes.string
};