import React, {Component} from 'react'
import Messages from '../Components/Messages/'
import firebase from "../firebase";
import { checkLogin, get_id_token, get_user_data } from '../utils.js';
var config = require('../react-config.js');
class MessagePage extends Component {

    constructor(props){
        super(props);
        let user_data = get_user_data();
        this.state = {
        	userRef:user_data
        };
    }

    componentDidMount = () => {
    	const _this1 = this
		firebase.auth().onAuthStateChanged(function(user) {
		  if (user) {
		  	// console.log('firebase.user')
		  	// console.log(user)
		  	let firebase_user = {
		  		'uid':user.uid,
		  		'name':_this1.state.userRef.first_name+' '+_this1.state.userRef.last_name,
				'avatar': config.BACKEND_URL+'avatar/'+user.uid
		  	}
		  	let userRef = {
		  		..._this1.state.userRef,
		  		...firebase_user
		  	}
		  	// console.log('firebase.userRef')
		  	// console.log(userRef)
		    _this1.setState({userRef})
		  } else {
		    // No user is signed in.
		  }
		});
    }

	render(){
		const {userRef} = this.state;
		// console.log('MessagePage.userRef')
		// console.log(userRef)
		return(
			<div className="page-full page-profile-full message-page-full">
                <Messages userRef={userRef} history={this.props.history} match={this.props.match} />
            </div>
		)
	}
}
export default MessagePage