import $ from 'jquery';
import axios from 'axios';
var config = require('./react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');



export function ajaxErrorHandler({ responseJSON = {}, statusText }) {    
    return responseJSON;
}

function register(data) {
    const url = api_url + 'user/register';
    return $.ajax({
        type: 'POST',
        url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        //crossDomain: true,
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}

function login(data) {
    const url = api_url + 'user/login';
    return $.ajax({
        type: 'POST',
        url,
        contentType: 'application/json',
        data: JSON.stringify(data),
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}

function logout() {
    const url = api_url + 'user/logout';
    //alert(token)
    return $.ajax({
        type: 'POST',
        url,
        contentType: 'application/json',
        headers:{"Authorization": "Token "+token},
        data: {},
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}

function addToWishList(data) {
    const url = api_url + 'post/like'; //add-to-wishlist
    return $.ajax({
        type: 'POST',
        url,
        contentType: 'application/json',
        headers:{"Authorization": "Token "+token},
        data: JSON.stringify(data),
        //data: JSON.stringify({id:id}),
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}

function addToAward(data) {
    const url = api_url + 'post/award'; //add-to-wishlist
    return $.ajax({
        type: 'POST',
        url,
        contentType: 'application/json',
        headers:{"Authorization": "Token "+token},
        data: JSON.stringify(data),
        //data: JSON.stringify({id:id}),
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}

function addToPin(data) {
    const url = api_url + 'post/pin'; //add-to-wishlist
    return $.ajax({
        type: 'POST',
        url,
        contentType: 'application/json',
        headers:{"Authorization": "Token "+token},
        data: JSON.stringify(data),
        //data: JSON.stringify({id:id}),
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}


function getBlogList(data) {
    const url = api_url + 'post/list';
        return $.ajax({
            type: 'GET',
            url,
            contentType: 'application/json',
            headers:token ? {"Authorization": "Token "+token}: '',
            data: data ? data : {},
            //data: data ? JSON.stringify(data): {},
            dataType: 'json',
        }).catch(ajaxErrorHandler);
}

/*function getBlogList() {
    const url = api_url + 'post/list';
    return axios({
       method: 'get',
       url: url,
       data: {},
       headers:{"content-type": "application/json","Authorization": "Token "+token}
   }).catch(ajaxErrorHandler); 

}*/

function comments(data) {
    const url = api_url + 'post/comment/list';
    //alert(token)
    return $.ajax({
            type: 'GET',
            url,
            contentType: 'application/json',
            headers:token ? {"Authorization": "Token "+token}: '',
            //data: JSON.stringify(data),
            data: data? data:{},
            dataType: 'json',
        }).catch(ajaxErrorHandler);

}

/*function comments(data) {
    //console.log(data);
    const url = api_url + 'post/comment/list';

    return axios({
       method: 'get',
       url: url,
       data: data,
       headers:{"content-type": "application/json","Authorization": "Token "+token}
   }).catch(ajaxErrorHandler); 

}*/

function commentAdd(data) {
    const url = api_url + 'post/comment/add';
    //alert(token)
    return $.ajax({
        type: 'POST',
        url,
        contentType: 'application/json',
        headers:{"Authorization": "Token "+token},
        data: JSON.stringify(data),
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}

function getStreamList(data) {
    const url = api_url + 'post/stream/list';
    //alert('StreamList')
    return $.ajax({
        type: 'POST',
        url,
        contentType: 'application/json',
        //headers:{"Authorization": "Token "+token},
        headers:token ? {"Authorization": "Token "+token}: '',
        data: JSON.stringify(data),
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}

function getTagsList(data) {
    const url = api_url + 'post/tag/list';
    //alert(token)
    return $.ajax({
        type: 'POST',
        url,
        contentType: 'application/json',
        //headers:{"Authorization": "Token "+token},
        headers:token ? {"Authorization": "Token "+token}: '',
        data: JSON.stringify(data),
        dataType: 'json',
    }).catch(ajaxErrorHandler);
}


export default {
    addToWishList,
    addToAward,
    addToPin,
    commentAdd,
    comments,
    getBlogList,
    login,
    logout,
    register,
    getStreamList,
    getTagsList,
};