import React, { Component } from 'react'
const nl2br = require('react-nl2br');

class ResumeAboutMe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            about_me_detail: props.about_me_detail
        }
    }
    render() {
        // const { about_me_detail } = this.props.about_me_detail;
        return (
            <div className="about-outer">
                <div className="about-me-text">
                    {nl2br(this.props.about_me_detail)}
                    
                    {/* <i className="icon-edit" onClick={this.handleEditAbout}></i> */}
                </div>
            </div>
        )
    }
}
export default ResumeAboutMe