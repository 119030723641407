import React, { Component } from 'react'
import $ from 'jquery';
import Alert from '../Alert.js';

import PasswordStrengthBar from 'react-password-strength-bar';

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

class ProfileEditForm extends Component {
    constructor(props) {
        super(props)


        let user_data = (this.props.user_data) ? this.props.user_data : '';
        // console.log(user_data);
        this.state = {
            user_data: (user_data) ? user_data : '',
            shortText: true,
            form: {
                first_name: (user_data) ? user_data.first_name : '',
                last_name: (user_data) ? user_data.last_name : '',
                organization: (user_data) ? user_data.organization : '',
                about_me: (user_data) ? user_data.about_me : '',
            },
            //brief: 'My name is Avika. I was born and raised on Guam (US Territory) for the first 10 years of my life. I am a senior at the University of Washington Tacoma and I am double majoring in Biomedical Sciences and Psychology. I plan on becoming a surgeon. I am highly interested in neurosurgery, cardiothoracic surgery, plastic surgery, and trauma surgery. My personal interests/hobbies include swimming, running, hiking, rock climbing, tennis, and power lifting. I was a competitive swimmer when I was in high school and qualified for State. I enjoy traveling and exploring the world. A fun fact about me is that I am a huge NASA fan!',
            // first_name: (user_data) ? user_data.first_name : '',
            // last_name: (user_data) ? user_data.last_name : '',
            username: (user_data) ? user_data.username : '',
            email: (user_data) ? user_data.email : '',
            // organization: (user_data) ? user_data.organization : '',
            about_me: (user_data) ? user_data.about_me : '',
            edit: false,
            alert:false,
            alert_msg:'',
            alert_type:'',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        //this.handlePassword = this.handlePassword.bind(this);
        this.onhandleChange = this.onhandleChange.bind(this);
        this.validate_form = this.validate_form.bind(this);


    }

    onhandleChange = (event) => {
        let form = this.state.form;
        const name = event.target.name;
        const value = event.target.value;
        form[name] = value;
        this.setState({ form: form });
    }

    /*handlePassword(password){
        let result = true;
        if(password.length < 8){
            $('#length').addClass("form-error");
            $('#length').removeClass("form-success");
            result = false;
        }else{
            $('#length').removeClass("form-error");
            $('#length').addClass("form-success");
        }

        var lowerCaseLetters = /[a-z]/g;
        if(password.match(lowerCaseLetters)) {
            $('#letter').removeClass("form-error");
            $('#letter').addClass("form-success");
        } else {
            $('input[name="password"]').addClass('error');
            $('#letter').addClass("form-error");
            $('#letter').removeClass("form-success");
            result = false;
        }

        var upperCaseLetters = /[A-Z]/g;
        if(password.match(upperCaseLetters)) {
            $('#capital').removeClass("form-error");
            $('#capital').addClass("form-success");
        } else {
            $('input[name="password"]').addClass('error');
            $('#capital').addClass("form-error");
            $('#capital').removeClass("form-success");
            result = false;
        }

        var numbers = /[0-9]/g;

        if(password.match(numbers)) {
            $('#number').removeClass("form-error");
            $('#number').addClass("form-success");
        } else {
            $('input[name="password"]').addClass('error');
            $('#number').addClass("form-error");
            $('#number').removeClass("form-success");
            result = false;
        }

        if(/^[a-zA-Z0-9- ]*$/.test(password) === false) {
            $('#spchar').removeClass("form-error");
            $('#spchar').addClass("form-success");
        }else{
            $('input[name="password"]').addClass('error');
            $('#spchar').addClass("form-error");
            $('#spchar').removeClass("form-success");
            result = false;
        }

        if (result === true){
            $('#message').css("display", "none");
        }
        return result;
    }*/


    close_popup = () => {
        var close = this.props.close;
    }

    validate_form(form_data) {
        var errors = {};
        let first_name = form_data['first_name'];

        // let last_name = form_data['last_name'];
        //let email = form_data['email'];
        //let password = form_data['password'];
        //let username = form_data['username'];
        let organization = form_data['organization'];

        if (first_name === undefined || first_name === '') {
            errors['first_name'] = "";
            $('input[name="first_name"]').addClass('error');

        }

        /*if(email === undefined || email === ''){
            errors['email'] = "Email required.";
            $('input[name="email"]').addClass('error');
        }else{
            var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if(reg.test(email) === false)
            {
                errors['email'] = "Invalid email.";
                $('input[name="email"]').addClass('error');
            }
        }*/



        /*if(password === undefined || password === ''){
            
        }else{
            const passres = this.handlePassword(password);
            $('#message').css("display", "none");
            if(passres === false){
                errors['password'] = "Password contain atleast one uppercase, one lowercase, one number, one special characters and at least 8 or more characters.";
                $('input[name="password"]').addClass('error');
            }
        }*/
        if(this.props.user_data.groups_id !==6){
            if (organization === undefined || organization === '') {
                errors['organization'] = "";
                $('input[name="organization"]').addClass('error');
            }
        }


        const error_len = Object.keys(errors).length;

        this.setState({ errors: errors });

        if (error_len > 0) {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const form_data = this.state.form;
        let errors = {};

        if (this.validate_form(form_data) === true) {
            const url = api_url + 'user/update';
            let _this = this;
            let data = {};
            data['first_name'] = this.state.form.first_name;
            data['last_name'] = this.state.form.last_name;
            if(this.props.user_data.groups_id !==6){
                data['organization'] = this.state.form.organization;
            }else{
                data['organization'] = ''
            }
            data['about_me'] = this.state.form.about_me;
            
            // this.setState({alert:false,alert_msg:'',alert_type:''})
            $.ajax({
                type: 'POST',
                url,
                data: JSON.stringify(data),
                headers: { "Authorization": "Token " + token },
                contentType: 'application/json',
                dataType: 'json',
                beforeSend: function () {

                },
                success: function (res) {

                    if (res.status === 'success') {
                        localStorage.setItem('user', JSON.stringify(res.user));
                        _this.setState({alert:true,alert_msg:'Updated successfully',alert_type:'success'})
                        _this.props.update_user_data(res.user);
                        _this.props.close();
                    }
                    else {
                        errors['organization'] = res.message;
                        _this.setState({ errors: errors,alert:true,alert_msg:res.message,alert_type:'error' });
                    }

                }
            }).catch();

        }
    }
    render() {
        // console.log("profile edit form sate",this.state)
        // console.log("profile edit props",this.props.user_data.groups_id)
        const { alert, alert_msg, alert_type } = this.state;
        //console.log(alert, alert_msg, alert_type)
        return (
            <div className="edit-profile-form">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-inner">
                        <div className="row">
                            <div className="col">
                                <div className="form-item">

                                    <input
                                        className="form-style"
                                        name="first_name"
                                        onChange={this.onhandleChange}
                                        placeholder="First Name"
                                        type="text"
                                        value={(
                                            (this.state.form && this.state.form.first_name)
                                        ) ? this.state.form.first_name : this.state.first_name}
                                    />

                                    <span className='form-error'>
                                        {
                                            (
                                                this.state.errors && this.state.errors.first_name
                                            ) ? this.state.errors.first_name : ''
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-item">
                                    <input
                                        className="form-style"
                                        name="last_name"
                                        onChange={this.onhandleChange}
                                        placeholder="Last name"
                                        type="text"
                                        value={(
                                            (this.state.form && this.state.form.last_name)
                                        ) ? this.state.form.last_name : this.state.last_name}
                                    />

                                    <span className='form-error'>
                                        {
                                            (
                                                this.state.errors && this.state.errors.last_name
                                            ) ? this.state.errors.last_name : ''
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-item">
                                    <input autoComplete="off" disabled className="form-style" name="username" placeholder="Username" type="text" value={this.state.username} /></div>
                            </div>
                            <div className="col">
                                <div className="form-item"><input disabled className="form-style" name="email" placeholder="Email" type="text" value={this.state.email} />
                                    <span className='form-error'>
                                        {
                                            (
                                                this.state.errors && this.state.errors.email
                                            ) ? this.state.errors.email : ''
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/*}
                            <div className="col">
                                <div className="form-item">
                                    <input autoComplete="off" onChange={this.onhandleChange} className="form-style" name="password" placeholder="Password" type="password" />
                                    {(this.state.form && this.state.form.password) ? (<PasswordStrengthBar password={this.state.form.password} />) : '' }
                                    <span className='form-error'>{
                                        (
                                            this.state.errors && this.state.errors.password
                                        ) ? this.state.errors.password: ''
                                    }</span>
                                    <div id="message" style={{'display':'none'}}>
                                        <h3>Password must contain the following:</h3>
                                        <p id="letter" className="form-error">A <b>lowercase</b> letter</p>
                                        <p id="capital" className="form-error">A <b>capital (uppercase)</b> letter</p>
                                        <p id="number" className="form-error">A <b>number</b></p>
                                        <p id="spchar" className="form-error">A <b>special characters</b></p>
                                        <p id="length" className="form-error">Minimum <b>8 characters</b></p>
                                    </div>
                                </div>
                            </div>
                        {*/}
                         { this.props.user_data.groups_id !==6 &&<div className="col">
                                <div className="form-item">

                                    <input
                                        className="form-style"
                                        name="organization"
                                        onChange={this.onhandleChange}
                                        placeholder="School/Organization"
                                        type="text"
                                        value={(
                                            (this.state.form && this.state.form.organization)
                                        ) ? this.state.form.organization : this.state.organization}
                                    />

                                    <span className='form-error'>{
                                        (
                                            this.state.errors && this.state.errors.organization
                                        ) ? this.state.errors.organization : ''
                                    }</span>

                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="form-item">
                        <textarea autoComplete="off" value={(
                                            (this.state.form && this.state.form.about_me)
                                        ) ? this.state.form.about_me : ''}
                                         onChange={this.onhandleChange} 
                                         className="form-style" name="about_me" placeholder="Username" placeholder="Heading"></textarea>
                    </div>
                    <div className="btn-outer row">
                        <div className="col"><button type="submit" className="btn btn-primary btn-full">Update</button></div>
                        <div className="col"><button type="submit" className="btn btn-primary btn-full btn-outline" onClick={this.props.close}>Cancel</button></div>
                    </div>
                </form>
            </div>
        )
    }
}
export default ProfileEditForm