import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill'
import axios from 'axios';

var config = require('../../react-config.js');
const api_url = config.api_url;

export default class TextEditor extends React.Component {
    constructor(props) {
        super(props);
        // this.quillRef = null; 
        this.state = {
            brief: props.brief,
            uuid: props.uuid,
            loader: false
        };
        this.imageHandler = this.imageHandler.bind(this);
    }
    imageHandler = () => {
        const { uuid } = this.state;
        const _this1 = this;
        const token = localStorage.getItem('token');
        const url = api_url + 'post/upload';
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async function () {
            const default_img = require('./../../Assets/img/loader.gif')
            const range = _this1.quillRef.getEditor().getSelection();
            const file = input.files[0];
            const formData = new FormData();
            formData.append('post_image', file);
            formData.append('post_uuid', uuid);
            _this1.quillRef.getEditor().enable(false);
            // Insert temporary loading placeholder image
            this.quillRef.getEditor().insertEmbed(range.index, 'image', default_img);
            // Move cursor to right side of image (easier to continue typing)
            this.quillRef.getEditor().setSelection(range.index + 1);
            const response = await axios({
                method: 'post',
                url: url,
                data: formData,
                headers: { "Authorization": "Token " + token },
            })
            // console.log(response);
            this.quillRef.getEditor().deleteText(range.index, 1);
            response.data.status = "success" ? _this1.quillRef.getEditor().insertEmbed(range.index, 'image', response.data.record.post_image) : '';
            _this1.quillRef.getEditor().enable(true);
        }.bind(this)

    }
    componentWillReceiveProps(nextProps) {
        const brief = nextProps.brief;
        this.setState({
            brief: brief,
        });
    }
    render() {
        const formats = [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ];
        const { brief } = this.state;
        return (
            <ReactQuill
                onChange={this.props.handleChangeBrief}
                ref={(el) => { this.quillRef = el }}
                value={brief}
                placeholder="Write here. Add images or a video for visual impact."
                modules={{
                    toolbar: {
                        container: [
                            [{ 'header': '1' }, { 'header': '2' }],
                            [{ size: [] }],
                            ['bold', 'italic', 'underline'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                            { 'indent': '-1' }, { 'indent': '+1' }],
                            ['blockquote', 'link', 'image', 'video'],
                            ['clean']
                        ],
                        handlers: { image: this.imageHandler },
                        // imageDrop: true,
                        // imageResize: {}
                    },
                    clipboard: {
                        matchVisual: false,
                    },
                }}
                formats={formats}
                name="brief"
            />
        );
    }
}

TextEditor.propTypes = {
    uuid: PropTypes.string.isRequired,
};