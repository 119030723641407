import React from 'react';
import PropTypes from 'prop-types';

export default class WishList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            isLoggedIn:props.isLoggedIn
        };
    }
    handleWishlist = (item) => {
        if (!this.state.isLoggedIn) {
            this.props.history.push('/login');
        }
        let data = {}
        data['post_uuid'] = item.uuid;
        if (this.state.item.like_status) {
            data['like_status'] = false;
        } else {
            data['like_status'] = true;
        }
        this.props.setToWishList(data).then((res) => {
            if (res.status === 'success') {
                this.setState({ item: res.record });
                //  handleWishList only in case detail open in popup
                this.props.handleWishlist(res.record)
            }
        });
    }
    componentWillReceiveProps = (nextPro) => {
        if(nextPro.item !=this.props.item){
            this.setState({item:nextPro.item});
        }
    }
    render() {
        const {item}=this.state;
        return (
            <span className={(item.like_status) ? "meta-item action-wishlist active count-box" : "meta-item action-wishlist count-box"} onClick={() => this.handleWishlist(item)}><i className="icon-heart"></i>
                {
                    item.total_likes ?
                    (
                    <span className="count">{item.total_likes}</span>
                    )
                    :''
                }
            </span>
        );
    }
}
WishList.propTypes = {
    setToWishList: PropTypes.func,
};