import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import Logo from '../Assets/img/logo.svg';
import PropTypes from 'prop-types';
import Notifications from './Notifications'
import Pinned from './Pinned'
import Suggestion from './Search/suggestion'
// import ProfileEdit from './Profile/profile-edit';
import { checkLogin, get_user_data } from '../utils.js';
import Menu from './Menu'
import axios from 'axios';
import firebase from "../firebase";
var config = require('../react-config.js');
const api_url = config.api_url;
var db = firebase.firestore();

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        // console.log('Header-props=',props)
        let user_data = get_user_data();
        // console.log('Header.user_data',user_data)
        // console.log(user_data)
        let isLoggedIn = checkLogin();
        this.state = {
            search: false,            
            dropdownUser: false,
            dropdownPinned: false,
            user_data: (user_data) ? user_data : '',
            groups_id: (user_data) ? user_data.groups_id : '',
            editor_approval: (user_data) ? user_data.editor_approval : '',
            slug: (user_data) ? user_data.slug : '',
            pinPostData: [],
            pinloader: true,
            totalPinnedCount:0,
            isLoggedIn: isLoggedIn,
            search_text: '',
            menu: false,
            notifications: [],
            notifications_icon: false,
            new_noti_count: 0,
            dropdownNotification: false,
            noti_loader: true,
            suggestion_list: [],
            show_suggestion_list:false,
            message_unread_count : 0
        };
        //console.log('user_data.groups_id')
        //console.log(user_data.groups_id)
      //  console.log(JSON.parse(localStorage.getItem('user')).uuid)
    }

    componentDidMount = () => {
        this.checkNotification()
        this.renderThumbImage()  
        this.getMessageCount()
    }


    handleSearchForm = () => {
        this.setState({ search: !this.state.search })
    }    

    handleLogout = () => {
        //this.props.history.push('/');
        //this.props.handleLogout();
        //localStorage.clear();
        //window.location.reload();

        const result = this.props.handleLogout();
        result.then((res) => {
            if (res.status === 'error') {
                alert(res.message);
            } else {
                //this.props.handleLogout();
                localStorage.clear();
                window.location.reload();
            }
        });
    }

    handleUserDropdown = () => {
        this.setState({
            dropdownUser: !this.state.dropdownUser
        })
    }

    handlePinnedDropdown = () => {
        this.setState({
            dropdownPinned: !this.state.dropdownPinned
        })
    }

    pinPostList = () => {
        // console.log("pin post called")
        var _this1 = this;
        const url = api_url + 'post/list';
        const token = (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) ? localStorage.getItem('token') : '';

        if (token) {
            let data = {};
            data['pin_post'] = true;
            $.ajax({
                type: 'GET',
                url,
                contentType: 'application/json',
                headers: { "Authorization": "Token " + token },
                //data: JSON.stringify(data),
                data: data,
                dataType: 'json',
                success: function (resp) {
                    if (resp.records && resp.records.length > 0) {
                        // console.log("pin response ",resp)
                        _this1.setState({ pinPostData: resp.records, pinloader: false,totalPinnedCount:resp.totel_records })
                    }
                    else {
                        _this1.setState({ pinPostData: [], pinloader: false })
                    }
                }
            }).catch();
        }
    }

    handelSearchChange = (e) => {
        this.setState({ search_text: e.target.value })
        if(e.target.value && e.target.value.length >= 3)
        {
            this.postSuggestionList({'search_text':e.target.value});
        }
        else
        {
            this.setState({
                show_suggestion_list: false
            })
        }
    }

    handleSearch = () => {
        const { search_text } = this.state;
        this.props.history.push({
            pathname: '/search',
            search: '?q=' + search_text,
        })
    }

    postSuggestionData = async (params) => {
        const token = (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) ? localStorage.getItem('token') : '';
        return await axios.get(api_url+'post/eslist',
            {
                params: params,
                headers: token ? { "Authorization": "Token " + token } : {},
            }
        )
    }

    postSuggestionList = (params) => {
        this.postSuggestionData(params)
            .then((res) => {
                if(res.data && res.data.records)
                {
                    this.setState({ suggestion_list: res.data.records, show_suggestion_list: true});
                }
            });
    }

    handleSuggestionClick = (suggestion) => {
        if(suggestion)
        {
            if(suggestion.value)
            {
                this.props.history.push({
                    pathname: '/article/'+suggestion.value
                })
                this.setState({show_suggestion_list: false});
            }
            else
            {
                this.props.history.push({
                    pathname: '/search',
                    search: "?q=" + suggestion.label,
                })            
                this.setState({search_text:suggestion.label,show_suggestion_list: false});
            }
        }
    }

    Ai = () => {
        window.scrollTo(0, 0)
        this.props.history.push({
            pathname: '/',
        })
    }
    handleMenu = () => {
        this.setState({
            menu: !this.state.menu
        })
        if (!this.state.menu) {
            this.pinPostList();
            this.getNotification();
        }
    }
    handleNotificationDropdown = (flag) => {
        // console.log("flag",flag)
        if(flag === "notification-by-bell"){
            this.setState({
                dropdownNotification: !this.state.dropdownNotification
            })
            if (!this.state.dropdownNotification) {
                this.getNotification();
                this.setState({ notifications_icon: false, new_noti_count: 0 });
    
            }
        }else{
            this.setState({ notifications_icon: false,new_noti_count: 0 });
        }

    }
    renderThumbImage = async () =>{
        const url = this.state.user_data.profile_image_thumb
        await axios.get(url, {}, {
            
        }).then((res) => {
           
        }).catch((res) => {
           
        });

    }
    getNotification = async () => {
        const url = api_url + 'notifications/list';
        const token = (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) ? localStorage.getItem('token') : '';
        await axios.post(url, {}, {
            headers: {
                'Authorization': `token ${token}`
            }
        }).then((res) => {
            if (res && res.data && res.data.records) {
                this.setState({ notifications: res.data.records, noti_loader: false });
            } else {
                this.setState({ notifications: [], noti_loader: false });
            }
        }).catch((res) => {
            this.setState({ notifications: [], noti_loader: false });
        });
    }

    checkNotification = async () => {
        //console.log('In side checkNotification')
        const url = api_url + 'notifications/check';
        const token = (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) ? localStorage.getItem('token') : '';

        if(token){

            await axios.post(url, {}, {
                headers: {
                    'Authorization': `token ${token}`
                }
            }).then((res) => {
                if (res && res.data && res.data.status == "success" && res.data.total_records > 0) {

                    
                    this.setState({ notifications_icon: true, new_noti_count:res.data.total_records  });
                    
                } else {
                    
                    this.setState({ notifications_icon: false, new_noti_count: 0 });
                    
                }
            }).catch((res) => {
                

            });

        }


    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            //console.log('do validate');
            this.handleSearch()
        }
    }

    componentWillMount() {
        this.getMessageCount()
        //console.log("hello",this.state.message_unread_count)
        this.unlisten = this.props.history.listen((location, action) => {
            if(this.state.notifications_icon===false)
            {
                //console.log("on route change", location);
                this.checkNotification()

            }
        });
        
    }

    getMessageCount=  ()=>{
       var  _this1 = this
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
              if(firebase.auth().currentUser)
              {
                var currentUserUid =   firebase.auth().currentUser.uid
               // console.log(currentUserUid)
              if(currentUserUid){
                db.collection("users").doc(currentUserUid).get().then((doc) => {
                    var userRef =   doc.data()
                    if(userRef.unread_count)
                    {
                        _this1.setState({message_unread_count:userRef.unread_count})

                    }

                })

                _this1.userInit(currentUserUid)
                
            
              }
              }
            }
        })

        
    }

    userInit=(currentUserUid)=>{
        var _this1 = this
        if(currentUserUid){
           // console.log("current",currentUserUid)
        db.collection("users").doc(currentUserUid)
        .onSnapshot(function(doc) {
            // console.log("Users onSnapshot.Current data: ", doc.data());
            if(doc.data() )
            {
                var userRef = doc.data();
                _this1.setState({message_unread_count:userRef.unread_count})
                           }
        });
    }
    }
    handleMessageCount =() =>{

        if (this.state.isLoggedIn)
        {
         var currentUserUid =   firebase.auth().currentUser.uid
         var userRef = db.collection("users").doc(currentUserUid)
            //  this.setState({message_unread_count:0})
            if(userRef)
            {
               userRef.update({
                   unread_count: 0,
               })
            }
        }

    }


   
    render() {
        const {search_text, pinPostData, pinloader, isLoggedIn, user_data, groups_id, suggestion_list, show_suggestion_list,editor_approval,slug} = this.state;
     //  console.log("user",user_data.profile_image_thumb,user_data.first_name)
    // console.log("hello",this.state.message_unread_count)
       
      
        return (
            <>
                <nav className="nav-full">
                    <div className="nav-inner flex">
                        <div className="nav-left flex-auto">
                            <div className={this.state.menu ? "nav-action-box active" : "nav-action-box"} onClick={this.handleMenu}><i className="icon icon-nav"></i></div>
                            <Link to={'/'} className="logo" onClick={() => this.Ai()}><img src={Logo} alt="AISquare.org" /></Link>
                            <div className="nav-actions">
                                {/* <span className="nav-item nav-item-stream"><Link to={'/search'} className="item-text">Stream</Link></span> */ }

                                {
                                    (isLoggedIn && (groups_id && (groups_id===4 || groups_id===5))) ? (
                                        <Link to={'/article/new'} className="nav-item" title="Add Article"><i className="icon icon-plus"></i></Link>
                                    ) : ('')
                                }

                                {
                                    this.state.search ? (
                                        <>
                                            <div className="search-box">
                                                <span className="back-action search-back-action" onClick={this.handleSearchForm}><i className="icon-back"></i></span>
                                                <div className="search-input-box"><input type="text" className="form-style" onKeyDown={this._handleKeyDown} onChange={this.handelSearchChange} value={search_text} placeholder="Search..." /></div>
                                                <button type="button" className="search-btn" onClick={this.handleSearch} title="Search for the Articles">
                                                    <i className="icon icon-search"></i>
                                                </button>

                                                {(show_suggestion_list && suggestion_list.length > 0)?(
                                                    <div className="suggestions">
                                                        <ul className="suggestion-list">
                                                        {
                                                            suggestion_list.map((sl,si)=>{
                                                                return <Suggestion sl={sl} key={si} handleSuggestionClick={this.handleSuggestionClick}/>
                                                            })
                                                        }
                                                        </ul>
                                                    </div>
                                                ):null}
                                            </div>
                                            <div className="page-overlay" onClick={this.handleSearchForm}></div>
                                        </>
                                    ) : (
                                            <span className="nav-item nav-item-search" onClick={this.handleSearchForm} title="Search for the Articles"><i className="icon icon-search"></i></span>
                                        )
                                }
                            </div>
                        </div>
                        <div className="nav-right">
                            <div className="nav-actions">
                                {
                                    (isLoggedIn) ? (
                                        <>
                                            <span className="nav-item pinned-box" onClick={this.handlePinnedDropdown} title="Pinned Articles">
                                                <i className="icon icon-pin"></i>
                                                {
                                                    this.state.dropdownPinned ? (
                                                        <>
                                                            <div className="dropdown">
                                                                <Pinned history={this.props.history} postData={pinPostData} pinPostList={this.pinPostList}pinloader={pinloader} onSetBlogList={this.props.onSetBlogList} />
                                                            </div>
                                                            <div className="page-overlay" onClick={this.handlePinnedDropdown}></div>
                                                        </>
                                                    ) : null
                                                }
                                            </span>
                                        </>
                                    ) : null
                                }

                                {(groups_id !=6) ?

                                <Link to={'/messages'} className="message-item-box"><div className="nav-item notification-box" onClick={this.handleMessageCount} title="Messages"><i className="icon icon-mail" ><span id="unread_count" className = {this.state.message_unread_count > 0 ?"alert-dot":''}></span> </i></div></Link>
                                
                                :''
                                }
                                {/* <span className="alert-dot"></span> */}
                                {
                                    (isLoggedIn && groups_id !=6) ? (
                                        <Notifications history={this.props.history} handleNotificationDropdown={this.handleNotificationDropdown} notifications={this.state.notifications} loader={this.state.noti_loader} dropdownNotification={this.state.dropdownNotification} new_noti_count={this.state.new_noti_count} notifications_icon={this.state.notifications_icon} />
                                        ) : null
                                }
                                {(groups_id !=6) ?
                                    <Link to={'/mynetwork/'} className="nav-item nav-item-connect"><span className="btn-connect">My <br />Network</span></Link>
                                :''
                                }
                                <div className="nav-item has-dropdown user-top" onClick={this.handleUserDropdown}>
                                    
                                    {
                                    (isLoggedIn && user_data.profile_image_thumb) ? (
                                        <div className="user-thumb"><img id="user_img" className="user-top-image" src={user_data.profile_image_thumb} alt={user_data.first_name} /></div>

                                        ) : <i className="icon icon-user"></i>
                                    }



                                    {
                                        (isLoggedIn) ? (
                                            this.state.dropdownUser ? (
                                                <>
                                                    <div className="dropdown">
                                                        <ul className="nav">
                                                            <li><Link to={`/p/${slug}`} >My Profile</Link></li>
                                                            {(groups_id !=6) ? <li><Link to={'/researcher'}>Researchers</Link></li> : ''}
                                                            {(groups_id !=6) ? <li><Link to={'/article/new'}>Add Article</Link></li> : ''}
                                                            {(groups_id !=6 && editor_approval == true) ? <li><Link  
                                                             to={{
                                                                 pathname:/P/+slug,
                                                                 state:{
                                                                     editor_approval_flag:true  
                                                                 }
                                                             }}
                                                             >Approve Article</Link></li> : ''}
                                                            <li className="nav-divider"></li>
                                                            <li><span onClick={this.handleLogout}>Logout</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="page-overlay" onClick={this.handleUserDropdown}></div>
                                                </>
                                            ) : ('')
                                        ) : (
                                                this.state.dropdownUser ? (
                                                    <>
                                                        <div className="dropdown">
                                                            <ul className="nav">
                                                                <li><Link to={{pathname :'/login', form_state :"login" }}>Login</Link></li>
                                                                <li><Link to={'/register'} onClick={() => this.props.onHandleAccountType('')} >Register</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="page-overlay" onClick={this.handleUserDropdown}></div>
                                                    </>
                                                ) : ('')
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                {this.state.menu && (
                    <>
                        <Menu postData={this.state.pinPostData} notifications={this.state.new_noti_count} handleMenu={this.handleMenu} history={this.props.history} handleNotificationDropdown={this.handleNotificationDropdown} totalPinnedCount={this.state.totalPinnedCount} />
                        <div className="page-overlay" onClick={this.handleMenu}></div>
                    </>
                )}
                
            </>
        )
    }
}

Header.propTypes = {
    logedin: PropTypes.bool,
    handleLogout: PropTypes.func,
    setBlogList: PropTypes.func,
};