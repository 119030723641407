import React, {Component} from 'react'
import RelatedPostSkeleton from './RelatedPost'

class DetailSkeleton extends Component {
    state = {
        relatedItem: [1,2,3,4,5,6,7,8]
    }
    render(){
        return(
            <div className="skeleton-screen">
                <div className="detail-page-full flex skeleton-main">
                    <div className="detail-container-main flex-auto">
                        <div className="detail-title-box flex">
                            <div className="post-thumb-img img-box skeleton"></div>
                            <div className="detail-title-info flex-auto">
                                <div className="detail-title flex-auto"><h1 className="heading2 skeleton">&nbsp;</h1></div>
                                <div className="detail-meta-outer flex">
                                    <div className="detail-meta">
                                        <div className="list-meta">
                                            <span className="meta-item action-wishlist count-box skeleton"></span>
                                            <span className="meta-item action-comments count-box skeleton"></span>
                                            <div className="meta-item action-share share-action-box skeleton"></div>
                                        </div>
                                    </div>
                                    <div className="detail-date detail-user flex">
                                        <div className="meta-item skeleton"></div>
                                        <div className="user-info">
                                            <div className="user-posted-by skeleton">&nbsp; </div>
                                            <div className="username skeleton">&nbsp; </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="detail-blog-data">
                            <div className="para-group">
                                <div className="detail-img-skeleton skeleton"></div>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton" style={{width: '30%'}}>&nbsp;</p>
                            </div>
                            <div className="para-group">
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton" style={{width: '30%'}}>&nbsp;</p>
                            </div>
                            <div className="para-group">
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton" style={{width: '30%'}}>&nbsp;</p>
                            </div>
                            <div className="para-group">
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton">&nbsp;</p>
                                <p className="skeleton" style={{width: '30%'}}>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    <div className="detail-sidebar">
                        <div className="author-info-box sidebar-group">
                            <div className="sidebar-title skeleton">&nbsp; </div>
                            <RelatedPostSkeleton />
                        </div>
                        <div className="sidebar-group related-posts">
                            <div className="sidebar-title skeleton">&nbsp; </div>
                            <div className="posts-outer">
                                {
                                    this.state.relatedItem.map((item,i)=>{
                                        return <RelatedPostSkeleton key={i} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DetailSkeleton