import React, {Component} from 'react'
import Researcher from '../Components/Researcher'

class ResearcherPage extends Component {

	render(){
		return(
			<div className="page-full page-profile-full">
                <Researcher history={this.props.history}/>
            </div>
		)
	}
}
export default ResearcherPage