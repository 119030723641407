export function checkLogin()
{
    if(localStorage.getItem('token') && localStorage.getItem('user'))
    {
        let token = localStorage.getItem('token');
        let user = localStorage.getItem('user');
        if(token === null || token === undefined || user === null || user === undefined)
        {
            return false;
        }
        else
        {
            return true;
        }
    }
    else
    {
        return false;
    }
}

export function get_id_token(){
    if(localStorage.getItem('token'))
    {
        let token = localStorage.getItem('token');
        if(token === null || token === undefined)
        {
            return null;
        }
        else
        {
            return token;
        }
    }
    else
    {
        return null
    }
}

export function get_user_data(){
    if(localStorage.getItem('user'))
    {
        let user = localStorage.getItem('user');
        if(user === null || user === undefined)
        {
            return {};
        }
        else
        {
            user = JSON.parse(user);
            return user;
        }
    }
    else
    {
        return {};    
    }
}

export function blobToFile(theBlob, fileName){
    //return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    let metadata = {
        type: 'image/jpeg'
      };
    return new File([theBlob], "profile.jpg", metadata);

    
}

export function base64StringtoFile(base64String, filename) {
    //console.log('base64String')
    //console.log(base64String)
    var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}


export function removeTags(str) { 
    if ((str===null) || (str==='')) 
        return false; 
    else
        str = str.toString(); 
        str = str.replace(/<br>/g,"&nbsp;"); 
          
    // Regular expression to identify HTML tags in  
    // the input string. Replacing the identified  
    // HTML tag with a null string. 
    return str.replace( /(<([^>]+)>)/ig, ''); 
} 

export function limitWord(str,length=200) { 
    if ((str===null) || (str==='')) 
        return false; 
    else
        str = str.toString(); 
        // Regular expression to identify HTML tags in  
        // the input string. Replacing the identified  
        // HTML tag with a null string.
        str.replace( /(<([^>]+)>)/ig, '');
        
        var t = str.substring(0, length);
        //var t = str.replace(/^(.{length}[^\s]*).*/, "$1")
          
     
    return t; 
} 


export function isUrlValid(input) {
    if(input && input !== ''){
       var res = input.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

       var regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;


       if (regexp.test(input)){
          return true;
      }
      else{
          return false;
      }
  }
  else{
       return false;
    }
}