import React from 'react';
import moment from 'moment';
import axios from 'axios';
import ExperienceModal from './Experience-modal'
import Alert from '../Alert';
const nl2br = require('react-nl2br');
const default_company = require('../../Assets/img/company.jpg');
//const default_company = require('../../Assets/img/img.jpg');

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');
export default class Experience extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display_component: true,
            experience_model: false,
            experience_item: props.item,
            my_post: props.my_post,
            alert: false,
            msg_type: '',
            api_response:'',
        };
    }
    handleRemove = async (id) => {
        const isDelete = "Are you sure you want to delete this experience data?";
        if(window.confirm(isDelete) === true){
            const url = api_url + 'user/experience/delete';
            this.setState({ api_response: "",alert: false });
            await axios.post(url, { uuid: id }, {
                headers: { "Authorization": "Token " + token }
            }).then((res) => {
                //console.log(res);
                if (res && res.data && res.data.status === "success") {
                    this.setState({ display_component: false, alert: true, msg_type: "success", api_response: "Removed Successfully" })
                }
            }).catch(err => {
                this.setState({ alert: true, msg_type: "error", api_response: "Get Some Error! Please Contact to Admin" })
            });
        }
    }
    handleEditExpModal = () => {
        const { experience_model } = this.state;
        this.setState({
            experience_model: !experience_model
        })
    }
    handleEditExperiance = async (data) => {
        const url = api_url + 'user/experience/add';
        this.setState({ api_response: "",alert: false });
        await axios.post(url, data, {
            headers: { "Authorization": "Token " + token }
        }).then((res) => {
            if (res && res.data && res.data.status === "success") {
                this.setState({ experience_item: res.data.record, api_response: "Experience update Successfully", experience_model: false,alert: true, msg_type: "success", });
                // alert("Experience Add Successfully")
            }
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.message) {
                this.setState({ api_response: err.response.data.message,alert: true, msg_type: "success" });
            } else {
                this.setState({ api_response: "error",alert: true, msg_type: "success" });
            }
        });
    }
    render() {
        const { experience_model, experience_item, my_post,alert,msg_type,api_response } = this.state;
        // console.log(alert);
        return (
            
            this.state.display_component ? (
            <>
                <div className="experience-item">
                    <div className="experience-item-box flex">
                        <div className="box-item-icon">{experience_item.experience_image ? <img src={experience_item.experience_image} alt="Experience Image"/> : <img src={default_company} alt="Company Image"/>} </div>
                        <div className="experience-info flex-auto flex items-end">
                            <div className="flex-auto">
                                <div className="title">{experience_item.title}</div>
                                <div className="organization">{experience_item.organization}</div>
                                <div className="date">{moment(experience_item.from_date).format('MMM YYYY')} - {experience_item.till_now ? "till now" : moment(experience_item.to_date).format('MMM YYYY')}</div>
                                <div className="date">{experience_item.details ? nl2br(experience_item.details) : ""}</div>
                            </div>
                            {my_post ?
                            <div className="exp-edit-box">
                                <div className="list-meta">
                                    <span className="meta-item action-delete" tooltip="Delete" onClick={() => this.handleRemove(experience_item.uuid)}><i className="icon-delete"></i></span>
                                    <span className="meta-item action-edit" tooltip="Edit" onClick={this.handleEditExpModal}><i className="icon-edit"></i></span>
                                </div>
                            </div>
                            : ''}
                        </div>
                    </div>
                </div>
                {alert && <Alert message={api_response} type={msg_type}></Alert>}
                {experience_model ? <ExperienceModal alert={false} msg_type={''} addExperience_message={''} handleEditExperiance={this.handleEditExperiance} close={this.handleEditExpModal} item={experience_item} component_state={"Edit"} /> : ''}
            </>) : <> {alert && <Alert message={api_response} type={msg_type}></Alert>}</>
             
        )
        }
}