import React, {Component} from 'react'
import ArticleListing from '../Components/PostArticle/Article-listing'

class ArticleListingPage extends Component {

	render(){
		return(
			<div className="page-full page-profile-full">
                <ArticleListing />
            </div>
		)
	}
}
export default ArticleListingPage