import React from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import Alert from '../Alert';
import AddConnection from '../Home/add-connection';
const token = localStorage.getItem('token');
var config = require('../../react-config.js');
const api_url = config.api_url;

export default class Following extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: false,
            item: props.item,
            avatar: require('../../Assets/img/img-default.jpg'),
            display_component: true,
            onlyConnect: true,
        };
    }
    handleDropdown = () => {
        this.setState({
            dropdown: !this.state.dropdown
        })
    }

    handleStatus = async (data) => {
        // console.log("handle status called",data)
        const { item } = this.state;
        const url = api_url + 'user/profile/follow/add';
        
        if (token) {
            await axios.post(url, {user_id: item.users_id,follow_status:data},
                {
                    headers: { "Authorization": "Token " + token },
                   
                }).then((res) => {
                    // console.log("response inside following",res)
                    if (res && res.data && res.data.record) {
                        if (res.data.status === "success") {
                            this.props.connectionTotal("following")
                            this.setState({ display_component: false, alert: true, alert_msg: "Update Successfully", alert_type: 'success' })
                            //alert(message)
                           localStorage.setItem('user',JSON.stringify(res.data.record))
                        //   console.log(res.data.record)
                        this.props.onTotalResult(localStorage.getItem('user'))
                        }
                        this.setState({ status: res.data.record.status })
                       
                    }
                }).catch((error) => {
                    // this.props.history.push('/login');
                    console.log(error)
                })
        } else {
            this.props.history.push('/login');
        }
    }

    handleMessage = () => {
        
        if(this.state.item && this.state.item.username)
        {
            this.props.history.push('/messages/'+this.state.item.username);
        }
        else
        {
            this.props.history.push('/messages')
        }
    }

    
    render() {
        const { status, avatar,follow,onlyConnect } = this.state;
        let item = this.state.item;
        item['user_id'] = item.users_id;
        //item.push({user_id:item.users_id})
        //console.log('item', item)
       
        return (
            this.state.display_component ?
            <div className="user-list flex items-center">
                <div className="user-list-left">
                    <div className="thumb">
                        <img src={item && item.profile_image_thumb ? item.profile_image_thumb : avatar} alt={(item.first_name)?item.first_name:'User Image'}/>
                        <span className={false ? "user-status online" : "user-status"}></span>
                    </div>
                </div>
                <div className="user-list-info flex-auto">
                    <div className="username"><Link to={'/p/' + item.user_slug}>{item.first_name} {item.last_name && item.last_name}</Link></div>
                    <div className="organization">{item.organization}</div>
                    <div className="organization followers">{item.total_follower} followers</div>
                </div>
                <div className="user-list-right flex items-center network-actions">
                    { 
                        item.is_connected === true ? ( 
                            <>
                        <span className="btn btn-outline btn-sm" onClick={this.handleMessage}>Message</span>
                       </>
                        ) : ('')
                    }
                  {item.follow_status? 
                  (<span className="btn btn-outline btn-sm btn-following"><i className="icon-check"></i> Following</span>)
                :(<span className="btn btn-outline btn-sm btn-following"> Follow</span>)}

                    { 
                            item.is_connected === false ? ( 
                                <>

                            <AddConnection item={item} history={this.props.history} onlyConnect={onlyConnect}/>

                        </>
                        ) : (
                            <span className="btn btn-outline btn-sm btn-connected" tooltip="Connected"><i className="icon-handshake" title="Connected"></i></span>

                        )
                     }
                     <span className="btn-dot has-dropdown">
                        <i className="icon-open-menu" onClick={this.handleDropdown}></i>
                        {
                            this.state.dropdown ? (
                                <>
                                <div className="dropdown right dropwdown-nav">
                                    <ul className="nav">
                                        <li>{item.follow_status? (<span className="remove-action"  onClick={()=>this.handleStatus(false)}><i className="icon-close" ></i> Unfollow</span>)
                                        :<span className="remove-action"><i className="icon-check"></i> follow</span>}</li>
                                    </ul>
                                </div>
                                <div className="page-overlay" onClick={this.handleDropdown}></div>
                                </>
                            ) : ('')
                        }
                    </span>
                </div>
            </div>: <>{alert ? <Alert message={this.state.alert_msg} type={this.state.alert_type}></Alert> : ""}</>
        );
    }
}