import React, { Component } from 'react'
import NotificationList from './notificationList'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { get_user_data } from '../../utils.js';

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');
const url = api_url + 'notifications/list';
class Notifications extends Component {
    state = {
        notifications: [],
        total_records: 0,
        page:1,
        load_more: false,
        loader:false,
        limit:10
    }
    componentDidMount(){

        let user_data = get_user_data();
        let groups_id = (user_data) ? user_data.groups_id : '';
        //In case of curius mind user =6
        if((groups_id && (groups_id===4 || groups_id===5)) === false)
        {
            this.props.history.push('/');

        }

        this.getNotification(this.state.page ,this.state.limit)
        // this.getNotification()
        // .then((res)=>{
        //     console.log("notification list",res)
        //     if(res && res.data && res.data.records){
        //         this.setState({notifications:res.data.records,total_records: res.data.total_records});
        //     }else{
        //         this.setState({notifications:[], total_records:0});
        //     }
        // })
    }
    
    // getNotification = async () => {
    //     if(token){
    //         return await axios.post(url, {}, {
    //             headers: {
    //                 'Authorization': `token ${token}`
    //             }
    //         });
    //     }else{
    //         this.props.history.push('/login');
    //     }
    // }

        getNotification = async (page,limit) => {
            this.setState({loader:true})
        if(token){
             await axios.post(url, {page,limit}, {headers: {'Authorization': `token ${token}`}})
                        .then((res)=>{
                            console.log("notification list",res)
                            if(res && res.data && res.data.records){
                                this.setState({notifications:[...this.state.notifications,...res.data.records],total_records: res.data.total_records,loader:false});
                                // this.setState({notifications:[],total_records: res.data.total_records,loader:false});
                            }else{
                                this.setState({notifications:[], total_records:0,loader:false});
                             }
                         })
                         .catch((error) =>{
                             console.log("error",error)
                         })
        }else{
            this.props.history.push('/login');
        }
    }

    loadMore = async() => {
        const { page,notifications ,limit } = this.state;
        this.getNotification(page+1,limit)
              
    }
    
    render() {
        const {notifications,total_records} =this.state;
        console.log("notification" ,notifications)
        return (
            <div className="page-full page-connect-full">
                <Helmet>
                           <title>Notifications | AISquare.org</title>
                            <meta name="description" content="Notifications - AISquare.org"/>
                </Helmet>
                <div className="page-main-body">
                    <div className="container">
                     { this.state.loader ? 
                     (<div className="loading"></div>)
                     :
                     (<div className="container-inner">
                               <div className="heading-box">
                                   <div className="heading">Notifications </div>
                               </div>
                                    <div className="card card-body">
                                        {notifications.length !==0 ?  (<div className="user-list-view">
                                                {
                                                    notifications.map((item,i) => {
                                                        return <NotificationList item={item} key={i}></NotificationList>
                                                })
                                            }
                                        </div>):(<div className="user-list-view no-found color-danger text-center"><p>No Notifications Found</p></div>)}
                                    </div>
                        </div>)}
                    </div>
                </div>
                 {
                   (notifications && !this.state.loader ) && ((notifications.length && (notifications.length < total_records))?
                                        <div className="text-center">
                                                <button style={{marginBottom:"1%"}} className="btn btn-primary" onClick={this.loadMore} > Load More</button>
                                        </div>
                                        : '')
                }
                </div>
        )
    }
}
export default Notifications