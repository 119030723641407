import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyAdhh0rRa_ZgIFVmPQ3PfgD9XQntDD-pfM",
  authDomain: "python-project-413006.firebaseapp.com",
  databaseURL: "https://python-project-413006-default-rtdb.firebaseio.com",
  projectId: "python-project-413006",
  storageBucket: "python-project-413006.appspot.com",
  messagingSenderId: "188412063373",
  appId: "1:188412063373:web:6053d65fdeb408ad3f7cc4"
};
firebase.initializeApp(config);

export default firebase;
