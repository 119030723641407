import {connect} from 'react-redux';
import Search from './../Components/Search/';
import {getBlogData} from './../selectors';
import {
	postComments,
	setBlogList,
	setCommentAdd,
	setToWishList,
    setToAward,
    setToPin,
} from './../thunks';

export function mapStateToProps(state) {
    return {
    	getBlogData:getBlogData(state),
        loading:state.loading
    };
}

export function mapDispatchToProps(dispatch) {
    return {
    	handleCommentAdd: comment => dispatch(setCommentAdd(comment)),
    	onSetBlogList: data => dispatch(setBlogList(data)),
    	setToWishList: id => dispatch(setToWishList(id)),
        setToAward: id => dispatch(setToAward(id)),
        setToPin: id => dispatch(setToPin(id)),
    	getPostComments: data => dispatch(postComments(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)