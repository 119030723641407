import React, {Component} from 'react'
import ConnectItem from './Connect/ConnectItem'
import Following from './/Connect/Following'
import axios from 'axios';

var config = require('../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

class Search extends Component{
    constructor(props) {
        super(props);
        this.state = {
            sent : props.sent,
            received : props.received,
            is_connected : props.is_connected,
            tabActive : props.tabActive,
            history : props.history,
            value: '',
            conncections:[],
            following:[]
        }
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            //console.log('do validate');
            // console.log("text to search",this.state.value)
            this.handleSearch()
        }
    }

    handelSearchChange = (e) => {
        this.setState({ value: e.target.value })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.is_connected !== this.props.is_connected) {
            this.setState({is_connected: nextProps.is_connected,value:''});
            //console.log('nextProps.is_connected', nextProps.is_connected)
        }
    }
    handleSearch = async () => {
        if(token){
                let url = '';
                let params = {}

                if("/mynetwork/connections"=== window.location.pathname){
                    url = api_url + 'user/connection/list'
                    params = {search_name:this.state.value,connection_status:1}
                }
                else if("/mynetwork/following" === window.location.pathname){
                    url = api_url + 'user/profile/follow/list'
                    
                    if(this.state.is_connected)
                    {
                        params = {search_name:this.state.value, is_connected:this.state.is_connected}

                    }
                    else
                    {
                        params = {search_name:this.state.value}
                    }
                    
                 }else {
                    if(!this.props.invite){
                        url = api_url + 'user/connection/list'
                        params = {search_name:this.state.value,connection_status:0,received:this.props.received, sent:this.props.sent}
                    }else{
                        url = api_url + 'user/invitations/list'
                        params = {search_text:this.state.value}
                    }
                }
                
               // console.log(params)
                await axios.post(url,params,
                    {
                        headers: { "Authorization": "Token " + token },
                    }).then((res)=>{
                        // console.log("res inside search",res)
                        if(res && res.data && res.data.records){ 
                            this.props.onSearchResult(res.data.records)
                           
                        } else if(res && res.data){
                            this.props.onSearchResult(res.data.record)
                        }
                        else {
                            this.props.onSearchResult([])
                        }
                    }).catch((error)=>{
                        // this.props.history.push('/login');
                    })
        
        
        }else{
            this.props.history.push('/login');

        }
    }
    render(){
        const { value } = this.state;
        // console.log("props inside search",this.props)
        return(
               <div className="sarch-input">
                        <input type="text" placeholder="Search" className="form-style"  value={value} onKeyDown={this._handleKeyDown} onChange={this.handelSearchChange} />
                        <button type="button"  onClick={this.handleSearch}> <i className="icon-sarch"></i></button>
                </div>
          
        )
    }
}
export default Search;