import React, { Component } from 'react';
import { TagBox } from 'react-tag-box';
import { List } from 'immutable';
import axios from 'axios';

var config = require('../../react-config.js');
const api_url = config.api_url;
// const token = localStorage.getItem('token');

const skills = List();
class SkillsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: skills,
            selectedSkills: skills.take(0),
            loader: true,
            suggestion:[],
        }
    }
    componentDidMount = async () => {
        //this.skillsSuggestion();

        const { selectedSkills } = this.state;
        const { existing_skills } = this.props;
        const url = api_url + 'user/skill/list';
        await axios.post(url, {}, {
            // for send header
        }).then((res) => {
            if (res && res.data && res.data.records) {
                const options = res.data.records.map(n => {
                    const obj = { value: n.uuid, label: n.title }
                    return obj;
                })
                this.setState({ skills: options, loader: false });
                if (Array.isArray(existing_skills)) {
                    const exiting = existing_skills.map(n => {
                        return { value: n.title, label: n.title }
                    })
                    this.setState({ selectedSkills: selectedSkills.concat(exiting) });
                }
            }
        }).catch(err => {
            this.setState({ loader: false });
                
            });
    }

    skillsSuggestion = async () => {

        const { selectedSkills } = this.state;

        const url = api_url + 'user/profile-skill/suggestion';
        await axios.post(url, {}, {
            // for send header
        }).then((res) => {
            if (res && res.data && res.data.records) {
                const options = res.data.records.map(n => {
                    const obj = { value: n.uuid, label: n.title }
                    return obj;
                })
                this.setState({ suggestion: options});
                
            }
        });

    }

    handleAddSkills = (e) => {
        e.preventDefault();
        let skills = this.state.selectedSkills;
        let a_skills = [];
        skills.map(t => {
            a_skills.push(t.label)
        });
        this.props.handleAddSkillsApi(a_skills);
    }

    render() {
        const { skills, selectedSkills, loader, suggestion } = this.state;
        // console.log(suggestion);
        const onSelectSkill = skill => {
            const newSkill = {
                label: skill.label,
                value: skill.value ? skill.value : ''
            }
            this.setState({
                selectedSkills: selectedSkills.push(newSkill)
            })
        }
        const removeSkill = skill => {
            this.setState({
                selectedSkills: selectedSkills.filter(t => t.value !== skill.value)
            })
        }

        return (
            <div className="popup-full">
                <span className="close-action" onClick={this.props.close}><i className="icon-close"></i></span>
                <div className="popup-main popup-md">
                    <div className="popup-body">
                        <h4 className="text-center">Add Skills</h4>
                        {loader ? <div className="loading"></div>: 
                        <div className="edit-profile-form">
                            <form onSubmit={this.handleAddSkills}>
                                <div className="form-main-box">
                                    <div className="skills-search-outer">
                                        <div className="skills-search-box">
                                            <i className="icon-search"></i>
                                            <TagBox
                                                tags={skills}
                                                selected={selectedSkills.toJS()}
                                                onSelect={onSelectSkill}
                                                removeTag={removeSkill}
                                                backspaceDelete={true}
                                                placeholder={'Skill (ex: Data Analysis)'}
                                            />
                                        </div>
                                        {/* <div className="text-sm">You can add 44 more skills</div> */}
                                    </div>
                                    <div className="skills-selected">
                                        {selectedSkills._tail && Array.isArray(selectedSkills._tail.array) && selectedSkills._tail.array.map((t, index) => {
                                            return <span key={index} className="skills-tag selected" onClick={() => removeSkill(t)}>{t.label} <i className="icon-skills"></i></span>
                                        })}
                                    </div>

                                    {
                                        suggestion && suggestion.length ?(

                                    <div className="skills-suggested">
                                        <h5>Suggested skills based off your profile:</h5>
                                        {
                                            suggestion.map((suggestion_skill, key) => {
                                                return suggestion_skill.title !== '' ? 
                                                <span className="skills-tag" key={key}>{suggestion_skill.label} <i className="icon-skills"></i></span>:''
                                            })
                                        }
                                    </div>
                                    ):''

                                    }
                                    {/* <div className="skills-suggested">
                                        <h5>Suggested skills based off your profile:</h5>
                                        <span className="skills-tag">Python <i className="icon-skills"></i></span>
                                        <span className="skills-tag">Nodejs <i className="icon-skills"></i></span>
                                        <span className="skills-tag">MongoDb <i className="icon-skills"></i></span>
                                        <span className="skills-tag">Angular <i className="icon-skills"></i></span>
                                    </div> */}
                                    <div className="btn-outer row">
                                        <div className="col"><button type="submit" className="btn btn-primary btn-full">Add</button></div>
                                        <div className="col"><button type="submit" className="btn btn-primary btn-full btn-outline" onClick={this.props.close}>Cancel</button></div>
                                    </div>
                                </div>
                            </form>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}
export default SkillsModal