import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlogList from '../Home/blog-list';
import ListingSkeleton from '../Skeletons/Listing'
import Suggestion from './suggestion'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { limitWord } from '../../utils.js';
var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

export default class Search extends Component {
    constructor(props) {
        super(props);
        // console.log(props);
        var search_text = ''
        if(props.location && props.location.search && !(props.location.search.includes('tag=') || props.location.search.includes('stream=')))
        {
            search_text = (props.match.params.title) ? props.match.params.title : ((props.location && props.location.search) ? props.location.search.replace('?q=','') : '')
        }



        this.state = {
            data: [],
            data_length:0,
            page: 1,
            limit: 10,
            total_records: 0,
            loading_state: false,
            load_more: false,
            post_not_found: false,
            url: window.location.pathname,
            query: this.props.location.search,
            search_text: search_text,
            tag: this.props.location.state ? (this.props.location.state.tag ? this.props.location.state.tag.tag : '') : '',
            loader_on_click_load_button: false,
            post_message: 'Sorry, we could not find any matches for your search.',
            loadingScreens: [1,2,3,4,5,6],
            search: '',
            streamList: [],
            suggestion_list: [],
            show_suggestion_list: false,
            selectedStream : ''
        };
    }

    handleSearch = (e) => {
        this.setState({
            search_text: e.target.value
        })
        if(e.target.value && e.target.value.length >= 3)
        {
            this.postSuggestionList({'search_text':e.target.value});
        }
        else
        {
            this.setState({
                show_suggestion_list: false
            })
        }
    }

    postSuggestionData = async (params) => {
        return await axios.get(api_url+'post/eslist',
            {
                params: params,
                headers: token ? { "Authorization": "Token " + token } : {},
            }
        )
    }

    postSuggestionList = (params) => {
        this.postSuggestionData(params)
            .then((res) => {
                if(res.data && res.data.records)
                {
                    this.setState({ suggestion_list: res.data.records, show_suggestion_list: true});
                }
            });
    }

    handleSuggestionClick = (suggestion) => {
        if(suggestion)
        {
            if(suggestion.value)
            {
                this.props.history.push({
                    pathname: '/article/'+suggestion.value
                });
                this.setState({show_suggestion_list: false});
            }
            else
            {
                this.props.history.push({
                    pathname: '/search',
                    search: "?q=" + suggestion.label,
                })            
                this.setState({search_text:suggestion.label,show_suggestion_list: false});
            }
        }
    }

    handleSearchClick = () => {
        if(this.state.search_text)
        {
            this.props.history.push({
                pathname: '/search',
                search: "?q=" + this.state.search_text,
            })
        }
    }

    _handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSearchClick()
        }
    }

    handleStreamClick = (stream) => {
        this.setState({selectedStream:stream.name,tag:'',search_text:''})
        this.props.history.push({
            pathname: '/search',
            search: "?stream=" + stream.name,
        })
    }

    componentDidMount() {

        console.log("hello i am search is called")
         
        if(this.props.location.search)
        {
            this.setState({ data: [], page: 1, load_more: false, total_records: 0,data_length:0 });
            const { location } = this.props;
            const params = new URLSearchParams(location.search);
            const search_text = params.get('q');
            const tag = params.get('tag');
            const pin = params.get('pin');
            const uuid = params.get('stream');
            // console.log("Did Mount uuid=="+uuid);

            if(uuid !== '')
            {
                this.setState({selectedStream:uuid})

            }
            
            if(pin !==null)
            {
                //console.log("Did Mount pin=="+pin);
                this.setState({post_message:'You do not have any pinned posts.'});
            }
            this.postList(search_text, 1, tag, pin, [], uuid);
        }

        this.streamList()
            .then((res) => {
                if (Array.isArray(res.data.records)) {
                    this.setState({ streamList: res.data.records })
                }
            }).catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    }

    streamList = async () => {
        return await axios.post(api_url+'post/stream/list',
            {
                headers: {}
            })
    }

    postData = async (params) => {
        return await axios.get(api_url+'post/elist',
            {
                params: params,
                headers: token ? { "Authorization": "Token " + token } : {},
            }
        )
    }

    postList = (search, page, tag, pin, data, uuid) => {
        const { limit, load_more } = this.state;
        //console.log("tag", tag)
        this.setState({tag:tag})
        let params = { pin_post: pin, search_text: search, search_tag: tag, page: page, limit: limit, loadMore: load_more, search_stream: uuid };
        for (var propName in params) {
            if (params[propName] === null || params[propName] === undefined || params[propName] === '') {
                delete params[propName];
            }
        }
        if (data.length === 0) {
            this.setState({ loading_state: true });
        }
        this.setState({ loader_on_click_load_button: true });
        this.postData(params)
            .then((res) => {
                if (data.length === 0) {
                    this.setState({ data: [],data_length:0 });
                }
                if (res && Array.isArray(res.data.records)) {                    
                    const post_data = [...data, ...res.data.records]
                    var post_not_found = false
                    if(data.length === 0)
                    {
                        post_not_found = true
                    }
                    this.setState({ data: post_data, page: page, loadMore: true, total_records: res.data.totel_records, loading_state: false, loader_on_click_load_button: false, post_not_found:post_not_found, data_length:data.length+res.data.records.length})
                } else {
                    this.setState({ loading_state: false });
                }
            });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.getBlogData !== this.props.getBlogData) {

        }
        // const params = new URLSearchParams(this.props.location.search);
        const params_next = new URLSearchParams(nextProps.location.search);
        const search_text_next = params_next.get('q');
        const tag_next = params_next.get('tag');
        const pin_next = params_next.get('pin');
        const uuid_next = params_next.get('stream');
        // console.log('nextProps.search_text_next')
        // console.log(search_text_next)
        // console.log(this.props.location.search)
        // console.log(nextProps.location.search)
        if(this.props.location.search !== nextProps.location.search){
            if(nextProps.location.search === '')
            {
                this.setState({
                    data: [],
                    data_length:0,
                    page: 1,
                    limit: 10,
                    total_records: 0,
                    loading_state: false,
                    load_more: false,
                    post_not_found: false,
                    search_text: '',
                });
            }
            else
            {
                if(pin_next !==null)
                {
                    //console.log("Did Mount pin=="+pin);
                    this.setState({post_message:'You do not have any pinned posts.'});
                }
                else
                {
                    this.setState({post_message:'Sorry, we could not find any matches for your search.'});
                }
                this.postList(search_text_next, 1, tag_next, pin_next, [], uuid_next);
            }

            if(nextProps.location && nextProps.location.search && !(nextProps.location.search.includes('tag=') || nextProps.location.search.includes('stream=')))
            {
                var search_text = (nextProps.match.params && nextProps.match.params.title) ? nextProps.match.params.title : ((nextProps.location && nextProps.location.search) ? nextProps.location.search.replace('?q=','') : '');
                this.setState({search_text:search_text});
            }            
        }
    }

    loadMore = () => {
        const { data } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const search_text = params.get('q');
        const tag = params.get('tag');
        const pin = params.get('pin');
        const uuid = params.get('stream');
        const { page } = this.state;
        this.postList(search_text, page + 1, tag, pin, data, uuid);
        
    }
    final_data = () =>{
        this.setState({data_length:this.state.data_length - 1})
    }
    render() {

        const { total_records, data, loading_state, post_not_found, loader_on_click_load_button,data_length, search_text, streamList, suggestion_list, show_suggestion_list } = this.state;
        //console.log('search_text');
        //console.log(search_text);
       // console.log('this.state.tag',this.state.tag);
        //console.log(this.state.search_tag);
        let message = ''
        if(search_text!='' && this.state.tag != '' && this.state.tag)
            message = '"You have searched" for "' +search_text+'" and "#'+this.state.tag+'"'
        else if (this.state.tag != '' && this.state.tag)
            message = '"You have searched" for "#'+ this.state.tag+'"'
        else if (search_text!='')
            message = '"You have searched" for "'+search_text+'"'

            const meta_title = 'AISquare | Search'
            const meta_description = 'AISquare Search'
        return (
            <div className="page-full page-profile-full page-login-full">
                <Helmet>
					<title> { meta_title }</title>
					<meta name="description" content={limitWord(meta_description, 200)}/>
                </Helmet>
                <div className="page-main-body">
                    <div className="container">
                        <div className="container-inner">
                            <div className="search-main-outer">
                                <div className="heading1 text-center">Popular Streams</div>
                                <div className="search-tag-box">
                                    {streamList.map((stream, i) => {
                                        return <span
                                        className = {this.state.selectedStream === stream.name ? "search-tag-item active" :("search-tag-item")}
                                          onClick={()=>this.handleStreamClick(stream)} key={i}>{stream.name}</span>
                                    })}
                                </div>
                                <div className="card">
                                    <div className="search-stream-box">
                                        <div className="search-stream-main">
                                            <div className="search-stream-button">
                                                <button onClick={this.handleSearchClick} className="btn-search"><img src={require('../../Assets/img/search2.svg')} alt="" /></button>
                                            </div>
                                            <div className="search-stream-input"><input type="text" className="form-style" value={search_text} placeholder="Search for Articles or Researchers..." onChange={this.handleSearch} onKeyDown={this._handleSearchKeyDown} /></div>
                                            



                                            {(show_suggestion_list && suggestion_list.length > 0)?(
                                            <div className="suggestions">
                                                <ul className="suggestion-list">
                                                {
                                                    suggestion_list.map((sl,si)=>{
                                                        return <Suggestion sl={sl} key={si} handleSuggestionClick={this.handleSuggestionClick}/>
                                                    })
                                                }
                                                </ul>
                                            </div>
                                            ):null}
                                        </div>
                                    </div>

                                        

                                </div>
                                <div className="search-for"> {message} </div>
                                    {/* {
                                        (search_text!='' && this.state.tag != '' && this.state.tag)?(

                                        <div className="search-for">

                                            "You have searched" for "{search_text}" and "#{this.state.tag}" 

                                        </div>

                                        ):((this.state.tag != '' && this.state.tag) ?
                                        <div className="search-for">
                                             "You have searched" for  "#{this.state.tag}" 
                                        </div>:((search_text!='')?
                                        <div className="search-for">
                                             "You have searched" for "{search_text}" 
                                        </div>: ''))

                                    } */}
                            </div>
                        </div>

                        <p>&nbsp;</p>

                        {(loading_state) ? (
                            <div className="skeleton-screen">
                                {
                                    this.state.loadingScreens.map((item,i)=>{
                                        return <ListingSkeleton key={i} />
                                    })
                                }
                                
                            </div>                                
                        ) : (this.state.data && data_length > 0) ? (
                            <div className="listing-outer">
                                {
                                    this.state.data.map((item, i) => {

                                        return <BlogList
                                                    getPostComments={this.props.getPostComments}
                                                    handleCommentAdd={this.props.handleCommentAdd}
                                                    history={this.props.history}
                                                    item={item}
                                                    onSetBlogList={this.props.onSetBlogList}
                                                    setToAward={this.props.setToAward}
                                                    setToPin={this.props.setToPin}
                                                    setToWishList={this.props.setToWishList}
                                                    location={this.props.location}
                                                    final_data={this.final_data}
                                                    key = {i}
                                                    limited_actions = {true}
                                                />
                                    })
                                }
                                {data && (data.length && (data.length < total_records ?
                                    <div className="text-center">
                                        {loader_on_click_load_button ? <ListingSkeleton /> :
                                            <button className="btn btn-primary" onClick={this.loadMore} > Load More</button>}
                                    </div>
                                    : null)
                                )}
                            </div>
                        ) : (post_not_found) ? (
                            <div className="card">
                                <div className="card-body"><div className="no-found color-danger text-center">{this.state.post_message}</div></div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }
}

Search.propTypes = {
    getBlogData: PropTypes.array,
    getPostComments: PropTypes.func,
    handleCommentAdd: PropTypes.func,
    onSetBlogList: PropTypes.func,
    setToWishList: PropTypes.func,
    setToAward: PropTypes.func,
    setToPin: PropTypes.func,
};