import React, { Component } from 'react'
import renderHTML from 'react-render-html'
const default_img = require('./../Assets/img/img-default.jpg')

class Pinned extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: props.postData,
            pinloader: props.pinloader
        }
    }

    setDetail = (slug) => {
        this.props.history.push({
            pathname: '/article/' + slug,
        })
    }

    handleMore = () => {
        // this.props.onSetBlogList({ pin_post: true });
        console.log("history",this.props.history)
        this.props.history.push({
            //pathname: '/search',
            pathname: '/',
            search: '?pin=' + true,
        })
    }
    componentDidMount() {
        this.props.pinPostList();
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.postData !== this.props.postData) {
            this.setState({pinloader: nextProps.pinloader,posts:nextProps.postData});
        }
    }
    render() {
        const { posts, pinloader } = this.state;
         
        let postData = posts ? posts.slice(0, 5) : [];
        //console.log(posts,postData);
        return (
            pinloader ? <div className="card-body"><div className="loading"></div></div> :
                <>
                    {postData.length ? <>
                        <div className="notification-dd-box">
                            {postData.map((item, i) => {
                                return (
                                    <div className="post-item notification-item flex items-center" key={i} onClick={() => this.setDetail(item.slug)}>
                                        {
                                            (item.post_image) ? (

                                                <div className="post-img-box" style={{ backgroundImage: 'url(' + item.post_image + ')' }}></div>
                                            ) : (
                                                    <div className="post-img-box" style={{ backgroundImage: 'url(' + default_img + ')' }}></div>
                                                )
                                        }
                                        <div className="post-info-box flex-auto">
                                            <div className="title"><h5>{renderHTML(item.title)}</h5></div>
                                            <div className="post-meta user-info">
                                                <span className="user-meta">{item.date}</span>
                                                { item.total_likes > 0 ? <span className="user-meta"><span className="meta-count">{item.total_likes}</span> Likes</span>:''}
                                                { item.total_comments > 0 ? <span className="user-meta"><span className="meta-count">{item.total_comments}</span> Comments</span>:''}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className="dropdown-bottom">
                            <div className="view-all-action" onClick={this.handleMore}>View All</div>
                        </div>
                    </> : <div className="no-found color-danger text-center"><div className="card-body">No data Found</div></div>
                    }
                </>
        )
    }
}
export default Pinned