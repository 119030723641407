import {connect} from 'react-redux';
import Login from './../Components/Login/';
import {submitLogin} from './../thunks';
import {
	setToken,handleAccountType,
} from './../actions';


export function mapStateToProps(state) {
    return {};
}

export function mapDispatchToProps(dispatch) {
    return {
    	onHandleAccountType: type => dispatch(handleAccountType(type)),
    	onSubmitLogin: data => dispatch(submitLogin(data)),
    	onSetToken:token => dispatch(setToken(token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)