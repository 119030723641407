import {connect} from 'react-redux';
import Header from './../Components/Header';
import {
	handleAccountType,
} from './../actions';

import {
    handleLogout,
    setBlogList
} from './../thunks';

export function mapStateToProps(state) {
    return {
        logedin:true,
    };
}

export function mapDispatchToProps(dispatch) {
    return {
    	onHandleAccountType: type => dispatch(handleAccountType(type)),
        handleLogout: () => dispatch(handleLogout()),
        onSetBlogList: data => dispatch(setBlogList(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)