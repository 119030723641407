import React, {Component} from 'react';
import { FaBeer,FaEye,FaComment,FaThumbsUp,FaShare,FaThumbtack } from 'react-icons/fa';
import axios from 'axios';
var config = require('../../react-config.js');
const api_url = config.api_url;


class Satistics extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			 user_id:props.user_id,
			 statistics:{
				 view:0,
				 comment:0,
				 like:0,
				 pinned:0,
				 share:0
			 },
		}
	}
	

	componentDidMount()
	{
		   let url = api_url +'statistics/details';
		   let data ={
			   user_id:this.state.user_id
		   }
		   axios.post(url,data)
		         .then((response)=>{
					  return response.data.record
				 })
				 .then((data)=>{
					//  console.log("datat",data)
					this.setState({statistics:{...this.state.statistics,view:data.view_count,comment:data.comment_count,like:data.like_count,share:data.share_count,pinned:data.pinned_count}})
				 })
	}

	render(){

		// console.log("props in stattatics",this.props)

		const { statistics } = this.state
		console.log("statistics",statistics)
		
		return(
			<div className="card-body">
				<div className="profile-resume-panel text-center">
					<ul className="tab-nav list-item" style={{padding:"2%"}} >
		                <li><span className="tab-nav-item" ><FaEye title="Article View" size='2.7rem' color="#035AA6" /><p>{ statistics.view}</p></span></li>
						<li><span className="tab-nav-item" ><FaComment title="Comment" size='2.7rem' color="#035AA6"/><p>{ statistics.comment}</p></span></li>
						<li><span className="tab-nav-item" ><FaThumbsUp title="Like" size='2.7rem' color="#035AA6"/><p>{ statistics.like}</p></span></li>
						<li><span className="tab-nav-item" ><FaShare title="Share" size='2.7rem' color="#035AA6"/><p>{ statistics.share }</p></span></li>
						<li><span className="tab-nav-item" ><FaThumbtack title="Pinned" size='2.7rem' color="#035AA6"/><p>{ statistics.pinned}</p></span></li>
					</ul>
				</div>
			</div>
		)
	}
}
export default Satistics