import $ from 'jquery';
import React from 'react';
import {Link} from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import PropTypes from 'prop-types';
import Button from '../Button'
import PasswordInput from "react-password-indicator";
import Alert from '../Alert';
import {LinkedIn } from '../Linkedin';
import firebase from "../../firebase";
import axios from 'axios';
// import { LinkedIn } from 'react-linkedin-login-oauth2';
var config = require('../../react-config.js');
const api_url = config.api_url;
const clientId = config.clientId;
const state = config.state;



const errorMessages = {
  minLen: "Password is not long enough!",
  maxLen: val => `Password exceeded the maximum length of ${val}`,
};
export default class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        let first_name = (props.first_name)?props.first_name:'';
        let last_name = (props.last_name)?props.last_name:'';
        let email = (props.email)?props.email:'';
        let organization = (props.organization)?props.organization:'';
        let uuid = (props.uuid)?props.uuid:'';
        let saveType = (props.email)?props.saveType:'';

        this.state = { 
            btnText:props.btnText,
            errors:{},
            otp_errors:{},
            form:{user_type:props.type,first_name:first_name,last_name:last_name,email:email,organization:organization,uuid:uuid,saveType:saveType},
            otpVerify: false,
            otp: '',
            submitted: false,
            title: props.title,
            type : props.type,
            processing: false,
            hidden: true,
            visible: false, password: "", validation: {},
            alert: false,
            alert_msg: '',
            alert_type: '',
            signupWithoutOtp: true
        };
        this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.onhandleChange = this.onhandleChange.bind(this);
        this.validate_form = this.validate_form.bind(this);
        this.togglePasswod = this.togglePasswod.bind(this);
        
        // console.log("state inside constructor",this.state)
        // console.log(props.email)
    }

    onhandleChange = (event) => {
        let form = this.state.form;
        const name = event.target.name;
        const value = event.target.value;
        form[name] = value;
        this.setState({form: form});
        if(name === 'password'){
            this.handlePassword(value)
             //console.log("password",this.state.password)
        }

        if(name === 'first_name' && (value !== undefined || value !== '')){
            this.state.errors.first_name = "";
            $('input[name="first_name"]').removeClass('error');
        }

        if(name === 'email' && (value !== undefined || value !== '')){
            this.state.errors.email = "";
            $('input[name="email"]').removeClass('error');
        }

        if(name === 'username' && (value !== undefined || value !== '')){
            this.state.errors.username = "";
            $('input[name="username"]').removeClass('error');
        }

        if(this.props.type !== "Guest"){
            if(name === 'organization' && (value !== undefined || value !== '')){
                this.state.errors.organization = "";
                $('input[name="organization"]').removeClass('error');
            }
        }
       
    }

    handlePassword(password){
        let result = true;
        // $('#message').css("display", "block");
        if(password.length < 8){
            $('#length').addClass("form-error");
            $('#length').removeClass("form-success");
            result = false;
        }else{
            
            $('#length').removeClass("form-error");
            $('#length').addClass("form-success");
        }

        var lowerCaseLetters = /[a-z]/g;
        if(password.match(lowerCaseLetters)) {
            $('#letter').removeClass("form-error");
            $('#letter').addClass("form-success");
        } else {
            $('input[name="password"]').addClass('error');
            $('#letter').addClass("form-error");
            $('#letter').removeClass("form-success");
            result = false;
        }

        var upperCaseLetters = /[A-Z]/g;
        if(password.match(upperCaseLetters)) {
            $('#capital').removeClass("form-error");
            $('#capital').addClass("form-success");
        } else {
            $('input[name="password"]').addClass('error');
            $('#capital').addClass("form-error");
            $('#capital').removeClass("form-success");
            result = false;
        }

        var numbers = /[0-9]/g;

        if(password.match(numbers)) {
            $('#number').removeClass("form-error");
            $('#number').addClass("form-success");
        } else {
            $('input[name="password"]').addClass('error');
            $('#number').addClass("form-error");
            $('#number').removeClass("form-success");
            result = false;
        }

        if(/^[a-zA-Z0-9- ]*$/.test(password) === false) {
            $('input[name="password"]').removeClass('error');
            $('#spchar').removeClass("form-error");
            $('#spchar').addClass("form-success");
        }else{
            $('input[name="password"]').addClass('error');
            $('#spchar').addClass("form-error");
            $('#spchar').removeClass("form-success");
            result = false;
        }

        if (result === true){
            $('#message').css("display", "none");
        }
        return result;
    }

    validate_form(form_data){
        var errors = {};
        let first_name = form_data['first_name'];
        // let last_name = form_data['last_name'];
        let email = form_data['email'];
        let password = form_data['password'];
        let username = form_data['username'];
        let organization = form_data['organization'];
        
        if(first_name === undefined || first_name === ''){
            errors['first_name'] = "First Name required.";
            $('input[name="first_name"]').addClass('error');

        }

        if(email === undefined || email === ''){
            errors['email'] = "Email required.";
            $('input[name="email"]').addClass('error');
        }else{
            var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if(reg.test(email) === false)
            {
                errors['email'] = "Invalid email.";
                $('input[name="email"]').addClass('error');
            }
        }

        if(username === undefined || username === ''){
            errors['username'] = "Username required.";
            $('input[name="username"]').addClass('error');
        }

        // if(password === undefined || password === ''){
        //     errors['password'] = "Password required.";
        //     $('input[name="password"]').addClass('error');
        // }else{
        //     const passres = this.handlePassword(password);
        //     $('#message').css("display", "none");
        //     if(passres === false){
        //         errors['password'] = "Password contain atleast one uppercase, one lowercase, one number, one special characters and at least 8 or more characters.";
        //         $('input[name="password"]').addClass('error');
        //     }
        // }

        if(this.state.validation.errors && this.state.validation.errors.length > 0){
           // console.log(this.state.validation.errors)
            errors['password'] = "invalid Password.";
            $('input[name="password"]').addClass('error');
        }else {
            form_data.password = this.state.password
            $('input[name="password"]').removeClass('error');
        }

        if(this.props.type !== "Guest")
        {
            if(organization === undefined || organization === ''){
                errors['organization'] = "School/Organization required.";
                $('input[name="organization"]').addClass('error');
            }
        }



        const error_len = Object.keys(errors).length;

        this.setState({errors:errors});

        if(error_len > 0){
            return false;
           
        }else{
            return true;
         }

       
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        const form_data = this.state.form;
        // console.log("form data",form_data)
        let errors = {};

        if(this.validate_form(form_data) === true){ 
            this.setState({ processing: true });
            this.props.handleSubmitRegister(form_data).then((res) => {
                if(res.status==='success'){
                    //this.setState({submitted:true});
                    this.setState({otpVerify:true,processing: false});
                }
                else
                {
                    if (res.otp_verified && res.otp_verified === "false") {
                        this.setState({signupWithoutOtp:false})
                    }
                     errors['password'] = res.message;
                    // console.log(res.message)
                     this.setState({errors:errors,processing: false});
                }
            });            
        }      
    }

    onhandleOtpChange = (event) => {
        
        const name = event.target.name;
        const value = event.target.value;
        
        this.setState({otp: value});
    }

    validate_otp_form(otp){
        var otp_errors = {};

        if(otp === undefined || otp === ''){
            otp_errors['otp'] = "OTP required.";
            $('input[name="otp"]').addClass('error');
        }

        const error_len = Object.keys(otp_errors).length;

        this.setState({otp_errors:otp_errors});

        if(error_len > 0){
            return false;
        }else{
            return true;
        }
    }

    handleResendOtp = (e) => {
        e.preventDefault();
        this.setState({otpVerify:true,processing: false,alert: false,
            alert_msg: '',
            alert_type: '',})
        const form_data = this.state.form;
        let email =this.state.form.email;
        //let email ='anand@ehostinguk.com';
        let otp_errors = {};

        //alert(email);return false;

        if(email){
            const url = api_url + 'user/resend_otp';
            let _this = this;
            let data = {};            
            data['email'] = this.state.form.email;
            $.ajax({
                    type: 'POST',
                    url,
                    data: JSON.stringify(data),
                    //headers:{"Authorization": "Token "+token},
                    contentType: 'application/json',
                    dataType: 'json',
                    beforeSend: function() {                        
                       
                    },
                    success: function(res) {
                        //alert('res')
                        //alert(res)                        
                        if(res.status==='success'){
                          //  alert('OTP resend successfully')
                          _this.setState({  alert: true, alert_msg: "OTP Sent Successfully!", alert_type: 'success' })
                        }
                        else
                        {
                            //alert('v')
                            otp_errors['otp'] = (res.message)?res.message:'Resend OTP Error.';
                            $('input[name="otp"]').addClass('error');
                            _this.setState({otp_errors:otp_errors});
                        }
                    }
                }).catch(function (error) {
                //console.log(error.responseJSON.message);                

                otp_errors['otp'] = (error.responseJSON.message)?error.responseJSON.message:'Resend OTP Error.';
                $('input[name="otp"]').addClass('error');
                _this.setState({otp_errors:otp_errors});
            });
        }        
    }

    handleOtpSubmit = (e) => {
        e.preventDefault();
        const form_data = this.state.form;
        const otp = this.state.otp;
        let otp_errors = {};

        if(this.validate_otp_form(otp) === true){
            this.setState({ processing: true });
             //alert('OTP submited')
            // alert(otp)
            const url = api_url + 'user/verify_otp';
            let _this = this;
            let data = {};
            data['otp'] = this.state.otp.trim();
            data['email'] = this.state.form.email;

            $.ajax({
                    type: 'POST',
                    url,
                    data: JSON.stringify(data),
                    //headers:{"Authorization": "Token "+token},
                    contentType: 'application/json',
                    dataType: 'json',
                    beforeSend: function() {                        
                       
                    },
                    success: function(res) {
                        //alert('res')
                        //alert(res)
                        
                        if(res.status==='success'){
                            _this.setState({otpVerify:false, submitted:true, processing:false});
                        }
                        else
                        {
                            //alert('v')
                            otp_errors['otp'] = (res.message)?res.message:'Wrong OTP.';
                            $('input[name="otp"]').addClass('error');
                            _this.setState({otp_errors:otp_errors,processing:false});
                        }                        
                    }
                }).catch(function (error) {
                //console.log(error.responseJSON.message);
                

                otp_errors['otp'] = (error.responseJSON.message)?error.responseJSON.message:'Wrong OTP.';
                $('input[name="otp"]').addClass('error');
                _this.setState({otp_errors:otp_errors});

            });
            
           // 
        }
        
    }

    togglePasswod=()=> {
        this.setState({ hidden: !this.state.hidden });
     }


     componentWillReceiveProps = (nextProps) => {
        //  console.log("component will receive props inside form",nextProps)
        let form = this.state.form;
        
        if(nextProps.first_name !==this.props.first_name){
            form['first_name'] = nextProps.first_name     
            
        }

        if(nextProps.last_name !==this.props.last_name){
            form['last_name'] = nextProps.last_name     
            
        }

        if(nextProps.email !==this.props.email){
            form['email'] = nextProps.email     
            
        }

        if(nextProps.organization !==this.props.organization){
            form['organization'] = nextProps.organization     
            
        }

        if(nextProps.uuid !==this.props.uuid){
            form['uuid'] = nextProps.uuid     
            
        }

        if(nextProps.saveType !==this.props.saveType){
            form['saveType'] = nextProps.saveType     
            
        }
        
        this.setState({form: form},() =>console.log("form data inside  ",this.state.form));

        console.log('nextProps.saveType')
        console.log(nextProps.saveType)
    }

    handleSuccess = (data) => {
        console.log("authorization code",data)
        this.setState({
            authorization_code: data.code,
            errorMessage: '',
        });

        let dataa = {
            authorization_code:data.code,
            uuid:this.state.form.uuid,
            saveType:this.state.saveType,
            action_type:'register',
            user_type:this.state.form.user_type
        }

        let url = api_url + 'user/linkedin_login'
           
        axios.post(url,dataa)
              .then((res) =>{
                  console.log("res",res)
                  if(res.status === 'error')
                  {
                           console.log("i am error called")
                  }else{
                     console.log("i am going to register user ")       
                    // console.log('res.user_jwt')
                    // console.log(res.user_jwt)
                    firebase.auth().signInWithCustomToken(res.data.user_jwt).then( async signedInUser => {
                        await localStorage.setItem('token',res.data.token);
                        await localStorage.setItem('user',JSON.stringify(res.data.user));
                        await this.props.onSetToken(res.data.token);
                        this.renderThumbImage(res.data.user.profile_image_thumb)
                        window.location.href = `/p/${res.data.user.slug}`;
                        //this.props.history.push('/');
                    }).catch(function(error) {
                        console.log("firebase error",error)
                    });
                  }
                })
              .catch((error) =>console.log("error",error))
      }

      renderThumbImage = async (profile_image_thumb) =>{
        if(profile_image_thumb){
        const url = profile_image_thumb
        await axios.get(url, {}, {
            
        }).then((res) => {
           
        }).catch((res) => {
           
        });
    }
    }
    
      handleFailure = (error) => {
        this.setState({
            authorizeCode: '',
          errorMessage: error.errorMessage,
        });
      }

    render() {
        // console.log("this state",this.props)
        // console.log("props inside forms",this.props)
        let redirectUri = window.location.origin+ "/linkedin";
        const{alert} = this.state
        return(
            <div className="page-full page-login-full flex items-center">
                {this.state.submitted ? '' : <span className="back-action" onClick={this.props.back}><i className="icon-back"></i></span>}            
                <div className="page-center">
                    <div className="page-body">
                        <div className="form-box">
                            <div className="card">
                                <div className="card-body">
                                    {
                                        this.state.submitted ? (
                                            (this.state.type === 'Researcher') ? (
                                                <>
                                                <div className="heading-box text-center"><div className="heading">Submitted</div></div>
                                                <div className="form-main text-center">
                                                    
                                                    <p>You have successfully registered as researcher. Please login to continue.</p>
                                                
                                                    
                                                    <div className="btn-outer">
                                                        <Link to="/login" className="btn btn-outline">Sign In</Link>
                                                    </div>
                                                </div>
                                                </>
                                            ) : 
                                            (this.state.type === 'Guest') ? (
                                                <>
                                                <div className="heading-box text-center"><div className="heading">Submitted</div></div>
                                                <div className="form-main text-center">
                                                    
                                                    <p>You have successfully registered as Curious Mind. Please login to continue.</p>
                                                
                                                    
                                                    <div className="btn-outer">
                                                        <Link to="/login" className="btn btn-outline">Sign In</Link>
                                                    </div>
                                                </div>
                                                </>
                                            ) :
                                            (
                                                <>
                                                <div className="heading-box text-center"><div className="heading">Thank You!</div></div>
                                                <div className="form-main text-center">
                                                <p>You have successfully registered as student correspondent. Please login to continue.</p>
                                                
                                                    
                                                    <div className="btn-outer">
                                                        <Link to="/login" className="btn btn-outline">Sign In</Link>
                                                    </div>
                                                </div>
                                                </>
                                            )                    
                                        ) : (
                                        
                                        this.state.otpVerify ? (
                                        <>

                                        <div className="heading-box text-center"><div className="heading">Verify Email</div></div>
                                        <div className={this.state.processing ? "form-main form-processing" : "form-main"}>
                                            <form onSubmit={this.handleOtpSubmit}>
                                                <div className="form-inner">         
                                                    
                                                    <div className="row">
                                                        
                                                        <div className="col">
                                                            <div className="form-item">
                                                                <input autoComplete="off"
                                                                    className="form-style text-center"
                                                                    name="otp"
                                                                    onChange={this.onhandleOtpChange}
                                                                    placeholder="Enter OTP"
                                                                    type="text"
                                                                    value={(
                                                                        this.state.otp
                                                                    ) ? this.state.otp : '' }
                                                                />
                                                                <span className='form-error text-center'>{
                                                                    (
                                                                        this.state.otp_errors && this.state.otp_errors.otp
                                                                    ) ? this.state.otp_errors.otp: ''
                                                                }</span>

                                                                <div className="link text-sm" onClick={this.handleResendOtp}>Resend OTP?</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <Button
                                                            type={'submit'}
                                                            className={'btn btn-primary btn-full'} 
                                                            value={'Verify'} 
                                                            processing={this.state.processing}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        </>
                                        ):(                                  



                                            <>
                                            <div className="heading-box text-center"><div className="heading">{this.state.title}</div></div>
                                            <div className={this.state.processing ? "form-main form-processing" : "form-main"}>
                                                <form onSubmit={this.handleSubmit} autoComplete="off">
                                                    <div className="form-inner">
                                                        
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-item">
        
                                                                    <input
                                                                        className="form-style"
                                                                        name="first_name"
                                                                        onChange={this.onhandleChange}
                                                                        placeholder="First Name"
                                                                        type="text"
                                                                        value={(
                                                                                this.state.form && this.state.form.first_name
                                                                            ) ? this.state.form.first_name : ''}
                                                                    />
                                                                    <span className='form-error'>{
                                                                        (
                                                                            this.state.errors && this.state.errors.first_name
                                                                        ) ? this.state.errors.first_name: ''
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-item">
                                                                    <input
                                                                        className="form-style"
                                                                        name = "last_name"
                                                                        onChange={this.onhandleChange}
                                                                        placeholder="Last Name"
                                                                        type="text"
                                                                        value={(
                                                                            this.state.form && this.state.form.last_name
                                                                        ) ? this.state.form.last_name : ''}
                                                                    />
                                                                    <span className='form-error'>{
                                                                        (
                                                                            this.state.errors && this.state.errors.last_name
                                                                        ) ? this.state.errors.last_name: ''
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-item">
                                                                    <input
                                                                        autoComplete="off"
                                                                        className="form-style"
                                                                        name="username"
                                                                        onChange={this.onhandleChange}
                                                                        placeholder="Username"
                                                                        type="text"
                                                                        value={(
                                                                            this.state.form && this.state.form.username
                                                                        ) ? this.state.form.username : ''}
                                                                    
                                                                    />
                                                                    <span className='form-error'>{
                                                                        (
                                                                            this.state.errors && this.state.errors.username
                                                                        ) ? this.state.errors.username: ''
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-item">
                                                                    <input
                                                                        className="form-style"
                                                                        name="email"
                                                                        onChange={this.onhandleChange}
                                                                        placeholder="Email"
                                                                        type="text"
                                                                        value={(
                                                                            this.state.form && this.state.form.email
                                                                        ) ? this.state.form.email : ''}
                                                                    />
                                                                    <span className='form-error'>{
                                                                        (
                                                                            this.state.errors && this.state.errors.email
                                                                        ) ? this.state.errors.email: ''
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-item form-password-item">
                                                                    {/* <input
                                                                        autoComplete="off"
                                                                        className="form-style"
                                                                        name="password"
                                                                        onChange={this.onhandleChange}
                                                                        placeholder="Password"
                                                                        type="password"
                                                                        value={(
                                                                            this.state.form && this.state.form.password
                                                                        ) ? this.state.form.password : ''}
                                                                    /> */}
                                                                    {/* {(this.state.form && this.state.form.password) ? (<PasswordStrengthBar password={this.state.form.password} />) : '' } */}
                                                                    {/* <span className='form-error'>{
                                                                        (
                                                                            this.state.errors && this.state.errors.password
                                                                        ) ? this.state.errors.password: ''
                                                                    }</span>
                                                                    <div id="message" style={{'display':'none'}}>
                                                                        <h3>Password must contain the following:</h3>
                                                                        <p id="letter" className="form-error">A <b>lowercase</b> letter</p>
                                                                        <p id="capital" className="form-error">A <b>capital (uppercase)</b> letter</p>
                                                                        <p id="number" className="form-error">A <b>number</b></p>
                                                                        <p id="spchar" className="form-error">A <b>special characters</b></p>
                                                                        <p id="length" className="form-error">Minimum <b>8 characters</b></p>
                                                                    </div> */}

                                                                <PasswordInput
                                                                    minLen={8} 
                                                                    digits={1} 
                                                                    specialChars={1} 
                                                                    uppercaseChars={1} 
                                                                    lowerCaseLetters = {1}
                                                                    defaultMessages={errorMessages}
                                                                    isVisible={this.state.visible}
                                                                    onChange={(password, validation) =>
                                                                     this.setState({ password, validation })
                                                                     
                                                                    // this.handlePassword
                                                                     }
                                                                    >
                                                                    {({
                                                                    getInputProps,
                                                                    getProgressProps,
                                                                    valid,
                                                                    touched
                                                                    }) => (
                                                                    <div>
                                                                       
                                                                        <input
                                                                        {...getInputProps()}
                                                                        value={this.state.password}
                                                                        className={touched ? (valid ? "valid" : "invalid") : ""}
                                                                        autoComplete="off"
                                                                        className="form-style"
                                                                        name="password"
                                                                        placeholder="Password"
                                                                        type={this.state.hidden ? 'password' : 'text'}/>
                                                                       
                                                                       <span className={this.state.hidden? 'show pwd-show-hide' :'hide pwd-show-hide'} onClick={this.togglePasswod}><svg id="Capa_1" enable-background="new 0 0 551.121 551.121" height="512" viewBox="0 0 551.121 551.121" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m275.561 68.887c-123.167 0-233.534 80.816-274.64 201.107-1.228 3.616-1.228 7.518 0 11.134 41.106 120.291 151.473 201.107 274.64 201.107s233.534-80.816 274.64-201.107c1.228-3.616 1.228-7.518 0-11.134-41.107-120.291-151.474-201.107-274.64-201.107zm0 378.902c-106.532 0-202.284-68.975-240.077-172.228 37.793-103.253 133.544-172.228 240.077-172.228 106.532 0 202.284 68.975 240.077 172.228-37.793 103.253-133.545 172.228-240.077 172.228z"></path><path d="m275.561 172.224c-56.983 0-103.337 46.354-103.337 103.337s46.354 103.337 103.337 103.337 103.337-46.353 103.337-103.337-46.354-103.337-103.337-103.337zm0 172.228c-37.995 0-68.891-30.897-68.891-68.891 0-37.995 30.897-68.891 68.891-68.891 37.995 0 68.891 30.897 68.891 68.891s-30.897 68.891-68.891 68.891z"></path></svg></span>
                                                                        
                                                    
                                                                    </div>
                                                                    )}
                                                                </PasswordInput>
                                                               
                                                                </div>
                                                            </div>
                                                           { this.props.type !=="Guest" ? (<div className="col">
                                                                <div className="form-item">
                                                                    <input
                                                                        className="form-style"
                                                                        name="organization"
                                                                        onChange={this.onhandleChange}
                                                                        placeholder="School/Organization"
                                                                        type="text"
                                                                        value={(
                                                                            this.state.form && this.state.form.organization
                                                                        ) ? this.state.form.organization : '' }
                                                                    />
                                                                    <span className='form-error'>{
                                                                        (
                                                                            this.state.errors && this.state.errors.organization
                                                                        ) ? this.state.errors.organization: ''
                                                                    }</span>
                                                                </div>
                                                             </div>):(<div className="col"></div>)}
                                                            
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                            <span className='form-error'>{
                                                                            (
                                                                                this.state.errors && this.state.errors.password
                                                                            ) ? this.state.errors.password: ''
                                                                        }
                                                                           {this.state.errors && this.state.errors.password && !this.state.signupWithoutOtp
                                                                            ?  <div className="link text-sm" onClick={this.handleResendOtp}> Click here to verify your email. </div>:''}</span> 
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {this.state.validation.errors && (
                                                                <div className="pwd-validation-box">
                                                                <ul> 
                                                                    {this.state.validation.errors.map(e => (
                                                                    <li key={e.key}>{e.message}</li>
                                                                    ))}
                                                                </ul> 
                                                                </div>
                                                            )} 
                                                        </div>
                                                        <div className="btn-outer">
                                                            <Button
                                                                type={'submit'} 
                                                                className={'btn btn-primary btn-full'} 
                                                                value={this.state.btnText} 
                                                                processing={this.state.processing}
                                                            />
                                                        </div>
                                                        <LinkedIn
                                                        clientId={clientId}
                                                        redirectUri={redirectUri}
                                                        scope="r_liteprofile,r_emailaddress"
                                                        state={state}
                                                        onFailure={this.handleFailure}
                                                        onSuccess={this.handleSuccess}
                                                        supportIE
                                                        >                              
                                                         <span className="linkedin-icon-setup-button" >in</span>  Continue With LinkedIn 
                                                       </LinkedIn>
                                                    </div>
                                                </form>
                                            </div>
                                            </>
                                        )

                                        )
                                    }
                                     
                                </div>    
                               
                            </div>
                        </div>
                    </div>
                </div>
                {alert ? <Alert message={this.state.alert_msg} type={this.state.alert_type}></Alert> : ""}
            </div>
        )
    }
}

RegisterForm.propTypes = {
    back: PropTypes.func,
    btnText: PropTypes.string,
    handleSubmitRegister: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
    onSetToken: PropTypes.func
};