import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom'
import Alert from '../Alert';
var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');
let user = {}
if(localStorage.getItem('user'))
{
    user = JSON.parse(localStorage.getItem('user'));
}

export default class ConnectItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            sent : props.sent,
            received : props.received,
            // default status 0-Pending, 1- accepted, 2- ignore
            status: props.item.status,
            note : (user.users_id === props.item.by_user_id) ? props.item.by_user_note : props.item.user_note,
            avatar: require('../../Assets/img/img-default.jpg'),
            // message:'',
            dropdown: false,
            display_component: true,
            alert: false,
            alert_msg: '',
            alert_type: '',
            note_form: false,
            
        };
    }
    handleStatus = async (data) => {
        const { item } = this.state;
        const url = api_url + 'user/connection/update';
        let requestData = {}
        if (token) {
        
          if(this.state.status === 1){
              if(user.users_id === item.by_user_id)
                  requestData = {user_id: item.by_user_id,id:item.id , cancel: data}
              else   requestData = {user_id: item.by_user_id,id:item.id , delete: data}
          }
           else if(this.state.received){
                requestData = {user_id: item.by_user_id,id:item.id , connection_status: data}
            }else if (this.state.sent) {
                // console.log( item.by_user_id ,item.id ,true)
                requestData = {user_id: item.by_user_id,id:item.id , cancel: data}
            }
            await axios.post(url,requestData,
                {
                    headers: { "Authorization": "Token " + token },
                   
                }).then((res) => {
                    if (res && res.data && res.data.record) {
                        if (res.data.record.status === 1) {
                            
                            let message = res.data.record.first_name + ' is now a connection, Message.'
                            this.setState({ display_component: false, alert: true, alert_msg: "Update Successfully", alert_type: 'success' })
                            
                            
                             if(this.state.status === 1) this.props.connectionTotal("network")
                             else if(this.state.received) this.props.connectionTotal("received")

                        }else if(res.data.record.status === 0){
                           // console.log(res.data.record)
                            this.props.connectionTotal("sent")
                            this.setState({ display_component: false, alert: true, alert_msg: "Request Cancel Successfully", alert_type: 'success' })
                        }
                        this.setState({ status: res.data.record.status })
                        localStorage.setItem('user',JSON.stringify(res.data.user_record))
                        this.props.onTotalResult(localStorage.getItem('user'))
                        
                    }
                }).catch((error) => {
                    // this.props.history.push('/login');
                    console.log(error)
                })
        } else {
            this.props.history.push('/login');
        }
    }
    handleDropdown = () => {
        this.setState({
            dropdown: !this.state.dropdown
        })
    }

    handleNoteChange = (e) => {
        const { value } = e.target;
        this.setState({note: value})
    }
    handleMessage = () => {
        // console.log('this.state.item')
        // console.log(this.state.item)
        if(this.state.item && this.state.item.connection_details && this.state.item.connection_details.username)
        {
            this.props.history.push('/messages/'+this.state.item.connection_details.username);
        }
    }

    handleToogleNote = () => {
        const NoteVisibility = this.state.note_form;
        this.setState({note_form: !NoteVisibility})
    }

    handleNoteSubmit = async () => {
        const { note, item } = this.state;
        const this1 = this;
        this1.setState({ alert: false, alert_msg: "", alert_type: '' })
        if(note === ""){
            this1.setState({ alert: true, alert_msg: "Note is required", alert_type: 'error' })
            //alert("note is required");
            return false;
        }

        const url = api_url + 'user/connection/add_note';
        let requestData = {}
        if (token) {
            if(user.users_id === item.by_user_id)
                requestData = {
                    by_user_note: note,
                    id: item.id
                }
            else{
                requestData = {
                    id: item.id,
                    user_note: note
                }
            }
            await axios.post(url,requestData,
                {
                    headers: { "Authorization": "Token " + token },
                   
                }).then((res) => {
                    if (res && res.data && res.data.record) {
                        const message = "Note added successfully.";
                        this1.setState({
                            alert: true,
                            alert_msg: message,
                            alert_type: 'success'
                        });
                        //alert(message);
                        this1.handleToogleNote(); 
                    }
                }).catch((error) => {
                    // this.props.history.push('/login');
                    // this1.setState({ alert: true, alert_msg: error, alert_type: 'error' })

                    console.log(error)
                })
        } else {
            this.props.history.push('/login');
        }

    }

    render() {        

        // console.log("state",this.state)

        const { item, status, avatar , alert } = this.state;
        //console.log('alert')
        //console.log(alert)

       return (
            this.state.display_component ?
             <div className="user-list flex items-center">
            { this.props.item.request_type === false ?
             ( <>
                <div className="user-list-left">
                    <div className="thumb">
                        <img src={item.connection_details && item.connection_details.profile_image_thumb ? item.connection_details.profile_image_thumb : avatar} alt="User Image" />
                        <span className={false ? "user-status online" : "user-status"}></span>
                    </div>
                </div>
                <div className="user-list-info flex-auto">
                    <div className="username">
                        <Link to={'/p/' + item.connection_details.slug}>{item.connection_details.first_name} {item.last_name && item.last_name}</Link></div>
                    <div className="organization">{item.connection_details && item.connection_details.organization ? item.connection_details.organization: ""}</div>
                    <div className="organization">{item.connection_details && item.connection_details.about_me ? item.connection_details.about_me: ""}</div>
                </div>
                </>):(
                       <div className="user-list-info flex-auto" >
                            <div className="organization"  ><h1>{this.props.item.email}</h1></div>
                        </div>
                    )
             }
                <div className="user-list-right flex items-center">
                    {
                        this.props.invitation === 1 ? (
                            status===0 ? <>
                    <span className="link" onClick={()=>this.handleStatus(2)}>IGNORE</span>
                    <span className="btn btn-outline btn-sm" onClick={()=>this.handleStatus(1)}>ACCEPT</span>
                    </> : 
                    ( status===1 ? 
                        (
                            <>
                                <span className="btn btn-outline btn-sm" onClick={this.handleMessage} >Message</span>
                                <span className="btn-dot has-dropdown">
                                    <i className="icon-open-menu" onClick={this.handleDropdown}></i>
                                    {
                                        this.state.dropdown ? (
                                            <>
                                            <div className="dropdown right dropwdown-nav">
                                                <ul className="nav">
                                                    <li><span className="remove-action" onClick={()=>this.handleStatus(true)}><i className="icon-delete"></i> Remove connection</span></li>
                                                    <li><span className="remove-action" onClick={()=>this.handleToogleNote()}><i className="icon icon-plus"></i> Add note</span></li>
                                                    {(this.state.note_form) ? (
                                                        <div className="msg-action-popup">
                                                            <div className="msg-action-inner">
                                                                <div className="msg-textbox"><textarea className="form-style" value={this.state.note} onChange={this.handleNoteChange}></textarea></div>
                                                                <div className="msg-post-btn">
                                                                    <button className="btn btn-sm" onClick={this.handleToogleNote}>Cancel</button>
                                                                    <button className="btn btn-sm" onClick={this.handleNoteSubmit}>Add Note</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null }
                                                </ul>
                                            </div>
                                            <div className="page-overlay" onClick={this.handleDropdown}></div>
                                            </>
                                        ) : ('')
                                    }
                                </span>
                            </>
                        )
                        : <span className="link">IGNORED</span>)
                        ) : (
                            <span className="btn btn-outline btn-sm btn-danger" onClick={()=>this.handleStatus(true)}>WITHDRAW</span>
                        )
                    }
                </div>
                {alert ? <Alert message={this.state.alert_msg} type={this.state.alert_type}></Alert> : ""}
            </div>: ''
        );
    }
}

ConnectItem.propTypes = {
    item: PropTypes.object.isRequired,
};