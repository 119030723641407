import {connect} from 'react-redux';
import Details from './../Components/Details/Details';

import {
    postComments,
    setBlogList,
	setCommentAdd,
	setToWishList,
    setToAward,
    setToPin,
} from './../thunks';

export function mapStateToProps(state) {
    //console.log('DetailsContainer-mapStateToProps(state)=', state)
    return {
    	details:JSON.parse(localStorage.getItem('BlogDetails')),
    };
}

export function mapDispatchToProps(dispatch) {
    return {
    	getPostComments: data => dispatch(postComments(data)),
    	handleCommentAdd: comment => dispatch(setCommentAdd(comment)),
    	handleToWishList: id => dispatch(setToWishList(id)),
        handleToAward: id => dispatch(setToAward(id)),
        handleToPin: id => dispatch(setToPin(id)),
        onSetBlogList: data => dispatch(setBlogList(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
