import $ from 'jquery';
import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import firebase from "../../firebase";
import axios from 'axios';
import Button from '../Button'
import { checkLogin } from '../../utils.js';
import Alert from '../Alert';
import {LinkedIn } from '../Linkedin';
var config = require('../../react-config.js');
const clientId = config.clientId;
const state = config.state;

// import { LinkedIn } from '../LinkedIn';
// import { LinkedIn } from 'react-linkedin-login-oauth2';


const api_url = config.api_url;

export default class Login extends React.Component {
    constructor(props){
        super(props);
        /*let token = localStorage.getItem('token');
        if(token !== null)
        {
            this.props.history.push('/');
        }*/
        
       // console.log(props.location.form_state)
        let isLoggedIn = checkLogin();
        if (isLoggedIn) {
            this.props.history.push('/');
        }

        this.state = { 
            
            i:'',
            errors:{},
            form:{},
            loading: false,
            processing: false,
            hidden: true,
            otpVerify : true,
            form_state: props.location.form_state,
            alert: false,
            alert_msg: '',
            alert_type: '',
            otp:'', 
            authorization_code: '',
            errorMessage: '',
            linkedinLoader:false
        };
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePasswod = this.togglePasswod.bind(this);
    }

    // check =() =>{
    //     this.setState({linkedinLoader:!this.state.linkedinLoader})
    // }


    componentWillReceiveProps(nextProps) {
      //  console.log("hghjgjh",nextProps.location.form_state)
        
            this.setState({form_state:nextProps.location.form_state})
            
        if(nextProps.location.form_state === 'login' ){
            //console.log("hghjgjh",nextProps.location.form_state)
            $('input[name="password"]').removeClass('error');
            var errors = {};
            errors['password'] = '';
            this.setState({errors:errors,otpVerify:true});
        }    
    }
    
    handleSuccess = (data) => {
        console.log("authorization code",data)
        this.setState({
            authorization_code: data.code,
            errorMessage: '',
            linkedinLoader:true
        });

        

        let url = api_url + 'user/linkedin_login';
        let dataa ={
            action_type:'login',
            authorization_code:data.code
        }
           
        axios.post(url,dataa)
              .then((res) =>{
                  console.log("res",res)
                  if(res.status === 'error')
                  {
                    this.setState({linkedinLoader:false})
                        //    console.log("i am error called")
                  }else{
                    //  console.log("i am going to register user ")       
                    // console.log('res.user_jwt')
                    // console.log(res.user_jwt)
                    firebase.auth().signInWithCustomToken(res.data.user_jwt).then( async signedInUser => {
                        await localStorage.setItem('token',res.data.token);
                        await localStorage.setItem('user',JSON.stringify(res.data.user));
                        await this.props.onSetToken(res.data.token);
                        this.renderThumbImage(res.data.user.profile_image_thumb)
                        window.location.href = "/";
                        this.setState({linkedinLoader:false})
                        //this.props.history.push('/');
                    }).catch(function(error) {
                        console.log("firebase error",error)
                    });
                  }
                })
              .catch((error) =>console.log("error",error))
      }
    
      handleFailure = (error) => {
        this.setState({
            authorizeCode: '',
          errorMessage: error.errorMessage,
          linkedinLoader:false
        });
      }
    

    handleChange = (event) => {
        let form = this.state.form;
        const name = event.target.name;
        let value = event.target.value;
        form[name] = value;
        this.setState({form: form});
        this.validate_form(form, true);
    }

    validate_form(form_data, onchange=false){
        var errors = {};
        let email_address = form_data['username'];
        let password = form_data['password'];

        if(email_address === undefined  || email_address === ''){
            errors['username'] = "Email address/Username required.";
            $('input[name="username"]').addClass('error');
            $("input[name='username']").removeClass('success');

            if(onchange===true){
                this.setState({errors:errors});
                return true;
            }
        }else{

            $("input[name='username']").removeClass('error');
            $("input[name='username']").addClass('success');
        }

        if(password === undefined || password === ''){
            errors['password'] = "";
            //$('input[name="password"]').addClass('error');
            if(onchange===true){
                this.setState({errors:errors});
                return true;
            }
        }else{
            if(password.length < 8){
                errors['password'] = "Password should be greater than or equal to 8 characters."
                $('input[name="password"]').addClass('error');
                $('input[name="password"]').removeClass('success');
                if(onchange===true){
                    this.setState({errors:errors});
                    return true;
                }
            }else{
                $('input[name="password"]').removeClass('error');
                $('input[name="password"]').addClass('success');
            }
        }

        this.setState({errors:errors});
        const error_len = Object.keys(errors).length;
        if(error_len > 0){
            return false;
        }else{
            return true;
        } 
    }

    handleLogin = (e)  => {
        e.preventDefault();
        const form_data = this.state.form;
        //const password = form_data['password'];
        if(this.validate_form(form_data) === true){
            this.setState({ 
                loading: true,
                processing: true
            });
            //const hashedPassword = passwordHash.generate(password);
            //delete form_data["password"];
            //form_data['password'] = hashedPassword;
            const result = this.props.onSubmitLogin(form_data);
            result.then( async (res) => {
                

                if(res.status === 'error')
                {
                    this.setState({ 
                        loading: false,
                        processing: false
                    });
                    
                    var errors = {};
                   // console.log(res)
                    if (res.otp_verified && res.otp_verified === "false") {
                       // console.log("hello")
                        
                        let res = { 'status' : 'success', 'message' : 'Your email is not verified. '}
                        errors['password'] = res.message;
                        this.setState({errors,otpVerify: false});
                        
                    }else {errors['password'] = res.message;
                         this.setState({errors});
                     }
                    $('input[name="password"]').addClass('error');
                    $('input[name="password"]').removeClass('success');
                   
                }
                else
                {
                    // console.log('res.user_jwt')
                    // console.log(res.user_jwt)
                    firebase.auth().signInWithCustomToken(res.user_jwt).then( async signedInUser => {
                        this.setState({form:{}});
                        //console.log('token'+res.token);
                        //console.log('email='+res.user.email);
                        await localStorage.setItem('token',res.token);
                        //localStorage.setItem('user',JSON.stringify(res.user));
                        await localStorage.setItem('user',JSON.stringify(res.user));
                        await this.props.onSetToken(res.token);
                        this.renderThumbImage(res.user.profile_image_thumb)
                        this.setState({
                            processing: false
                        })
                        //this.setState({ loading: false });
                        window.location.href = "/";
                        //this.props.history.push('/');
                    }).catch(function(error) {
                        this.setState({ 
                            loading: false,
                            processing: false
                        });
                        // Handle Errors here.
                        // var errorCode = error.code;
                        // var errorMessage = error.message;
                        // ...
                        let res = { 'status' : 'success', 'message' : error.message}
                        $('input[name="password"]').addClass('error');
                        $('input[name="password"]').removeClass('success');
                        var errors = {};
                        errors['password'] = error.message;
                        this.setState({errors});
                    });
                }
            });
            
                
        }
        return false;
    }
    togglePasswod=()=> {
        this.setState({ hidden: !this.state.hidden });
     }

     handleResendOtp = (e) => {
        e.preventDefault();
        
        let email =this.state.form['username']
        //let email ='anand@ehostinguk.com';
        let otp_errors = {};

        this.setState({alert: false,
            alert_msg: '',
            alert_type: ''})
     //   alert(email);return false;

        if(email){
            const url = api_url + 'user/resend_otp';
            let _this = this;
            let data = {};            
            data['email'] = this.state.form['username'];
            $.ajax({
                    type: 'POST',
                    url,
                    data: JSON.stringify(data),
                    //headers:{"Authorization": "Token "+token},
                    contentType: 'application/json',
                    dataType: 'json',
                    beforeSend: function() {                        
                       
                    },
                    success: function(res) {
                        //alert('res')
                        //alert(res)                        
                        if(res.status==='success'){
                           //alert('OTP send successfully')
                           _this.setState({  alert: true, alert_msg: "OTP Sent Successfully!", alert_type: 'success',form_state : 'otp' })
                           
                        }
                        else
                        {
                            //alert('v')
                            otp_errors['otp'] = (res.message)?res.message:'Send otp error.';
                            $('input[name="otp"]').addClass('error');
                            _this.setState({otp_errors:otp_errors});
                        }
                    }
                }).catch(function (error) {
                //console.log(error.responseJSON.message);                

                otp_errors['otp'] = (error.responseJSON.message)?error.responseJSON.message:'Resend otp error.';
                $('input[name="otp"]').addClass('error');
                _this.setState({otp_errors:otp_errors});
            });
        }        
    }

    onhandleOtpChange = (event) => {
        
        const name = event.target.name;
        const value = event.target.value;
        
        this.setState({otp: value});
    }

    validate_otp_form(otp){
        var otp_errors = {};

        if(otp === undefined || otp === ''){
            otp_errors['otp'] = "OTP required.";
            $('input[name="otp"]').addClass('error');
        }

        const error_len = Object.keys(otp_errors).length;

        this.setState({otp_errors:otp_errors});

        if(error_len > 0){
            return false;
        }else{
            return true;
        }
    }

    handleOtpSubmit = (e) => {
        e.preventDefault();
        const form_data = this.state.form;
        const otp = this.state.otp;
        let otp_errors = {};

        if(this.validate_otp_form(otp) === true){
            this.setState({ processing: true });
             //alert('OTP submited')
            // alert(otp)
            const url = api_url + 'user/verify_otp';
            let _this = this;
            let data = {};
            data['otp'] = this.state.otp;
            data['email'] = this.state.form['username'];

            $.ajax({
                    type: 'POST',
                    url,
                    data: JSON.stringify(data),
                    //headers:{"Authorization": "Token "+token},
                    contentType: 'application/json',
                    dataType: 'json',
                    beforeSend: function() {                        
                       
                    },
                    success: function(res) {
                        //alert('res')
                        //alert(res)
                        
                        if(res.status==='success'){
                            _this.setState({otpVerify:false, submitted:true, processing:false});
                        }
                        else
                        {
                           
                            
                            otp_errors['otp'] = (res.message)?res.message:'Wrong OTP.';
                            $('input[name="otp"]').addClass('error');
                            _this.setState({otp_errors:otp_errors,processing:false,form_state:'login'});
                        }                        
                    }
                }).catch(function (error) {
                //console.log(error.responseJSON.message);
                
                otp_errors['otp'] = (error.responseJSON.message)?error.responseJSON.message:'Wrong OTP.';
                $('input[name="otp"]').addClass('error');
                _this.setState({otp_errors:otp_errors,processing:false});

            });
            
           // 
        }
        
    }

    renderThumbImage = async (profile_image_thumb) =>{
        if(profile_image_thumb){
        const url = profile_image_thumb
        await axios.get(url, {}, {
            
        }).then((res) => {
           
        }).catch((res) => {
           
        });
    }
    }
    render() {

        // console.log("processing state",this.state.processing)
   
    const{alert ,code, errorMessage } = this.state;
    let redirectUri = window.location.origin+ "/linkedin";
    // console.log("url",window.location.href)
    // console.log("redirecrt",redirectUri)
        return(
            <div className="page-full page-login-full flex items-center">
                <Helmet>
							<title>Login | AISquare.org</title>
							<meta name="description" content="Login - AISquare.org"/>
				</Helmet>
                <div className="page-center">
                    <div className="page-body">

                        <div className="form-box">
                            <div className="card">
                                <div className="card-body">
                                     {this.state.form_state === 'otp' ? (
                                        <>
                                        {this.state.submitted ? (
                                             <>
                                             <div className="heading-box text-center"><div className="heading">Thank You!</div></div>
                                             <div className="form-main text-center">
                                             
                                                 <p>Thank you for registering  account.</p>
                                                 <div className="btn-outer">
                                                     <Link to={{pathname :'/login', form_state :"login" }} className="btn btn-outline" >Sign In</Link>
                                                 </div>
                                             </div>
                                             </>
                                         )    : ( <>
                                        <div className="heading-box text-center"><div className="heading">Verify Email</div></div>
                                        <div className={this.state.processing ? "form-main form-processing" : "form-main"}>
                                            <form onSubmit={this.handleOtpSubmit}>
                                                <div className="form-inner">         
                                                    
                                                    <div className="row">
                                                        
                                                        <div className="col">
                                                            <div className="form-item">
                                                                <input autoComplete="off"
                                                                    className="form-style text-center"
                                                                    name="otp"
                                                                    onChange={this.onhandleOtpChange}
                                                                    placeholder="Enter OTP"
                                                                    type="text"
                                                                    value={(
                                                                        this.state.otp
                                                                    ) ? this.state.otp : '' }
                                                                />
                                                                <span className='form-error text-center'>{
                                                                    (
                                                                        this.state.otp_errors && this.state.otp_errors.otp
                                                                    ) ? this.state.otp_errors.otp: ''
                                                                }</span>

                                                                <div className="link text-sm" onClick={this.handleResendOtp}>Resend OTP?</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <Button
                                                            type={'submit'}
                                                            className={'btn btn-primary btn-full'} 
                                                            value={'Verify'} 
                                                            processing={this.state.processing}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </form>
                                           
                                        </div>
                                        {/* {alert ? <Alert message={this.state.alert_msg} type={this.state.alert_type}></Alert> : ""} */}
                                        </>)}
                                    </>
                                        
                                        ):(
                                        <>
                                    <div className="heading-box text-center"><div className="heading">Sign In</div></div>
                                    <div className={this.state.processing ? "form-main form-processing" : "form-main" }>
                                        <form onSubmit={this.handleLogin}>
                                            <div className="form-inner">
                                                <div className="form-item">
                                                    <input
                                                        type="text"
                                                        className= "form-style"
                                                        name="username"
                                                        placeholder="Username or Email address"
                                                        onChange={this.handleChange}
                                                        value={(
                                                                this.state.form && this.state.form.username
                                                            )? this.state.form.username : ''}
                                                        data-success=''
                                                    />
                                                    <span className='form-error'>{
                                                        (
                                                            this.state.errors && this.state.errors.email_address
                                                        ) ? this.state.errors.email_address: ''
                                                    }</span>
                                                    <Link to = {"/forgot-user"} className="link text-sm">Forgot Username or Email Address?</Link>
                                                </div>
                                                <div className="form-item form-password-item">
                                                    <input
                                        
                                                        className="form-style"
                                                        name="password"
                                                        placeholder="Password"
                                                        onChange={this.handleChange}
                                                        value={(
                                                                this.state.form && this.state.form.password
                                                            ) ? this.state.form.password : '' }
                                                        data-success = ''
                                                        type={this.state.hidden ? 'password' : 'text'}/>
                                                                       
                                                        <span className={this.state.hidden? 'show pwd-show-hide' :'hide pwd-show-hide'} onClick={this.togglePasswod}><svg id="Capa_1" enableBackground="new 0 0 551.121 551.121" height="512" viewBox="0 0 551.121 551.121" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m275.561 68.887c-123.167 0-233.534 80.816-274.64 201.107-1.228 3.616-1.228 7.518 0 11.134 41.106 120.291 151.473 201.107 274.64 201.107s233.534-80.816 274.64-201.107c1.228-3.616 1.228-7.518 0-11.134-41.107-120.291-151.474-201.107-274.64-201.107zm0 378.902c-106.532 0-202.284-68.975-240.077-172.228 37.793-103.253 133.544-172.228 240.077-172.228 106.532 0 202.284 68.975 240.077 172.228-37.793 103.253-133.545 172.228-240.077 172.228z"></path><path d="m275.561 172.224c-56.983 0-103.337 46.354-103.337 103.337s46.354 103.337 103.337 103.337 103.337-46.353 103.337-103.337-46.354-103.337-103.337-103.337zm0 172.228c-37.995 0-68.891-30.897-68.891-68.891 0-37.995 30.897-68.891 68.891-68.891 37.995 0 68.891 30.897 68.891 68.891s-30.897 68.891-68.891 68.891z"></path></svg></span> 
                                                    <span className='form-error'>{
                                                        (
                                                            this.state.errors && this.state.errors.password
                                                        ) ? (this.state.errors.password): ''
                                                    }
                                                 {this.state.errors && this.state.errors.password && !this.state.otpVerify
                                                  ?  <div className="link text-sm" onClick={this.handleResendOtp}> Click here to verify your email. </div>:''}</span>
                                                    
                                                    <Link to={"forgot-password"} className="link text-sm">Forgot Password?</Link>
                                                </div>
                                                <div className="row btn-outer">
                                                    <div className="col">
                                                        {/* <button type="submit" className="btn btn-primary btn-full">Login</button> */}
                                                        <Button className={'btn btn-primary btn-full'} type={'submit'} value={'Login'} processing={this.state.processing} />
                                                        </div>
                                                    <div className="col"><Link to={'/register'} onClick={() => this.props.onHandleAccountType('')} className="btn btn-outline btn-full">Create Account</Link></div>
                                                </div>
                                                <div className="row btn-outer">
                                                 <div className="col"> 
                                                      <LinkedIn
                                                        clientId={clientId}
                                                        redirectUri={redirectUri}
                                                        scope="r_liteprofile,r_emailaddress"
                                                        state={state}
                                                        onFailure={this.handleFailure}
                                                        onSuccess={this.handleSuccess}
                                                        supportIE
                                                        linkedinLoader={this.state.linkedinLoader}
                                                       >                              
                                                         <span className="linkedin-icon-setup-button" >in</span>  Continue With LinkedIn 
                                                       </LinkedIn>
                                                      </div>
                                                   </div>
                                                    
                                            </div>
                                        </form>
                                        </div>
                                        {/* <button onClick={this.check}>check</button> */}
                                    </>)
                                    }
                                    {/* {alert ? <Alert message={this.state.alert_msg} type={this.state.alert_type}></Alert> : ""} */}
                                </div> 
                            </div>
                            {alert ? <Alert message={this.state.alert_msg} type={this.state.alert_type}></Alert> : ""}
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }
}

Login.propTypes = {
    onSubmitLogin: PropTypes.func,
    onSetToken: PropTypes.func,
};

// TiSocialLinkedin


                                              {/* {this.state.linkedinLoader ?
                                                (<div className="row btn-outer">
                                                    <div className="col"> 
                                                      <LinkedIn
                                                        clientId="78s4tio7u412h9"
                                                        redirectUri={redirectUri}
                                                        scope="r_liteprofile,r_emailaddress"
                                                        state="34232423"
                                                        onFailure={this.handleFailure}
                                                        onSuccess={this.handleSuccess}
                                                        supportIE
                                                       >                              
                                                         <span className="linkedin-icon-setup-button" >in</span>  Continue With LinkedIn 
                                                    </LinkedIn>
                                                    </div>
                                                </div>):(<i className="icon-loading"></i>)
                                                } */}