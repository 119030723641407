import React, {Component} from 'react'

class ListingSkeleton extends Component {
    render(){
        // console.log("listing skelton render method called")
        return(
            <div className="list-item skeleton-main">
                <div className="list-item-inner flex">
                    <div className="list-left skeleton"></div>
                    <div className="list-info flex-auto">
                        <div className="list-info-body">
                            <div className="title-box flex">
                                <div className="title flex-auto"><h4 className="skeleton">&nbsp;</h4> </div>
                                <div className="award-action-box"><span className="btn btn-sm btn-outline skeleton">&nbsp;</span></div>
                            </div>
                            <div className="tags-outer users-item-box">
                                <span className="user-item flex">
                                    <span className="user-thumb skeleton"></span>
                                    <span className="user-thumb skeleton"></span>
                                </span>
                            </div>
                            <div className="tags-outer">
                                <span className="label label-tag skeleton">&nbsp;</span>
                                <span className="label label-tag skeleton">&nbsp;</span>
                                <span className="label label-tag skeleton">&nbsp;</span>
                            </div>
                            <div className="list-meta">
                                <span className="meta-item skeleton"></span>
                                <span className="meta-item skeleton"></span>
                                <span className="meta-item skeleton"></span>
                                <span className="meta-item skeleton"></span>
                            </div>
                        </div>
                    </div>
                    <div className="list-right">
                        <ul className="list-actions">
                            <li><span className="action-item skeleton"></span></li>
                            <li><span className="action-item skeleton"></span></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default ListingSkeleton