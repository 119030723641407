import React, { Component } from 'react'
import $ from 'jquery';
import RelatedPosts from './related-posts';
import Resume from './resume';
import Satistics from './satistics';
import Alert from '../Alert'
import { checkLogin, get_id_token, get_user_data } from '../../utils.js';
import ProfileImageBox from './profile-image-box';
import axios from 'axios';
import ProfileEdit from './profile-edit';
import ProfileSkeleton from '../Skeletons/Profile'
import AddConnection from '../Home/add-connection';
import { Helmet } from "react-helmet";
import { limitWord } from '../../utils.js';

const default_img = require('./../../Assets/img/user-default.jpg')
var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

class Profile extends Component {
	constructor(props) {
		super(props)
		let isLoggedIn = checkLogin();
		let user_data = get_user_data();
		// console.log("user data inside profile",user_data)
		this.state = {
			user_data: (user_data) ? user_data : '',
			logged_user_groups_id:(user_data) ? user_data.groups_id : '',
			logged_editor_approval:(user_data) ? user_data.editor_approval: '',
			about_me: '',
			total_post: 0,
			about_me_error: '',
			editAbout: false,
			editProfile: false,
			total_following: 0,
			total_follower: 0,
			first_name: '',
			groups_id: '',
			last_name: '',
			organization: '',
			profile_image: '',
			shortText: true,
			tabActive: 1,
			profileImage: false,
			file: '',
			uploadedImage: '',
			errorImage: false,
			updatedImage: false,
			userPostData: [],
			posted_by: 1,
			loading: true,
			my_post: false,
			follow: false,
			alert: false,
			alert_msg: '',
			alert_type: '',
			post_message:'No Post Found.',
			draft_loader : true,
			Published_loader : true,
			is_connected : false,
			onlyConnect: true,
			curious_mind_pins_loader:true,
			editor_approval_data_recieve_through_link:(props.location.state == undefined)?false:true
		}
		this.onhandleBriefChange = this.onhandleBriefChange.bind(this);
		this.userPostList = this.userPostList.bind(this);
		//console.log(window.location.href)
		//console.log(this.state.user_data.groups_id)
	}

	componentDidMount() {
		
		if(this.state.logged_user_groups_id === 6){
			
			this.setState({tabActive:5})
		}else{
			if(this.state.editor_approval_data_recieve_through_link){
				this.setState({tabActive:8})
				}
		}
		//if(token){ 
			const slug = this.props.profileSlug;
			this.postedByUserDetail(slug);


		//}else{
        //    this.props.history.push('/login');
        //}
	}

	componentWillReceiveProps(nextProps) {
		// console.log("nexttttttttttttttttttttttttttttt",nextProps)
		const slug = this.props.profileSlug
		const next_slug = nextProps.profileSlug
		if (slug !== next_slug) {
			this.postedByUserDetail(next_slug);
		}
		// this.setState({
		// 	userPostData: nextProps.userPostData,
		// 	user_data: get_user_data()
		// });
	}
	postedByUserDetail = (slug) => {
		this.setState({ loading: true });
		const url = api_url + 'user/details';
		const _this = this;
		$.ajax({
			type: 'POST',
			url,
			data: JSON.stringify({ slug: slug }),
			headers: token ? { "Authorization": "Token " + token } : {},
			contentType: 'application/json',
			dataType: 'json',
			beforeSend: function () {

			},
			success: function (res) {
				let my_post = undefined;
				let posted_by = undefined;
				if (get_user_data() && (res.records.slug === get_user_data().slug)) {
					my_post = true;
					posted_by = 0;
				} else {
					my_post = false;
					posted_by = 1;
				}
				_this.setState({
					user_data: res.records,
					about_me: res.records.about_me,
					groups_id: res.records.groups_id,
					first_name: res.records.first_name,
					last_name: res.records.last_name,
					organization: res.records.organization,
					profile_image: res.records.profile_image,
					total_post: res.records.total_post,
					total_following: res.records.total_following,
					total_follower: res.records.total_follower,
					posted_by: posted_by,
					follow: res.records.follow_status,
					loading: false,
					my_post: my_post,
					is_connected: res.records.is_connected,
				});

				if(_this.state.editor_approval_data_recieve_through_link){
						let data = {}
						data['editor_approval'] = true;
						_this.userPostList(data);
				}else{
					   let data = {}
					   data['user_id'] = res.records.users_id;
					   _this.userPostList(data);
				}




				let posted_by_name = (res.records.first_name)?res.records.first_name:'';

				let post_message = _this.state.post_message;
				if(_this.state.tabActive ===1)
				{
					post_message = posted_by_name+ ' has not published any articles';
					_this.setState({post_message:post_message})

				}
			}
		}).catch();
	}

	onhandleBriefChange = (event) => {
		const value = event.target.value;
		this.setState({ about_me: value });
	}
	onUpdateProfile = (event) => {
		const about_me = this.state.about_me;
		if (about_me === '') {
			const error = "About Me required.";
			this.setState({ about_me_error: error });
		} else {
			const token = localStorage.getItem('token');
			const url = api_url + 'user/update';
			let data = {};
			const _this1 = this;
			data['first_name'] = this.state.first_name;
			data['last_name'] = this.state.last_name;
			data['organization'] = this.state.organization;
			data['about_me'] = about_me;
			
			this.setState({ alert: false, alert_msg: " ", alert_type: '' })
			$.ajax({
				type: 'POST',
				url,
				data: JSON.stringify(data),
				headers: { "Authorization": "Token " + token },
				contentType: 'application/json',
				dataType: 'json',
				beforeSend: function () {

				},
				success: function (res) {
					if (res.status === 'success') {
						_this1.setState({ about_me_error: '', editAbout: !_this1.state.editAbout, alert: true, alert_msg: "Updated successfully", alert_type: 'success', });
						localStorage.setItem('user', JSON.stringify(res.user));
					}
				}
			}).catch();
			//
		}
	}



	handleReadMore = (i) => {
		this.setState({
			shortText: !this.state.shortText
		})
	}
	handleActive = (id) => {
		this.setState({
			tabActive: id,Published_loader:true,draft_loader:true
		})
		let data = {}
		data['user_id'] = (this.state.user_data)?this.state.user_data.users_id:'';
			
		let post_message = this.state.post_message;
		let posted_by_name = (this.state.user_data)?this.state.user_data.first_name:'';
		if(id===4)
		{
			//post_message = posted_by_name+ ' has not draft any articles';
			post_message = 'You do not have any articles in draft.';
			this.setState({ userPostData: [] , post_message:post_message })
			// console.log(id)
			data['draft'] = true;
			this.userPostList(data);
		}
		else if(id===1)
		{
			post_message = posted_by_name+ ' has not published any articles';
			this.setState({ userPostData: [] , post_message:post_message})
			
			this.userPostList(data);

		}else if(id===6){
			post_message = 'You do not have any Pinned Article';
			this.setState({ userPostData: [] , post_message:post_message})
			data = {}
			data['pin_post'] = true
			this.userPostList(data)
		}else if(id === 8){
			data = {}
			data['editor_approval'] = true;
			this.userPostList(data);
		}
	}
	handleEditAbout = () => {
		this.setState({
			editAbout: !this.state.editAbout
		})
	}

	userPostList = (value) => {
     
		var _this1 = this;
		value['page'] =1 
		value['limit'] =1000
		value['loadMore'] =false  
		// const {posted_by,user_id} =this.state;
		// if(_this1.state.tabActive===4) {
		// 	value['status'] = 0;
		// }

		const url = api_url + 'post/list';
		const token = (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) ? localStorage.getItem('token') : '';
		//if (token) {
		$.ajax({
			type: 'GET',
			url,
			contentType: 'application/json',
			headers: value['my_post'] || value['pin_post'] || value['editor_approval'] ? { "Authorization": "Token " + token } : {},
			//data: JSON.stringify(value),
			data: value,
			dataType: 'json',
			success: function (resp) {
				// console.log("response ",resp)
				if (resp.records && resp.records.length > 0) {
					_this1.setState({ userPostData: resp.records })
					
				}
				if(_this1.state.tabActive === 4 || _this1.state.tabActive === 8) _this1.setState({draft_loader : false})
					else if (_this1.state.tabActive === 1 || _this1.state.tabActive === 6) _this1.setState({Published_loader : false,curious_mind_pins_loader : false})
			}
		}).catch();

		//}


	}
	imageError = (errorImage, updatedImage, profile_image) => {
		this.setState({ alert: false, alert_msg: " ", alert_type: '' });
		if (errorImage) {
			//console.log('errorImage')
			this.setState({ alert: true, alert_msg: "Invalid image type", alert_type: 'error', errorImage: errorImage, updatedImage:updatedImage, profile_image:profile_image });
		}
		else if (updatedImage) {
			//console.log('updatedImage')
			this.setState({ alert: true, alert_msg: "Image Updated successfully!", alert_type: 'success', errorImage: errorImage, updatedImage:updatedImage, profile_image:profile_image });
		}
		else if(profile_image) {
			//console.log('profile_image')
			this.setState({ alert: true, alert_msg: " ", alert_type: '', errorImage: errorImage, updatedImage:updatedImage, profile_image:profile_image });
		}
	}
	handleFollow = async () => {
		const { user_data, follow } = this.state;
		let user_id = user_data ? user_data.users_id : '';

		if(token){ 
			
			
			if (user_id) {
				const url = api_url + 'user/profile/follow/add';
				await axios.post(url, { user_id: user_id, follow_status: !follow }, {
					headers: { "Authorization": "Token " + token }
				}).then((res) => {
					if (res && res.data && res.data.status === "success") {
						this.setState({ follow: !follow, total_follower: res.data.total_follower });
						localStorage.setItem('user', JSON.stringify(res.data.record));
					}
				}).catch(err => {
					if (err.response && err.response.data && err.response.data.message) {
					// this.setState({ addExperience_message: err.response.data.message });
				} else {
					// this.setState({ addExperience_message: "error" });
				}
			});
			} else {
				console.log("get some error");
			}

		}else{
			this.props.history.push('/login');
		}

	}
	handleTotalPost = () => {
		this.setState({ total_post: this.state.total_post - 1 });
	}
	handleEditProfile = () => {
		this.setState({
			editProfile: !this.state.editProfile
		})
	}
	update_user_data = (user_data) => {
		this.setState({ user_data:user_data,alert: false, alert_msg: "", alert_type: ''});
		this.setState({ user_data:user_data,alert: true, alert_msg: "Updated successfully", alert_type: 'success',first_name:user_data.first_name,about_me:user_data.about_me,last_name:user_data.last_name,organization:user_data.organization});
		localStorage.setItem('user', JSON.stringify(user_data));
	}

	handleMessage = () => {
		// console.log('handleMessage.this.state.item')
		// console.log(this.state.item)
		// console.log(this.state.user_data);
        if(this.state.user_data && this.state.user_data.username)
        {
            this.props.history.push('/messages/'+this.state.user_data.username);
        }
        else
        {
			this.props.history.push('/messages')
		}
    }
	render() {


		
		// console.log("profile indexjs props",this.props)
		// console.log("profile indexjs state",this.state)
		// console.log("userPostData",this.state.userPostData)
		let brief = this.state.about_me;
		const { logged_editor_approval,groups_id, logged_user_groups_id, first_name, posted_by, last_name, profile_image, loading, my_post, follow, alert, alert_msg, alert_type, is_connected, onlyConnect} = this.state;
		// console.log(user_data);

		let user_data = this.state.user_data;
		user_data['user_id'] = user_data.users_id;
		const meta_title = 'AISquare -'+ user_data.first_name+' '+user_data.last_name
		const meta_description = 'AISquare /'+user_data.username
		//console.log(user_data);
        
		return (
			<>
				{alert ? <Alert message={alert_msg} type={alert_type} ></Alert> : ""}
				<div className="page-full page-profile-full">
				{ user_data ?(<Helmet>
					<title> { meta_title }</title>
					<meta name="description" content={limitWord(meta_description, 200)}/>
                </Helmet>):''}
					<div className="page-main-body">
						<div className="container">
							{
								loading ? <ProfileSkeleton /> : (
									<div className="profile-page-outer">
										<div className="card">
											<div className="card-body">
												<div className="profile-main-box">
													<div className="profile-top flex">
														<ProfileImageBox posted_by={posted_by} profile_image={profile_image ? profile_image : default_img} loading={loading} user_data={user_data} imageError={this.imageError}></ProfileImageBox>
														<div className="profile-info flex-auto">
															<div className="profile-info-body">
																<div className="profile-info-top flex">
																	<div className="profile-name-box flex-auto">
																		<div className="username">{first_name ? first_name : null} {last_name ? last_name : null}</div>
																		<div className="profile-type-box">
																		{ 
																			groups_id && groups_id===5 ?
																			<span className="profile-type-label" title="Correspondent">C</span>
																			:
																			groups_id && groups_id===4 ?
																			<span className="profile-type-label" title="Researcher">R</span>
																			/*:
																			groups_id && groups_id===6 ?
																			<span className="profile-type-label" title="Curious Mind">C</span>*/
																			:''
																		}
																		
																		</div>
																	</div>
																	<div className="profile-top-right">
																	<ul className="profile-actions">
																	{ posted_by ? '' :	<li><span onClick={this.handleEditProfile} tooltip="Edit Profile" className="btn btn-outline profile-edit-action btn-sm"><i className="icon-edit"></i></span></li>}
																	
																	{ (groups_id != 6 && logged_user_groups_id!=6)?
																		<>
																	{
																		posted_by ? <li><span className="btn btn-sm" onClick={this.handleFollow}>{follow ? "Unfollow" : "Follow"}</span></li> : ''}
																		{ 
																			posted_by ? 
																			<li>
																			{ 
																				is_connected ?

																				<span className="btn btn-outline btn-sm" onClick={this.handleMessage}>Message</span>

																				:
																				<>

																				<AddConnection item={user_data} history={this.props.history} onlyConnect={onlyConnect}/>

																				</>

																			}

																			</li>

																			:''

																		}
																		</>
																		:''
																		}
																		</ul>
																	</div>
																</div>
																<div className="user-info-item color-blue user-organization">{this.state.organization ? this.state.organization : null}</div>
																<div className="profile-about">

																	{
																		(this.state.editAbout || this.state.about_me_error !== '') ? (
																			<>
																				<div className="about-edit">
																					<textarea className={(this.state.about_me_error) ? 'form-style error' : 'form-style'} value={this.state.about_me} onChange={this.onhandleBriefChange} />

																				</div>
																				<div><button className="btn btn-outline btn-sm" onClick={this.onUpdateProfile}>Update</button></div>
																			</>
																		) : (
																				brief.length > 118 ? (
																					!this.state.shortText ? (
																						<p className="task-brief">
																							{
																								brief + ' '
																							}
																							<span className="link" onClick={this.handleReadMore}>Read less</span>
																						</p>
																					) : (
																							<p className="task-brief">
																								{
																									brief.substring(0, 118) + '... '
																								}
																								<span className="link" onClick={this.handleReadMore}>Read more</span>
																							</p>
																						)
																				) : (
																						<p className="task-brief">{brief}</p>
																					)
																			)
																	}
																</div>
															</div>
															{ (groups_id != 6)?
															<div className="profile-meta-box flex">
																<div className="flex-auto flex">
																	<div className="profile-meta-item flex">
																		<div className="profile-meta-icon"><img src={require('../../Assets/img/like.svg')} alt="Like icon"/></div>
																		<div className="profile-meta"><span className="meta-count">{this.state.total_follower ? this.state.total_follower : 0} </span>Follower</div>
																	</div>
																	<div className="profile-meta-item flex">
																		<div className="profile-meta-icon"><img src={require('../../Assets/img/following.svg')} alt="Following icon" /></div>
																		<div className="profile-meta"><span className="meta-count">{this.state.total_following ? this.state.total_following : 0} </span>Following</div>
																	</div>
																	<div className="profile-meta-item flex">
																		<div className="profile-meta-icon"><img src={require('../../Assets/img/posts.svg')} alt="Posts"/></div>
																		<div className="profile-meta"><span className="meta-count">{this.state.total_post ? this.state.total_post : 0} </span>Post(s)</div>
																	</div>
																</div>
																{/*}<div><span className="btn btn-outline btn-sm edit-profile-btn" onClick={this.handleEditProfile}>Edit Profile</span></div> {*/}
															</div>
															:''
															}
														</div>
													</div>
													{ (groups_id != 6 )?
													<div className="profile-main-panel">
														<div className="profile-tabs">
															<ul className="tab-nav">
															{ logged_editor_approval ===true && posted_by === 0? <li className={this.state.tabActive === 8 ? "active" : ""} key={8}><span className="tab-nav-item" onClick={() => this.handleActive(8)}>Editor Approval</span></li>:''}
																{ posted_by ? '' :<li className={this.state.tabActive === 4 ? "active" : ""} key={4}><span className="tab-nav-item" onClick={() => this.handleActive(4)}>Draft</span></li>}
																<li className={this.state.tabActive === 1 ? "active" : ""} key={1}><span className="tab-nav-item" onClick={() => this.handleActive(1)}>Published</span></li>
																<li className={this.state.tabActive === 2 ? "active" : ""} key={2}><span className="tab-nav-item" onClick={() => this.handleActive(2)}>Resume</span></li>
																<li className={this.state.tabActive === 3 ? "active" : ""} key={3}><span className="tab-nav-item" onClick={() => this.handleActive(3)}>Statistics</span></li>
															</ul>
														</div>
													</div>
													:
													<div className="profile-main-panel">
													<div className="profile-tabs">
														<ul className="tab-nav">
															<li className={(this.state.tabActive === 5 || this.state.tabActive ===  1 )? "active" : ""} key={5}><span className="tab-nav-item" onClick={() => this.handleActive(5)}>About Me</span></li>
															<li className={(this.state.tabActive === 6 ) ? "active" : ""} key={6}><span className="tab-nav-item" onClick={() => this.handleActive(6)}>Pins</span></li>
															<li className={this.state.tabActive === 7 ? "active" : ""} key={7}><span className="tab-nav-item" onClick={() => this.handleActive(7)}>Donations</span></li>
														</ul>
													</div>
												</div>
													}
												</div>
											</div>
											{ (groups_id != 6 || this.state.tabActive === 6)? 
											<div className="profile-full-container">
												{
													((this.state.tabActive === 4) && Array.isArray(this.state.userPostData)) &&
													<div className="card-body post-related-body">
													{this.state.draft_loader?<div className="loading"></div>: ((this.state.userPostData && this.state.userPostData.length > 0) ? (
														<div className="blog-outer">
															{
																this.state.userPostData.map((item, i) => {
																	return <RelatedPosts type={"draft"} my_post={my_post} key={item.uuid} totalPost={this.handleTotalPost} postData={item} history={this.props.history} location={this.props.location} posted_by={posted_by} setToWishList={this.props.setToWishList} curiousMind ={ this.state.tabActive === 6 ? false: true} />
																})
															}
														</div>
														) : 
							                                <div className="card">
							                                    <div className="card-body"><div className="no-found color-danger text-center">{this.state.post_message}</div></div>
							                                </div>
							                            )}
													</div>
												}
												{
													(( this.state.tabActive === 8) && Array.isArray(this.state.userPostData)) &&
													<div className="card-body post-related-body">
													{this.state.draft_loader?<div className="loading"></div>: ((this.state.userPostData && this.state.userPostData.length > 0) ? (
														<div className="blog-outer">
															{
																this.state.userPostData.map((item, i) => {
																	return <RelatedPosts type={"editor_approval"} my_post={my_post} key={i} totalPost={this.handleTotalPost} postData={item} history={this.props.history} location={this.props.location} posted_by={posted_by} setToWishList={this.props.setToWishList} curiousMind ={ this.state.tabActive === 6 ? false: true} />
																})
															}
														</div>
														) : 
							                                <div className="card">
							                                    <div className="card-body"><div className="no-found color-danger text-center">{this.state.post_message}</div></div>
							                                </div>
							                            )}
													</div>
												}
												{
													((this.state.tabActive === 1 || this.state.tabActive ===6) && Array.isArray(this.state.userPostData)) &&
													<div className="card-body post-related-body">
													{  (this.state.curious_mind_pins_loader || this.state.Published_loader)?<div className="loading"></div>:((this.state.userPostData && this.state.userPostData.length > 0) ? (
														<div className="blog-outer">
															{
																this.state.userPostData.map((item, i) => {
																	return <RelatedPosts my_post={my_post} key={i} totalPost={this.handleTotalPost} postData={item} history={this.props.history} location={this.props.location} posted_by={posted_by} setToWishList={this.props.setToWishList} curiousMind ={ this.state.tabActive === 6 ? false: true} />
																})
															}
														</div>
														) :
														<div className="card">
															<div className="card-body"><div className="no-found color-danger text-center">{this.state.post_message}</div></div>
														</div>)}


													</div>
												}
												{
													(this.state.tabActive === 2 ) && (<Resume my_post={my_post} user_data={this.state.user_data} history={this.props.history} />)
												}
												{this.state.tabActive === 3 && <Satistics user_id={user_data.user_id} />}

											</div>
											:
										      this.state.tabActive === 5 ?  <div className="profile-full-container"><Resume my_post={my_post} user_data={this.state.user_data} history={this.props.history} /></div>:''
											
											}
										</div>
									</div>
								)
							}
						</div>
					</div>
				</div>
				{this.state.editProfile ? <ProfileEdit close={this.handleEditProfile} user_data={this.state.user_data} update_user_data={this.update_user_data} /> : null}
			</>
		)
	}
}
export default Profile