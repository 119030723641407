import * as redux from 'redux';
import rootReducer from './reducers';
import API from './apigateway';
import thunk from 'redux-thunk';

export default function createStore() {
    return redux.createStore(
        rootReducer,
        redux.compose(
        	redux.applyMiddleware(thunk.withExtraArgument(API)),
        	// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        ),
    );
}