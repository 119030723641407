import React, {Component} from 'react'

class ProfileSkeleton extends Component {
    render(){
        return(
            <div className="profile-page-outer">
                <div className="card">
                    <div className="card-body">
                        <div className="profile-main-box skeleton-main">
                            <div className="profile-top flex">
                                <div className="profile-left"><div className="thumb thumb-lg skeleton"></div></div>
                                <div className="profile-info flex-auto">
                                    <div className="profile-info-body">
                                        <div className="profile-info-top flex">
                                            <div className="profile-name-box flex-auto">
                                                <div className="username skeleton">&nbsp;</div>
                                            </div>
                                            <div className="profile-top-right">
                                                <ul className="profile-actions">
                                                    <li><span className="btn btn-outline profile-edit-action btn-sm skeleton"></span></li>
                                                    <li><span className="btn btn-sm skeleton"></span></li>
                                                    <li><span className="btn btn-outline btn-sm skeleton"></span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="user-info-item color-blue user-organization skeleton">&nbsp;</div>
                                        <div className="profile-about">
                                            <p className="task-brief skeleton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
                                        </div>
                                    </div>
                                    <div className="profile-meta-box flex">
                                        <div className="flex-auto flex">
                                            <div className="profile-meta-item flex">
                                                <div className="profile-meta-icon skeleton"></div>
                                                <div className="profile-meta">
                                                    <span className="meta-count skeleton">&nbsp;</span>
                                                    <span className="skeleton">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </div>
                                            </div>
                                            <div className="profile-meta-item flex">
                                                <div className="profile-meta-icon skeleton"></div>
                                                <div className="profile-meta">
                                                    <span className="meta-count skeleton">&nbsp;</span>
                                                    <span className="skeleton">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </div>
                                            </div>
                                            <div className="profile-meta-item flex">
                                                <div className="profile-meta-icon skeleton"></div>
                                                <div className="profile-meta">
                                                    <span className="meta-count skeleton">&nbsp;</span>
                                                    <span className="skeleton">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-main-panel">
                                <div className="profile-tabs">
                                    <ul className="tab-nav">
                                        <li className="active"><span className="tab-nav-item skeleton">&nbsp;</span></li>
                                        <li><span className="tab-nav-item"><span className="skeleton" style={{display: 'block', height: '18px', marginBottom: '10px'}}>&nbsp;&nbsp;</span></span></li>
                                        <li><span className="tab-nav-item"><span className="skeleton" style={{display: 'block', height: '18px', marginBottom: '10px'}}>&nbsp;&nbsp;</span></span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ProfileSkeleton