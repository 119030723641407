import React, { Component } from 'react'
import NotificationList from './Notifications/notificationList.js'
import { Link } from 'react-router-dom'

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            loader: props.loader
        };
    }

    handleViewAll = () => {
        this.props.history.push("/notifications");
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.loader !== this.props.loader) {
            this.setState({ loader: nextProps.loader })
        }
    }

    handleNotificationDropdownOfHeader = () =>{
        // console.log("notification inside bell")
        this.props.handleNotificationDropdown("notification-by-bell")
    }
    render() {
        const { notifications, dropdownNotification,notifications_icon,new_noti_count } = this.props;
        const { loader } = this.state;
        let notification = [];
        notification = notifications.length > 5 ? notifications.slice(0, 5) : notifications
        return (
            <div className="nav-item notification-box" onClick={this.handleNotificationDropdownOfHeader} title="Notifications">
                <i className="icon icon-bell "></i>
                {
                    (notifications_icon)?
                    <>
                    <span className="alert-dot setting">{ new_noti_count ? new_noti_count :'' }</span>
                    </>
                    :''
                }
                {
                    dropdownNotification ? (
                        <>
                            <div className="dropdown">
                                {loader ? <div className="card">
                                    <div className="card-body">
                                        <div className="loading"></div>
                                    </div>
                                </div> : (
                                        notification.length > 0 ?
                                            <>
                                                <div className="notification-dd-box">
                                                    {
                                                        notification.map((item, i) => {
                                                            return <NotificationList item={item} key={i}></NotificationList>
                                                        })
                                                    }
                                                </div>
                                                <div className="dropdown-bottom">
                                                    <div className="view-all-action" onClick={this.handleViewAll}>View All</div>
                                                </div>
                                            </> : <div className="no-found color-danger text-center"><div className="card-body">No data Found</div></div>
                                    )
                                }
                            </div>
                            <div className="page-overlay" onClick={this.props.handleNotificationDropdown}></div>
                        </>
                    ) : null
                }
            </div>
        )
    }
}
export default Notifications