// var sandbox_mode = true;
var sandbox_mode = false;

// clientid and secret for linkedin login
 const clientId = "86pg8wfy4ry4nh";
 const state = "34232423";

let base_url;
let api_url;
let BACKEND_URL;

if(sandbox_mode)
{
    base_url = 'http://localhost:3000/';
    api_url = 'http://localhost:8000/api/v1/';
    BACKEND_URL = 'http://localhost:8000/';
}
else
{
    base_url = 'https://www.aisquare.org/';
    // api_url = 'https://backend-dot-aisquare-live.wl.r.appspot.com/api/v1/';
    // BACKEND_URL = 'https://backend-dot-aisquare-live.wl.r.appspot.com/';
      //api_url = 'https://backend.aisquare.org/api/v1/';
      //BACKEND_URL = 'https://backend.aisquare.org/';
      //api_url = 'https://www.aisquare.org/api/v1/';
      //BACKEND_URL = 'https://www.aisquare.org/';
      api_url = 'https://backend-dot-python-project-413006.as.r.appspot.com/api/v1/';
      BACKEND_URL = 'https://backend-dot-python-project-413006.as.r.appspot.com/';
}

module.exports = {
    'base_url': base_url,
    'api_url': api_url,
    'BACKEND_URL': BACKEND_URL,
    'clientId':clientId,
    'state':state
};