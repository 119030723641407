import React, {Component} from 'react'
import {connect} from 'react-redux';
import Profile from '../Components/Profile/'
import { checkLogin,get_id_token,get_user_data } from './../utils.js';
import {setToWishList} from './../thunks';

export function mapDispatchToProps(dispatch) {
    return {
    	setToWishList: id => dispatch(setToWishList(id)),
    };
}
class ProfilePage extends Component {

	constructor(props){
        super(props)
        //console.log('ProfilePage =>',props.match.params.slug)
        let isLoggedIn = checkLogin();
    }
	render(){
		// let brief = this.state.brief
		return(
			<div className="page-full page-profile-full">
                <Profile history={this.props.history} location={this.props.location} profileSlug={this.props.match.params.slug} setToWishList={this.props.setToWishList}/>
            </div>
		)
	}
}
export default connect(null, mapDispatchToProps)(ProfilePage) 