import React, {Component} from 'react'
import ArticleDraft from './Article-draft'
import ArticlePublished from './Article-published'

const data = [1,2,3,4,5]
class ArticleListing extends Component{
    state = {
        dropdown: -1,
        tabActive: 1
    }
    handleDropdown = (i) => {
        if(this.state.dropdown == i){
            this.setState({
                dropdown: -1
            })
        } else {
            this.setState({
                dropdown: i
            })
        }
        
    }
    handleTab = (id) => {
        this.setState({
            tabActive: id
        })
    }
    render(){
        return(
            <div className="page-full page-connect-full story-listing-page">
				<div className="page-main-body">
					<div className="container">
						<div className="container-inner2">
							<div className="heading-box text-center">
                                <div class="heading">Your Stories</div>
								<ul className="nav-tabs">
									<li className={this.state.tabActive === 1 ? "active" : ""}><span onClick={() => this.handleTab(1)}>Drafts <span>(5)</span></span></li>
									<li className={this.state.tabActive === 2 ? "active" : ""}><span onClick={() => this.handleTab(2)}>Published <span>(12)</span></span></li>
								</ul>
							</div>							
							<div className="card">
                                {
                                    this.state.tabActive == 1 ? (
                                        <div className="article-listing-outer">
                                            {
                                                data.map((item,i)=>{
                                                    return(
                                                        <ArticleDraft key={i} />
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div className="article-listing-outer">
                                            {
                                                data.map((item,i)=>{
                                                    return(
                                                        <ArticlePublished key={i} />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }
							</div>
						</div>
					</div>
				</div>
			</div>
        )
    }
}
export default ArticleListing