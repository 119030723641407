import {
    REGISTER_FORM_TYPE,
} from './../actions';

export const formType = (state ='', action) => {
    switch(action.type){
        case REGISTER_FORM_TYPE:
            return action.value;
        default:
            return state
    }    
}