import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export default class ReplyOnPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blog_uuid: props.blog_uuid,
            reply_msg: '',
            reply_error: '',
            reply_success: '',
            parent_uuid:props.parent_uuid,
            comments_user:props.comments_user ? props.comments_user : {}
        };
        this.onReplyChange = this.onReplyChange.bind(this);
    }

    onReplyChange = (event) => {
        const value = event.target.value;
        this.setState({ reply_msg: value });
    }

    onSubmitReply = (event) => {
        const {reply_msg,parent_uuid,blog_uuid} = this.state;
        if (reply_msg === '') {
            const error = "reply required.";
            this.setState({ reply_error: error });
        } else {
            let data = {};
            const _this1 = this;
            data['comment'] = reply_msg;
            data['uuid'] = uuidv4();
            data['post_uuid'] = blog_uuid;
            data['parent_uuid'] = parent_uuid;
            const result = this.props.handleCommentAdd(data);
            result.then(async (res) => {
                _this1.props.updateReplyList(res.record);
                const success = 'Reply added successfully.'
                _this1.setState({ 'reply_success': success, reply_msg: ''});
            });
            setTimeout(() => {
                _this1.props.onReply();
            }, 2000);
        }
    }

    render() {
        const {reply_error,reply_msg,reply_success,comments_user}=this.state;
        return (
            <div className="write-comment-box">
                <div className="write-comment-inner flex">
                    <div className="write-comment-main flex-auto">
                        <div className="write-comment-textbox">
                            {comments_user ? <b>{comments_user.name}</b>:''}<textarea placeholder="Leave a message..." name="reply" className={(reply_error) ? 'form-style error' : 'form-style'} value={reply_msg} onChange={this.onReplyChange}></textarea>
                        </div>
                        <div className="write-comment-button text-right">
                            <button className="btn btn-sm" type="button" onClick={this.onSubmitReply}>Reply</button>
                        </div>
                        <div style={{ 'color': 'green', 'textAlign': 'left' }}>{/* (reply_success) ? reply_success : '' */}</div>
                    </div>
                </div>
            </div>
        );
    }
}

ReplyOnPost.propTypes = {
    blog_uuid: PropTypes.string.isRequired,
    handleCommentAdd: PropTypes.func.isRequired,
    onReply:PropTypes.func.isRequired
};