import React from 'react';
import renderHTML from 'react-render-html';
import PropTypes from 'prop-types';
import BlogCommentList from './blog-comment-list';
import moment from 'moment';
// import AddBlogComment from './add-blog-comment';
import Details from '../Details/index';
import { checkLogin, get_user_data } from '../../utils.js';
import SharePost from './share';
import { Link } from 'react-router-dom';
import Tag from './tag';
import AddConnection from './add-connection';
import WishList from './wishList';
import axios from 'axios';
var config = require('../../react-config.js');
const default_img = require('./../../Assets/img/img-default.jpg')
const user_default_img = require('./../../Assets/img/user-default.jpg')


const disableSpan = { opacity: 0.7 };
export default class BlogList extends React.Component {
    constructor(props) {
        super(props);
        let isLoggedIn = checkLogin();
        let user_data = get_user_data();

        this.state = {
            user_data: (user_data) ? user_data : '',
            groups_id: (user_data) ? user_data.groups_id : '',
            blog_uuid: props.blog_uuid,
            comments: [],
            data: props.item,
            detailsItem: '',
            totalComments: props.item.total_comments,
            detailsModal: false,
            fullContent: '',
            post_uuid: '',
            wishlist: '',
            award: '',
            pin: '',
            max_comments: 0,
            load_more: true,
            total_likes: props.item.total_likes,
            isLoggedIn: isLoggedIn,
            award: '',
            display_component: true,
            awardPopup: false,
            awardGold: props.item.award_count_data.gold ? props.item.award_count_data.gold:0,
            awardPlatinum: props.item.award_count_data.platinum ? props.item.award_count_data.platinum:0,
            awardSilver: props.item.award_count_data.silver ? props.item.award_count_data.silver : 0,
            canGiveAwardStatus:props.item.award_status,
            awardCountData: props.item.award_count_data,
            display_award_button: true,
            display_connect_button: true,
            write_comment: true,
        };

    }

    handleFullContent = (item) => {
        this.setState({
            comments: []
        })
        if (this.state.fullContent === item) {
            this.setState({ fullContent: '' });
        } else {
            this.setState({ fullContent: item });
        }
    }

    handleComment = (item) => {
        this.setState({
            fullContent: ''
        })
        if (this.state.comments === item) {
            this.setState({
                comments: []
            });
        } else {
            this.getComments(item.uuid);
            this.setState({
                comments: item
            });
        }
    }

    handleAwardbox = (item) => {

        if (!this.state.isLoggedIn) {

            if (this.state.award === item) {
                this.setState({ award: '' });
            } else {
                this.setState({ award: item });
            }

        }
        else if(this.state.groups_id == 4)
        {
            // Only Researcher can give awards
            if (this.state.award === item) {
                this.setState({ award: '' });
            } else {
                this.setState({ award: item });
            }
            

        }

        
        
    }

    handleAward = (item, type = 'add') => {
        // this is for post award
        if (!this.state.isLoggedIn) {
            this.props.history.push('/login');
        }

        let data = {}
        let _this = this;
        data['post_uuid'] = item.uuid;

        if (type === 'add') {
            data['award_status'] = true;
        }
        else {
            data['award_status'] = false;
        }
        this.props.setToAward(data).then((res) => {
            if (res.status === 'success') {

                // this.setState(res.record);
                this.setState({ data: res.record });
            }
        });
        //console.log(data)
    }

    handlePin = (item) => {

        // console.log("item",item)

        if (!this.state.isLoggedIn) {
            this.props.history.push('/login');
        }

        let data = {}
        let _this = this;
        data['post_uuid'] = item.uuid;

        if (this.state.data.pin_status) {
            data['pin_status'] = false;
        } else {
            data['pin_status'] = true;
        }
        this.props.setToPin(data).then((res) => {
            if (res.status === 'success') {
                const params = new URLSearchParams(this.props.location.search);
                const pin = params.get('pin');
                if(pin && res.record.pin_status === false){
                    this.props.final_data();
                    this.setState({ display_component: false });
                }

                // this.setState(res.record);
                this.setState({ data: res.record });
            }
        });
        //console.log(data)
    }

    updateCommentsList = (blog) => {
        const mcomm = this.state.comments;
        const totalComments = this.state.totalComments;
        mcomm[blog.post_uuid].push(blog);
        this.setState({
            comments: mcomm,
            totalComments: totalComments + 1,
            max_comments: ((mcomm[blog.post_uuid].length > 5) ? 5 : mcomm[blog.post_uuid].length),
            load_more: ((mcomm[blog.post_uuid].length > 5) ? true : false)
        });
    }
    load = () => {
        const { comments, data } = this.state;
        this.setState({
            load_more: false,
            max_comments: comments[data.uuid].length
        })
    }
    getComments = (post_uuid) => {
        let data = {}
        let _this = this;
        data['post_uuid'] = post_uuid;
        const comments = this.props.getPostComments(data);
        comments.then(async (res) => {
            const mcomm = this.state.comments;
            mcomm[post_uuid] = (res.records) ? res.records : []
            _this.setState({
                post_uuid: post_uuid,
                comments: mcomm,
                max_comments: ((mcomm[post_uuid].length > 5) ? 5 : mcomm[post_uuid].length),
                load_more: ((mcomm[post_uuid].length > 5) ? true : false)
            });
        });
    }

    handleDetail = (item) => {
        this.setState({
            detailsModal: !this.state.detailsModal,
            detailsItem: item
        })
        if (!this.state.detailsModal === true) {
            document.body.setAttribute('data-popup', 'active')
        } else {
            document.body.setAttribute('data-popup', '')
        }
    }

    setDetail = (blog) => {
        this.props.history.push({
            pathname: '/article/' + blog.slug,
        })
    }

    setToProfileDetail = (slug) => {
        //alert('HI')
        this.props.history.push({
            pathname: '/p/' + slug,
        })
    }

    sendToLogin = () => {
        if (!this.state.isLoggedIn) {
            this.props.history.push('/login');
        }
    }
    handleWishlist = (data) => {
        //console.log("itwm or post of parent page=" + data.total_likes);
        this.setState({ data: data });
    }

    handleStreamClick = (stream) => {
       
        this.props.history.push({
            pathname: '/search',
            search: "?stream=" + stream.name,
        })
    }
    handleAwardPopup = () => {
        this.setState({
            awardPopup: !this.state.awardPopup
        })
    }

    handleGiveAward = async (data,uuid,award_status,award_type) => {
        console.log("handle give award callled")
        if (!this.state.isLoggedIn) {
            this.props.history.push('/login');
        }
        if(data === 'gold'){
           await this.setState({
                awardGold:  this.state.awardGold+1,
                awardPopup: false,
                canGiveAwardStatus:!this.state.canGiveAwardStatus
            })
        }else if(data === 'platinum'){
           await this.setState({
                awardPlatinum: this.state.awardPlatinum+1,
                awardPopup: false,
                canGiveAwardStatus:!this.state.canGiveAwardStatus
            })
        }else if(data === 'silver'){
          await  this.setState({
                awardSilver: this.state.awardSilver+1,
                awardPopup: false,
                canGiveAwardStatus:!this.state.canGiveAwardStatus
            })
        }
        // this.setState({
        //     awardPopup: false
        // })

        this.props.handleGivenAward(uuid,award_status,award_type)
    }
    render() {
        // console.log("props inside bloglist render",this.props)
        // console.log("state inside bloglist render",this.state)
        const { groups_id, max_comments, load_more, display_component, isLoggedIn,awardCountData } = this.state;
        let item = this.state.data;
        let display_award_button = this.state.display_award_button;
        let display_connect_button = this.state.display_connect_button;
        let write_comment = this.state.write_comment;

        if (!this.state.isLoggedIn)
        {

        }
        else if (groups_id != 4)
        {
            display_award_button = false;
        }

        if (groups_id == 6)
        {
            display_connect_button = false;
            write_comment = false;
        }
        
        return (
            display_component ? <div className="list-item">
                <div>
                    <div className="list-item-inner flex">
                        {
                            (item.post_image) ? (
                                <div className="list-left" style={{ backgroundImage: 'url(' + item.post_image + ')' }}></div>
                            ) : (
                                    <div className="list-left" style={{ backgroundImage: 'url(' + default_img + ')' }}></div>
                                )
                        }
                        <div className="list-info flex-auto">
                            <div className="list-info-body">
                                <div className="title-box flex">
                                    <div className="title flex-auto">
                                        <h4 onClick={() => this.handleDetail(item)} className="link">{item.title}</h4>
                                    </div>
                                    {(!this.props.limited_actions && display_award_button)?(
                                    <div className="award-action-box">
                                        <div className="new-award-box flex">
                                            <div className="new-award-list">
                                                {(this.state.awardGold > 0 && awardCountData)? (<div className="new-awarded-item award-gold"><i><img src={require('../../Assets/img/award-gold.png')}  alt="Award Gold"/></i> {this.state.awardGold}
                                                    <div className="new-award-popup award-hover-popup">
                                                        <div className="new-award-popup-top">
                                                            <span className="award-item-view"><img src={require('../../Assets/img/award-gold.png')} alt="Award Gold"/></span>
                                                        </div>
                                                        <div className="new-award-popup-body">
                                                            <div className="title2">Gold Award</div>
                                                            <p>Description... </p>
                                                        </div>
                                                    </div>
                                                </div>) : ''}
                                                {(this.state.awardPlatinum > 0 && awardCountData)? (<div className="new-awarded-item award-platinum"><i><img src={require('../../Assets/img/award-platinum.png')} alt="Award Platinum"/></i> {this.state.awardPlatinum}
                                                    <div className="new-award-popup award-hover-popup">
                                                        <div className="new-award-popup-top">
                                                            <span className="award-item-view"><img src={require('../../Assets/img/award-platinum.png')} alt="Award Platinum"/></span>
                                                        </div>
                                                        <div className="new-award-popup-body">
                                                            <div className="title2">Platinum Award</div>
                                                            <p>Description... </p>
                                                        </div>
                                                    </div>
                                                </div>) : ''}
                                                {(this.state.awardSilver > 0 && awardCountData)? (<div className="new-awarded-item award-silver"><i><img src={require('../../Assets/img/award-silver.png')} alt="Award Silver"/></i> {this.state.awardSilver}
                                                    <div className="new-award-popup award-hover-popup">
                                                        <div className="new-award-popup-top">
                                                            <span className="award-item-view"><img src={require('../../Assets/img/award-silver.png')} alt="Award Silver"/></span>
                                                        </div>
                                                        <div className="new-award-popup-body">
                                                            <div className="title2">Silver Award</div>
                                                            <p>Description... </p>
                                                        </div>
                                                    </div>
                                                </div>) : ''}
                                            </div>
                                            <div className="new-award-give">
                                                {this.state.canGiveAwardStatus ?'': <span className="add-award" tooltip="Give Award" onClick={this.handleAwardPopup}><i className="icon-award-plus"></i></span>}
                                                {
                                                    (this.state.awardPopup) ? (
                                                        <>
                                                        <div className="new-award-popup">
                                                            <div className="new-award-popup-body">
                                                                <h4>Give Award</h4>
                                                                <div className="new-award-list new-give-award-list">
                                                                    <span className="new-award-item award-gold" onClick={() => this.handleGiveAward('gold',item.uuid,true,1)}><i><img src={require('../../Assets/img/award-gold.png')} alt="Award Gold"/> </i> Gold</span>
                                                                    <span className="new-award-item award-platinum" onClick={() => this.handleGiveAward('platinum',item.uuid,true,2)}><i><img src={require('../../Assets/img/award-platinum.png')} alt="Award Platinum"/> </i> Platinum</span>
                                                                    <span className="new-award-item award-silver" onClick={() => this.handleGiveAward('silver',item.uuid,true,3)}><i><img src={require('../../Assets/img/award-silver.png')} alt="Award Silver"/> </i> Silver</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="page-overlay" onClick={this.handleAwardPopup}></div>
                                                        </>
                                                    ) : ('')
                                                }
                                            </div>
                                        </div>
                                        {/* <span className={(item.award_status) ? "btn btn-sm active btn-outline" : "btn btn-sm btn-outline"} onClick={() => this.handleAwardbox(item)}>Awards</span> */}
                                        {/* {
                                            this.state.award && (
                                                <>
                                                    <div className="msg-action-popup arrow-top">
                                                        <div className="awards-popup-body">
                                                            <div className="title1"><strong>{(item.total_awards) ? item.total_awards : 0}</strong> Awards Received</div>
                                                            <div className="btn-outer">
                                                                {(item.award_status) ? <>
                                                                    <span className="btn btn-outline btn-sm" style={{ opacity: 0.2 }}>Give Award</span>
                                                                    <span className="btn btn-cancel btn-sm" onClick={() => this.handleAward(item, 'remove')}>Dismiss</span>
                                                                </> : <>
                                                                        <span className="btn btn-outline btn-sm" onClick={() => this.handleAward(item, 'add')}>Give Award</span>
                                                                        <span className="btn btn-cancel btn-sm" style={{ opacity: 0.2 }}>Dismiss</span>
                                                                    </>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="page-overlay" onClick={() => this.handleAwardbox(item)}></div>
                                                </>
                                            )
                                        } */}
                                    </div>
                                    ):null}
                                </div>
                                <div className="posted-by-meta">
                                    { 
                                        item.researcher.length ? (
                                    <span className="posted-by-users-outer">
                                        <span className="posted-by-users flex">
                                        {                                       
                                            item.researcher.map((researcher, idx) => {
                                                let res_title = researcher.name;
                                                
                                                 if(researcher.researcher_data && researcher.researcher_data.organization)
                                                {
                                                    res_title = res_title + " ("+researcher.researcher_data.organization+")";
                                                }
                                               //console.log('idx',idx)
                                                return (
                                                    <>

                                                    {researcher.researcher_data && researcher.researcher_data.user_slug && idx <= 2 ? (
                                                        <Link to={'/p/' + researcher.researcher_data.user_slug}>
                                                        <span className="posted-by-user flex items-center"><span className="user-thumb" title={res_title}><img src={user_default_img} alt="User Icon"/> </span> <span className="user-name">{researcher.name}</span> </span> 
                                                        </Link>
                                                        ) : (( idx <= 2)?
                                                        <span className="posted-by-user flex items-center"><span className="user-thumb" title={res_title}><img src={user_default_img} alt="User Icon"/> </span> <span className="user-name">{researcher.name}</span> </span>
                                                        
                                                            :('')
                                                         )
                                                    }
                                                    </> 
                                                    )
                                            })

                                        }
                                        {
                                            item.researcher.length > 4 ? (
                                            <span className="researcher-more-box link" onClick={() => this.setDetail(item)}>+{item.researcher.length - 4}</span>
                                            ) : ('')
                                        }




                                            {/* }<span className="posted-by-user flex items-center"><span className="user-thumb"><img src={user_default_img} alt="User Icon"/> </span> <span className="user-name">Santosh Mishra</span> </span> 
                                            <span className="posted-by-user flex items-center"><span className="user-thumb"><img src={user_default_img} alt="User Icon"/> </span> <span className="user-name">Prakash Jain</span></span> 
                                            {*/}
                                        </span>     
                                    </span> 

                                    ) : ('')
                                }   
                                </div>
                                {/* <div className="tags-outer users-item-box">
                                    {                                       
                                        item.researcher.map((researcher, idx) => {
                                            return (
                                                <span className="user-item" tooltip={researcher.name} key={idx}>
                                                    {researcher.user_slug ? (

                                                        <Link to={'/p/' + researcher.user_slug} className="user-thumb"><img src={user_default_img} /></Link>
                                                    ) : (
                                                            <span className="user-thumb"><img src={user_default_img} /></span>
                                                        )
                                                    }
                                                </span>
                                            )                                          
                                        })
                                    }
                                </div> */}
                                <div className="stream-tag-box">
                                    {
                                        item.streams.length ? (
                                            <div className="stream-labels">
                                            {                                       
                                                item.streams.map((stream, idx) => {
                                                    return (
                                                    <>
                                                    <span className="label stream-label link" onClick={()=>this.handleStreamClick(stream)} key={idx} >{stream.name}</span>
                                                    </> 
                                                    )
                                                })
                                            }
                                            </div>
                                        ) : ('')
                                    }
                                
                                    <Tag post={item} location={this.props.location} history={this.props.history} onSetBlogList={this.props.onSetBlogList}></Tag>
                                </div>
                                <div className="list-bottom-meta flex">
                                    <div className="list-meta flex-auto">
                                        <span className={(this.state.fullContent === item) ? "meta-item action-detail active" : "meta-item action-detail"} onClick={() => this.handleFullContent(item)}><i className="icon-detail"></i></span>
                                        {(!this.props.limited_actions)?(
                                        <WishList item={item} isLoggedIn={isLoggedIn} handleWishlist={this.handleWishlist} setToWishList={this.props.setToWishList} history={this.props.history}></WishList>
                                        ):null}

                                        
                                        <span className={(this.state.comments === item) ? "meta-item action-comments count-box active" : "meta-item action-comments count-box"} onClick={() => this.handleComment(item)}><i className="icon-comments"></i>
                                            {this.state.totalComments ? <span className="count">{this.state.totalComments}</span> : ''}
                                        </span>
                                        
                                        <SharePost handlePin={this.handlePin} item={item} limited_actions={this.props.limited_actions} ></SharePost>
                                    </div>
                                    <div className="list-date-view">
                                        
                                        {
                                            (item.user_name!=='') ? (
                                            <div className="posted-by-date posted-date-box">
                                                <span className="research-date">Posted by: </span>
                                                <span className="date-box link" onClick={()=>this.setToProfileDetail(item.user_slug)}>{item.user_name}</span>   
                                            </div>
                                            ):''

                                        }
                                        <div className="posted-by-date posted-date-box">
                                            <span className="research-date">Posted on: </span>
                                            <span className="date-box"><i className="icon-date"></i> {moment(item.date_added).format('DD MMM, YYYY')}</span>    
                                        </div>
                                        {(item.research_date ?<div className="posted-by-date research-date-box flex">
                                            <span className="research-date">Research date: </span>
                                            <span className="date-box"><i className="icon-date"></i> {moment(item.research_date).format('DD MMM, YYYY')}</span>    
                                        </div>:('')) }
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <AddConnection item={item} hideConnect={!display_connect_button} history={this.props.history} />
                    </div>
                    {
                        (this.state.fullContent === item) ? (
                            <div className="article-full">
                                <div className="article-full-inner flex">
                                    {/* <div className="list-img"><img src={(item.post_image) ? item.post_image : default_img} alt="blog" /></div> */}
                                    <div className="list-full-data flex-auto">
                                        <div className="article-content"><p>{renderHTML(item.brief.replace('\r\n', '<br/><br/>'))}</p></div>
                                        <div className="article-readmore">
                                            <span className="btn btn-sm btn-outline btn-full-article" onClick={() => this.setDetail(item)} >Full article</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ('')
                    }
                    {
                        (this.state.comments === item) ? (
                            <div className="comment-full-panel" key={item.id}>
                                {/* {(this.state.isLoggedIn) ? (
                                    <AddBlogComment blog_uuid={item.uuid} handleCommentAdd={this.props.handleCommentAdd} updateCommentsList={this.updateCommentsList} />
                                ) : null} */}
                                <div className="comment-listing-outer">
                                    <div className="comment-title-box flex">
                                        <div className="heading2 flex-auto">
                                        <span className="comment-icon-box"><i className="icon-comments"></i></span>
                                        {
                                                (this.state.totalComments) ? (
                                                    <>
                                                    Comments ({this.state.totalComments})
                                                    </>

                                                    ):'Be the first to comment on this.'

                                            }
                                         
                                        

                                        </div>

                                        <div className="btn btn-sm btn-write-comment" onClick={() => this.handleDetail(item)}>Write Comments</div>

                                    </div>
                                    {(this.state.comments[item.uuid]) ? (
                                        <>
                                            <ul className="comment-listing-box">
                                                {this.state.comments[item.uuid].map((comment, index) => {
                                                    if (max_comments && index < max_comments) {
                                                        return (
                                                            <li key={index}>
                                                                <BlogCommentList comment={comment} blog_uuid={item.uuid} handleCommentAdd={this.props.handleCommentAdd} parent_uuid={comment.uuid} key={index} />
                                                            </li>
                                                        )
                                                    }
                                                })
                                                }
                                            </ul>
                                            {load_more ? <div onClick={this.load}>Load more comments</div> : ''}
                                        </>
                                    ) : (<div className="loading"></div>)}
                                </div>
                            </div>
                        ) : ('')
                    }
                </div>
                {
                    this.state.detailsModal ? (<div className="popup-full popup-detail-full" id="articleDetailPopup">
                        <span className="close-action" onClick={this.handleDetail}>
                            <i className="icon-close"></i>
                        </span>
                        <div className="popup-main">
                            <Details item={item} handleCommentAdd={this.props.handleCommentAdd} getComments={this.getComments} comments={this.state.comments} handleWishlist={this.handleWishlist} setToWishList={this.props.setToWishList} totalComments={this.state.totalComments} updateCommentsList={this.updateCommentsList} handlePin={this.handlePin}
                                location={this.props.location} history={this.props.history} onSetBlogList={this.props.onSetBlogList} />
                        </div>
                    </div>) : null
                }
            </div> : ""
        );
    }
}

BlogList.propTypes = {
    getPostComments: PropTypes.func.isRequired,
    handleCommentAdd: PropTypes.func.isRequired,
    item: PropTypes.object,
    onSetBlogList: PropTypes.func.isRequired,
    setToAward: PropTypes.func,
    setToPin: PropTypes.func,
    setToWishList: PropTypes.func,
};