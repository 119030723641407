import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Alert from '../Alert';
import { get_user_data } from '../../utils.js';

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

const url = api_url + 'user/connection/add';
const connection_check_url = api_url + 'user/connection/check';

export default class AddConnection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            messagebox: false,
            messagetxtbox: true,
            //connection_message: 'Hi there! I am really interested in the research from this post and I was wondering if we should collaborate?',
            connection_message: 'Hi there! I am interested in this research article & I was wondering if we should collaborate?',
            alert: false,
            alert_msg: '',
            txt_msg: '',
            alert_type: '',
            hideConnect:(props.hideConnect)?props.hideConnect:false,
            onlyConnect:(props.onlyConnect)?props.onlyConnect:false,
        };
        //console.log('props.onlyConnect')
        //console.log(props.onlyConnect)
    }
    handleMessagebox = () => {
        const { messagebox,item } = this.state;
        this.setState({ messagebox: !messagebox });

        if (token) {
            const user_data=get_user_data()


            if(user_data && item.user_id === user_data.users_id)
            {
                this.setState({ txt_msg: 'The request for self-connection is not allowed.' });
            }
            else
            {
                axios.post(connection_check_url, { user_id: item.user_id },
                {
                    headers: { "Authorization": "Token " + token },
                }).then((res) => {
                    if (res.data.status === "success") {
                        this.setState({ txt_msg: res.data.message });
                    }
                }).catch((error) => {
                    this.setState({ txt_msg: '', messagetxtbox: false });

                })

            }

            

        }
        else
        {
            this.setState({ txt_msg: '', messagetxtbox: false });

        }
    }
    handleChange = (e) => {
        this.setState({ connection_message: e.target.value })
    }
    handleSend = async () => {
        const { item, connection_message } = this.state;

        if (token) {
            this.setState({ alert: false, alert_msg: '', alert_type: '' });
            await axios.post(url, { message: connection_message, user_id: item.user_id },
                {
                    headers: { "Authorization": "Token " + token },
                }).then((res) => {
                    if (res.data.status === "success") {
                        this.setState({ messagebox: false, alert: true, alert_msg: 'Connection Request has been sent Successfully', alert_type: 'success' });
                    }
                }).catch((error) => {
                    this.setState({ messagebox: false, alert: true, alert_msg: error.response.data.message, alert_type: 'error' });
                })
        } else {
            this.props.history.push('/login');
        }
    }
    render() {
        const { messagetxtbox, txt_msg, messagebox, connection_message,alert,alert_msg, alert_type, onlyConnect, hideConnect } = this.state;
        return (
            <div className="list-right">
                {alert ? <Alert message={alert_msg} type={alert_type}></Alert> : ""}
                <ul className="list-actions">
                    {
                        hideConnect===false ? (
                    <li className="msg-action-box">
                        <span className="action-item" onClick={this.handleMessagebox}><i className="icon-refresh" title="Connect"></i></span>
                        {
                            messagebox ? (
                                <>

                                {
                            messagetxtbox ? (
                                <>
                                    <div className="msg-action-popup">
                                        <div className="msg-action-inner">
                                        {txt_msg}
                                        </div>
                                    </div>
                                    <div className="page-overlay" onClick={this.handleMessagebox}></div>
                                </>
                                ) :
                                <>



                                    <div className="msg-action-popup">
                                        <div className="msg-action-inner">
                                            <div className="msg-textbox"><textarea className="form-style" value={connection_message} onChange={this.handleChange}>{connection_message}</textarea></div>
                                            <div className="msg-post-btn"><button className="btn btn-sm" onClick={this.handleSend}>Send</button> </div>
                                        </div>
                                    </div>
                                    <div className="page-overlay" onClick={this.handleMessagebox}></div>
                                


                                </>
                            }
                                </>






                            ) : null
                        }
                    </li>

                    ) : null
                    }
                    
                    {
                        onlyConnect===false ? (
                        <>
                        <li><span className="action-item bg-green"><i className="icon-dollar"></i></span></li>
                        </>
                        ) : null
                    }
                </ul>
            </div>
        );
    }
}

AddConnection.propTypes = {
    item: PropTypes.object.isRequired,
};