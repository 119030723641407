import React from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';
import RelatedPostSkeleton from '../Skeletons/RelatedPost'

var config = require('../../react-config.js');
const api_url = config.api_url;
//const post_default_img = require('../../Assets/img/img1.jpg')
const post_default_img = require('./../../Assets/img/img-default.jpg')
const token = localStorage.getItem('token');
export default class RelatedPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relatedPostData: [],
            page: 1,
            limit: 10,
            loading: true,
            post:props.post,   
            relatedItem: [1,2,3,4,5,6,7,8],        
        };
    }
    componentDidMount = () => {
        this.relatedPostList();
        //window.scrollTo(0, 0);
    }

    postData = async (params) => {
        return await axios.get(api_url+'post/elist',
            {
                params: params,
                headers: token ? { "Authorization": "Token " + token } : {},
            }
        )
    }
    relatedPostList = () => {

        var _this1 = this;
        let data = {}
        let params = {}
        let tags = []
        let researchers = []
        data['page'] = _this1.state.page;
        data['limit'] = _this1.state.limit;
        //data['search_text'] = _this1.state.post.title;

        _this1.state.post.tags.map((tag, idx) => {
            tags.push(tag.name)
        })

        _this1.state.post.researcher.map((researcher, idx) => {
            researchers.push(researcher.name)
        })

        if(tags.length > 0)
        {
            data['search_tag'] = tags;
            //params['search_tag'] = tags;
        }

        if(researchers.length > 0)
        {
            data['search_researchers'] = researchers;
            //params['search_researchers'] = researchers;
        }


        /*this.postData(params)
            .then((res) => {
                if (data.length === 0) {
                    _this1.setState({relatedPostData:[],loading:false }) 
                }
                if (res && Array.isArray(res.data.records)) {                    
                   _this1.setState({ relatedPostData: res.data.records,loading:false }) 
                } else {
                    this.setState({ loading: false });
                }
            });*/

        //console.log('tag')
        //console.log(tags.length)
        // const {posted_by,user_id} =this.state;

        const url = api_url + 'post/related';
        $.ajax({
            type: 'GET',
            url,
            contentType: 'application/json',
            data: data,
            dataType: 'json',
            success: function (resp) {
                if (resp.records && resp.records.length > 0) {
                    _this1.setState({ relatedPostData: resp.records,loading:false })
                }else{
                    _this1.setState({relatedPostData:[],loading:false }) 
                }
            }
        }).catch(err=>{
            _this1.setState({loading:false }) 
        });
    }
    // handleDetail = (item) => {
    //    console.log(item);
    // }
    setDetail = (blog) => {
        this.props.history.push({
            pathname: '/article/'+blog.slug,
        })
    }
    render() {
        const { post } = this.props;
        const { loading } = this.state;
        return (
            <div className="sidebar-group related-posts">
                
                {loading ? (
                    <div className="skeleton-main">
                        <div className="sidebar-title skeleton">&nbsp;</div>
                        <div className="posts-outer">
                            {
                                this.state.relatedItem.map((item,i)=>{
                                    return <RelatedPostSkeleton key={i} />
                                })
                            }
                        </div>
                    </div>                    
                ) : <>
                        <div className="sidebar-title">Related Posts</div>
                        <div className="posts-outer">
                            {
                                this.state.relatedPostData.length > 0 ?
                                this.state.relatedPostData.map((item, i) => {
                                    return (
                                        <>
                                            {
                                                item.uuid !== post.uuid ? (
                                                    <div className="post-item flex" key={i}>
                                                        {
                                                            (item.post_image) ? (
                                                                <div className="post-img-box" style={{ backgroundImage: 'url(' + item.post_image + ')' }}></div>
                                                            ) : (
                                                                    <div className="post-img-box " style={{ backgroundImage: 'url(' + post_default_img + ')' }}></div>
                                                                )
                                                        }
                                                        <div className="post-info-box flex-auto">
                                                            <div className="title" ><h5 onClick={() => this.setDetail(item)} className="link">{item.title}</h5></div>
                                                            <div className="date"></div>
                                                            <div className="post-meta user-info">
                                                                <span className="user-meta">{moment(item.date_added).format('DD MMM, YYYY')}</span>
                                                               {(item.total_likes>0 ? <span className="user-meta"><span className="meta-count">{item.total_likes}</span> Likes</span>:'')}
                                                                {(item.total_comments >0 ?<span className="user-meta"><span className="meta-count">{item.total_comments}</span> Comments</span>:'')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ''
                                            }
                                        </>
                                    )
                                })
                                :"There are no related posts."
                            }
                        </div>
                    </>}
            </div>
        );
    }
}

RelatedPost.propTypes = {
    // item: PropTypes.object.isRequired,
    // handlePin: PropTypes.func.isRequired,
};