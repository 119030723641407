import {connect} from 'react-redux';
import React, {Component} from 'react'
import CreatePost from '../Components/CreatePost'
//import {getStreamData, getTagData} from './../selectors';

//import {setStreamList,setTagList} from './../thunks';


class CreatePostPage extends Component {

	constructor(props){
        super(props);

        this.state = {
            editorData: '',
            tagsData: props.getTagData,
            researchers: {},
            streamData: props.getStreamData
        }
       
    }
    /*
    componentDidMount() {
        this.props.onSetStreamList();  
        this.props.onSetTagList(); 
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.getStreamData !== this.state.streamData) {
            const streamList = nextProps.getStreamData;        
           
			this.setState({streamData:streamList})
        }

        if (nextProps.getTagData !== this.state.tagsData) {
            const tagsList = nextProps.getTagData;            
			this.setState({tagsData:tagsList})
        }

        
    }*/


	render(){
		return(
			<div className="page-full page-create-post-full">
                <CreatePost history={this.props.history}/>
            </div>
		)
	}
}
//export default CreatePostPage


export function mapStateToProps(state) {
    return {
    	//getStreamData:getStreamData(state),
    	//getTagData:getTagData(state),
    };
}

export function mapDispatchToProps(dispatch) {
    return {    	
    	//onSetStreamList: () => dispatch(setStreamList()),
    	//onSetTagList: () => dispatch(setTagList()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostPage)
