import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import axios from 'axios';
import { get_user_data } from '../utils.js';

var config = require('../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');
const url = api_url + 'user/connection/list';
let user = {}
if(localStorage.getItem('user'))
{
    user = JSON.parse(localStorage.getItem('user'));
}

class Menu extends React.Component {
    constructor(props) {
        super(props);

        let user_data = get_user_data();
        this.state = {
            groups_id: (user_data) ? user_data.groups_id : '',
            conncections:'',
            loader:true,
        }
    }
    handlelinkClick = (path) => {
        this.props.handleMenu();
        this.props.history.push(path);
        this.props.handleNotificationDropdown("notification-by-menu")
    }
    componentDidMount() {
        this.list();
    }
    list = async () =>{
        if(token){
            await axios.post(url,{},
                {
                    headers: { "Authorization": "Token " + token },
                }).then((res)=>{
                    
                    if(res && res.data && res.data.total_invitation_received)
                    {
                        let total_invitation_received = res.data.total_invitation_received > 0 ?res.data.total_invitation_received : '';
                        this.setState({conncections:total_invitation_received,loader:false})

                    }
                }).catch((error)=>{
                    // this.props.history.push('/login');
                })
        }else{
            //  code ..
        }
    }
    render() {
        const { postData, notifications ,totalPinnedCount} = this.props;
        const {conncections,loader,groups_id} = this.state;
        //const connect_request = Array.isArray(conncections) ? conncections.length : 0;
        return (
            <div className="menu-left-full">
                <Scrollbars className="customScrollBar" style={{ height: '100%' }}>
                    <div className="menu-left-body">
                        <ul className="menu-left-nav">
                            <li><span onClick={() => this.handlelinkClick("/about")}>About</span></li>
                            <li><span onClick={() => this.handlelinkClick("/team")}>Team</span></li>
                            <li><span onClick={() => this.handlelinkClick("/search")}>Streams</span></li>
                           { (groups_id !=6) ? <li><span onClick={() => this.handlelinkClick("/article/new")}>Create Post Article</span></li>:'' }
                            <li><span onClick={() => this.handlelinkClick("/")}>Search</span></li>
                            <li><span onClick={() => token? this.handlelinkClick("/?pin=" + true): this.handlelinkClick("/login")}>Pinned<span className="menu-count">{totalPinnedCount ? totalPinnedCount:''}</span></span></li>
                            { (groups_id !=6) ? <li><span onClick={() => this.handlelinkClick("/messages")}>Messages<span className="menu-count"></span></span></li>:'' }
                            { (groups_id !=6) ? <li><span onClick={() => this.handlelinkClick("/notifications")}>Notifications <span className="menu-count">{notifications ? notifications : ''}</span></span></li>:''}
                            { (groups_id !=6) ? <li><span onClick={() => this.handlelinkClick("/mynetwork/")}>My Network<span className="menu-count">{loader ? <span className="loading"></span> :conncections}</span></span></li>:'' }
                            <li><span onClick={() => token ? this.handlelinkClick("/p/"+user.slug):this.handlelinkClick("/login")}>Profile</span></li>
                            <li><span onClick={() => this.handlelinkClick("/help")}>Help / Suggestions</span></li>
                        </ul>
                    </div>
                </Scrollbars>
            </div>
        )
    }
}
export default Menu