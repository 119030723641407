import {streamList,tagList,blogList,loading} from './actions';

export function registerAccount(data) {
    return (dispatch, getState, api) => {
        return api
            .register(data)
            .then((res) => {
                return res;
            });
    };
}

export function submitLogin(data) {
    return (dispatch, getState, api) => {
        return api
            .login(data)
            .then((res) => {
                return res;
            });
    };
}

export function setCommentAdd(data) {
    return (dispatch, getState, api) => {
        return api
            .commentAdd(data)
            .then((res) => {
                return res;
            });
    };
}

export function setStreamList(data) {
    return (dispatch, getState, api) => {
        return api
            .getStreamList(data)
            .then((res) => {
                dispatch(streamList(res.records))
                return res;
            });
    };
}

export function setTagList(data) {
    return (dispatch, getState, api) => {
        return api
            .getTagsList(data)
            .then((res) => {
                dispatch(tagList(res.records))
                return res;
            });
    };
}

export function setBlogList(data) {
    return (dispatch, getState, api) => {
        if(!data.loadMore){
            dispatch(loading(true));
        }
        return api
            .getBlogList(data)
            .then((res) => {
                setTimeout(()=>{ 
                    if((res.records))
                    {
                        // console.log(res.records);
                        // if(data.loadMore && data.loadMore === true){
                        //     return res.records;
                        // }else{
                            
                        // }
                        dispatch(blogList(res.records))

                    }else{
                        dispatch(blogList([]))
                    }
                    dispatch(loading(false));

                }, 300);
                
                return res.records;
            });
    };
}

export function setToWishList(id) {
    return (dispatch, getState, api) => {
        return api
            .addToWishList(id)
            .then((res) => {
                return res;
            });
    };
}

export function setToAward(id) {
    return (dispatch, getState, api) => {
        return api
            .addToAward(id)
            .then((res) => {
                return res;
            });
    };
}

export function setToPin(id) {
    return (dispatch, getState, api) => {
        return api
            .addToPin(id)
            .then((res) => {
                return res;
            });
    };
}

export function handleLogout() {
    return (dispatch, getState, api) => {
        return api
            .logout()
            .then((res) => {
                return res;
            });
    };
}

export function postComments(data) {
    return (dispatch, getState, api) => {
        return api
            .comments(data)
            .then((res) => {
                //console.log('Comment List')
                //console.log(res.data)
                return res;
            });
    };
}