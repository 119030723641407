import React, {Component} from 'react'
import ContentEditable from 'react-contenteditable'
class Filepreview extends Component {
    constructor(props){
        super(props);
        this.contentEditable = React.createRef();
        this.state = { 
            addedFiles: props.file,
            mainPreview: props.file[0],
            message:props.message,
        };
    }

    handleFileView = (file) => {
        this.setState({
            mainPreview: file
        })
    }

    handleDataSend = () => {
        this.props.filePreviewSubmit(this.state)
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.file  !== this.state.addedFiles)
        {
            this.setState({addedFiles:nextProps.file});
        }
        if(nextProps.message  !== this.state.message)
        {
            this.setState({message:nextProps.message});
        }
    }

    render(){
        let {addedFiles,message} = this.state
        return(
            <div className="preview-main">
                <div className="preview-header">
                    <span className="close" onClick={this.props.close}><i className="icon-close"></i> </span>
                    <div className="heading2">Preview</div>
                </div>
                <div className="preview-body">
                    <div className="file-preview-box">
                        <div className="file-preview">
                            <div className="main-file-preview">
                                {this.state.mainPreview.blob_url ? <img src={this.state.mainPreview.blob_url} /> : this.props.close() }
                            </div>
                        </div>	
                        <div className="file-caption-box">
                            <div className="file-caption-input">
                                {/*<input type="text" className="caption-input form-style" />*/}
                                <ContentEditable
                                    innerRef={this.contentEditable}
                                    html={message} // innerHTML of the editable div
                                    disabled={false}       // use true to disable editing
                                    onChange={this.props.handleMessage} // handle innerHTML change
                                    // tagName='message' // Use a custom HTML tag (uses a div by default)
                                    className="caption-input form-style"
                                    placeholder="Type a message"
                                />
                            </div>
                            <div className="file-caption-button">
                                <button className="caption-send-button" onClick={this.handleDataSend} ><i className="icon-send"></i> </button>
                            </div>
                        </div>	
                    </div>
                </div>
                <div className="preview-bottom">
                    <div className="preview-bottom-inner">
                        <div className="added-files-outer">
                            <div className="added-files">
                                <div className="added-files-view">
                                    {
                                        addedFiles.map((file,i) => {
                                            // console.log('addedFiles.map')
                                            // console.log(file)
                                            // console.log(file[0].blob_url)
                                            return(
                                                <div key={i} className={(this.state.mainPreview.blob_url === file.blob_url) ? "added-item active" : "added-item"} onClick={() => this.handleFileView(file)} style={{backgroundImage: 'url('+file.blob_url+')'}}><span className="remove" onClick={() => this.props.handleFileRemove(file)}></span></div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="added-item add-item-file">
                                    <div className="add-new-file">
                                        <input type="file" onChange={this.props.handleAddFile} />
                                        <div className="icon-plus"></div>
                                        <span>Add File</span>
                                    </div>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Filepreview