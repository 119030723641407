import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom'
import Alert from '../Alert';

import AddResearcher from './../PostArticle/Add-researcher'

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

const user_default_img = require('./../../Assets/img/user-default.jpg')
//const avatar = require('../../Assets/img/img-default.jpg');
export default class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            users_id: props.users_id,
            researchers: props.researchers,
            edit: true,
            alert: false,
            dropdown: false,
            display_component: true,
            addResearcher: false,
            alert_msg: '',
            alert_type: '',
            
        };
    }

    handleDropdown = () => {
        this.setState({
            dropdown: !this.state.dropdown
        })
    }

    
    /*handleAddClaim = async (data) => {

        const isClaimed = "Are you sure you want to Claimed this researcher?";
        if(window.confirm(isClaimed) === true){          

            //api call Here for update

            const url = api_url + 'user/researcher/add';
            this.setState({ alert: false, alert_msg: " ", alert_type: '' })
            await axios.post(url, { 'id':data.id,'first_name':data.first_name,'claimed':true,'added_by': this.
            state.users_id }, {
                headers: { "Authorization": "Token " + token }
            }).then((res) => {
                // console.log(res);
                if (res && res.data && res.data.status === "success") {
                    this.setState({ alert: true, alert_msg: "Claimed successfully", alert_type: 'success' })
                    this.updateUpdatedResearcher(res.data.record)
                }
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.message) {
                    this.setState({ alert: true, alert_msg: err.response.data.message, alert_type: 'error' })
                }
            });

        }        



    }*/

     handleResearcherUpdate = () => {
        this.setState({
            addResearcher: true
        })
    }
    handleResearcherCancel = () => {
        this.setState({
            addResearcher: false
        })
    }

    updateUpdatedResearcher=(data)=>{
        this.setState({item:data})
       // this.handleDropdown();
         
      }

    componentWillReceiveProps(nextProps) {
        const researchers = nextProps.researchers;
        if(researchers != this.state.researchers)
        {
            this.setState({researchers:researchers})

        }

    }
    

    render() {        

        const { avatar, item, alert, researchers, edit, users_id} = this.state;
       
       return (
            this.state.display_component ?
            <div className="user-list flex items-center">
                <div className="user-list-left">
                    <div className="thumb">
                        <img src={user_default_img} alt="" />                        
                    </div>
                </div>
                <div className="user-list-info flex-auto">
                    <div className="username">
                        {item.first_name} {item.last_name && item.last_name}
                        {(item.organization)?' ('+item.organization+')':''}
                    </div>
                    <div className="organization">{item.email && item.email}</div>
                    <div className="organization">{item.qualification && item.qualification}</div>
                    
                </div>
                <div className="user-list-right flex items-center">

                     {
                        (item.claimed == 0 | item.claimed==null) ? (
                            <>

                                <span className="btn-dot has-dropdown">
                                    <i className="icon-open-menu" onClick={this.handleDropdown}></i>
                                    {
                                        this.state.dropdown ? (
                                            <>
                                            <div className="dropdown right dropwdown-nav">
                                                <ul className="nav">
                                                    <li><span className="remove-action" onClick={()=>this.handleResearcherUpdate(item)}><i className="icon-edit"></i> Update Researcher</span></li>
                                                    {/*}<li><span className="remove-action" onClick={()=>this.handleAddClaim(item)}><i className="icon icon-plus"></i> Add Claim</span></li> {*/}
                                                    
                                                </ul>
                                            </div>
                                            {this.state.addResearcher && <AddResearcher close={this.handleResearcherCancel} edit = {edit}  item={item} list={researchers} updateResearcherList = {this.props.updateResearcherList} addedResearcher = {this.updateUpdatedResearcher} users_id={users_id}/>}
                                            <div className="page-overlay" onClick={this.handleDropdown}></div>
                                            </>
                                        ) : ('')
                                    }
                                </span>
                                </>

                        ) : (item.claimed == 1)?
                        <span className="claimed"><i class="icon-check"></i>Claimed</span>
                        :''



                        
                    } 
                


                    
                </div>
                {alert ? <Alert message={this.state.alert_msg} type={this.state.alert_type}></Alert> : ""}
            </div>: ''
        );
    }
}

Item.propTypes = {
    item: PropTypes.object.isRequired,
};