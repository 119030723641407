import React, {Component} from 'react'

class PrivacyPage extends Component{
    render(){
        return(
            <div className="page-full page-home-full cms-page-full">
                <div className="banner-inner">
                    <div className="container">
                        <div className="heading-box text-center"><div className="page-title">Privacy</div></div>
                    </div>
                </div>
                <div className="page-main-body">
                    <div className="container">
                        <div className="cms-page-container">
                            <div className="cms-data-inner">
                                <p>We are committed to protecting our users from unauthorized access to or unauthorized alteration, disclosure or destruction of personal information which we may otherwise hold. For example:</p>
                                <ul className="list-style">
                                    <li>We store information about you in a data center with restricted access, and we use a variety of technical security measures to secure your data. We also use intrusion detection and virus protection software.</li>
                                    <li>We encrypt the database that stores your data (encrypted “at rest”) and the digital methods for transporting your data (secure socket layer (SSL))</li>
                                    <li>We periodically review our information collection, storage and processing practices, including physical security measures.</li>
                                    <li>We restrict access to only AISquare employees, contractors and agents who need to know that information to manage the hub and process such data for us, and who are subject to confidentiality obligations.</li>
                                    <li>All passwords are protected and are not visible by AISquare, its contractors or agents, or even you.</li>
                                </ul>
                                <p>Despite these measures, we cannot guarantee that unauthorized persons will not be able to hack our system or otherwise defeat our security measures.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default PrivacyPage