import React, {Component} from 'react'
import Connect from '../Components/Connect/'

class ConnectPage extends Component {

	render(){
		return(
			<div className="page-full page-profile-full">
                <Connect history={this.props.history}/>
            </div>
		)
	}
}
export default ConnectPage