import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { checkLogin, limitWord } from '../../utils.js';
import Details from './index';
import $ from 'jquery';
import { Helmet } from "react-helmet";
import renderHTML from 'react-render-html';
//import Meta from '../Meta';


var config = require('../../react-config.js');
const api_url = config.api_url;
const base_url = config.base_url;

const token = localStorage.getItem('token');
class DetailPage extends Component {
    constructor(props) {
        super(props);

        var slug = this.props.match.params.slug

        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const edit = (params.get('edit'))?params.get('edit'):'';


        let isLoggedIn = checkLogin();
        this.state = {
            // post: props.location.state.details,
            slug: slug,
            edit:edit,
            post: '',
            comments: [],
            // totalComments: props.location.state.details ? props.location.state.details.total_comments : '',
            totalComments: 0,
            isLoggedIn: isLoggedIn,
        };

        if (slug && slug.length > 0) {
            this.getPost(slug)
        }
    }
    componentDidMount() {
        // this.getPost(this.props.match.params.slug);
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const edit = params.get('edit');
        this.setState({edit:true})

        //console.log('location', params.get('edit'))


    }
    getPost = (slug) => {
        //console.log('location state', this.state.edit);
        const url = api_url + 'post/details';
        let postData = '';
        //if (token) {
            postData = $.ajax({
                type: 'GET',
                url,
                contentType: 'application/json',
                //headers: { "Authorization": "Token " + token },
                headers:token ? {"Authorization": "Token "+token}: '',
                data: JSON.stringify({ "slug": slug,'edit': this.state.edit }),
                data: { "slug": slug, 'edit': this.state.edit },
                dataType: 'json',
            }).catch(function (e) {
                console.log(e);
            });
        /*}
        else {
            postData = $.ajax({
                type: 'POST',
                url,
                contentType: 'application/json',
                //headers:{"Authorization": "Token "+token},
                data: JSON.stringify({ "slug": slug }),
                dataType: 'json',
            }).catch(function (e) {
                console.log(e);
            });
        }*/
        postData.then(async (res) => {
            this.setState({
                post: res.records,
                totalComments: res.records.total_comments
            });
        });
    };

    getComments = (post_uuid) => {
        let data = {}
        let _this = this;
        data['post_uuid'] = post_uuid;
        if (post_uuid) {
            const comments = this.props.getPostComments(data);
            comments.then(async (res) => {
                const mcomm = this.state.comments;
                mcomm[post_uuid] = (res.records) ? res.records : []
                _this.setState({
                    comments: mcomm,
                    totalComments: mcomm[post_uuid].length
                });
            });
        }
    }

    handlePin = (item) => {
        if (!this.state.isLoggedIn) {
            this.props.history.push('/login');
        }
        let data = {}
        data['post_uuid'] = item.uuid;
        if (this.state.post.pin_status) {
            data['pin_status'] = false;
        } else {
            data['pin_status'] = true;
        }
        this.props.handleToPin(data).then((res) => {
            if (res.status === 'success') {
                this.setState(res.record);
                this.setState({ post: res.record });
            }
        });
    }
    updateCommentsList = (blog) => {
        const mcomm = this.state.comments;
        const totalComments = this.state.totalComments;
        mcomm[blog.post_uuid].push(blog);
        this.setState({
            comments: mcomm,
            totalComments: totalComments + 1
        });
    }


    handleWishlist = (item) => {
        if (!this.state.isLoggedIn) {
            this.props.history.push('/login');
        }
        let data = {}
        data['post_uuid'] = item.uuid;
        //if(this.state.wishlist === item){
        if (this.state.post.like_status) {
            data['like_status'] = false;
        } else {
            data['like_status'] = true;
        }
        this.props.handleToWishList(data).then((res) => {
            if (res.status === 'success') {
                this.setState(res.record);
                this.setState({ post: res.record });
            }
            else {
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        // const params = new URLSearchParams(this.props.location.search);
        // const params_next = new URLSearchParams(nextProps.location.search);
        // const editText = params_next.get('edit') ?params_next.get('edit'):'';
        // if(params !== params_next){
        //     this.setState({edit:editText})
        //     // this.getPost(nextProps.slug)
		// }
        // console.log('componentWillReceiveProps(nextProps)=', nextProps)
        const post = nextProps.post;
        const totalComments = nextProps.totalComments;
        this.setState({
            post: post,
            totalComments: totalComments,
        })

        if (nextProps.match.params.slug && nextProps.match.params.slug.length > 0) {
            var newSlug = nextProps.match.params.slug
            var currentSlug = this.state.slug

            if (newSlug !== currentSlug) {
                this.getPost(newSlug)
                this.setState({ slug: newSlug })
            }
        }

 
    }
    render() {
        const { post } = this.state;
        return (
            <div className="page-full page-detail-full">
                
                <Link className="back-action" to={"/"}><i className="icon-back"></i></Link>
                <div className="page-main-body">
                    <div className="container">
                        <div className="details-page-outer">
                            <div className="card">
                                {post ? <Details item={post} handleCommentAdd={this.props.handleCommentAdd} setToWishList={this.props.handleToWishList} getComments={this.getComments} comments={this.state.comments} handleWishlist={this.handleWishlist} totalComments={this.state.totalComments} updateCommentsList={this.updateCommentsList} handlePin={this.handlePin } location={this.props.location} history={this.props.history} onSetBlogList={this.props.onSetBlogList} /> : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DetailPage