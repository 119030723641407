import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default class NotificationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { item, i } = this.props;
        return (
            <div className="post-item notification-item flex items-center">
                 <div className="notification-icon">
                        {(item.activity==="like" || item.activity==="unlike") ? <i className="icon-heart"></i> : ''}
                        {item.activity==="add" ? <i className="icon-comments"></i> : ''}
                        {item.activity==="share" ? <i className="icon-share"></i> : ''}
                        {item.activity==="pin" ||  item.activity==="unpin" ? <i className="icon-pin"></i> : ''}
                        {item.activity==="connection request" ? <i className="icon-connect"></i> : ''}
                        {item.activity==="give award" || item.activity==="dismiss award" ? <i className="icon-award"></i> : ''}
                        {item.activity==="follow" ? <i className="icon-follow"></i> : ''}
                        {item.activity==="unfollow" ? <i className="icon-unfollow"></i> : ''}
                 </div>
                  {/* {item.activity} */}
                 <div className="post-info-box flex-auto">
                     <div className="title"><h5><b>{item.first_name} {item.last_name && item.last_name}</b><Link to={`/${item.details_url}`}> {item.content}. </Link></h5></div>
                     <div className="date">{moment(item.date_updated).format('DD MMM, YYYY')}</div>
                 </div>
             </div>
        )
    }

}

NotificationList.propTypes = {
    item: PropTypes.object.isRequired,
};