import React, {Component} from 'react'
import renderHTML from 'react-render-html'

class TeamModal extends Component{
    render(){
        let team = this.props.data
        return(
            <div className="popup-full team-popup-full">
                <span className="close-action" onClick={this.props.close}><i className="icon-close"></i></span>
                <div className="popup-main">
                    <div className="popup-body">
                        <div className="team-detail-view flex">
                            <div className="team-detail-left">
                                <div className="team-img" style={{backgroundImage: 'url('+team.thumb+')'}}></div>
                                <div className="team-info">
                                    <h5>{team.name}</h5>
                                    <div className="team-des">{team.designation}</div>
                                </div>
                            </div>
                            <div className="team-detail-right flex-auto">{renderHTML(team.content)}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default TeamModal