import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class Footer extends Component{
    render(){
        return(
            <div className="footer-full">
                <div className="container">
                    <div className="footer-box flex">
                        <div className="footer-left flex-auto">
                            <ul className="f-nav">
                                <li><a href="#">Contact Us</a></li>
                                <li><a href="/faq">FAQ's</a></li>
                                <li><Link to={'/terms'}>Terms</Link></li>
                                <li><Link to={'/privacy'}>Privacy</Link></li>
                            </ul>
                            <div className="copyright-text">© 2020 www.aisquare.org. All rights reserved</div>
                        </div>
                        <div className="footer-right">
                            <ul className="social-links">
                                <li><a href="#"><img src={require('../Assets/img/facebook.svg')} alt="f" /> </a></li>
                                <li><a href="#"><img src={require('../Assets/img/twitter.svg')} alt="t" /> </a></li>
                                <li><a href="#"><img src={require('../Assets/img/linkedin.svg')} alt="in" /> </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Footer