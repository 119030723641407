import React, {Component} from 'react';
import { Helmet } from "react-helmet";

class AboutPage extends Component{
    render(){
        return(
            <div className="page-full page-home-full cms-page-full page-login-full">
                 <Helmet>
							<title>About Us | AISquare.org</title>
							<meta name="description" content="AISquare Covid-19 is a research hub built to accelerate solutions and innovations surrounding the current pandemic."/>
				 </Helmet>
                <div className="banner-inner">
                    <div className="container">
                        <div className="heading-box text-center"><div className="page-title">About Us</div></div>
                    </div>
                </div>
                <div className="page-main-body">
                    <div className="container">
                        <div className="cms-page-container">
                            
                            <div className="cms-data-inner">
                                <div className="heading-new text-center">Vision and Mission</div>
                                <div className="data-outer">
                                    <div className="data-item">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>VISION</h3>
                                                <p><strong>AISquare</strong> aspires to serve as a <strong>launchpad and a source of truth</strong> for the next human challenge, creating <strong>Content and Connections</strong> for Global communities and empowering them to collaborate and solve the real world problems at scale.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data-item">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>WHAT IS AIS</h3>
                                                <p>AISquare Covid-19 is a research hub built to accelerate solutions and innovations surrounding the current pandemic. AISquare is a non profit initiative part of Open Growth organization. </p>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div className="data-item">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>WHO WE ARE</h3>
                                                <p>Founded by Industry leaders and student leaders from leading universities, AISquare is poised to become the ‘Source of Truth’ for all COVID related research today, and scale to other planetary issues in future.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data-item">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>OUR MEMBERS</h3>
                                                <p>Membership is open to any student or researcher interested in joining AIS, for the benefit of the research community.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data-item">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>WHO WE SERVE</h3>
                                                <p>Broad stakeholder community that includes individuals- students and researchers, research and scholarly organizations, data repositories and libraries, publishers, service providers etc.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AboutPage