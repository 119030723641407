import React from 'react';


export default class UnderConstruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="page-full page-connect-full">
                <div className="page-main-body">
                    <div className="container">
                        <div className="container-inner">
                            <div className="search-main-outer">
                                {/* <div className="card"> */}
                                    <h5>page under construction</h5>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
