import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlogList from './blog-list';
import ListingSkeleton from '../Skeletons/Listing'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { limitWord } from '../../utils.js';

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');
const url = api_url + 'post/list';
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data_length:0,
            page: 1,
            limit: 10,
            total_records: 0,
            loading_state: true,
            load_more: false,
            url: window.location.pathname,
            query: this.props.location.search,
            search_text: this.props.match.params.title,
            tag: this.props.location.state ? (this.props.location.state.tag ? this.props.location.state.tag.tag : '') : '',
            loader_on_click_load_button: false,
            post_message: 'No post found.',
            loadingScreens: [1,2,3,4,5,6],
        };
    }

    componentDidMount() {
        this.setState({ data: [], page: 1, load_more: false, total_records: 0,data_length:0 });
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const search_text = params.get('q');
        const tag = params.get('tag');
        const pin = params.get('pin');
        const uuid = params.get('stream');
        // console.log("Did Mount uuid=="+uuid);
        if(pin !==null)
        {
            //console.log("Did Mount pin=="+pin);
            this.setState({post_message:'You do not have any pinned post.'});
        }

        this.postList(search_text, 1, tag, pin, [], uuid);
    }
    postData = async (params) => {
        return await axios.get(url,
            {
                params: params,
                headers: token ? { "Authorization": "Token " + token } : {},
            })
    }
    postList = (search, page, tag, pin, data, uuid) => {
        const { limit, load_more } = this.state;
        let params = { pin_post: pin, search_text: search, search_tag: tag, page: page, limit: limit, loadMore: load_more, search_stream: uuid };
        for (var propName in params) {
            if (params[propName] === null || params[propName] === undefined || params[propName] === '') {
                delete params[propName];
            }
        }
        if (data.length === 0) {
            this.setState({ loading_state: true });
        }
        this.setState({ loader_on_click_load_button: true });
        this.postData(params)
            .then((res) => {
                if (data.length === 0) {
                    this.setState({ data: [],data_length:0 });
                }
                if (res && Array.isArray(res.data.records)) {
                    this.setState({ data: [...data, ...res.data.records], page: page, loadMore: true, total_records: res.data.totel_records, loading_state: false, loader_on_click_load_button: false,data_length:data.length+res.data.records.length})
                } else {
                    this.setState({ loading_state: false });
                }
            });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.getBlogData !== this.props.getBlogData) {

        }
        const params = new URLSearchParams(this.props.location.search);
        const params_next = new URLSearchParams(nextProps.location.search);
        const search_text_next = params_next.get('q');
        const tag_next = params_next.get('tag');
        const pin_next = params_next.get('pin');
        const uuid_next = params_next.get('stream');
        if(params !== params_next){
            if(pin_next !==null)
            {
                //console.log("Did Mount pin=="+pin);
                this.setState({post_message:'You do not have any pinned posts.'});
            }
            else
            {
                this.setState({post_message:'No post found.'});
            }
            this.postList(search_text_next, 1, tag_next, pin_next, [], uuid_next);
        }
    }

    loadMore = () => {
        const { data } = this.state;
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const search_text = params.get('q');
        const tag = params.get('tag');
        const pin = params.get('pin');
        const uuid = params.get('stream');
        const { page } = this.state;
        this.postList(search_text, page + 1, tag, pin, data, uuid);
    }
    final_data = () =>{
        this.setState({data_length:this.state.data_length - 1})
    }

    handleGivenAward = (post_uuid,award_status,award_type) =>{
               console.log("handle give award callled inside index.js")
               let url = api_url +'post/award';
               let data ={
                post_uuid,award_status,award_type
               }
               axios.post(url,data,  {headers: token ? { "Authorization": "Token " + token } : {},})
                          .then((res) =>{
                                  console.log("response",res)
                                  var response = this.state.data.filter((item) => item.uuid !== res.data.record.uuid )
                                  var finalData = [...response,res.data.record]
                                //   console.log("final data",finalData)
                                  this.setState({data:finalData})

                           })
                           .catch((error) =>{
                               console.log("error",error)
                           })
    }
    

    render() {
        // console.log("state data length",this.state.data.length)
        const { total_records, data, loading_state, loader_on_click_load_button,data_length } = this.state;
        const meta_title = 'AISquare.org'
        const meta_description = 'AISquare Home'
        return (
            <div className="page-full page-home-full">
                <Helmet>
					<title> { meta_title }</title>
					<meta name="description" content={limitWord(meta_description, 200)}/>
                </Helmet>
                <div className="page-main-body">
                    <div className="container">
                            {(loading_state) ? (
                                <div className="skeleton-screen">
                                    {
                                        this.state.loadingScreens.map((item,i)=>{
                                            return <ListingSkeleton key={i} />
                                        })
                                    }
                                    
                                </div>                                
                            ) : (this.state.data && data_length > 0) ? (
                                <div className="listing-outer">
                                    {
                                        this.state.data.map((item, i) => {
                                                // console.log("datataa aaaa",item)
                                            return <BlogList
                                                        getPostComments={this.props.getPostComments}
                                                        handleCommentAdd={this.props.handleCommentAdd}
                                                        history={this.props.history}
                                                        item={item}
                                                        onSetBlogList={this.props.onSetBlogList}
                                                        setToAward={this.props.setToAward}
                                                        handleGivenAward={this.handleGivenAward}
                                                        setToPin={this.props.setToPin}
                                                        setToWishList={this.props.setToWishList}
                                                        location={this.props.location}
                                                        final_data={this.final_data}
                                                        key = {i}
                                                    />
                                        })
                                    }
                                    {data && (data.length && (data.length < total_records ?
                                        <div className="text-center">
                                            {loader_on_click_load_button ? <ListingSkeleton /> :
                                                <button className="btn btn-primary" onClick={this.loadMore} > Load More</button>}
                                        </div>
                                        : null)
                                    )}
                                </div>
                            ) : (
                                <div className="card">
                                    <div className="card-body"><div className="no-found color-danger text-center">{this.state.post_message}</div></div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        )
    }
}

Home.propTypes = {
    getBlogData: PropTypes.array,
    getPostComments: PropTypes.func,
    handleCommentAdd: PropTypes.func,
    onSetBlogList: PropTypes.func,
    setToWishList: PropTypes.func,
    setToAward: PropTypes.func,
    setToPin: PropTypes.func,
};