import React from 'react';
export default class Suggestion extends React.Component {
    constructor(props) {
        super(props);
    }

    handleSuggestionClick = () => {
    	this.props.handleSuggestionClick(this.props.sl)
    }

    render() {
        const { sl, si } = this.props
        
       
        return (
            <li key={si} onClick={this.handleSuggestionClick} >
                <div className="suggestion-item">
                {
                    (sl.value===null)?<><i className="icon-researcher"></i></>:<><i className="icon-post"></i></>
                }
                 
                 
                {sl.label}</div>
            </li>
        )
    }
}