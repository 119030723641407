import React from 'react';
import PropTypes from 'prop-types';
import ShareIcons from './shareIcons';

export default class SharePost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            reply: '',
            reply_error: '',
            reply_success: '',
            parent_uuid:props.parent_uuid,
            pinStatus:props.item.pin_status
        };
    }
    componentWillReceiveProps = (nextPro) => {
        if(nextPro.item !== this.props.item){
            this.setState({item:nextPro.item});
        }
    }

    handlePin = async(item) =>{
          await this.setState({pinStatus:!this.state.pinStatus})

          this.props.handlePin(item)
    }
    render() {


        const {item,pinStatus}=this.state;
        const {title,brief,details,slug,post_image,uuid} = item;

        return (
            <div className="meta-item action-share share-action-box">
                <i className="icon-share"></i>
                <div className="share-dropdown">
                    <ul className="share-options">
                        {(!this.props.limited_actions)?(
                        <li className="divider">
                            <span className={(pinStatus) ? "share-item share-item-pin active" : "share-item share-item-pin"} onClick={() => this.handlePin(item)} title={(item.award_status) ? "Unpin this" : "Pin this"}>
                                <i className="icon-add-pin"></i>
                            </span>
                        </li>
                        ):null}
                        <ShareIcons post_uuid={uuid}title={title} details={details} brief={brief} slug={slug} post_image={post_image}></ShareIcons>
                    </ul>
                </div>
            </div>
        );
    }
}

SharePost.propTypes = {
    item: PropTypes.object.isRequired,
    handlePin: PropTypes.func.isRequired,
};