import React, { Component } from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import TextareaAutosize from 'react-textarea-autosize'
import Editor from './Editor'
import { List } from 'immutable'
import Alert from '../Alert';
import DatePicker from "react-datepicker"
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css"
import TagBoxComponent from './tagBox'
import SelectComponent from './selectBox'
import $ from 'jquery';
import { checkLogin,removeTags, get_user_data } from '../../utils.js';
import { v4 as uuidv4 } from 'uuid';
import Button from '../Button'
import AddResearcher from './Add-researcher'
import { Helmet } from "react-helmet";



const options = [
    { value: '1', label: 'Data Privacy' },
    { value: '2', label: 'Cloud' },
    { value: '3', label: 'Networking' },
    { value: '4', label: 'Startups' },
    { value: '5', label: 'Technology' }
]

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');


class CreatePost extends Component {
    constructor(props) {
        super(props);

        let isLoggedIn = checkLogin();
        let user_data = get_user_data();

        if (!isLoggedIn) {
            this.props.history.push('/');
        }

        



        var uuid = (props.match.params.uuid) ? props.match.params.uuid : uuidv4();
        var edit = (props.match.params.uuid) ? true : false;
        //const tags = [];
        const tags = List();
      
        //const researchers = [];
        const researchers = List();
        this.state = {
            user_data: (user_data) ? user_data : '',
            groups_id: (user_data) ? user_data.groups_id : '',
            edit: edit,
            customInsert: false,
            dropdown: -1,
            schedule: false,
            selectedStream: [],
            selectedResearcher: [],
            selectedTags: [],
            tags: tags,
            researchers: researchers,
            userData: {},
            streamData: {},
            startDate: new Date(),
            title: '',
            brief: '',
            details: '',
            editor_register_field: '',
            meta_title: '',
            meta_description: '',
            uuid:uuid,
            status:0,
            alert: false,
            alert_msg: '',
            alert_type: '',
            loading: false,
            processing: false,
            addResearcher: false,
          
            researchersList: [
                {id:'1', name:'Vikash Kumar', email:'vikash@ehostinguk.com', qualification:'MCA', university:'Delhi University'},
                {id:'2', name:'Ali Mohammad', email:'ali@ehostinguk.com', qualification:'MCA', university:'Delhi University'},
                {id:'3', name:'Braham Dev', email:'braham@ehostinguk.com', qualification:'MCA', university:'Delhi University'}
            ],
            publishOptions: false,
            publishError: 'Please enter heading, summary and researcher',
            researchDate: '',
            auto:false,
            autoupdate:false,
            setBrief:false,
            blink:false,
            timer:0,
            start_timer:false,
        }

        if((this.state.groups_id && (this.state.groups_id===4 || this.state.groups_id===5)) === false)
        {
            this.props.history.push('/');

        }

       

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate_form = this.validate_form.bind(this);

        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeBrief = this.handleChangeBrief.bind(this);
        this.handleChangeDetails = this.handleChangeDetails.bind(this);

        if (this.state.edit) {
            
            
            this.getPost(uuid)
            //console.log('constructor')
        }

    }
    // onhandleChange = (e) => {
    //     var name = e.target.name
    //     var value = e.target.value
    //     this.setState({ [name]: value })
    // }
    componentDidMount() {
        //console.log('componentDidMount')

        this.streamList();
        this.tagList();
        this.userList();

        setInterval(function(){
            // this.handleAutoSubmit()
            let timer = this.state.timer;
            if(this.state.start_timer === true)
            {
                timer += 1
                this.setState({'timer':timer})
            }
            
            if(this.state.timer >= 5)
            {
                this.handleAutoSubmit();
                this.setState({'start_timer':false,'timer':0})
            }
        }.bind(this), 1000)


        
    }

    handleAutoSubmit = (e) => {       

        let title = this.state.title;
        let details = this.state.details;
        let autoupdate = this.state.autoupdate; 
        let callSubmit = false
        if(autoupdate === false)
        {
            callSubmit = true
        } 


        if (!(title === undefined || title === '') && !(details === undefined || details === '')) {            
            this.setState({ 'autoupdate': true },()=>{
                this.handleSubmit(e)    
            });
            /*setTimeout(()=>{
                this.handleSubmit(e)
                //console.log('Hi handleSubmit')
            }, 5000);*/            
        }       
        
    }

    getPost = (uuid) => {
        //console.log('getPost')
       
        const url = api_url + 'post/details';
        let postData = '';
            postData = $.ajax({
                type: 'GET',
                url,
                contentType: 'application/json',               
                headers:token ? {"Authorization": "Token "+token}: '',
                //data: JSON.stringify({ "slug": uuid, "edit": true}),
                data: { "slug": uuid, "edit": true },
                dataType: 'json',
            }).catch(function (e) {
                console.log(e);
            });

        postData.then(async (res) => {

            //this.setState({
            //    post: res.records,
             
            //});

            let selectedResearcher = [];            

            if (res.records.researcher && res.records.researcher.length > 0) {   
            
                let obj = {}
                const newResearchers = res.records.researcher.map(n => {
                    //console.log(n.researcher_data)
                    if(n.researcher_data.organization)
                         obj = { value: n.id, label: n.name+' ('+n.researcher_data.organization+')' }
                    else  obj = { value: n.id, label: n.name }                       

                        selectedResearcher.push(obj)
                    })
               this.setState({ selectedResearcher })
                //console.log('selectedResearcher', selectedResearcher)

            }

            this.setState({
                'title':res.records.title,
                'brief':res.records.brief,
                'details':res.records.details,
                'editor_register_field':res.records.editor_register_field,
                'status':res.records.status,
                'meta_title':res.records.meta_title,
                'meta_description':res.records.meta_description,
                'researchDate':(res.records.research_date) ? new Date(res.records.research_date) : null,
                'status':res.records.status,
                'startDate':(res.records.future_date && (new Date(res.records.future_date) > this.state.startDate))?new Date(res.records.future_date):this.state.startDate,
            });

            // console.log('this.state.startDate=> ', this.state.startDate)

            let selectedStream = [];            

            if (res.records.streams && res.records.streams.length > 0) {
                res.records.streams.map((s) => {                    
                    var newStream = {
                        label: s.name,
                        value: s.uuid
                    }
                    selectedStream.push(newStream)
                })
            }
        
            this.setState({ selectedStream })

            

            let selectedTags = [];            

        
            if (res.records.tags && res.records.tags.length > 0) {               

                const newTags = res.records.tags.map(n => {
                        const obj = { value: n.uuid, label: n.name }                       

                        selectedTags.push(obj)
                    })
                this.setState({ selectedTags })

            }

            
        
            //this.setState({ selectedTags })
            //console.log('selectedResearcher')
            //console.log(selectedResearcher)



        });
    };

    validate_form(form_data) {
        var errors = {};
        let title = this.state.title;
        //let autoupdate = this.state.autoupdate;
        //let tags = form_data['tags'];
        //let stream = form_data['stream'];
        //let brief = this.state.brief
        //let researchers = form_data['researchers'];

        if (title === undefined || title === '') {
            errors['title'] = "Heading is required";
            $('input[name="title"]').addClass('error');



        }

        //if (brief === undefined || brief === '') {
        //    errors['brief'] = "Detail is required";
        //    $('input[name="brief"]').addClass('error');

        //}

        let researchers = (this.state.selectedResearcher)?this.state.selectedResearcher:[];
        
        if (researchers.length === 0) {

           errors['researchers'] = "Researchers is required";
           $('input[name="researchers"]').addClass('error');

        }


        const error_len = Object.keys(errors).length;

        this.setState({ errors: errors });

        if (error_len > 0) {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = (e) => {
        //console.log('status', this.state.status)
        //return false;


        //e.preventDefault();
        const {uuid, autoupdate} = this.state;   
        let errors = {};
        
        var validateForm = this.validate_form()
        //var validateForm = true
        this.setState({ alert: false, alert_msg: " ", alert_type: '', start_timer:false, timer:0 })

        if (validateForm) {

            if(this.state.status===1 && autoupdate===true)
            {
                this.setState({ 
                    loading: true,
                    processing: true
                });

            }

            

            
            // return false;
            //console.log('autoupdate')
            //console.log(autoupdate)

            const url = api_url + 'post/add';
            let _this = this;
            let data = {};
            data['title'] = _this.state.title;
            data['brief'] = _this.state.brief;
            data['details'] = _this.state.details;            
            data['meta_title'] = _this.state.meta_title;            
            data['meta_description'] = _this.state.meta_description;            
            data['uuid'] = uuid;

            let stream = [];
            let selectedStream = _this.state.selectedStream;
            selectedStream.map(n => {
                stream.push(n.value)
            });

            let tag = [];
            let selectedTags = _this.state.selectedTags;
            selectedTags.map(t => {
                tag.push(t.label)
            });
            let researcher = [];
            let selectedResearcher = (_this.state.selectedResearcher)?_this.state.selectedResearcher:[];
            selectedResearcher.map(t => {
                let id = isNaN(t.value) ? 0:t.value;
                if(t.organization)
                researcher.push({ "id": id, "name": t.label+' ('+t.organization+')' })
                else researcher.push({ "id": id, "name": t.label })
            });

          
            //console.log(researcher);return false;

            data['streams'] = stream;
            data['tags'] = tag;
            data['researcher'] = researcher;

            let status = _this.state.status;
            if(_this.state.status === 2)
            {
                
                data['future_date'] = moment(_this.state.startDate).format();
                
                //moment(this.state.startDate, 'YYYY-MMM-DD').format('YYYY-MM-DD')
            }

            if(_this.state.researchDate)
            {
                
                data['research_date'] = moment(_this.state.researchDate).format();
                
                //moment(this.state.startDate, 'YYYY-MMM-DD').format('YYYY-MM-DD')
            }
            
            data['status'] = status;

            // return false;
            $.ajax({
                type: 'POST',
                url,
                data: JSON.stringify(data),
                headers: { "Authorization": "Token " + token },
                contentType: 'application/json',
                dataType: 'json',
                beforeSend: function () {

                    _this.setState({ 
                            blink: true,
                        });
                    

                },
                success: function (res) {

                    _this.setState({ 
                            loading: false,
                            processing: false,
                            blink: false,
                        });

                    if (res.status === 'success') {

                        if(_this.state.edit)
                        {
                            
                            

                            let profile_slug = (_this.state.user_data && _this.state.user_data.slug)?_this.state.user_data.slug:''

                            if(profile_slug!='' && _this.state.autoupdate === false){

                                _this.setState({ alert: true, alert_msg: "Post Updated Successfully", alert_type: 'success' })
                                _this.handleDropdown(2)

                                setTimeout(()=>{
                                 window.location.href = "/p/"+profile_slug;
                             }, 2000);

                            }



                        }
                        else
                        {
                            //alert('Post Added successfully');
                            
                            let profile_slug = (_this.state.user_data && _this.state.user_data.slug)?_this.state.user_data.slug:''

                            if(profile_slug!='' && _this.state.autoupdate === false){
                                _this.setState({ alert: true, alert_msg: "Post Added Successfully", alert_type: 'success' })
                           

                                setTimeout(()=>{
                                 window.location.href = "/p/"+profile_slug;
                             }, 2000);

                            }
                            

                        }

                        
                        _this.setState({ autoupdate: false });
                    }
                    else {
                        errors['researchers'] = res.message;
                        _this.setState({ errors: errors });

                        _this.setState({ alert: true, alert_msg: res.message, alert_type: 'error',autoupdate: false, start_timer:false, timer:0 })
                    }


                }
            }).catch(
                err => {
                    const errStatus = err ? err.status : 500;
                    const errMsg = err.responseJSON ? err.responseJSON.message : 'Some error occured!';
                    //console.log('errStatus', err)
                    //console.log('errStatus=', errStatus)                    
                    //console.log('errStatus.message', err.responseJSON.message)
                    //console.log('_this.state.autoupdate', _this.state.autoupdate)
                    //console.log('errStatus.status', err.responseJSON.status)

                    if (errStatus === 400){
                        if(_this.state.autoupdate === false){
                            _this.setState({ alert: true, alert_msg: errMsg, alert_type: 'error',autoupdate: false })
                        }

                    // do something
                    } else {
                    // do another thing
                    }
                    _this.setState({ loading: false, processing: false, blink: false,start_timer:false, timer:0 })
                }
            );

        }

        //alert('hi')


    }

    
    handleDraftToSubmit = (e) => {
        this.setState({'status':0})
        setTimeout(()=>{
            this.handleSubmit(e)
        }, 100);
    }

    handleScheduleToSubmit = (e) => {
        this.setState({'autoupdate':false})
        this.setState({'status':2})
        setTimeout(()=>{
            this.handleSubmit(e)
        }, 100);
    }

    handlePublishToSubmit = (e) => {
       
        this.setState({'autoupdate':false})
        this.setState({'status':1})
        setTimeout(()=>{
            this.handleSubmit(e)
        }, 100);
    }

    handlePublish = () => {

        let researchers = (this.state.selectedResearcher)?this.state.selectedResearcher:[];
        
        
        if(!this.state.title == '' && !$(".ql-editor").hasClass("ql-blank") && researchers.length > 0){
            this.setState({
                publishOptions: !this.state.publishOptions,
                publishError: ''
            })
        } else {
            if(this.state.title == '' && $(".ql-editor").hasClass("ql-blank") && researchers.length === 0){
                this.setState({
                    publishError: 'Please enter heading, summary and researcher'
                })
            } else if(this.state.title == ''){
                this.setState({
                    publishError: 'Please enter heading'
                })
            } else if(researchers.length === 0){
                this.setState({
                    publishError: 'Please select researchers'
                })
            } else {
                this.setState({
                    publishError: 'Please enter summary'
                })
            }
        }
        
    }
    
    handleChangeTitle = (e) => {
        var name = e.target.name
        var value = e.target.value

        let researchers = (this.state.selectedResearcher)?this.state.selectedResearcher:[];
        
        this.setState({ [name]: value });
        this.setState({ 'meta_title': value.replace("&nbsp;", "") });
        //console.log('this.state.title', this.state.title)
        if(e.target.value == '' && $(".ql-editor").hasClass("ql-blank") && researchers.length === 0){
            this.setState({
                publishError: 'Please enter heading, summary and researcher'
            })
        } else if(e.target.value == ''){
            this.setState({
                publishError: 'Please enter heading'
            })
        } 
        else if($(".ql-editor").hasClass("ql-blank")){
            this.setState({
                publishError: 'Please enter summary'
            })
        } 
        else if(researchers.length === 0){
                this.setState({
                    publishError: 'Please select researchers'
                })
        } 

        else {
            this.setState({
                publishError: ''
            })
        }

        // For auto update call
       // this.setState({ 'autoupdate': true });
        //console.log('handleChangeTitle')
        // setTimeout(function(){
        //     this.handleAutoSubmit()
        // }.bind(this), 100)

        this.setState({'start_timer':true,'timer': 0});
    }

    handleChangeBrief = (e) => {

        var name = e.target.name
        var value = e.target.value
        this.setState({ [name]: value })
        //console.log('this.state.title', this.state.title)

        // For auto update call
        //console.log('handleChangeTitle')
        //this.setState({ 'autoupdate': true });
        
        // this.handleAutoSubmit()
        this.setState({'start_timer':true,'timer': 0});       

    }

    handleChangeDetails = value => {        
        //const match = value.match(/<(\w+)>(.*?)<\/\1>/);


        let value_find = value;
        //value_find = value_find.replace("<p><br></p>","");
        value_find = value_find.replace(/<img[^>]*>/g,"");
        value_find = value_find.replace(/<div class="(.*?)image-container-main".*?>(.*?)<\/div>/g, "");
        value_find = value_find.replace(/<p><br><\/p>/g,"");
        value_find = value_find.replace(/<p><\/p>/g,"");
        value_find = value_find.replace("<p></p>","");

       // console.log('value_find', value_find)

        const match = value_find.match(/<(\w+)>(.*?)<\/\1>/);
        let txt = match ? match[2]: '';
        const spaceBrief = removeTags(txt);
        const brief = (spaceBrief)?spaceBrief.replace("&nbsp;", ""):"";
        const spaceMetaDescription = brief.substring(0, 200)
        const metaDescription = spaceMetaDescription.replace("&nbsp;", "");

        let researchers = (this.state.selectedResearcher)?this.state.selectedResearcher:[];
        
        /* if(this.state.setBrief === true)
        {
            this.setState({ 'brief': brief });
            this.setState({ 'meta_description': metaDescription });

        }
        */

        //console.log('handleChangeDetails', researchers)
        
        this.setState({ 'details': value });
        this.setState({ 'brief': brief });
        this.setState({ 'meta_description': metaDescription });
        //console.log('details', this.state.details)
        //console.log('brief', this.state.brief)

        if($(".ql-editor").hasClass("ql-blank") && this.state.title == '' && researchers.length === 0){
            this.setState({
                publishError: 'Please enter heading, summary and researcher'
            })
        } else if(this.state.title == ''){
            this.setState({
                publishError: 'Please enter heading'
            })
        } else if($(".ql-editor").hasClass("ql-blank")){
            this.setState({
                publishError: 'Please enter summary'
            })
        } else if(researchers.length === 0){
                this.setState({
                    publishError: 'Please select researchers'
                })
        }
        else {
            this.setState({
                publishError: ''
            })
        }


        // setTimeout(function(){
        //     this.handleAutoSubmit()
        // }.bind(this), 6000)
        this.setState({'start_timer':true,'timer': 0});

    };

    async streamList() {

        var _this1 = this;
        const url = api_url + 'post/stream/list';

        $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json',
            headers: { "Authorization": "Token " + token },
            data: {},
            dataType: 'json',
            success: function (resp) {
                if (resp.records && resp.records.length > 0) {
                    // _this1.setState({streamData:resp.records})

                    const options = resp.records.map(n => {
                        const obj = { value: n.uuid, label: n.name }
                        return obj;
                    })
                    _this1.setState({ streamData: options })

                    //console.log(options)
                }


            }
        }).catch();
    }

    async userList() {
        /*this.setState({
            userData: options,
            researchers: options
        })*/

        var _this1 = this;
        const url = api_url + 'user/researcher/list';
        $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json',
            //headers: { "Authorization": "Token " + token },
            data: {},
            dataType: 'json',
            success: function (resp) {
                if (resp.records) {
                    let obj = {}
                    const options = resp.records.map(n => {
                       // console.log(n)
                        if(n.organization)
                         obj = { value: n.id, label: n.first_name + ' ' + n.last_name+' ('+n.organization+')' }
                        else obj = { value: n.id, label: n.first_name + ' ' + n.last_name}
                        return obj;
                    })
                    _this1.setState({
                        userData: options,
                        researchers: options
                    })
                    //console.log(options)
                }
            }
        }).catch();
    }

    async tagList() {
        var _this1 = this;
        const url = api_url + 'post/tag/list';
        $.ajax({
            type: 'POST',
            url,
            contentType: 'application/json',
            headers: { "Authorization": "Token " + token },
            data: {},
            dataType: 'json',
            success: function (resp) {
                if (resp.records && resp.records.length > 0) {
                    const options = resp.records.map(n => {
                        const obj = { value: n.uuid, label: n.name }
                        return obj;
                    })
                    _this1.setState({ tags: options })
                }
            }
        }).catch();
    }
    handleCustomInsert = () => {
        this.setState({
            customInsert: !this.state.customInsert
        })
    }
    handleFocus = () => {
        this.setState({
            customInsert: false
        })
    }
    handleDropdown = (id) => {
        this.setState({
            schedule: false
        })
        if(this.state.dropdown === id){
            this.setState({
                dropdown: -1
            })
        } else {
            this.setState({
                dropdown: id
            })
        }

        // For auto update call
        /*this.setState({ 'autoupdate': true });
        this.handleAutoSubmit()*/
    }
    handleChange = date => {
        this.setState({
          startDate: date
        });
        //let m = moment(date).format()
        //console.log('date', date)
        //console.log('date-format', m)

        // For auto update call
        //console.log('handleChangeTitle')
        //this.setState({ 'autoupdate': true });

        this.setState({'start_timer':true,'timer': 0});
        // this.handleAutoSubmit()

    };

    handleResearchDate = date => {
        this.setState({
            researchDate: date
        })

        // For auto update call
        //console.log('handleChangeTitle')
        //this.setState({ 'autoupdate': true });
        // this.handleAutoSubmit()
        this.setState({'start_timer':true,'timer': 0});
    }

    handleTagsChange = (tags) => {
        //console.log('tags')
        //console.log(tags)

        this.setState({'selectedTags': tags})

        // For auto update call
        /*this.setState({ 'autoupdate': true });
        setInterval(function(){
            this.handleAutoSubmit()
        }.bind(this), 100)*/
        this.setState({'start_timer':true,'timer': 0});
    };

    handleResearcherChange = async(researchers_data) => {
        //console.log('researchers')
        // console.log("researcher data after removing from list",researchers_data)
        // console.log("selected researcher",this.state.selectedResearcher)

        if(researchers_data === null)
        {
            await this.setState({selectedResearcher:[]})
        }else{
            await this.setState({selectedResearcher: researchers_data})
        }

        
        

        let researchers = (researchers_data !== null)?this.state.selectedResearcher:[];

        // console.log('researchers after compare', researchers)
        
        if($(".ql-editor").hasClass("ql-blank") && this.state.title == '' && researchers.length === 0){
            this.setState({
                publishError: 'Please enter heading, summary and researcher'
            })
        } else if(this.state.title == ''){
            this.setState({
                publishError: 'Please enter heading'
            })
        } else if($(".ql-editor").hasClass("ql-blank")){
            this.setState({
                publishError: 'Please enter summary'
            })
        } else if(researchers.length === 0){
                this.setState({
                    publishError: 'Please select researchers'
                })
        }
        else {
            this.setState({
                publishError: ''
            })
        }

        // For auto update call
        /*this.setState({ 'autoupdate': true });
        setInterval(function(){
            this.handleAutoSubmit()
        }.bind(this), 100)*/
        this.setState({'start_timer':true,'timer': 0});

    };

    handleChangeStream = stream => {
        // console.log('stream=',stream)
        /*var selectedStream = this.state.selectedStream

        if (stream && stream.length > 0) {
            stream.map((s) => {

                var newStream = {
                    label: s.label,
                    value: s.value || s.label
                }
                selectedStream.push(newStream)

            })
        }*/

        // console.log('selectedStream=',selectedStream)
        this.setState({ selectedStream:stream })

        // For auto update call
        //console.log('handleChangeTitle')
        //this.setState({ 'autoupdate': true });
        // this.handleAutoSubmit()
        this.setState({'start_timer':true,'timer': 0});

    };

    handleSchedule = () => {
        this.setState({
            schedule: !this.state.schedule
        })
    }
    handleResearcherAdd = () => {
        this.setState({
            addResearcher: true
        })
    }
    handleResearcherCancel = () => {
        this.setState({
            addResearcher: false
        })
    }

    updateResearcherList = (data) => {
        // console.log("update researcherlist data",data)
        let obj = {}
        
        const options = data.map(n => {
            if( n.organization)
             obj = { value: n.id, label: n.first_name + ' ' + n.last_name+' ('+ n.organization+')' }
             else obj =  { value: n.id, label: n.first_name + ' ' + n.last_name}
            return obj;
        })
        this.setState({
            userData: options,
            researchers: options
        })
        // For auto update call
        //console.log('handleChangeTitle')
        //this.setState({ 'autoupdate': true });
        // this.handleAutoSubmit()
        this.setState({'start_timer':true,'timer': 0});
    }

    updateAddedResearcher= async(data)=>{
        // console.log("update updateAddedResearcher data",data)
        // console.log("update updateAddedResearcher data and selectedresearcher",this.state.selectedResearcher)
        let obj = {}
        if(data.organization)
        obj = { value: data.id, label: data.first_name + ' ' + data.last_name+' ('+data.organization+')' }
        else obj = { value: data.id, label: data.first_name + ' ' + data.last_name}
       await this.setState({selectedResearcher:[...this.state.selectedResearcher,obj] })

        let researchers = this.state.selectedResearcher

        if($(".ql-editor").hasClass("ql-blank") && this.state.title == '' && researchers.length === 0){
            this.setState({
                publishError: 'Please enter heading, summary and researcher'
            })
        } else if(this.state.title == ''){
            this.setState({
                publishError: 'Please enter heading'
            })
        } else if($(".ql-editor").hasClass("ql-blank")){
            this.setState({
                publishError: 'Please enter summary'
            })
        } else if(researchers.length === 0){
                this.setState({
                    publishError: 'Please select researchers'
                })
        }
        else {
            this.setState({
                publishError: ''
            })
        }
       
      }

      updateDublicateResearcher = (data) =>
      {
        let obj = {}
        
        obj = {value: data.value, label: data.label}
        const found = this.state.selectedResearcher.some(el => el.value === data.value);

        if(!found)
        {            
            this.setState({selectedResearcher:[...this.state.selectedResearcher,obj] })
        }


      }
    render() {  
        const {alert, alert_msg, alert_type,uuid,tags,selectedTags,researchers,selectedResearcher,streamData,selectedStream,title,brief,details,editor_register_field,processing,loading, researchersList, blink} = this.state;
        const options = streamData;  
    
        //sessionStorage.setItem('editor_register_field',editor_register_field);

        //console.log('editor_register_field')
        //console.log(editor_register_field)

       // console.log("tags",researchers,"select",selectedResearcher)
        //console.log('Anand!',selectedTags)

        // console.log('processing')
        // console.log(processing)
        // console.log('loading')
        // console.log(loading)
        
        return (
            <>
            <div className={processing ? "create-post-full form-processing" : "create-post-full" }>
                {
                    this.state.edit === true ?
                    (
                        <Helmet>
                           <title>Edit Article | AISquare.org</title>
                            <meta name="description" content="Edit Article - AISquare.org"/>
                        </Helmet>
                    )
                    :
                    (
                        <Helmet>
                           <title>Create Article | AISquare.org</title>
                            <meta name="description" content="Create Article - AISquare.org"/>
                        </Helmet>
                    )
                }
                <div className="post-article-top">
                    <div className="container">
                        <div className="profile-page-outer">
                            <div className="post-top-inner flex">
                                <div className="post-top-left flex-auto"></div>  
                                <div className="post-article-btn">
                                    {/*}<span className="saving-status color-green">Saved</span> {*/}
                                    <span className={(blink)?"saving-status color-green text-blink":"saving-status color-green"}>Saved</span>
                                    <span className="btn-action-outer has-dropdown">
                                        {
                                            !this.state.publishError == '' ? (
                                                <button type="submit" className="btn btn-primary btn-sm btn-publish disabled" tooltip={this.state.publishError} onClick={this.handlePublish}>Publish</button>
                                            ) : (
                                                <button type="submit" className="btn btn-primary btn-sm btn-publish" onClick={this.handlePublish}>Publish</button>
                                            )
                                        }
                                        {/* <button type="submit" className="btn btn-primary btn-sm" onClick={this.handlePublish}>Publish</button> */}
                                        {
                                            this.state.publishOptions ? (
                                                <>
                                                <div className="dropdown dropdown-info">
                                                    <div className="dropdown-info-body">                                                        
                                                        {
                                                            this.state.schedule ? (
                                                                <div className="schedule-later">
                                                                    <div className="form-item">
                                                                        <label>Schedule a time to publish:</label>
                                                                        <div className="date-input-outer">
                                                                            <i className="icon-date"></i>
                                                                            <DatePicker
                                                                            selected={this.state.startDate}
                                                                            onChange={this.handleChange}
                                                                            timeInputLabel="Time:"
                                                                            dateFormat="MM/dd/yyyy h:mm aa"
                                                                            showTimeInput
                                                                            className="form-style2"
                                                                            />
                                                                        </div>
                                                                        <div className="text-sm">Calcutta time (GMT+5.5)</div>
                                                                    </div>
                                                                    <div className="info-bottom">
                                                                        <button type="button" onClick={this.handleScheduleToSubmit} className="btn btn-sm">Schedule to publish</button>
                                                                        <button type="button" className="btn btn-cancel btn-sm" onClick={this.handleSchedule}>Cancel scheduling</button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="btn-schedule-outer">
                                                                    {/* <button type="button" onClick={this.handlePublishToSubmit} className="btn">Publish now</button> */}
                                                                    <Button className={'btn'} handleSubmit={this.handlePublishToSubmit}  type={'submit'} value={'Publish now'} processing={processing} />
                                                                    <span type="button" className="link" onClick={this.handleSchedule}>Schedule for later</span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="page-overlay" onClick={this.handlePublish}></div>
                                                </>
                                            ) : ('')
                                        }
                                    </span>                                    
                                    <span className="btn-action-outer has-dropdown">
                                        <span className="btn-action" onClick={() => this.handleDropdown(3)}><i className="icon-more"></i></span>
                                        {
                                            this.state.dropdown === 3 ? (
                                                <>
                                                <div className="dropdown dropdown-post-options">
                                                    <ul className="nav">
                                                        <li><span onClick={() => this.handleDropdown(1)}>Change display subtitle</span></li>
                                                        <li><span onClick={() => this.handleDropdown(4)}>Seo Settings</span></li>
                                                    </ul>
                                                </div>
                                                <div className="page-overlay" onClick={() => this.handleDropdown(3)}></div>
                                                </>
                                            ) : ('')
                                        }
                                        {
                                            this.state.dropdown === 1 ? (
                                                <>
                                                <div className="dropdown dropdown-info">
                                                    <div className="dropdown-info-body">
                                                        <div className="info-item">
                                                            <div className="info-left"><div className="title2">Subtitle</div></div>
                                                            <div className="info-right">
                                                                <div className="info-subtitle"><textarea uuid={uuid} onChange={(e) => this.handleChangeBrief(e)} value={brief} className="form-style" rows="6" placeholder="Add a custom subtitle..." name="brief"></textarea></div>
                                                            </div>
                                                        </div>
                                                        <div className="info-bottom">
                                                            <button type="button" className="btn btn-outline btn-sm" onClick={() => this.handleDropdown(3)}>Done</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="page-overlay" onClick={() => this.handleDropdown(3)}></div>
                                                </>
                                            ) : ('')
                                        }
                                        {
                                            this.state.dropdown === 4 ? (
                                                <>
                                                <div className="dropdown dropdown-info">
                                                    <div className="dropdown-info-body">
                                                        <div className="info-item">
                                                            <div className="info-left"><div className="title2">Meta title</div></div>
                                                            <div className="info-right">
                                                                <div className="info-subtitle">
                                                                    <input
                                                                        className="form-style"
                                                                        name = "meta_title"
                                                                        onChange = {this.handleChangeBrief}
                                                                        placeholder="Enter meta title"
                                                                        type="text"
                                                                        value = {this.state.meta_title}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="info-item">
                                                            <div className="info-left"><div className="title2">Meta description</div></div>
                                                            <div className="info-right">
                                                                <div className="info-subtitle">
                                                                <textarea
                                                                    className="form-style"
                                                                    name="meta_description"
                                                                    onChange = {this.handleChangeBrief}
                                                                    placeholder="Add description ... "
                                                                    rows="6"
                                                                    maxlength="200"
                                                                    value = {this.state.meta_description}
                                                                ></textarea></div>
                                                            </div>
                                                        </div>
                                                        <div className="info-bottom">
                                                            <button type="button" className="btn btn-outline btn-sm" onClick={() => this.handleDropdown(3)}>Done</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="page-overlay" onClick={() => this.handleDropdown(3)}></div>
                                                </>
                                            ) : ('')
                                        }
                                    </span>                                    
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-main-body">
                    <div className="container">
                        <form>
                            <div className="profile-page-outer">
                                <div className="cp-form">
                                    <div className="cp-title">
                                        <TextareaAutosize className="form-style" onChange={(e) => this.handleChangeTitle(e)} value={title} placeholder="Heading" name="title" />
                                    </div>
                                </div>
                                <div className="editor-main-outer">
                                    <Editor uuid={uuid} editor_register_field={editor_register_field} details={details} handleChangeDetails={this.handleChangeDetails} value={details} />
                                </div>
                                <div className="cs-bottom post-bottom-items">
                                    <div className="cs-researchers post-bottom-item flex">
                                        <div className="cs-researcher-left flex-auto">
                                            <SelectComponent list={researchers} placeholder={"Add Researchers"} handleChange={this.handleResearcherChange} selectedTags={selectedResearcher} onCreateOption={
                                                () => {
                                                    alert('Create')
                                                }
                                            } formatCreateLabel={'Add '}></SelectComponent>
                                            <div className="add-researcher-box">
                                                {/* <div className="researchers-selected">
                                                    {
                                                        researchersList.map((item,i)=>{
                                                            return(
                                                                <span className="researchers-item" key={i}>
                                                                    <i className="icon-remove"></i>
                                                                    <span className="researcher-name">{item.name}</span>
                                                                    <span className="researcher-university">{item.university}</span>
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    
                                                </div> */}
                                                <div className="add-researcher">
                                                    <button className="btn btn-outline btn-sm" type="button" onClick={this.handleResearcherAdd}>+ Add Researcher</button>
                                                </div>
                                            </div>
                                            {this.state.addResearcher && <AddResearcher close={this.handleResearcherCancel} updateResearcherList = {this.updateResearcherList}  addedResearcher = {this.updateAddedResearcher} addedDublicateResearcher = {this.updateDublicateResearcher}  list={researchers}/>}
                                        </div>
                                        <div className="cs-researcher-date">
                                            <div className="date-input-outer">
                                                <i className="icon-date"></i>
                                                <DatePicker
                                                selected={this.state.researchDate}
                                                onChange={this.handleResearchDate}
                                                dateFormat="MM/dd/yyyy"
                                                className="form-style"
                                                placeholderText="Research date"
                                                maxDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cp-tags post-bottom-item">
                                        <Select
                                            value={selectedStream}
                                            options={streamData}
                                            isMulti
                                            placeholder="Stream"
                                            onChange={this.handleChangeStream}
                                        />                                       
                                    </div>
                                    <div className="cp-stream post-bottom-item">
                                        <TagBoxComponent list={tags} placeholder={"Add new tag"} handleChange={this.handleTagsChange} selectedTags={selectedTags}></TagBoxComponent> 
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {alert ? <Alert message={alert_msg} type={alert_type}></Alert> : ""}
            </>
        )
    }
}
export default CreatePost