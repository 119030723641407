import React from 'react';
import axios from 'axios';

var config = require('../../react-config.js');
const api_url = config.api_url;
// const token = localStorage.getItem('token');
const url = api_url + 'user/forgot/username';

export default class ForgotUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: '',
            username: '',
            submit_message: '',
            css_class: "form-style"
        };
    }

    handleChange = (event) => {
        let value = event.target.value;
        this.setState({ username: value });
        this.validate_form(value);
    }

    validate_form(username) {
        if (username === undefined || username === '') {
            this.setState({ css_class: "form-style error", errors: "Email address/Username required." })
            return false
        } else {
            this.setState({ css_class: "form-style success", errors: '' })
            return true;
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { username } = this.state;
        if (this.validate_form(username) === true) {
            await axios.post(url, { email: username },
                {
                    //  header section
                }).then((res) => {
                    if (res && res.data && res.data.status === "success") {
                        this.setState({ submit_message: "We have sent an email with your username to your email : "+ res.data.email })
                    }
                }).catch((error) => {
                    this.setState({ css_class: "form-style error", errors: error.response.data.message })
                })
        }
        return false;
    }

    render() {
        const { submit_message, username, css_class, errors } = this.state;
        return (
            <div className="page-full page-login-full flex items-center">
                <div className="page-center">
                    <div className="page-body">
                        <div className="form-box">
                            <div className="card">
                                <div className="card-body">
                                    {submit_message ? <p> {submit_message} </p> :
                                        <>
                                            <div className="heading-box text-center">
                                                <div className="heading">Forgot Username or Email Address</div>
                                            </div>
                                            <div className="form-main">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-inner">
                                                        <div className="form-item">
                                                            <input
                                                                type="text"
                                                                className={css_class}
                                                                name="username"
                                                                placeholder="Username or Email address"
                                                                onChange={this.handleChange}
                                                                value={username}
                                                                data-success=''
                                                            />
                                                            <span className='form-error'> {errors && errors} </span>
                                                        </div>
                                                        <div className="row btn-outer">
                                                            <div className="col"><button type="submit" className="btn btn-primary btn-full">Submit</button></div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}