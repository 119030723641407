import React, { Component } from 'react'
import renderHTML from 'react-render-html';
import PropTypes from 'prop-types';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import axios from 'axios';
import moment from 'moment';
import Alert from '../Alert';
import WishList from '../Home/wishList';
import { checkLogin } from '../../utils.js';
import { Link } from 'react-router-dom';
import Tag from '../Home/tag';
import userDefaultThumb from '../../Assets/img/user-default.jpg'

//const default_img = require('../../Assets/img/img1.jpg')
const default_img = require('./../../Assets/img/img-default.jpg')
const user_default_img = require('./../../Assets/img/user-default.jpg')
var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US');

export default class RelatedPosts extends Component {

    constructor(props) {
        super(props);
        let isLoggedIn = checkLogin();
        this.state = {
            posts: props.postData,
            my_post: props.my_post,
            display_component: true,
            alert: false,
            alert_msg: '',
            alert_type: '',
            isLoggedIn: isLoggedIn,
            researchCount : 0,
            disapprovePopUp:false,
            comment_message:'',
            selectedUuidForDisapproval:''
        
        }
    }

    setDetail = (slug) => {

        //console.log("set deatail slug",slug)

        if(this.props.type === "editor_approval" || this.state.posts.editor_approval===0 || this.state.posts.editor_approval===2){
                // console.log("history",this.props.history)
            console.log("inside editor approve")
                this.props.history.push({
                    pathname: '/article/' + slug ,
                    search: "?edit=true",
                })
    
        }else{
            if(this.state.posts.status===1)
            {
    
                this.props.history.push({
                    pathname: '/article/' + slug ,
                })
    
            }
        }


    }


    handleDelete = async (uuid,post_status) => {


        const isDelete = "Are you sure you want to delete this post?";
        if(window.confirm(isDelete) === true){

            const url = api_url + 'post/delete';
            this.setState({ alert: false, alert_msg: " ", alert_type: '' })
            await axios.post(url, { uuid: uuid }, {
                headers: { "Authorization": "Token " + token }
            }).then((res) => {
                // console.log(res);
                if (res && res.data && res.data.status === "success") {
                    this.setState({ display_component: false, alert: true, alert_msg: "Deleted successfully", alert_type: 'success' })
                    if(post_status === 1)
                        {
                            this.props.totalPost();
                        }
                }
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.message) {
                    this.setState({ alert: true, alert_msg: err.response.data.message, alert_type: 'error' })
                }
            });

        }
    }


    handleEditorApproval = async (uuid) => {


        const isApprove = "Are you sure you want to approve this Article?";
        if(window.confirm(isApprove) === true){

            const url = api_url + 'post/editor_approval';
            this.setState({ alert: false, alert_msg: " ", alert_type: '' })
            await axios.post(url, { uuid: uuid,editor_approval:"1" }, {
                headers: { "Authorization": "Token " + token }
            }).then((res) => {
                // console.log(res);
                if (res && res.data && res.data.status === "success") {
                    this.setState({ display_component: false, alert: true, alert_msg: "Approved successfully", alert_type: 'success' })
                }
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.message) {
                    this.setState({ alert: true, alert_msg: err.response.data.message, alert_type: 'error' })
                }
            });

        }
    }

    handleEditorDisapproval = (uuid) =>{
        const isDisapprove = "Are you sure you want to Disapprove this Article?";
        if(window.confirm(isDisapprove) === true){
           console.log("object")
           this.setState({disapprovePopUp : !this.state.disapprovePopUp,selectedUuidForDisapproval:uuid})
        }

    }

    sendDisapprovalComment = async () =>{

               const { comment_message,selectedUuidForDisapproval} = this.state

               console.log("value inside send", comment_message,selectedUuidForDisapproval)

               const url = api_url + 'post/editor_approval';
               this.setState({ alert: false, alert_msg: " ", alert_type: '' })
               await axios.post(url, { uuid: selectedUuidForDisapproval,editor_approval:"2",comment:comment_message }, {
                   headers: { "Authorization": "Token " + token }
               }).then((res) => {
                   console.log("res inside disapprove",res);
                   if (res && res.data && res.data.status === "success") {
                       this.setState({ display_component: false, alert: true, alert_msg: "Disapproved successfully", alert_type: 'success',disapprovePopUp:false,selectedUuidForDisapproval:'' })
                   }
               }).catch(err => {
                   if (err.response && err.response.data && err.response.data.message) {
                       this.setState({ alert: true, alert_msg: err.response.data.message, alert_type: 'error',disapprovePopUp:false,selectedUuidForDisapproval:'' })
                   }
               });
    }

    handleChangeComment = (e) => {
        this.setState({ comment_message: e.target.value })
    }

    closeDisapprovalPopUp = () =>{
        this.setState({disapprovePopUp:false,selectedUuidForDisapproval:''})
    }

    handleEdit = (uuid) => {
        this.props.history.push({
            pathname: '/article/update/' + uuid,
        })
    }

    increment=()=>{
        this.setState({ researchCount: this.state.researchCount + 1 });
      }

      handleStreamClick = (stream) => {
       
        this.props.history.push({
            pathname: '/search',
            search: "?stream=" + stream.name,
        })
    }
    render() {
        const { my_post, posts, alert, alert_msg, alert_type, isLoggedIn } = this.state;
        const toShowBrief = (posts.brief.length > 100) ? posts.brief.substring(0, 100) + "..." : posts.brief;
        const toShowTitle = (posts.title.length > 100) ? posts.title.substring(0, 100) + "..." : posts.title;
        const toShowName = (posts.length > 40) ? posts.substring(0, 40) + "..." : posts.tags.length>0 ?posts.tags[0].name : "";
        //  console.log(posts)
       
       
        return (
            this.state.display_component ?
                <div className="list-item">
                    <> 
                         <div className="list-item-inner flex related-post-item">
                            {/* to disapprove post popup */}
                                    { this.state.disapprovePopUp && <>
                                    <div className="msg-action-popup-new">
                                        <div className="msg-action-inner">
                                            <h6>Comments</h6>
                                            <div className="msg-textbox"><textarea className="form-style" onChange={this.handleChangeComment}></textarea></div>
                                            <div className="msg-post-btn">
                                                <button className="btn btn-sm"  onClick={this.closeDisapprovalPopUp} >Cancel</button> 
                                                <button className="btn btn-sm"  onClick={this.sendDisapprovalComment} >Submit</button> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-overlay" onClick={this.closeDisapprovalPopUp}></div>
                                    </>}
                            {
                                (posts.post_image) ? (
                                    <div className="list-left" style={{ backgroundImage: 'url(' + posts.post_image + ')' }}></div>
                                ) : (
                                        <div className="list-left" style={{ backgroundImage: 'url(' + default_img + ')' }}></div>
                                    )
                            }
        
                            <div className="list-info flex-auto">
                                <div className="list-info-body">
                                    {/* <div className="stream-labels">
                                    {                                       
                                        posts.streams.map((stream, idx) => {
                                            return (
                                            <>
                                            <span className="label stream-label">{stream.name}</span>

                                            </> 
                                            )
                                        })

                                    }

                                    </div> */}
                                    <div className="title-box flex">
                                        <div className="title flex-auto"><h4 className="link" onClick={() => this.setDetail(posts.slug)}>{renderHTML(toShowTitle.replace('\r\n', '<br/><br/>'))}</h4></div>
                                    </div>
                                    <div className="tags-outer users-item-box">
                                        <div className="posted-by-meta">
                                    { 
                                        posts.researcher.length ? (
                                    <span className="posted-by-users-outer">
                                        <span className="posted-by-users flex">
                                        {                                       
                                            posts.researcher.map((researcher, idx) => {
                                                let res_title = researcher.name;
                                                
                                                 if(researcher.researcher_data.organization)
                                                {
                                                    res_title = res_title + " ("+researcher.researcher_data.organization+")";
                                                }
                                                
                                                return (
                                                    <>

                                                    {researcher.researcher_data && researcher.researcher_data.user_slug && idx <= 3 ? (
                                                        <Link to={'/p/' + researcher.researcher_data.user_slug}>
                                                        <span className="posted-by-user flex items-center"><span className="user-thumb"  title={res_title}><img src={user_default_img} alt="User icon"/> </span> <span className="user-name">{researcher.name}</span> </span> 
                                                        </Link>
                                                        ) : ( (idx <= 3)?
                                                        <span className="posted-by-user flex items-center"><span className="user-thumb"  title={res_title}><img src={user_default_img}  alt="User icon"/> </span> <span className="user-name">{researcher.name}</span> </span>
                                                        :('')
                                                        )
                                                    }



                                                    </> 
                                                    )
                                            })

                                        }

                                        {
                                            posts.researcher.length > 4 ? (
                                            <span className="researcher-more-box link" onClick={() => this.setDetail(posts.slug)} >+{posts.researcher.length - 4}</span>
                                            ) : ('')
                                        }
                                        </span>     
                                    </span> 

                                    ) : ('')
                                }
  
                                </div>

                                        {/*}
                                        <div className="posted-by-meta">
                                            <span className="posted-by-users-outer">
                                                <span className="posted-by-users flex">
                                                    <span className="posted-by-user flex items-center"><span className="user-thumb"><img src={userDefaultThumb} /> </span> <span className="user-name">Santosh Mishra</span> </span> 
                                                    <span className="posted-by-user flex items-center"><span className="user-thumb"><img src={userDefaultThumb} /> </span> <span className="user-name">Prakash Jain</span></span> 
                                                </span>     
                                            </span>  
                                            <span className="posted-by-date">{moment(posts.date_added).format('DD MMM, YYYY')}</span>    
                                        </div>
                                        {*/}
                                    </div>
                                    <div className="tags-outer">
                                        { /*
                                          posts.researcher.map((researcher, idx) => {
                                             return (
                                                 <span className="user-item" tooltip={researcher.name} key={idx}>
                                                         {researcher.user_slug ? (
        
                                                             <Link to={'/p/' + researcher.user_slug} className="user-thumb"><img src={user_default_img}  alt="User thumb"/></Link>
                                                            ) : (
                                                                    <span className="user-thumb"><img src={user_default_img} /></span>
                                                                )
                                                            }
                                                        </span>
                                                    )
                                                })
                                                */
                                     }
                                    </div>
                                    {/* <span className="label label-tag">{renderHTML(toShowName.replace('\r\n', '<br/><br/>'))}</span> */}
                                    <div className="stream-tag-box">
                                    <div className="stream-labels">
                                    {                                       
                                        posts.streams.map((stream, idx) => {
                                            return (
                                            <>
                                            <span className="label stream-label link" onClick={()=>this.handleStreamClick(stream)} >{stream.name}</span>

                                            </> 
                                            )
                                        })

                                    }

                                    </div>
                                    <Tag post={posts} location={this.props.location} history={this.props.history}></Tag>
                                    </div>
                                    <div className="tags-outer">
                                       
                                    </div>
                                    <div className="flex related-post-meta">
                                        <div className="list-meta flex-auto">
                                            <span className="meta-item action-detail" onClick={() => this.setDetail(posts.slug)}><i className="icon-detail"></i></span>
                                            <WishList item={posts} isLoggedIn={isLoggedIn} setToWishList={this.props.setToWishList} history={this.props.history}></WishList>
                                            <span className="meta-item action-comments count-box" onClick={() => this.setDetail(posts.slug)}><i className="icon-comments"></i>
                                            
                                            {posts.total_comments ? <span className="count">{posts.total_comments}</span> : ''}

                                            </span>
                                        </div>
                                        <div className="list-date-view">
                                            <div className="posted-by-date posted-date-box">
                                                <span className="research-date">Posted on: </span><span className="date-box"><i className="icon-date"></i> {moment(posts.date_added).format('DD MMM, YYYY')}</span>
                                            </div>
                                            <div className="posted-by-date research-date-box flex">
                                                {/* <span className="research-date">Research date: </span> */}
                                                <span className="date-box"><i className="icon-date"></i> {this.props.type == 'draft' ? 'Last edited '+timeAgo.format(new Date(posts.date_added)) : ((posts.status===1 && posts.editor_approval===0)? ('Pending for Approval'): ((posts.status===1 && posts.editor_approval===2)?'Disapproved':'Published '+timeAgo.format(new Date(posts.date_added))))}</span>
                                            </div>
                                        </div>
                                        {this.props.curiousMind && <div className="list-meta">                                           
                                        {/* {(posts.research_date ?<div className="related-date-view research-date-box flex">
                                            <span className="research-date">Research date: </span>
                                            <span className="date-box"><i className="icon-date"></i> {moment(posts.research_date).format('DD MMM, YYYY')}</span>    
                                        </div>:('')) } */}
                                            <span className="related-date-view">
                                            </span>
                                            { (this.props.curiousMind && (this.props.posted_by) || (this.props.type && this.props.type === 'editor_approval')? '' :  <span className="meta-item action-delete" title="Delete" onClick={() => this.handleDelete(posts.uuid,posts.status)}><i className="icon-delete"></i></span>)}
                                            { (this.props.curiousMind && (this.props.posted_by) || (this.props.type && this.props.type === 'editor_approval') ? '' :   <span className="meta-item action-edit" title="Edit" onClick={() => this.handleEdit(posts.uuid)}><i className="icon-edit"></i></span> )}
                                            { this.props.type && this.props.type === 'editor_approval' ? 
                                                  <span>
                                                     <span  className="meta-item approve-button" title="Approve Article" onClick={() => this.handleEditorApproval(posts.uuid)}>Approve</span> 
                                                     <span  className="meta-item approve-button" title="Disapprove Article" onClick={() => this.handleEditorDisapproval(posts.uuid)}>Disapprove</span> 
                                                   </span>
                                                   :''}
                                        </div>}
                                    </div>
                                    { 
                                        this.props.posted_by ? '' : <>

                                        { 
                                            posts.status==2 ? <div className="posted-by-date schedule-to-publish text-right"> Scheduled to publish: {moment(posts.future_date).format('DD MMM, YYYY h:mm A')} </div>: <></>

                                        }
                                        </> 
                                    }
                                </div>
                            </div>
                        </div>
                        {alert ? <Alert message={alert_msg} type={alert_type}></Alert> : ""}
                    </>
                </div> : <>{alert ? <Alert message={alert_msg} type={alert_type}></Alert> : ""}</>
        )
    }
}
RelatedPosts.propTypes = {
    setToWishList: PropTypes.func,
};