import React, { Component } from 'react'
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize'
import { v4 as uuidv4 } from 'uuid';
import { month } from 'javascript-time-ago/gradation';
import Alert from '../Alert'



const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
class ExperienceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabActive: 1,
            // Compont used for add or edit default used for edit
            component_state: props.component_state ? props.component_state : "Add",
            currentYear: moment(moment()).format("Y"),
            currentMonth: moment(moment()).format("MMM"),
            s_month_index: undefined,
            s_year: props.item ? (props.item.from_date ? moment(props.item.from_date).format("YYYY") : '') : '',
            s_month: props.item ? (props.item.from_date ? moment(props.item.from_date).format("MMM") : '') : '',
            e_year: props.item ? (props.item.to_date ? moment(props.item.to_date).format("YYYY") : '') : moment(moment()).format("Y"),
            e_month: props.item ? (props.item.to_date ? moment(props.item.to_date).format("MMM") : '') : moment(moment()).format("MMM"),
            title: props.item ? (props.item.title ? props.item.title : '') : '',
            location: props.item ? (props.item.location ? props.item.location : '') : '',
            till_now: props.item ? (props.item.till_now ? props.item.till_now : false) : false,
            file: undefined,
            file_url: props.item ? (props.item.experience_image ? props.item.experience_image : '') : '',
            organization: props.item ? (props.item.organization ? props.item.organization : '') : '',
            uuid: props.item ? (props.item.uuid ? props.item.uuid : uuidv4()) : uuidv4(),
            details: props.item ? (props.item.details ? props.item.details : '') : '',
            errors: '',
            api_error: props.addExperience_message,
            alert: props.alert,
            msg_type: props.msg_type
        }
    }
    handleActive = (id) => {
        this.setState({
            tabActive: id
        })
    }
    handleEnddate = (e) => {
        if (e.target.checked) {
            this.setState({ till_now: true })
        } else {
            this.setState({ till_now: false })
        }
    }
    validate_form(form_data) {
        if (form_data) {
            this.setState({ errors: undefined });
            return true;
        } else {
            this.setState({ errors: "title is required" });
            return false;
        }
    }
    onhandleChange = (event) => {
        const { s_month, s_year, e_year } = this.state;
        const name = event.target.name;
        const value = event.target.value;
        let file = event.target.files ? event.target.files[0] : undefined;
        // console.log(uuid)
        if (file) {
            this.setState({ file: file });
        } else {
            var stateObject = function () {
                var returnObj = {};
                returnObj[name] = value;
                return returnObj;
            }.bind(event)();
            this.setState(stateObject);
        }
        if (name === "e_year" && value === s_year) {
            this.setState({
                s_month_index: months.findIndex(month => {
                    return month === s_month;
                })
            });
        }
        else if (name === "s_year") {
            if ((e_year && s_month) && (value === e_year)) {
                this.setState({
                    s_month_index: months.findIndex(month => {
                        return month === s_month;
                    })
                });
            }
        }
        else if (name === "s_month") {
            if (e_year && s_year) {
                this.setState({
                    s_month_index: months.findIndex(month => {
                        return month === value;
                    })
                });
            }
        } else {
            this.setState({ s_month_index: undefined });
        }


    }
    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            api_error: ''
        })
        const { e_month, e_year, file, location, organization, s_month, s_year, title, uuid, till_now, component_state, details } = this.state;
        const data = new FormData();
        file && data.append('experience_image', file);
        title && data.append('title', title);
        uuid && data.append('uuid', uuid);
        organization && data.append('organization', organization);
        location && data.append('location', location);

        s_year && (s_month && data.append('from_date', moment(s_year + "-" + s_month + "-01", 'YYYY-MMM-DD').format('YYYY-MM-DD')));

        details && data.append('details', details)
        if (till_now) {
            data.append('till_now', till_now)
        } else {
            e_year && (e_month && data.append('to_date', moment(e_year + "-" + e_month + "-01", 'YYYY-MMM-DD').format('YYYY-MM-DD')));
            data.append('till_now', till_now);
        }
        if (this.validate_form(title) === true) {
            component_state === "Add" ? this.props.handleAddExperiance(data) : this.props.handleEditExperiance(data);
        }
    }
    componentDidMount = () => {
        this.setState({ api_error: '' });
    }
    componentWillReceiveProps = (nextProps) => {
        if(nextProps.alert !==this.props.alert){
            this.setState({alert:nextProps.alert,msg_type:nextProps.msg_type});
            if ("error" === nextProps.addExperience_message || "Already mark till now!" === nextProps.addExperience_message) {
                this.setState({ api_error: nextProps.addExperience_message });
            } else {
                this.setState({ api_error: nextProps.addExperience_message, e_month: '', e_year: '', file: undefined, location: '', organization: '', s_month: '', s_year: '', title: '', uuid: uuidv4(), till_now: false, details: '' })
            }
        }
    }
    render() {
        const { title, organization, location, currentYear, s_year, details, file, e_month, e_year, s_month, errors, till_now, component_state, api_error, file_url, s_month_index, alert, msg_type } = this.state;
        const years = Array.from(new Array(20), (val, index) => currentYear - index).reverse();
        return (
            <div className="popup-full">
                {api_error ? <Alert message={api_error} type={msg_type} /> : ''}
                <span className="close-action" onClick={this.props.close}><i className="icon-close"></i></span>
                <div className="popup-main popup-md">
                    <div className="popup-body">
                        <h4 className="text-center">{component_state} Experience</h4>
                        
                        <div className="edit-profile-form">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-main-box flex">
                                    <div className="form-main-left">
                                        <div className="upload-img-box">
                                            <div className="img-preview-box">
                                                <img src={file ? URL.createObjectURL(file) : (file_url ? file_url : require('../../Assets/img/company.jpg'))} alt="Image"/>
                                            </div>
                                            <div className="img-add-box"><span className="btn btn-outline btn-sm btn-upload">Add Logo
                                            <input type="file" name={"experience_image"} onChange={this.onhandleChange} /></span> </div>
                                            </div>
                                        </div>
                                        <div className="form-main-right">
                                            <div className="form-item">
                                                <label>Title</label>
                                                <input type="text" className="form-style"
                                                    onChange={this.onhandleChange}
                                                    value={title}
                                                    name={"title"}
                                                />
                                                <span className='form-error'>
                                                    {errors ? errors : ''}
                                                </span>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-item">
                                                        <label>Organization</label>
                                                        <input type="text" className="form-style"
                                                            onChange={this.onhandleChange}
                                                            value={organization}
                                                            name={"organization"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-item">
                                                        <label>Location</label>
                                                        <input type="text" className="form-style"
                                                            onChange={this.onhandleChange}
                                                            value={location}
                                                            name={"location"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="form-item">
                                            <label>
                                                <input type="checkbox" onChange={this.handleEnddate} defaultChecked={till_now} /> Till Now </label>
                                        </div>
                                        <div className="row exp-date-box">
                                            <div className="col"  style={{marginRight:"4%"}}>
                                                <label>Start Date </label>
                                                <div className="row">
                                                    <div className="col" >
                                                        <div className="form-item">
                                                            <select className="form-style" name={"s_year"} value={s_year} onChange={this.onhandleChange}>
                                                                <option value="" key={99}>Year</option>
                                                                {years.map((year, index) => {
                                                                    return <option value={year} key={'100' + index}>{year}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-item">
                                                            <select className="form-style" value={s_month} name={"s_month"} onChange={this.onhandleChange}>
                                                                <option value="" key={199} >Month</option>
                                                                {months.map((month, index) => {
                                                                    return <option value={month} key={'199' + index}>{month}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                {
                                                    till_now === false ? (
                                                        s_year && <>
                                                            <label>End Date </label>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-item">
                                                                        <select className="form-style" value={e_year} name={"e_year"} onChange={this.onhandleChange}>
                                                                            <option value={""} key={299}>Year</option>
                                                                            {years.map((year, index) => {
                                                                                return (year >= s_year ? <option value={year} key={'299' + index}>{year}</option> : '')
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="form-item">
                                                                        <select className="form-style" value={e_month} name={"e_month"} onChange={this.onhandleChange}>
                                                                            <option value={""} key={399}>Month</option>
                                                                            {months.map((month, index) => {
                                                                                return (s_month_index ? (index >= s_month_index && <option value={month} key={'399' + index} >{month}</option>) : <option value={month} key={'399' + index} >{month}</option>)
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : ('')
                                                }
                                            </div>
                                        </div>
                                        <label>Details </label>
                                            <div className="form-item">
                                                <TextareaAutosize minRows="4" onChange={this.onhandleChange} value={details} className="form-style" placeholder="details" name="details" />
                                            </div>
                                            <div className="btn-outer row">
                                                <div className="col">
                                                    <button type="submit" className="btn btn-primary btn-full">{component_state === "Add" ? "Save and add another" : "Update"}</button>
                                                </div>
                                                <div className="col"><button type="submit" className="btn btn-primary btn-full btn-outline" onClick={this.props.close}>Cancel</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}
export default ExperienceModal