import {
    LOADING,
} from './../actions';

export const loading = (state = false, action) => {
    switch(action.type){
        case LOADING:
            return action.value
        default:
            return state
    }
}