import React, {Component} from 'react'
import TeamModal from './TeamModal'

class TeamItem extends Component{
    state = {
        modal: false
    }
    handleModal = () => {
        this.setState({
            modal: !this.state.modal
        })
    } 
    render(){
        let team = this.props.data
        return(
            <div className="team-item">
                <div className="team-item-inner">
                    <div className="team-img" onClick={() => this.handleModal(team)} style={{backgroundImage: 'url('+team.thumb+')'}}></div>
                    <div className="team-info">
                        <h5 onClick={() => this.handleModal(team)}>{team.name}</h5>
                        <div className="team-des">{team.designation}</div>
                    </div>
                </div>
                {this.state.modal && <TeamModal data={team} close={this.handleModal} />}            
            </div>
        )
    }
}
export default TeamItem