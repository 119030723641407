import React, { Component } from 'react';
import Alert from '../Alert';
import $ from 'jquery';
var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');

class AddResearcher extends Component{
    constructor(props) {
        super(props);
        this.state = {
            errors:{},
            researcherList : props.list,
            form:{
                first_name:(props.item && props.item.first_name)?props.item.first_name:'',
                last_name:(props.item && props.item.last_name)?props.item.last_name:'',
                email:(props.item && props.item.email)?props.item.email:'',
                organization:(props.item && props.item.organization)?props.item.organization:'',
                qualification:(props.item && props.item.qualification)?props.item.qualification:'',
                status:true,
                alert: false,
                alert_msg: '',
                alert_type: '',
                duplicateResearcher:[],
                
                
            },
            edit:(props.edit)?props.edit:false,
            id:(props.item && props.item.id)?props.item.id:'',
            submitted: false,
        };


        this.handleSubmit = this.handleSubmit.bind(this);
        this.onhandleChange = this.onhandleChange.bind(this);

        this.wrapperRef = React.createRef();        
        this.handleClickOutside = this.handleClickOutside.bind(this);
        
    }

    onhandleChange = (event) => {
       // console.log(this.state.researcherList)
        let form = this.state.form;
        const name = event.target.name;
        const value = event.target.value;
        form[name] = value;
        this.setState({form: form});
        var errors = {};
        if(name === 'first_name' && (value !== undefined || value !== '')){
            this.state.errors.first_name = "";
            $('input[name="first_name"]').removeClass('error');
            if(value.length >= 3){
            const duplicate = this.state.researcherList.filter(item =>  (item.label).toLowerCase().startsWith (value.toLowerCase()))
            this.setState({duplicateResearcher: duplicate})   
            // if(duplicateResearche && duplicateResearche.length > 0){
                // errors['first_name'] = duplicateResearche[0].label+" already exist.";
                // $('input[name="first_name"]').addClass('information');
                // this.setState({errors:errors});
         //   }
            }else{
                this.setState({duplicateResearcher :[]})
            }
        }

        if(name === 'last_name' && (value !== undefined || value !== '')){
            this.state.errors.last_name = "";
            $('input[name="last_name"]').removeClass('error');
        }

        if(name === 'organization' && (value !== undefined || value !== '')){
            this.state.errors.organization = "";
            $('input[name="organization"]').removeClass('error');
        }

      
        if(name === 'email'){
            errors['email'] = ""
            $('input[name="email"]').removeClass('error');
            this.setState({errors:errors})
          
        }
    }

    validate_form(form_data){
        var errors = {};
        let first_name = form_data['first_name'];
        let last_name = form_data['last_name'];
        let organization = form_data['organization'];
        let email = form_data['email'];
        
        if(first_name === undefined || first_name === ''){
            errors['first_name'] = "First Name required.";
            $('input[name="first_name"]').addClass('error');

        }

        if(last_name === undefined || last_name === ''){
            errors['last_name'] = "Last Name required.";
            $('input[name="last_name"]').addClass('error');

        }

        if(organization === undefined || organization === ''){
            errors['organization'] = "Organization / University required.";
            $('input[name="organization"]').addClass('error');

        }

        if(email !== ''){
            var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if(reg.test(email) === false)
            {
                errors['email'] = "Invalid email.";
                $('input[name="email"]').addClass('error');
            }
        }

        const error_len = Object.keys(errors).length;

        this.setState({errors:errors});

        if(error_len > 0){
            return false;
           
        }else{
            return true;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const form_data = this.state.form;
        const _this = this;
        const url = api_url + 'user/researcher/add';
        let errors = {};
        _this.setState({ alert: false, alert_msg: " ", alert_type: '' })
        if(_this.validate_form(form_data) === true){ 
            if(_this.state.edit)
            {
                form_data['id'] = _this.state.id;
                form_data['new_email'] = form_data['email'];
                //form_data['added_by'] = _this.state.users_id;

            }

            //console.log('form_data', form_data)

            //return true;

            $.ajax({
                    type: 'POST',
                    url,
                    data: JSON.stringify(form_data),
                    headers:{"Authorization": "Token "+token},
                    contentType: 'application/json',
                    dataType: 'json',
                    beforeSend: function() {                        
                       
                    },
                    success: function(res) {
                        if(res.status==='success'){
                          //  alert('Researcher added successfully.');
                          _this.setState({submitted:true,alert: true, alert_msg: "Researcher added successfully.", alert_type: 'success' });
                          _this.props.close()
                          _this.props.updateResearcherList(res.records);
                          _this.props.addedResearcher(res.record)

                           if(_this.props.edit === false)
                           {
                                _this.setState({alert_msg: "Researcher updated successfully."});

                           }
                            
                        }  
                        
                        
                    }
                }).catch(function (error_res) {
                                 
                        if(error_res.responseJSON.status === 'error' && error_res.responseJSON.message === 'This email is already taken. Please choose another email address.'){
                            errors['email'] = error_res.responseJSON.message;
                            $('input[name="email"]').addClass('error');
                         _this.setState({errors:errors})
                        }
                   
                });         
        }       
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            //alert('You clicked outside of me!');
            setTimeout(()=>{
                 this.setState({duplicateResearcher :[]})
             }, 250);
             

        }
    }

    handleClickDublicate = (item) => {

        if(this.state.edit === false)
        {
            this.props.addedDublicateResearcher(item)
            this.props.close()

        }
        
        


    }

    render(){
        const {alert, alert_msg, alert_type,edit} = this.state
        // console.log(this.state.errors.first_name)
        
        return(
            <>
            <div className="popup-full">
                <span className="close-action" onClick={this.props.close}><i className="icon-close"></i></span>
                <div className="popup-main popup-md">
                    <div className="popup-body">
                        <h4 className="text-center">{(edit)?'Edit':'Add'} Researcher</h4>
                        <form autoComplete="off">                        
                        <div className="form-main-box">
                            <div className="row">
                                <div className="col">
                                    <div className="form-item researcher-find-box">
                                        <label>First Name <span className="color-red">*</span></label>
                                        <input
                                            ref={this.wrapperRef}
                                            className="form-style"
                                            name="first_name"
                                            onChange={this.onhandleChange}
                                            type="text"
                                            autoComplete="new-text"
                                            value={(
                                                    this.state.form && this.state.form.first_name
                                                ) ? this.state.form.first_name : ''}
                                        />
                                        <div className={(this.state.duplicateResearcher && this.state.duplicateResearcher.length> 0) ? 'form-error suggestions':'form-error'}>{
                                           this.state.duplicateResearcher && this.state.duplicateResearcher.length> 0 ?
                                           this.state.duplicateResearcher.map((item, i) => {
                                            return <ul className="suggestion-list"><li><div className="suggestion-item" onClick={() => this.handleClickDublicate(item)}>{item.label}</div></li></ul>
                                            })
                                          : (this.state.errors && this.state.errors.first_name
                                                    ? this.state.errors.first_name: '')
                                        }</div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-item">
                                        <label>Last Name <span className="color-red">*</span></label>
                                        <input
                                            className="form-style"
                                            name = "last_name"
                                            onChange={this.onhandleChange}
                                            type="text"
                                            value={(
                                                this.state.form && this.state.form.last_name
                                            ) ? this.state.form.last_name : ''}
                                        />

                                        <span className='form-error'>{
                                            (
                                                this.state.errors && this.state.errors.last_name
                                            ) ? this.state.errors.last_name: ''
                                        }</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-item">
                                        <label>Email Address</label>
                                        <input
                                            className="form-style"
                                            name="email"
                                            onChange={this.onhandleChange}
                                            type="text"
                                            value={(
                                                this.state.form && this.state.form.email
                                            ) ? this.state.form.email : ''}
                                        />
                                        <span className='form-error'>{
                                            (
                                                this.state.errors && this.state.errors.email
                                            ) ? this.state.errors.email: ''
                                        }</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-item">
                                        <label>Qualification</label>
                                        <input
                                            className="form-style"
                                            name="qualification"
                                            onChange={this.onhandleChange}
                                            type="text"
                                            value={(
                                                this.state.form && this.state.form.qualification
                                            ) ? this.state.form.qualification : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-item">
                                <label>Organization / University <span className="color-red">*</span></label>
                                <input
                                    className="form-style"
                                    name="organization"
                                    onChange={this.onhandleChange}
                                    type="text"
                                    value={(
                                        this.state.form && this.state.form.organization
                                    ) ? this.state.form.organization : ''}
                                />

                                <span className='form-error'>{
                                    (
                                        this.state.errors && this.state.errors.organization
                                    ) ? this.state.errors.organization: ''
                                }</span>
                            </div>
                            <div className="btn-outer row">
                                <div className="col"><button type="button" className="btn btn-primary btn-full" onClick={this.handleSubmit}>{(edit)?'Update':'Add'} Researcher</button></div>
                                <div className="col"><button type="button" onClick={this.props.close} className="btn btn-primary btn-full btn-outline">Cancel</button></div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
              
            </div>
            {alert ? <Alert message={alert_msg} type={alert_type}></Alert> : ""}
            </>
        )
    }
}
export default AddResearcher