import React, {Component} from 'react'
import ProfileEditForm from './profile-edit-form'
import ChangePasswordForm from './change-password-form'

class ProfileEdit extends Component {
    state = {
        tabActive: 1
    }
    handleActive = (id) => {
		this.setState({
			tabActive: id
		})
	}
	render(){
		return(
			<div className="popup-full">
                <span className="close-action" onClick={this.props.close}><i className="icon-close"></i></span>
                <div className="popup-main popup-sm">
                    <div className="popup-body">
                        <div className="heading-box">
                            <ul className="heading-tabs">
                                <li className={this.state.tabActive === 1 ? "active" : "" } onClick={() => this.handleActive(1)}><span>Edit Profile</span></li>
                                <li className={this.state.tabActive === 2 ? "active" : "" } onClick={() => this.handleActive(2)}><span>Change Password</span></li>
                            </ul>
                        </div>
                        {this.state.tabActive === 1 ? <ProfileEditForm update_user_data={this.props.update_user_data} close={this.props.close} user_data={this.props.user_data} /> : null }
                        {this.state.tabActive === 2 ? <ChangePasswordForm close={this.props.close} user_data={this.props.user_data} /> : null }
                    </div>
                </div>
            </div>
		)
	}
}
export default ProfileEdit