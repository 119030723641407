import $ from 'jquery';
import React, {Component} from 'react'
import PasswordStrengthBar from 'react-password-strength-bar';
import Alert from '../Alert.js';
import PasswordInput from "react-password-indicator";

var config = require('../../react-config.js');
const api_url = config.api_url;
const token = localStorage.getItem('token');
const errorMessages = {
    minLen: "Password is not long enough!",
    maxLen: val => `Password exceeded the maximum length of ${val}`,
  };

class ChangePasswordForm extends Component {

    constructor(props){
        super(props)

        
        let user_data = (this.props.user_data)?this.props.user_data:'';
        this.state = {
            user_data:(user_data)?user_data:'',
            errors:{},
            form:{},
            edit:false,
            alert:false,
            alert_msg:'',
            alert_type:'',
            visible: false, new_password: "", validation: {},
            hidden: true
        }

        //this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.onhandleChange = this.onhandleChange.bind(this);
        this.validate_form = this.validate_form.bind(this);
        this.togglePasswod = this.togglePasswod.bind(this);

    }

    onhandleChange = (event) => {
        let form = this.state.form;
        const name = event.target.name;
        const value = event.target.value;
        form[name] = value;
        this.setState({form: form});
        if(name === 'new_password'){            
            this.handlePassword(value)
        }
        if(name === 'old_password' && (value !== undefined || value !== '')){
            this.state.errors.old_password = "";
            $('input[name="old_password"]').removeClass('error');
        }

        if(name === 'confirm_password' && (value !== undefined || value !== '')){
            this.state.errors.confirm_password = "";
            $('input[name="confirm_password"]').removeClass('error');
        }

    }

    handlePassword(new_password){
        return true;
        let result = true;
        // $('#message').css("display", "block");
        if(new_password.length < 8){
            $('#length').addClass("form-error");
            $('#length').removeClass("form-success");
            result = false;
        }else{
            $('#length').removeClass("form-error");
            $('#length').addClass("form-success");
        }

        var lowerCaseLetters = /[a-z]/g;
        if(new_password.match(lowerCaseLetters)) {
            $('#letter').removeClass("form-error");
            $('#letter').addClass("form-success");
        } else {
            $('input[name="new_password"]').addClass('error');
            $('#letter').addClass("form-error");
            $('#letter').removeClass("form-success");
            result = false;
        }

        var upperCaseLetters = /[A-Z]/g;
        if(new_password.match(upperCaseLetters)) {
            $('#capital').removeClass("form-error");
            $('#capital').addClass("form-success");
        } else {
            $('input[name="new_password"]').addClass('error');
            $('#capital').addClass("form-error");
            $('#capital').removeClass("form-success");
            result = false;
        }

        var numbers = /[0-9]/g;

        if(new_password.match(numbers)) {
            $('#number').removeClass("form-error");
            $('#number').addClass("form-success");
        } else {
            $('input[name="new_password"]').addClass('error');
            $('#number').addClass("form-error");
            $('#number').removeClass("form-success");
            result = false;
        }

        if(/^[a-zA-Z0-9- ]*$/.test(new_password) === false) {
            $('#spchar').removeClass("form-error");
            $('#spchar').addClass("form-success");
        }else{
            $('input[name="new_password"]').addClass('error');
            $('#spchar').addClass("form-error");
            $('#spchar').removeClass("form-success");
            result = false;
        }

        if (result === true){
            $('#message').css("display", "none");
        }
        return result;
    }


    validate_form(form_data){
        var errors = {};
        let old_password = form_data['old_password'];
        let new_password = form_data['new_password'];
        let confirm_password = form_data['confirm_password'];

        if(old_password === undefined || old_password === ''){
            errors['old_password'] = "Current Password required.";
            $('input[name="old_password"]').addClass('error');

        }

        // if(new_password === undefined || new_password === ''){
        //     errors['new_password'] = "New Password required.";
        //     $('input[name="new_password"]').addClass('error');

        // }

        if(this.state.validation.errors.length > 0){
             errors['new_password'] = "invalid Password.";
             $('input[name="new_password"]').addClass('error');
         }else {
             form_data.new_password = this.state.new_password
             $('input[name="new_password"]').removeClass('error');
             
         }

         
        if(confirm_password === undefined || confirm_password === ''){
            errors['confirm_password'] = "Confirm Password required.";
            $('input[name="confirm_password"]').addClass('error');

        }
        else{            
            if(this.state.new_password !== confirm_password)
            {
                errors['confirm_password'] = "Confirm password not match.";
                $('input[name="confirm_password"]').addClass('error');
            }
        }
        

        const error_len = Object.keys(errors).length;

        this.setState({errors:errors});

        if(error_len > 0){
            return false;
        }else{
            return true;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();     
        const form_data = this.state.form;   
        let errors = {};


         if(this.validate_form(form_data) === true){
            // alert("success")
            // return false
            const url = api_url + 'user/update';
            let _this = this;
            let data = {};
            data['old_password'] = this.state.form.old_password;
            data['new_password'] = this.state.form.new_password;
            this.setState({alert:false,alert_msg:'',alert_type:''})
            $.ajax({
                    type: 'POST',
                    url,
                    data: JSON.stringify(data),
                    headers:{"Authorization": "Token "+token},
                    contentType: 'application/json',
                    dataType: 'json',
                    beforeSend: function() {
                       
                    },
                    success: function(res) {
                        
                        if(res.status==='success'){
                            _this.setState({alert:true,alert_msg:'Password changed successfully',alert_type:'success'})
                            localStorage.setItem('user',JSON.stringify(res.user));
                            _this.props.close();
                        }
                        else
                        {
                             errors['confirm_password'] = res.message;
                             _this.setState({errors:errors});
                        }
                    }
                }).catch();

            }      
    }
    
    togglePasswod=()=> {
        this.setState({ hidden: !this.state.hidden });
      }
    

	render(){
        const {alert,alert_msg,alert_type} = this.state;
		return(
			<div className="edit-profile-form">
               {alert ? <Alert message={alert_msg} type={alert_type}></Alert> : ""} 
            <form onSubmit={this.handleSubmit}>
                <div className="form-inner">
                    <div className="form-item">                    
                    <input
                    className="form-style"
                    name="old_password"
                    onChange={this.onhandleChange}
                    placeholder="Enter Current Password"
                    type="password"
                    value={(
                    this.state.form && this.state.form.old_password
                    ) ? this.state.form.old_password : ''}
                    />
                    <span className='form-error'>{
                    (
                    this.state.errors && this.state.errors.old_password
                    ) ? this.state.errors.old_password: ''
                    }</span>

                    </div>
                    <div className="form-item">
                        {/* <input
                        autoComplete="off"
                        className="form-style"
                        name="new_password"
                        onChange={this.onhandleChange}
                        placeholder="New Password"
                        type="password"
                        value={(
                        this.state.form && this.state.form.new_password
                        ) ? this.state.form.new_password : ''}
                        />
                        {(this.state.form && this.state.form.new_password) ? (<PasswordStrengthBar password={this.state.form.new_password} />) : '' }
                        <span className='form-error'>{
                        (
                        this.state.errors && this.state.errors.new_password
                        ) ? this.state.errors.new_password: ''
                        }</span>
                        <div id="message" style={{'display':'none'}}>
                        <h3>Password must contain the following:</h3>
                        <p id="letter" className="form-error">A <b>lowercase</b> letter</p>
                        <p id="capital" className="form-error">A <b>capital (uppercase)</b> letter</p>
                        <p id="number" className="form-error">A <b>number</b></p>
                        <p id="spchar" className="form-error">A <b>special characters</b></p>
                        <p id="length" className="form-error">Minimum <b>8 characters</b></p>
                        </div> */}
                        <PasswordInput
                            minLen={8} 
                            digits={1} 
                            specialChars={1} 
                            uppercaseChars={1} 
                            lowerCaseLetters = {1}
                            defaultMessages={errorMessages}
                            isVisible={this.state.visible}
                            onChange={(new_password, validation) =>
                               this.setState({ new_password, validation })
                             }> 
                            {({
                               getInputProps,valid,touched}) => (
                                   <div className="form-password-item">
                                    
                                       <input
                                            {...getInputProps()}
                                             value={this.state.new_password}
                                             className={touched ? (valid ? "valid" : "invalid") : ""}
                                             autoComplete="off"
                                             className="form-style"
                                             name="new_password"
                                             placeholder="New Password"
                                             type={this.state.hidden ? 'password' : 'text'}/>
                                            <span className={this.state.hidden? 'show pwd-show-hide' :'hide pwd-show-hide'} onClick={this.togglePasswod}><svg id="Capa_1" enable-background="new 0 0 551.121 551.121" height="512" viewBox="0 0 551.121 551.121" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m275.561 68.887c-123.167 0-233.534 80.816-274.64 201.107-1.228 3.616-1.228 7.518 0 11.134 41.106 120.291 151.473 201.107 274.64 201.107s233.534-80.816 274.64-201.107c1.228-3.616 1.228-7.518 0-11.134-41.107-120.291-151.474-201.107-274.64-201.107zm0 378.902c-106.532 0-202.284-68.975-240.077-172.228 37.793-103.253 133.544-172.228 240.077-172.228 106.532 0 202.284 68.975 240.077 172.228-37.793 103.253-133.545 172.228-240.077 172.228z"></path><path d="m275.561 172.224c-56.983 0-103.337 46.354-103.337 103.337s46.354 103.337 103.337 103.337 103.337-46.353 103.337-103.337-46.354-103.337-103.337-103.337zm0 172.228c-37.995 0-68.891-30.897-68.891-68.891 0-37.995 30.897-68.891 68.891-68.891 37.995 0 68.891 30.897 68.891 68.891s-30.897 68.891-68.891 68.891z"></path></svg></span>
                                
                                                <span className='form-error'>{
                                                    ( this.state.errors && this.state.errors.new_password
                                                     ) ? this.state.errors.new_password: ''
                                                }</span>
                                            
                                    </div>
                                 )}
                        </PasswordInput>

                    </div>
                    <div className="form-item">
                    <input
                    className="form-style"
                    name="confirm_password"
                    onChange={this.onhandleChange}
                    placeholder="Confirm New Password"
                    type="password"
                    value={(
                    this.state.form && this.state.form.confirm_password
                    ) ? this.state.form.confirm_password : ''}
                    />
                    <span className='form-error'>{
                    (
                    this.state.errors && this.state.errors.confirm_password
                    ) ? this.state.errors.confirm_password: ''
                    }</span>
                    </div>
                    <div>
                        {this.state.validation.errors && (
                        <div className="pwd-validation-box">
                            <ul> 
                             {this.state.validation.errors.map(e => (
                               <li key={e.key}>{e.message}</li> ))}
                            </ul>                                         
                        </div>
                         )} 
                    </div>
                    <div className="btn-outer row">
                        <div className="col"><button type="submit" className="btn btn-primary btn-full">Update</button></div>
                        <div className="col"><button type="submit" className="btn btn-primary btn-full btn-outline" onClick={this.props.close}>Cancel</button></div>
                    </div>
                </div>
                </form>
            </div>
		)
	}
}
export default ChangePasswordForm