import {
    API_TOKEN,
} from './../actions';

const initialState = {
    logedin: false
}
export const Login = (state = initialState, action) => {
    switch(action.type){
        case 'ACTION_LOGIN':
            return {
                logedin: action.payload
            }
        
        default:
            return state
    }    
}



export const getToken = (state ='', action) => {
    switch(action.type){
        case API_TOKEN:
            return action.value;
        default:
            return state
    }    
}