import React from 'react';
import moment from 'moment';
import renderHTML from 'react-render-html';
export default class FilePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: props.file
        };
    }
    render() {
        const type = [
            { name: "pdf", image: "http://wwwimages.adobe.com/content/dam/acom/en/legal/images/badges/Adobe_PDF_file_icon_32x32.png" },
            { name: "doc", image: "https://upload.wikimedia.org/wikipedia/commons/f/fb/.docx_icon.svg" },
            { name: "docx", image: "https://upload.wikimedia.org/wikipedia/commons/f/fb/.docx_icon.svg"},
            { name: "txt", image: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Rpb_bureaucracy_icon.png"},
            { name: "xls", image: "https://upload.wikimedia.org/wikipedia/commons/3/37/.xls_icon.svg"},
            { name: "xlsx", image: "https://upload.wikimedia.org/wikipedia/commons/3/37/.xls_icon.svg"},
            { name: "odt", image: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Rpb_bureaucracy_icon.png"},
            { name: "wpd", image: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Rpb_bureaucracy_icon.png"},
            
          ];
        const {file}=this.state;
        var ext = file.file_name.substr(file.file_name.lastIndexOf('.') + 1);
        var searchvalue = type.filter(value =>(value.name).toLowerCase().includes ((ext).toLowerCase()))
        return (
            <div className="message-item-outer">
                {/* <a href={file.file_url} target="_blank"><img src={file.file_url} height="50" /></a> */}
                {(file.file_type.includes('image'))?(
                <a href={file.file_url} target="_blank"><img src={file.file_url} height="50" /></a>
                ):(
                   searchvalue.length>0? (searchvalue.map(value=>{
                   return(  <a href={file.file_url} target="_blank"><img src={value.image} height="50"/><br/>{file.file_name}</a>)
                    }))
                :(  <a href={file.file_url} target="_blank"><img src="https://www.wetype.uk/wp-content/uploads/2020/08/Upload-icon.png" height="70"/><br/>{file.file_name}</a>)
                ) }
               
                    
                  {/* <a href={file.file_url} target="_blank">{file.file_name}</a> */}
                
            </div>
        );
    }
}
