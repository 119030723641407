export const LOADING = 'LOADING';
export const STREAM_LIST = 'STREAM_LIST';
export const TAG_LIST = 'TAG_LIST';
export const BLOG_LIST = 'BLOG_LIST';
export const REGISTER_FORM_TYPE = 'REGISTER_FORM_TYPE';
export const API_TOKEN = 'API_TOKEN';

export function loading(type) {
    return { type: LOADING, value:type };
}

export function handleAccountType(type) {
    return { type: REGISTER_FORM_TYPE, value:type };
}

export function streamList(data) {
    return { type: STREAM_LIST, value:data };
}

export function tagList(data) {
    return { type: TAG_LIST, value:data };
}

export function blogList(data) {
    return { type: BLOG_LIST, value:data };
}

export function setToken(token) {
    return { type: API_TOKEN, value:token };
}
