import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton } from "react-share";

var config = require('../../react-config.js');
const api_url = config.api_url;
const base_url = config.base_url;
const token = localStorage.getItem('token');
const url = api_url + 'post/share/notifications-add';
const shareCountUrl = api_url + 'post/share/statistics-count';
export default class ShareIcons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            details: props.details,
            brief: props.brief,
            slug:props.slug,
            post_image:props.post_image,
            uuid:props.post_uuid
        };
    }
    handleShare =(uuid) =>{
        
       /*await axios.post(url,{post_uuid:uuid},
            {
                headers: token ? { "Authorization": "Token " + token } : {},
            })*/
         axios.post(shareCountUrl,{post_uuid:uuid},{
                headers: token ? { "Authorization": "Token " + token } : {},
            })
            .then((response) =>{
                console.log("response",response)
            })
    }
    render() {
        const { brief, title, details,slug,post_image,uuid } = this.state;
        return (
            <>
                <li onClick={()=>this.handleShare(uuid)}><FacebookShareButton 
                    url={base_url+"details/"+slug} 
                    quote={details.replace(/<[^>]+>/g, '')}
                    imageurl={post_image} 
                    hashtag={title}
                    >
                    <span className="share-item"><i className="icon-facebook"></i></span>
                </FacebookShareButton>
                </li>
                <li onClick={()=>this.handleShare(uuid)}><TwitterShareButton url={"http://twitter.com/"} quote={brief}>
                    <span className="share-item"><i className="icon-twitter"></i></span></TwitterShareButton>
                </li>
                <li onClick={()=>this.handleShare(uuid)}><LinkedinShareButton url={"https://www.linkedin.com/"} quote={brief}>
                    <span className="share-item"><i className="icon-linkedin"></i></span></LinkedinShareButton>
                </li>
                <li onClick={()=>this.handleShare(uuid)}><PinterestShareButton url={"https://www.pinterest.com/"} quote={brief}>
                    <span className="share-item"><i className="icon-pinterest"></i></span></PinterestShareButton>
                </li>
                <li><span className="share-item"><i className="icon-instagram"></i></span></li>
            </>
        );
    }
}

ShareIcons.propTypes = {
    title: PropTypes.string
};