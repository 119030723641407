import {connect} from 'react-redux';
import React, {Component} from 'react'
import PostArticle from '../Components/PostArticle'

class ArticlePostPage extends Component {

	constructor(props) {
        super(props);        

        this.state = {
	        editorData: ''
	    }


    }

	
           
    
	render(){
		return(
			<div className="page-full post-article-full">
                <PostArticle history={this.props.history} history={this.props.history} match={this.props.match} />
            </div>
		)
	}
}

export default connect()(ArticlePostPage)
