
import React, { Component } from 'react';

import {  Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import HeaderContainer from './Container/HeaderContainer'
import LoginContainer from './Container/LoginContainer'
import RegisterContainer from './Container/RegisterContainer'
import HomeContainer from './Container/HomeContainer'
import Profile from './Container/profileContainer'
import Details from './Container/DetailsContainer'
import CreatePostPage from './Container/CreatePostContainer'
import MessagePage from './Container/MessageContainer'
import ConnectPage from './Container/ConnectContainer'
// import StreamPage from './Container/StreamContainer'
import SearchContainer from './Container/SearchContainer'
import PostArticlePage from './Container/PostArticleContainer'
import ResearcherContainer from './Container/ResearcherContainer'
import UnderConstruction from './Components/underConstraction'
import Notifications from './Components/Notifications/index'
import ForgotUser from './Components/Forgot/userName.js';
import ForgotPassword from './Components/Forgot/password';
import ArticleListingPage from './Container/ArticleListingContainer'
import AboutPage from './Pages/About'
import TermsPage from './Pages/Terms'
import PrivacyPage from './Pages/Privacy'
import TeamPage from './Pages/Team';
import FAQPage from './Pages/FAQ';
import Footer from './Components/Footer'
// import { LinkedInPopUp } from './Components/Linkedin'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
// import { LinkedInPopUp } from './Components/Linkedin';
import './Assets/css/style.css'




/********** Online/Offline Start Braham **********/
import firebase from "./firebase";
// Fetch the current user's ID from Firebase Authentication.
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    if(firebase.auth().currentUser)
    {
        // console.log('App.js firebase.auth().currentUser')
        // console.log(firebase.auth().currentUser)
        var uid = firebase.auth().currentUser.uid;

        // Create a reference to this user's specific status node.
        // This is where we will store data about being online/offline.
        var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

        // We'll create two constants which we will write to 
        // the Realtime database when this device is offline
        // or online.
        var isOfflineForDatabase = {
            online: false,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
        };

        var isOnlineForDatabase = {
            online: true,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
        };

        var userStatusFirestoreRef = firebase.firestore().doc('/users/' + uid);

        // Firestore uses a different server timestamp value, so we'll 
        // create two more constants for Firestore state.
        var isOfflineForFirestore = {
            online: false,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        };

        var isOnlineForFirestore = {
            online: true,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        };

        firebase.database().ref('.info/connected').on('value', function(snapshot) {
            if (snapshot.val() == false) {
                // Instead of simply returning, we'll also set Firestore's state
                // to 'offline'. This ensures that our Firestore cache is aware
                // of the switch to 'offline.'
                userStatusFirestoreRef.update(isOfflineForFirestore);
                return;
            };

            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
                userStatusDatabaseRef.set(isOnlineForDatabase);

                // We'll also add Firestore set here for when we come online.
                userStatusFirestoreRef.update(isOnlineForFirestore);
            });
        });


    }
  } else {
    // No user is signed in.
  }
});
/********** Online/Offline End **********/
var config = require('./react-config.js');


//console.log("baseurl",config.base_url)

class App extends Component {
  constructor(props) {
        super(props);        
    }




    render() {
      // console.log('App-props-render=',this.props)
      return (
        <main className="main-full">
          <BrowserRouter>
            {/*<HeaderContainer />*/}

            {/* Old Url to New URL */}
            
            <Switch>
            {/*<Redirect status={301} from="/article/leadin-network2" to="/article/leadin-network" />*/}
            <Redirect status={301} from="/article/the-new-york-times-surge-in-florida-s-coronavirus-cases" to="/article/the-new-york-times-surge-in-floridas-coronavirus-cases" /> 
            <Redirect status={301} from="/article/cnbc-u-s-sees-record-amount-of-covid-19-cases" to="/article/cnbc-us-sees-record-amount-of-covid-19-cases" /> 
            <Redirect status={301} from="/article/novavax-novavax-receives-1-6-billion-from-u-s-government-for-vaccine" to="/article/novavax-novavax-receives-16-billion-from-us-government-for-vaccine" />
            <Redirect status={301} from="/article/reuter-designer-created-the-hygienehook-to-eliminate-touching-public-doors-and-buttons" to="/article/reuters-designer-created-the-hygienehook-to-eliminate-touching-public-doors-and-buttons" />
            <Redirect status={301} from="/article/cnbc-u-s-production-of-covid-19-vaccine-to-begin-by-the-end-of-summer" to="/article/cnbc-us-production-of-covid-19-vaccine-to-begin-by-the-end-of-summer" />
            <Redirect status={301} from="/article/washington-post-the-health-202-why-determining-the-coronavirus-death-rate-is-so-tricky-figuring-out-the-coronavirus-death-rate" to="/article/the-washington-post-figuring-out-the-coronavirus-death-rate" />
            <Redirect status={301} from="/article/novid-a-carnegie-mellon-university-professor-has-created-a-contact-tracing-app-that-anonymously-traces-an-individual-s-exposure-to-covid-19" to="/article/novid-a-carnegie-mellon-university-professor-has-created-a-contact-tracing-app-that-anonymously-traces-an-individuals-exposure-to-covid-19"/>
            <Redirect status={301} from="/article/mcgill-researchers-at-mcgill-university-are-working-on-a-way-of-preventing-the-spread-of-covid-19-on-high-metallic-surfaces" to="/article/mcgill-spray-for-high-touch-metallic-surfaces-to-reduce-covid-19-transmission-rate"/>
            <Redirect status={301} from="/article/bbc-oxford-s-vaccine-shows-promising-results" to="/article/bbc-oxfords-vaccine-shows-promising-results"/>

            <Redirect status={301} from="/article/covid-19-cases-highest-in-regions-with-greatest-income-inequality" to="/"/>            

            <Redirect status={301} from="/de/riya-sinha" to="/p/riya-sinha"/>
            <Redirect status={301} from="/privacy-policy" to="/privacy"/> 

            <Redirect status={301} from="/tag/pfizer" to="/search?tag=pfizer"/>
            <Redirect status={301} from="/tag/health" to="/search?tag=health"/>
            <Redirect status={301} from="/tag/california" to="/search?tag=california"/>
            <Redirect status={301} from="/tag/america" to="/search?tag=america"/>
            <Redirect status={301} from="/tag/mask" to="/search?tag=mask"/>
            <Redirect status={301} from="/tag/schools" to="/search?tag=schools"/>
            <Redirect status={301} from="/tag/hugs" to="/search?tag=hugs"/>
            <Redirect status={301} from="/tag/mask1" to="/search?tag=mask1"/>
            <Redirect status={301} from="/tag/novavax" to="/search?tag=novavax"/>
            <Redirect status={301} from="/tag/open-source" to="/search?tag=open-source"/>
            <Redirect status={301} from="/tag/operation-warp-speed" to="/search?tag=operation-warp-speed"/>
            <Redirect status={301} from="/tag/primary-research-article" to="/search?tag=primary-research-article"/>

            <Redirect status={301} from="/contact-us" to="/" />
            <Redirect status={301} from="/posts" to="/" />
            <Redirect status={301} from="/eula" to="/" />
            <Redirect status={301} from="/de/ishaan-mishra" to="/" />
            <Redirect status={301} from="/de/ishaan-mishra" to="/" />
            <Redirect status={301} from="/page/alka-asthana" to="/" />
            <Redirect status={301} from="/page/Jessica" to="/" />
            <Redirect status={301} from="/page/prakash-jain" to="/" />
            <Redirect status={301} from="/compatible-browser" to="/" />
            <Redirect status={301} from="/posts?tp=138" to="/" />
            <Redirect status={301} from="/posts?tp=143" to="/" />
            <Redirect status={301} from="/users_detail/users_liked_by/298" to="/" />
            <Redirect status={301} from="/request_page/login_member/uid/304" to="/" />
            <Redirect status={301} from="/request_page/login_member/uid/302" to="/" />
            <Redirect status={301} from="/users_article/:id?" to="/" />
            <Redirect status={301} from="/networks/user/:id?" to="/" />
            <Redirect status={301} from="/recent_activity/user/:id?" to="/" />
            

            </Switch>
        











            {/* End Old Url to New URL */}

            <Route exact path="/linkedin" component={LinkedInPopUp} />
           { window.location.href !== config.base_url+'linkedin' ? <Route component={HeaderContainer} />:<div></div>}
            <Route exact path="/" component={HomeContainer} />
            <Route exact path="/login" component={LoginContainer} />
            <Route exact path="/register" component={RegisterContainer} />
            {/* <Route exact path="/profile" component={Profile} /> */}
            <Route exact path="/p/:slug?" component={Profile} />
            <Switch>
            <Route exact path="/article/new" component={PostArticlePage} /> 
            <Route exact path="/article/update/:uuid?" component={PostArticlePage} /> 
            <Route path="/article/:slug?" component={Details} />
            </Switch>
            {/* <Route exact path="/create-post" component={CreatePostPage} /> */}
            <Route path="/messages/:username?" component={MessagePage} />
            <Route exact path="/mynetwork/" component={ConnectPage} />
            <Route exact path="/mynetwork/connections" component={ConnectPage} />
            <Route exact path="/mynetwork/following" component={ConnectPage} />
            <Route exact path="/search" component={SearchContainer} />
            {/* <Route exact path="/about/" component={UnderConstruction} /> */}
            <Route exact path="/help/" component={UnderConstruction} />
            <Route exact path="/notifications/" component={Notifications} />
            {/* <Route exact path="/article/new" component={CreatePostPage} /> */}
            {/* <Route exact path="/post-article" component={PostArticlePage} /> */ }
            <Route exact path="/forgot-user" component={ForgotUser} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/article-listing" component={ArticleListingPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/faq" component={FAQPage} />
            <Route exact path="/terms" component={TermsPage} />
            <Route exact path="/privacy" component={PrivacyPage} />
            <Route exact path="/team" component={TeamPage} />
            <Route exact path="/researcher" component={ResearcherContainer} />
            { window.location.href !== config.base_url+'linkedin' ? <Footer />:<div></div>}            
          </BrowserRouter>
        </main>
      )
    }
}

/*function App() {
  return (
    <main className="main-full">
      <BrowserRouter>
        <HeaderContainer />
        <Route exact path="/" component={HomeContainer} />
        <Route exact path="/login" component={LoginContainer} />
        <Route exact path="/register" component={RegisterContainer} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/details" component={Details} />
        <Route exact path="/create-post" component={CreatePostPage} />
        <Route exact path="/messages" component={MessagePage} />
      </BrowserRouter>
    </main>
  );
}*/

export default App;
