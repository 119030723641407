import React, {Component} from 'react';
import { Helmet } from "react-helmet";

class FAQPage extends Component{
    render(){
        return(
            <div className="page-full page-home-full cms-page-full page-login-full">
                 <Helmet>
							<title>FAQ | AISquare.org</title>
							<meta name="description" content="AISquare Covid-19 is a research hub built to accelerate solutions and innovations surrounding the current pandemic."/>
				 </Helmet>
                <div className="banner-inner">
                    <div className="container">
                        <div className="heading-box text-center"><div className="page-title">FAQ</div></div>
                    </div>
                </div>
                <div className="page-main-body">
                    <div className="container">
                        <div className="cms-page-container">
                            <div className="cms-data-inner">
                                <div className="data-outer-faq">
                                    <div className="data-item-faq">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>What do we do?</h3>
                                                <p>As the problems and solutions are multifaceted, our platform collects research and solutions from all industry verticals and scientific fields. AISquare is a research hub that fosters diverse and innovative ideas that arise from both the private and public sector, inclusive of government and nonprofit entities.</p>
                                                <p>Our network showcases and promotes researchers and their work, creating a collaborative global platform for dynamic conversation in order to drive societal change. It is a vibrant and thought-provoking host that encourages the exchange of ideas. AISquare serves as a resource for professionals and researchers to find existing solutions and proposals.</p>
                                                <p>Starting with COVID19, our regional correspondents across the world will be able to report via multimedia blog posts on local publications, enriching our platform with global research, sparking engaging conversations and innovation. The curated AISquare blog posts make complex solutions easily accessible and comprehensible, making it quicker to locate and innovate solutions to the pressing issues of our world.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data-item-faq">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>Why did we start AIS?</h3>
                                                <p>AISquare is a solution built in response to the COVID19 pandemic, with a vision to create a ‘source of truth’ platform for all research and a network of experts, to collaborate and respond at scale to the current and future, local and global issues. Too many researchers  and people do not have one place to go and constantly have to reinvent the wheel when  it comes to starting their own research. AISquare aims to eliminate that inefficiency by being  a one-stop place where everyone can get all the research they need from all different sectors to help solve pressing issues in a timely and thorough manner. </p>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div className="data-item-faq">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>How do we work?</h3>
                                                <p>Our regional correspondents across the world will be able to report via multimedia blog posts, podcasts, or YouTube videos, on local publications, enriching our platform with global research, sparking engaging conversations and innovation. The curated AISquare blog posts make complex solutions easily accessible and comprehensible, making it quicker to locate and innovate solutions to the pressing issues of our world. If people find research compelling, they can also choose to donate through our website, or promote the correspondent who reported on the research by liking/sharing the post.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data-item-faq">
                                        <div className="data-item-inner card">
                                            <div className="card-body">
                                                <h3>How can you participate: student correspondent, researcher and curious mind?</h3>
                                                <p>There are multiple ways to engage with AiSquare—whether you are an established researcher, or even a curious student. First, researchers can sign onto our platform through a ‘researcher’ account to interact with posts and even publish original content themselves. Student correspondents can also create their own account to be able to post on exciting innovations and research. And finally, if you fall into neither category, you can still engage with AiSquare through our ‘curious mind’ account! This is the account through which the general public will be able to browse AiSquare.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FAQPage