import React from 'react';
import moment from 'moment';
import renderHTML from 'react-render-html';
import FilePreview from "./message-file-preview";


var userDefault = require('../../Assets/img/user-default.jpg')

export default class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
            userRef: props.userRef,
            isOwnMessage: (props.message.user && props.message.user.id === props.userRef.uid) ? "message-right" :"message-left",
            user_avatar: (props.message.user && props.message.user.id === props.userRef.uid) ? ((props.userRef.avatar)?props.userRef.avatar:userDefault) : ((props.senderRef.avatar)?props.senderRef.avatar:userDefault)
        };
    }

    render() {
        const {message, isOwnMessage, user_avatar}=this.state;
        // console.log('message');
        // console.log(message);
        // console.log(message.timestamp.toDate());
        return (
            <div className="message-item-outer">
                <div className={"message-item "+ isOwnMessage}>
                    {/*<div className="message-left"><div className="thumb user-thumb"><img src={user_avatar} /> </div></div>*/}
                    <div className="message-text-view">
                        {(message.files && message.files.length > 0)?(
                            <div className="message-file">
                            {
                                message.files.map((file,fi)=>{
                                    return(<FilePreview key={fi} file={file} />)
                                })
                            }
                            </div>
                        ):null}
                       {message.text? <div className="message-text"> {renderHTML(message.text) }</div>:('') }
                        <div className="date">{moment(message.timestamp.toDate()).format('h:mm A')}</div>
                    </div>
                </div>
            </div>
        );
    }
}
